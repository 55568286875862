import React from 'react'

import { Typography, Button } from '@material-ui/core'
import { BRAND_LOGO, PINK_PENCIL } from '../../../constants/image-reference'
import { getUserType } from '../../../utils/string.utils'
import { HH, ADHOC } from '../../../constants/misc'

import './recommendation-header.component.scss'

export default function RecommendationHeader (props) {
  const {
    t, profileCount, entity, positions, positionClickCallback, hasViewOnlyAccess, userSession, recomProfilesCount,
    recomProfiles, recommendedProfiles
  } = props
  const userType = getUserType(userSession)
  const isHhOrAdHocUser = (userType === HH || userType === ADHOC)

  const handlePositionClick = () => {
    positionClickCallback()
  }

  const getRecomProfilePosition = () => {
    let position = ''
    if (recomProfiles && recomProfiles.length > 0) {
      position = recomProfiles[0].recommendation.position ? recomProfiles[0].recommendation.position.positionAr : ''
    }
    return position
  }

  const onEditClick = () => {
    props.history.push('/recommendation')
  }

  const isRecommendationResultScreen = () => {
    const { url } = props.match
    if (url === '/position-recommendation-results' || url === '/board-recommendation-results') {
      return true
    }
    return false
  }

  const renderMainHeading = () => {
    if (isHhOrAdHocUser) {
      return (
        <Typography className='total-candidate'>
          {t('header.candidatesFound', { total: recomProfilesCount || 0, position: getRecomProfilePosition() })}
        </Typography>
      )
    }

    if (!isRecommendationResultScreen()) {
      return (
        <Typography className='candidate-count-heading'>
          {t('header.candidatesFound', { total: recommendedProfiles.length || 0 })}
        </Typography>
      )
    }

    return (
      <Typography className='total-candidate'>
        {t('header.totalCandidatesFor', { total: profileCount || recomProfiles.length || 0 })}
      </Typography>
    )
  }

  return (
    <div className='recommendation-header-container'>
      <div className='content-wrapper'>
        <img src={BRAND_LOGO} alt='Brand logo' className='brand-logo' />
        <div className='heading-wrapper'>
          <Typography className='heading'>ترشيـحـات</Typography>
          <div className='sub-heading-wrapper'>
            {renderMainHeading()}
            {!isHhOrAdHocUser && (
              <Typography className='total-position' onClick={handlePositionClick}>
                {t('header.totalPositions', { total: positions ? positions.length : 0 })}
              </Typography>)}
            {!isHhOrAdHocUser && (
              <Typography className='entity'>
                {t('header.atTheEntity', { entity: entity ? entity.label : '' })}
              </Typography>)}
            {!hasViewOnlyAccess && (
              <Button
                onClick={onEditClick}
                variant='outlined'
                className='edit-button'
                classes={{ root: 'edit-button', startIcon: 'edit-icon' }}
                startIcon={<img src={PINK_PENCIL} alt='Edit' />}
              >
                <Typography className='edit-button-text'>{t('common.edit')}</Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
