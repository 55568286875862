import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { LEFT_ARROW_CIRCLE } from '../../../constants/image-reference'
import { getRecom, loadMoreRecom, clearRecomVar } from '../../recommendation/recommendation.action-creator'

import classNames from 'classnames'
import Loader from '../../../common/loader/loader.component'
import InsightCard from '../../../common/insight-card/insight-card.component'

import './archive-insight.component.scss'

function ArchiveRecom (props) {
  const { recom, isLoading, t, recomCount, match } = props

  const [tabIndex, setTabIndex] = useState(0)

  // component mount
  useEffect(() => {
    const { tabIndex } = match.params
    setTabIndex(parseInt(tabIndex))
    fetchRecom(tabIndex)
  }, [])

  const fetchRecom = (tab) => {
    props.getRecom({
      queryParams: {
        limit: 5,
        offset: 0,
        ...getTabQueryParams(tab)
      }
    })
  }

  const loadMoreRecom = (tab) => {
    const { recom } = props
    props.loadMoreRecom({
      queryParams: {
        limit: 5,
        offset: recom && recom.length ? recom.length : 0,
        ...getTabQueryParams(tab)
      }
    })
  }

  const tabNavigate = (index) => {
    props.clearRecomVar()
    setTabIndex(index)
    fetchRecom(index)
  }

  const navigateBack = () => {
    props.history.push('/sent-insight')
  }

  const getTabQueryParams = (tab) => {
    if (tab === 0) {
      return { isDeleted: true }
    } else if (tab === 1) {
      return { isSent: false, isDeleted: false }
    }
    return { isDeleted: false }
  }

  const renderRecomList = () => {
    return (
      <div className='archive-recom-list'>
        {recom.map((data, index) => (
          <InsightCard
            {...props}
            index={index}
            key={index}
            sentRecomPage={tabIndex !== 0}
            recomDetails={data}
            onClickCallback={() => console.log('click')}
          />
        ))}
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>
          {t('recommendation.noRecommendationsFound')}
        </Typography>
      </div>
    )
  }

  const renderLoadMoreButton = () => {
    return (
      <div className='pagination'>
        <Button onClick={loadMoreRecom} className='load-more-button' variant='outlined' color='secondary'>
          {t('common.loadMore')}
        </Button>
      </div>
    )
  }

  return (
    <div className='archive-insight-container'>
      <div className='back-button' onClick={navigateBack}>
        <img src={LEFT_ARROW_CIRCLE} alt='Back' className='back-icon' />
      </div>
      <div className='archive-recom'>
        <div className='header-container'>
          <Typography className='title'>{t('recommendation.archive')}</Typography>
        </div>
        <div className='tab-view'>
          <div className='tabs'>
            <Button
              onClick={() => tabNavigate(0)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 0
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.deleted')}
            </Button>
            <Button
              onClick={() => tabNavigate(1)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 1
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.drafts')}
            </Button>
            <Button
              onClick={() => tabNavigate(2)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 2
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.all')}
            </Button>
          </div>
        </div>
        {(recom && recom.length) ? renderRecomList() : isLoading ? <Loader color='white' /> : renderEmptyState()}
        {recom && recom.length && recom.length !== recomCount ? renderLoadMoreButton() : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // Recommendation
    recom: state.recommendation.recom,
    isLoading: state.recommendation.recomLoading,
    recomCount: state.recommendation.recomCount
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, { getRecom, loadMoreRecom, clearRecomVar })(ArchiveRecom))
