import React, { useState, useEffect } from 'react'
import { Typography, Button } from '@material-ui/core'
import { PROFILE_CIRCLE, AVATAR } from '../../constants/image-reference'
import { imageBaseUrl } from '../../utils/string.utils'

import classNames from 'classnames'

import './tier-visualization.component.scss'

const tierCircleSize = { first: 140, second: 280, third: 420, fourth: 550 }

export default function TierVisualization (props) {
  console.log('^^^^^^^^', props);
  const { t, visualData, profileNameVisible } = props

  const [visualPoints, setVisualPoints] = useState('')
  const [emptyState, setEmptyState] = useState(false)
  const [showVisual, setShowVisual] = useState(false)
  const [tierStartPoint, setTierStartPoint] = useState({})

  const getProfilePoints = (noOfPoints, radius, tierName) => {
    var intialOffset
    if (tierStartPoint[tierName]) {
      intialOffset = tierStartPoint[tierName]
    } else {
      intialOffset = 2 * Math.PI * Math.random()
      setTierStartPoint({
        ...tierStartPoint,
        [tierName]: intialOffset
      })
    }
    var points = []
    var slice = 2 * Math.PI / noOfPoints
    for (var i = 0; i < noOfPoints; i++) {
      var angle = slice * i + intialOffset
      var newX = radius + radius * Math.cos(angle)
      var newY = radius + radius * Math.sin(angle)
      points.push({
        x: newX,
        y: newY
      })
    }
    return points
  }

  const getMarkerPoints = (noOfPoints, radius) => {
    var points = []
    var currentRadius
    setShowVisual(false)
    for (var i = 0; i < noOfPoints; i++) {
      currentRadius = radius + ((Math.random() * 15) * (Math.random() < 0.5 ? -1 : 1))
      var angle = Math.random() * 2 * Math.PI
      var newX, newY
      var circleQuarters = [0, Math.PI / 2, Math.PI, Math.PI * 1.5, Math.PI * 2]
      const randomOffset = (Math.random() * 10)
      newX = currentRadius * Math.cos(angle) + radius
      newY = currentRadius * Math.sin(angle) + radius
      if (angle >= circleQuarters[0] && angle < circleQuarters[1]) {
        newX = newX + randomOffset
        newY = newY + randomOffset
      } else if (angle >= circleQuarters[1] && angle < circleQuarters[2]) {
        newX = newX - randomOffset
        newY = newY + randomOffset
      } else if (angle >= circleQuarters[2] && angle < circleQuarters[3]) {
        newX = newX - randomOffset
        newY = newY - randomOffset
      } else {
        newX = newX + randomOffset
        newY = newY - randomOffset
      }
      points.push({
        x: Math.floor(newX),
        y: Math.floor(newY),
        size: Math.floor(Math.random() * 4) + 2,
        radius: currentRadius,
        angle: angle
      })
    }
    return points
  }

  useEffect(() => {
    const { visualDetails } = visualData
    const visualPointsCopy = visualDetails.map(data => {
      const { profiles, profilesCount, tierName } = data
      var profilePoints = getProfilePoints(profiles.length, tierCircleSize[tierName] / 2, tierName)
      var markerPoints = getMarkerPoints(profilesCount, tierCircleSize[tierName] / 2)
      return {
        diameter: tierCircleSize[tierName],
        profilePoints,
        markerPoints
      }
    })
    console.log('visualPointsCopy...', visualPointsCopy)
    setVisualPoints(visualPointsCopy)
    setTimeout(() => {
      setShowVisual(true)
    }, 500)
  }, [visualData])

  useEffect(() => {
    if (visualPoints && visualPoints.length) {
      let emptyState = true
      for (let Indx = 0; Indx < visualPoints.length; Indx++) {
        const markerPoints = visualPoints[Indx].markerPoints
        if (markerPoints && markerPoints.length) {
          emptyState = false
          break
        }
      }
      setEmptyState(emptyState)
    }
  }, [visualPoints])

  const renderEmptyStateView = () => {
    return (
      <div className='empty-state-wrapper'>
        <Typography className='empty-state-text'>{t('insights.noResultsFoundForFilters')}</Typography>
      </div>
    )
  }

  return (
    <div
      className={classNames(['tier-visualization-container', showVisual ? 'show-visual' : 'hide-visual'])}
      style={{
        display: emptyState ? 'flex' : '',
        alignItems: emptyState ? 'center' : '',
        justifyContent: emptyState ? 'center' : '',
        height: emptyState ? '600px' : ''
      }}
    >
      {!emptyState && visualPoints ? visualPoints.map((tier, visualIndx) => (
        <div
          key={visualIndx}
          className='tier-circle'
          style={{
            width: tier.diameter,
            height: tier.diameter,
            borderRadius: tier.diameter / 2,
            top: ((tierCircleSize.fourth - tier.diameter) / 2),
            left: ((tierCircleSize.fourth - tier.diameter) / 2),
            zIndex: (profileNameVisible && tier.profilePoints.length) ? 600 : 550 - tier.diameter
          }}
        >
          {tier.markerPoints.map((markerPoint, dotIndex) => (
            <div
              key={dotIndex}
              className='profile-dot'
              style={{
                width: markerPoint.size,
                height: markerPoint.size,
                left: markerPoint.x,
                top: markerPoint.y,
                borderTopLeftRadius: 0.45 * markerPoint.size,
                borderTopRightRadius: 0.8 * markerPoint.size,
                borderBottomRightRadius: 0.45 * markerPoint.size
              }}
            />
          ))}
          {tier.profilePoints.map((profilePoint, profileIndx) => {
            const profile = visualData.visualDetails[visualIndx].profiles[profileIndx]
            const profileImagePath = (profile && profile.profileImage) ? profile.profileImage.url : null
            const isNetworkProfile = !!((profile && profile.isNetworkProfile))
            return (
              <div
                key={profileIndx}
                className='profile-cont'
                style={{
                  top: profilePoint.x,
                  left: profilePoint.y
                }}
                onClick={() => props.viewProfileCallback(profile.id)}
              >
                {!isNetworkProfile &&
                  <img
                    src={PROFILE_CIRCLE}
                    alt='Profile circle'
                    className='profile-circle-image'
                  />}
                {profileImagePath && (
                  <img
                    src={`${imageBaseUrl}${profileImagePath}`}
                    className={classNames(['profile-circle', {
                      'grayscale-filter': isNetworkProfile
                    }])}
                    alt=''
                    onerror="this.style.display='none'"
                  />)}
                {!profileImagePath && (
                  <img
                    src={AVATAR}
                    className={classNames(['profile-circle', {
                      'grayscale-filter': isNetworkProfile
                    }])}
                    alt='Profile circle'
                  />)}
                {profileNameVisible && <Typography className='user-name'>{profile ? profile.nameAr : ''}</Typography>}
                {profileNameVisible && <Button variant='contained' className='view-profile-btn'>عرض الملف</Button>}
              </div>
            )
          })}
        </div>
      )) : renderEmptyStateView()}
    </div>
  )
}
