import React, { useState, useEffect } from 'react'
import { Slider } from '@material-ui/core'
import { debounce } from 'lodash'

import PropTypes from 'prop-types'

import './range-slider.scss'

export default function RangeSlider (props) {
  const { color, handleChangeCallback, minValue, maxValue } = props
  const [value, setValue] = useState([20, 90])

  const valueChangeDelayedCallback = debounce((value) => handleChangeCallback(value), 500)

  useEffect(() => {
    if (typeof minValue === 'number' && typeof maxValue === 'number') {
      setValue([minValue, maxValue])
    }
  }, [])

  useEffect(() => {
    if (typeof minValue === 'string' && typeof maxValue === 'string') {
      setValue([20, 90])
    }
  }, [minValue, maxValue])

  const handleChange = (event, newValue) => {
    const newLowValue = newValue[0]
    const newHighValue = newValue[1]
    if (newLowValue < newHighValue) {
      valueChangeDelayedCallback(newValue)
      setValue(newValue)
    }
  }

  return (
    <div className='range-slider-container'>
      <Slider
        value={value}
        classes={{ root: 'range-slider' }}
        min={20}
        max={90}
        style={{ color: color }}
        onChange={handleChange}
        valueLabelDisplay='on'
        aria-labelledby='range-slider'
      />
    </div>
  )
}

RangeSlider.propTypes = {
  type: PropTypes.oneOf(['default', 'customized']),
  color: PropTypes.string.isRequired
}
