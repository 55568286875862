import React from 'react'
import { CircularProgress } from '@material-ui/core'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import './loader.component.scss'

export default function Loader (props) {
  const { color } = props
  return (
    <div className='loader-container'>
      <CircularProgress
        className={classNames(['loader', {
          'white-loader': color === 'white',
          'footer-loader': color === 'blue'
        }])}
        classes={{ root: 'loader' }}
      />
    </div>
  )
}

Loader.propTypes = {
  color: PropTypes.oneOf(['white', 'blue'])
}
