import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { LEFT_ARROW_CIRCLE } from '../../../constants/image-reference'
import { getRecom, loadMoreRecom, clearRecomVar, deleteRecom } from '../../recommendation/recommendation.action-creator'

import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../../common/loader/loader.component'
import RecomCard from '../../../common/recom-card/recom-card.component'

import './archive-recom.component.scss'

function ArchiveRecom (props) {
  const { recom, isLoading, t, recomCount, match } = props

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    const { tabIndex } = match.params
    setTabIndex(parseInt(tabIndex))
    fetchRecom(parseInt(tabIndex))
  }, [])

  const fetchRecom = (tab) => {
    props.getRecom({
      queryParams: {
        limit: 5,
        offset: 0,
        ...getTabQueryParams(tab)
      }
    })
  }

  const loadMoreRecom = (tab) => {
    const { recom } = props
    props.loadMoreRecom({
      queryParams: {
        limit: 5,
        offset: recom && recom.length ? recom.length : 0,
        ...getTabQueryParams(tab)
      }
    })
  }

  const deleteRecom = (recomId) => {
    props.deleteRecom({
      queryParams: {
        recommendationId: recomId
      },
      token: {
        token: `jwt ${props.token}`
      }
    })
  }

  const tabNavigate = (index) => {
    props.clearRecomVar()
    props.history.push(`/archive-recom/${index}`)
    setTabIndex(index)
    fetchRecom(index)
  }

  const navigateBack = () => {
    props.history.push('/sent-recommendation')
  }

  const getTabQueryParams = (tab) => {
    if (tab === 0) {
      return { isDeleted: true }
    } else if (tab === 1) {
      return { isSent: false, isDeleted: false }
    }
    return { isDeleted: false }
  }

  const renderRecomList = () => {
    const recomListLen = recom.length
    return (
      <InfiniteScroll
        scrollThreshold={0.75}
        dataLength={recomListLen}
        className='archive-recom-list'
        next={() => loadMoreRecom(tabIndex)}
        hasMore={recomCount > recomListLen}
        height='calc(100vh - 140px - 80px)'
        loader={(
          <div className='pagination'>
            <Loader color='white' />
          </div>
        )}
      >
        {recom.map((data, index) => (
          <RecomCard
            {...props}
            index={index}
            key={index}
            sentRecomPage={tabIndex !== 0}
            recomDetails={data}
            deleteRecomCallback={(recomId) => deleteRecom(recomId)}
            onClickCallback={() => console.log('click')}
          />
        ))}
      </InfiniteScroll>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>
          {t('recommendation.noRecommendationsFound')}
        </Typography>
      </div>
    )
  }

  return (
    <div className='archive-recom-container'>
      <div className='back-button' onClick={navigateBack}>
        <img src={LEFT_ARROW_CIRCLE} alt='Back' className='back-icon' />
      </div>
      <div className='archive-recom'>
        <div className='header-container'>
          <Typography className='title'>{t('recommendation.archive')}</Typography>
        </div>
        <div className='tab-view'>
          <div className='tabs'>
            <Button
              onClick={() => tabNavigate(0)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 0
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.deleted')}
            </Button>
            <Button
              onClick={() => tabNavigate(1)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 1
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.drafts')}
            </Button>
            <Button
              onClick={() => tabNavigate(2)}
              className={classNames(['tab-button', {
                'active-button': tabIndex === 2
              }])}
              variant='outlined' color='secondary'
            >
              {t('recommendation.all')}
            </Button>
          </div>
        </div>
        {(recom && recom.length) ? renderRecomList() : isLoading ? <Loader color='white' /> : renderEmptyState()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // Recommendation
    recom: state.recommendation.recom,
    isLoading: state.recommendation.recomLoading,
    recomCount: state.recommendation.recomCount
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    getRecom,
    loadMoreRecom,
    clearRecomVar,
    deleteRecom
  })(ArchiveRecom))
