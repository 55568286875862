import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography } from '@material-ui/core'
import { EDIT, DELETE, ENTITY_THUMBNAIL, TIER_THUMBNAIL } from '../../constants/image-reference'
import { setSelectedInsight } from '../../app/insights/insights.action-creator'
import { useSwipeable } from 'react-swipeable'
import { getUserType } from '../../utils/string.utils'
import { HH, ADHOC } from '../../constants/misc'

import AlertDialog from '../alert-dialog/alert-dialog.component'

import './insight-card.component.scss'

function InsightRecomCard (props) {
  const [expanded, setExpanded] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const { t, history, recomDetails, sentRecomPage, setSelectedInsight, userSession } = props
  const { title, insightType, id, headline } = recomDetails
  const userType = getUserType(userSession)

  const onSwipeRight = () => {
    if (sentRecomPage && (userType !== HH && userType !== ADHOC)) {
      setExpanded(true)
    }
  }

  const onSwipeLeft = () => {
    setExpanded(false)
  }

  const handleOnClick = () => {
    if (!expanded) {
      setSelectedInsight(recomDetails)
      history.push('/insights')
    }
  }

  const deleteRecom = () => {
    setExpanded(false)
    setShowConfirmModal(false)
    props.deleteInsightCallback(id)
  }

  const handleEditClick = () => {
    const insightId = id
    history.push(`/insights/${insightId}/edit`)
  }

  const renderAlertDialog = () => {
    return (
      <AlertDialog
        {...props}
        type='warning'
        buttonText={t('common.ok')}
        handleConfirm={deleteRecom}
        handleClose={() => setShowConfirmModal(false)}
      >
        {t('recommendation.areYouSureWantToDelete')}
      </AlertDialog>
    )
  }

  const handlers = useSwipeable({
    onSwipedRight: () => onSwipeRight(),
    onSwipedLeft: () => onSwipeLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <div className='insight-card-container' style={{ width: expanded ? '100%' : '60%' }}>
      <div className='recom-card' {...handlers}>
        <div className='recom-details' onClick={handleOnClick}>
          <Typography className='title'>{t('insights.analyzes')}</Typography>
          <Typography className='position'>{headline || ''}</Typography>
        </div>
        <div
          onClick={handleOnClick}
          className='insight-thumbnail'
          style={{ backgroundImage: `url(${insightType === 'entity' ? ENTITY_THUMBNAIL : TIER_THUMBNAIL})` }}
        />
      </div>
      {expanded && (
        <div className='recom-action'>
          <div className='action-button'>
            <img src={EDIT} alt='Edit' className='edit-icon' onClick={handleEditClick} />
            <Typography className='action-text'>{t('recommendation.edit')}</Typography>
          </div>
          <div className='action-button'>
            <img src={DELETE} alt='Delete' className='delete-icon' onClick={() => setShowConfirmModal(true)} />
            <Typography className='action-text'>{t('recommendation.delete')}</Typography>
          </div>
        </div>)}
      {showConfirmModal && renderAlertDialog()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, { setSelectedInsight })(InsightRecomCard))
