import React, { useState, useEffect, useRef } from 'react'
import { TextField, Typography, InputAdornment } from '@material-ui/core'
import { CARET_DOWN, CARET_DOWN_WHITE, DOWN_ARROW_GREY } from '../../constants/image-reference'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import './search-select-input.scss'

function SearchSelectInput (props) {
  const wrapperRef = useRef(null)
  const [input, setInput] = useState('')
  const [searchResults, setSearchResult] = useState(false)
  const [focused, setFocus] = useState(false)

  const { options, disabled, value, type, placeholder, showAddEntityLink, showAddPositionLink } = props
  const { t, handleChange, handleSearch, addEntityCallback, addPositionCallback, recomView } = props

  useEffect(() => {
    if (options) {
      if (focused) {
        showResults()
      }
    }
  }, [options, focused])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideResults()
    }
  }

  const onChange = event => {
    const searchQuery = event.target.value
    setInput(searchQuery)
    if (handleSearch) {
      handleSearch(searchQuery)
    }
  }

  const handleOptionClick = (option) => {
    setInput('')
    hideResults()
    unFocusInput()
    handleChange(option)
  }

  const showResults = () => {
    setSearchResult(true)
  }

  const hideResults = () => {
    setSearchResult(false)
    unFocusInput()
  }

  const focusInput = () => {
    setFocus(true)
    showResults()
  }

  const unFocusInput = () => {
    setFocus(false)
  }

  const handleAddEntityClick = () => {
    addEntityCallback()
    hideResults()
    unFocusInput()
  }

  const handleAddPositionClick = () => {
    addPositionCallback()
    hideResults()
    unFocusInput()
  }

  const renderSearchOptions = () => {
    if (options && options.length) {
      return (
        <div className='search-result-container'>
          {showAddEntityLink && renderAddEntityLink()}
          {showAddPositionLink && renderAddPositionLink()}
          <div className='result-wrapper'>
            {options.map(option => (
              <Typography
                key={option.value}
                className='search-option'
                onClick={() => handleOptionClick(option)}
              >{option.label}
              </Typography>
            ))}
          </div>
        </div>
      )
    }
    return renderEmptyState()
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        {showAddEntityLink && renderAddEntityLink()}
        {showAddPositionLink && renderAddPositionLink()}
        <Typography className='no-option-found-text'>لا توجد خيارات</Typography>
      </div>
    )
  }

  const renderAddEntityLink = () => {
    return (
      <div className='add-link-wrapper'>
        <Typography className='add-info-text'>{t('recommendation.addEntityInfoText')}</Typography>
        <Typography className='hyperlink' onClick={handleAddEntityClick}>{t('recommendation.addEntity')}</Typography>
      </div>
    )
  }

  const renderAddPositionLink = () => {
    return (
      <div className='add-link-wrapper'>
        <Typography className='add-info-text'>{t('recommendation.addPositionInfoText')}</Typography>
        <Typography className='hyperlink' onClick={handleAddPositionClick}>{t('recommendation.addPosition')}</Typography>
      </div>
    )
  }

  const getValue = () => {
    let searchInputValue = ''
    if (focused) {
      searchInputValue = input
    } else {
      if (typeof value === 'number') {
        searchInputValue = (options && options.length) ? options.find(x => x.id === value).label : value.label
      } else {
        searchInputValue = value.label
      }
    }
    return searchInputValue
  }

  return (
    <div
      className={classNames(['search-select-input-container', {
        'white-border-dropdown': (type === 'whiteBorderDropdown'),
        'blue-border-dropdown': (type === 'blueBorderDropdown'),
        'disabled-border-dropdown': disabled,
        'recom-dropdown': recomView
      }])}
      ref={wrapperRef}
    >
      <TextField
        className='search-select-input'
        margin='normal'
        variant='outlined'
        fullWidth
        onChange={onChange}
        value={getValue()}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' classes={{ root: 'input-icon' }}>
              {(type === 'blueBorderDropdown') && !disabled && <img src={CARET_DOWN} className='caret-icon' alt='' />}
              {(type === 'whiteBorderDropdown') && !disabled && <img src={CARET_DOWN_WHITE} className='caret-icon' alt='' />}
              {disabled && <img src={DOWN_ARROW_GREY} className='caret-icon' alt='' />}
            </InputAdornment>
          )
        }}
        onFocus={focusInput}
      />
      {searchResults ? renderSearchOptions() : null}
    </div>
  )
}

export default SearchSelectInput

SearchSelectInput.propTypes = {
  type: PropTypes.oneOf(['blueBorderDropdown', 'whiteBorderDropdown'])
}
