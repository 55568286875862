import React, { useState, useRef, useEffect } from 'react'
import { Typography, TextField } from '@material-ui/core'
import { BLUE_PENCIL } from '../../../../constants/image-reference'

import './header.component.scss'

export default function NewInsight (props) {
  const { t, title, onChangeCallback } = props
  const wrapperRef = useRef(null)
  const [titleView, setTitleView] = useState(true)
  const [insightTitle, setInsightTitle] = useState(title.value)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideTitleEditor()
      // setInsightTitle(oldTitle => {
      //   onChangeCallback(oldTitle, 'title')
      //   return insightTitle
      // })
      // hideTitleEditor()
    }
  }

  const hideTitleEditor = () => {
    setTitleView(true)
  }

  const handleOnChange = (event) => {
    const text = event.target.value
    setInsightTitle(text)
    onChangeCallback(text, 'title')
  }

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleTitleView()
      onChangeCallback(insightTitle, 'title')
    }
  }

  const toggleTitleView = () => {
    setTitleView(!titleView)
  }

  const showEditorView = () => {
    toggleTitleView()
    setInsightTitle(title.value)
  }

  const renderTitleView = () => {
    return (
      <div className='view-text-wrapper'>
        <Typography className='view-text'>
          {title.value.length ? title.value : t('insights.viewTitle')}
        </Typography>
        <img src={BLUE_PENCIL} className='edit-icon' alt='Edit' onClick={showEditorView} />
      </div>
    )
  }

  const renderEditTitleView = () => {
    return (
      <div className='title-input-wrapper'>
        <TextField
          ref={wrapperRef}
          className='title-input'
          margin='normal'
          variant='outlined'
          placeholder={t('insights.editAndDisplayName')}
          fullWidth
          autoFocus
          value={insightTitle}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
        />
      </div>
    )
  }

  return (
    <div className='header'>
      {titleView ? renderTitleView() : renderEditTitleView()}
    </div>
  )
}
