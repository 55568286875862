import React, { useState, useEffect, useRef } from 'react'
import { SEARCH_ICON, CLOSE_WHITE } from '../../../constants/image-reference'
import { Button, TextField, Typography } from '@material-ui/core'
import { deepCopy } from '../../../utils/object.utils'
import { debounce } from 'lodash'

import TierVisualization from '../../../common/tier-visualization/tier-visualization.component'

import './insight-search.component.scss'

export default function InsightSearch (props) {
  const inputRef = useRef(null)
  const wrapperRef = useRef(null)
  const [searchResults, setSearchResult] = useState(false)
  const [focused, setFocus] = useState(false)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [visualData, setVisualData] = useState({
    insightType: 'tier',
    visualDetails: []
  })

  const {
    t, history, addInsightCallback, getTierInsightCallback, fetchProfileListCallback,
    showSearchedProfileCallback, setPreviousPathCallback
  } = props
  const { isHhOrAdHocUser, tiersProfileCount, options, showSearchedProfileView, insightList } = props

  const delayedCallback = debounce(value => fetchProfileList(value), 500)

  useEffect(() => {
    if (options) {
      if (focused) {
        showResults()
      }
    }
  }, [options, focused])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (tiersProfileCount && tiersProfileCount.length) {
      initializeTierVisualization()
    }
  }, [tiersProfileCount])

  const init = () => {
    getTierInsightCallback()
    fetchProfileList()
  }

  const initializeTierVisualization = () => {
    const visualDataCopy = deepCopy(visualData)
    const visualDataArray = []
    tiersProfileCount.map(data => {
      const results = {
        tierId: data.tier.id,
        tierName: data.tier.nameEn,
        profilesCount: data.profilesCount,
        profiles: []
      }
      visualDataArray.push(results)
    })
    visualDataCopy.visualDetails = visualDataArray
    setVisualData(visualDataCopy)
  }

  const fetchProfileList = (value) => {
    fetchProfileListCallback(value)
  }

  const onProfileSelect = (profile) => {
    const visualDataCopy = deepCopy(visualData)
    const tierIds = visualDataCopy.visualDetails.map((data, index) => {
      visualDataCopy.visualDetails[index].profiles = []
      return data.tierId
    })
    if (profile.tier && tierIds.includes(profile.tier.id)) {
      const tierIndex = tierIds.indexOf(profile.tier.id)
      visualDataCopy.visualDetails[tierIndex].profiles.push(profile)
      setVisualData(visualDataCopy)

      setSelectedProfiles([profile.id])
    }
    setVisualData(visualDataCopy)
  }

  const handleSearchInputIconClick = () => {
    const parentEle = inputRef.current.children[0]
    const inputEle = parentEle.children[0]
    inputEle.focus()
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      hideResults()
    }
  }

  const handleOnChange = event => {
    const searchQuery = event.target.value
    setSearchQuery(searchQuery)
    delayedCallback(searchQuery)
  }

  const handleOptionClick = (option) => {
    setSearchQuery(option.label)
    hideResults()
    unFocusInput()
    onProfileSelect(option.profile)
  }

  const showResults = () => {
    setSearchResult(true)
  }

  const hideResults = () => {
    setSearchResult(false)
  }

  const focusInput = () => {
    setFocus(true)
  }

  const unFocusInput = () => {
    setFocus(false)
  }

  const handleViewProfile = (profileId) => {
    setPreviousPathCallback()
    history.push(`/profile-details/${profileId}`)
  }

  const handleClearSearch = () => {
    if (insightList.length) {
      showSearchedProfileCallback()
    } else {
      setSearchQuery('')
      setSelectedProfiles([])
      initializeTierVisualization()
    }
  }

  const renderTierVisualization = () => {
    return (
      <TierVisualization
        {...props}
        profileNameVisible
        visualData={visualData}
        viewProfileCallback={(profileId) => handleViewProfile(profileId)}
      />
    )
  }

  const renderSearchInputIcon = () => {
    return (
      <div className='placeholder-wrapper' onClick={handleSearchInputIconClick}>
        <img src={SEARCH_ICON} alt='Search profiles' className='search-input-icon' />
        <Typography className='placeholder-text'>تصنيف</Typography>
      </div>
    )
  }

  const renderSearchOptions = () => {
    if (options && options.length) {
      return (
        <div className='search-result-container'>
          <div className='result-wrapper'>
            {options.map(option => (
              <Typography
                key={option.id}
                className='search-option'
                onClick={() => handleOptionClick(option)}
              >{option.label}
              </Typography>
            ))}
          </div>
        </div>
      )
    }
    return renderEmptyState()
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='no-option-found-text'>لا توجد خيارات</Typography>
      </div>
    )
  }

  const renderCloseIcon = () => {
    return (
      <img
        src={CLOSE_WHITE}
        alt='Clear search'
        className='clear-search-icon'
        onClick={handleClearSearch}
      />
    )
  }

  return (
    <div className='insight-search-container'>
      <div className='search-wrapper'>
        <div className='input-wrapper' ref={wrapperRef}>
          <TextField
            fullWidth
            className='search-input'
            margin='normal'
            variant='outlined'
            value={searchQuery}
            onChange={handleOnChange}
            ref={inputRef}
            onFocus={focusInput}
          />
          {(searchQuery.length === 0) && renderSearchInputIcon()}
          {searchQuery.length ? renderCloseIcon() : null}
          {searchResults ? renderSearchOptions() : null}
        </div>
      </div>
      <div className='tier-visualization-wrapper'>
        {renderTierVisualization()}
        {!isHhOrAdHocUser && !selectedProfiles.length && !showSearchedProfileView &&
          <Button variant='outlined' className='add-insight-button' onClick={addInsightCallback}>
            {t('insights.addInsight')}
          </Button>}
      </div>
    </div>
  )
}
