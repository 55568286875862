import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { deepCopy, clearObjectValues, assignObjectValues } from '../../../../utils/object.utils'
import { Typography, TextField, Slide } from '@material-ui/core'
import { SEARCH_BLUE, WHITE_CLOSE } from '../../../../constants/image-reference'

import SelectInput from '../../../../common/select-input/select-input'
import RadioButton from '../../../../common/radio-button/radio-button.component'
import RangeSlider from '../../../../common/range-slider/range-slider'

import CustomChip from '../../../../common/chip/chip.component'

import './filters.component.scss'

const filterTypes = [
  { id: 1, label: 'أضف تصنيف', value: 'filter' },
  { id: 2, label: 'أضف قائد', value: 'leader' }
]

export default function TabTwoFilters (props) {
  const inputRef = useRef(null)
  const [values, setValues] = useState({
    tier: { type: 'text', value: '', isRequired: true },
    ageMin: { type: 'number', value: '', isRequired: true },
    ageMax: { type: 'number', value: '', isRequired: true },
    educationLevel: { type: 'text', value: [], isRequired: true },
    gender: { type: 'text', value: '', isRequired: true }
  })
  const [leaderValues, setLeaderValues] = useState({
    leaders: { type: 'text', value: [], isRequired: true }
  })
  const [searchResults, setSearchResult] = useState(false)
  const [focused, setFocus] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const delayedCallback = debounce(value => fetchProfileList(value), 500)

  const {
    t, filterType, profileList, filterOptions, leaderIds, tierList, insightType, entityIds, educationLevelList,
    genderList, dropdownChangeCallback, fetchProfileListCallback, fetchTierListCallback, activeIndex, activeDirection,
    fetchEducationLevelListCallback, fetchGenderListCallback, onChangeCallback, showErrorDialogCallback
  } = props
  const { tier, educationLevel, gender, ageMin, ageMax } = values
  const { leaders } = leaderValues

  useEffect(() => {
    populateValues()
    fetchTierListCallback()
    fetchEducationLevelListCallback()
    fetchGenderListCallback()

    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      hideResults()
    }
  }

  useEffect(() => {
    if (profileList) {
      if (focused) {
        showResults()
      }
    }
  }, [profileList, focused])

  const populateValues = () => {
    if (filterType.value === 'filter') {
      const fieldsCopy = assignObjectValues(values, filterOptions.value)
      setValues(fieldsCopy)
    } else if (filterType.value === 'leader') {
      const fieldsCopy = deepCopy(leaderValues)
      fieldsCopy.leaders.value = leaderIds.value
      setLeaderValues(fieldsCopy)
    }
  }

  const onDropdownChangeHandler = (value, key) => {
    dropdownChangeCallback(value, key)
    // filter values
    const fieldsCopy = clearObjectValues(values)
    setValues(fieldsCopy)
    // leader ids
    const leaderFieldsCopy = deepCopy(leaderValues)
    leaderFieldsCopy.leaders.value = []
    setLeaderValues(leaderFieldsCopy)
    delayedCallback('')
  }

  const fetchProfileList = (value) => {
    let params = {}
    if (insightType.value === 'entity') {
      params = { organizationIds: entityIds.value.join(',') }
    }
    fetchProfileListCallback({
      search: value,
      limit: 10,
      isPublished: true,
      offset: 0,
      ...params
    })
  }

  const isEduSelected = (value) => {
    let isSelected = false
    educationLevel.value.map(data => {
      if (data === value) {
        isSelected = true
      }
    })
    return isSelected
  }

  const handleRemoveAttribute = (leaderId, key, index) => {
    const fieldsCopy = deepCopy(leaderValues)
    fieldsCopy[key].value.splice(index, 1)
    onChangeCallback(fieldsCopy[key].value, 'leaderIds')
    setLeaderValues(fieldsCopy)
  }

  const onChangeHandler = (field, key) => {
    const fieldsCopy = deepCopy(values)
    if (key === 'educationLevel') {
      let index = ''
      let isSelected = false
      for (let Indx = 0; Indx < educationLevel.value.length; Indx++) {
        const educationLevelId = educationLevel.value[Indx]
        if (educationLevelId === field) {
          index = Indx
          isSelected = true
          break
        }
      }
      if (isSelected) {
        fieldsCopy[key].value.splice(index, 1)
      } else {
        fieldsCopy[key].value.push(field)
      }
    } else if (key === 'ageMin') {
      fieldsCopy.ageMin.value = field[0]
      fieldsCopy.ageMax.value = field[1]
    } else {
      fieldsCopy[key].value = field
    }

    onChangeCallback(constructRequestBody(fieldsCopy), 'filterOptions')
    setValues(fieldsCopy)
  }

  const removeHandler = (key) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy[key].value = ''
    onChangeCallback(constructRequestBody(fieldsCopy), 'filterOptions')
    setValues(fieldsCopy)
  }

  const constructRequestBody = (fields = {}) => {
    const params = {}
    for (const key in fields) {
      if (fields[key].value) {
        params[key] = fields[key].value
      }
    }
    return params
  }

  const onChangeLeaderValue = (field, key) => {
    setSearchQuery('')
    hideResults()
    unFocusInput()
    const errorMsg = t('insights.maxLeadersError')
    const fieldsCopy = deepCopy(leaderValues)
    if (fieldsCopy[key].value.length < 4) {
      fieldsCopy[key].value.push(field)
      setLeaderValues(fieldsCopy)
      onChangeCallback(fieldsCopy.leaders.value, 'leaderIds')
    } else {
      showErrorDialogCallback(errorMsg)
    }
  }

  const handleSearch = (event) => {
    const searchQuery = event.target.value
    setSearchQuery(searchQuery)
    delayedCallback(searchQuery)
  }

  const showResults = () => {
    setSearchResult(true)
  }

  const hideResults = () => {
    setSearchResult(false)
    unFocusInput()
  }

  const focusInput = () => {
    setFocus(true)
  }

  const unFocusInput = () => {
    setFocus(false)
  }

  const tierOptions = tierList && tierList.map(tier => {
    return { id: tier.id, label: tier.nameAr, value: tier.id }
  })

  const eduLevelOptions = educationLevelList && educationLevelList.map(eduLevel => {
    return { id: eduLevel.id, label: eduLevel.nameEn, value: eduLevel.id }
  })

  const genderOptions = genderList && genderList.map(gender => {
    return { id: gender.id, label: gender.nameAr, value: gender.id }
  })
  if (genderList && genderList.length) {
    genderOptions.push({ label: 'الـكـل', value: 'الكل' })
  }

  const renderFilterView = () => {
    return (
      <div>
        <div className='filter-wrapper'>
          <Typography className='filter-name'>{t('common.tier')}</Typography>
          <div className='tier-options-wrapper'>
            {(tierOptions && tierOptions.length) && tierOptions.map((option) => (
              <RadioButton
                key={option.id}
                value={option.value}
                label={option.label}
                activeButtonValue={tier.value}
                handleChange={value => onChangeHandler(value, 'tier')}
                removeCallback={() => removeHandler('tier')}
              />
            ))}
          </div>
        </div>
        <div className='filter-wrapper'>
          <Typography className='filter-name'>{t('common.ageRange')}</Typography>
          <RangeSlider
            type='default'
            color='#001c47'
            step={1}
            minValue={ageMin.value}
            maxValue={ageMax.value}
            handleChangeCallback={(value) => onChangeHandler(value, 'ageMin')}
          />
        </div>
        <div className='filter-wrapper'>
          <Typography className='filter-name'>{t('common.gender')}</Typography>
          <div className='gender-options-wrapper'>
            {genderOptions.map((option, index) => (
              <RadioButton
                key={index}
                value={option.value}
                label={option.label}
                activeButtonValue={gender.value}
                handleChange={value => onChangeHandler(value, 'gender')}
                removeCallback={() => removeHandler('gender')}
              />
            ))}
          </div>
        </div>
        <div className='filter-wrapper'>
          <Typography className='filter-name'>{t('common.educationLevel')}</Typography>
          <div className='education-filter-wrapper' style={{ padding: '0px' }}>
            {eduLevelOptions && eduLevelOptions.length
              ? (
                <div className='search-results-wrapper' style={{ overflowY: 'hidden' }}>
                  {eduLevelOptions.map((eduLevel) => renderEducationLevel(eduLevel))}
                </div>
              )
              : renderEmptyState()}
          </div>
        </div>
      </div>
    )
  }

  const renderEducationLevel = (eduLevel) => {
    return (
      <div key={eduLevel.id} className='search-result'>
        <Typography className='label'>{eduLevel.label}</Typography>
        <span className='org-checkbox' onClick={() => onChangeHandler(eduLevel.value, 'educationLevel')}>
          <input
            type='checkbox'
            checked={isEduSelected(eduLevel.value)}
            onChange={() => onChangeHandler(eduLevel.id, 'educationLevel')}
          />
          <span>
            <span className='checkmark' />
          </span>
        </span>
      </div>
    )
  }

  const renderLeaderView = () => {
    return (
      <div className='leader-filter-wrapper'>
        <div className='search-input-wrapper'>
          <TextField
            fullWidth
            ref={inputRef}
            className='search-input'
            margin='normal'
            variant='outlined'
            value={searchQuery}
            onChange={handleSearch}
            onFocus={focusInput}
          />
          {(searchQuery.length === 0) && renderSearchInputIcon()}
          {searchResults ? renderSearchOptions() : null}
        </div>
        {leaders.value.length ? renderLeaderTags(leaders.value) : null}
      </div>
    )
  }

  const renderLeaderTags = (leaders) => {
    return (
      <div className='search-tags-wrapper'>
        {leaders.map((leader, index) => renderChip(leader.label, leader.value, 'leaders', index))}
      </div>
    )
  }

  const renderChip = (label, id, type, index) => {
    return (
      <CustomChip
        key={id}
        label={label}
        id={id}
        type={type}
        crossIcon={WHITE_CLOSE}
        crossClickCallback={(id, type) => handleRemoveAttribute(id, type, index)}
      />
    )
  }

  const getProfileOptions = () => {
    const options = []
    if (profileList && profileList.length) {
      const selectedProfiles = leaders.value.map(data => { return data.value })
      profileList.map(data => {
        if (!selectedProfiles.includes(data.id)) {
          options.push({ label: data.nameAr, value: data.id, profile: data })
        }
      })
    }
    return options
  }

  const handleSearchInputIconClick = () => {
    const parentEle = inputRef.current.children[0]
    const inputEle = parentEle.children[0]
    inputEle.focus()
  }

  const renderSearchOptions = () => {
    const options = getProfileOptions()
    if (options && options.length) {
      return (
        <div className='search-result-container'>
          <div className='result-wrapper'>
            {options.map(option => (
              <Typography
                key={option.value}
                className='search-option'
                onClick={() => onChangeLeaderValue(option, 'leaders')}
              >{option.label}
              </Typography>
            ))}
          </div>
        </div>
      )
    }
    return renderEmptyState()
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='no-option-found-text'>لا توجد خيارات</Typography>
      </div>
    )
  }

  const renderSearchInputIcon = () => {
    return (
      <div className='placeholder-wrapper' onClick={handleSearchInputIconClick}>
        <img src={SEARCH_BLUE} alt='Search profiles' className='search-input-icon' />
        <Typography className='placeholder-text'>تصنيف</Typography>
      </div>
    )
  }

  return (
    <Slide
      timeout={400}
      direction={activeDirection}
      in={activeIndex === 1}
      mountOnEnter
      unmountOnExit
    >
      <div className='tab-two-filters'>
        <div className='filter-wrapper'>
          <SelectInput
            id='filterType'
            type='blueBorderDropdown'
            placeholder={t('common.add')}
            value={filterType.value}
            handleChange={(option) => onDropdownChangeHandler(option, 'filterType')}
            options={filterTypes}
          />
        </div>
        {filterType.value === 'filter' && renderFilterView()}
        {filterType.value === 'leader' && renderLeaderView()}
      </div>
    </Slide>
  )
}
