import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { deepCopy } from '../../../utils/object.utils'
import { imageBaseUrl } from '../../../utils/string.utils'
import { AVATAR, LEFT_ARROW } from '../../../constants/image-reference'
import { setPreviousPath } from '../../../common/common.action-creator'
import { getTiersProfileCount } from '../../insights/insights.action-creator'
import {
  getProfile, getPersonalProfileList, getProfessionalProfileList, getEducationalProfileList
} from '../profile.action-creator'

import classNames from 'classnames'
import Loader from '../../../common/loader/loader.component'
import NetworkProfileCard from './network-profile-card.component'
import TierVisualization from '../../../common/tier-visualization/tier-visualization.component'

import './network.component.scss'

function AchievementDialog (props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isVizLoading, setVizLoading] = useState(true)
  const [activeNetworkProfileList, setActiveNetworkProfileList] = useState([])
  const [visualData, setVisualData] = useState({
    insightType: 'tier',
    visualDetails: []
  })

  const {
    match, getTiersProfileCount, getProfile, getPersonalProfileList, getProfessionalProfileList,
    getEducationalProfileList
  } = props
  const {
    profileDetails, personalProfileList, educationalProfileList, professionalProfileList,
    tiersProfileCount, isProfileLoading
  } = props

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (tiersProfileCount && tiersProfileCount.length) {
      const visualDataCopy = deepCopy(visualData)
      const visualDataArray = []
      tiersProfileCount.map(data => {
        const results = {
          tierId: data.tier.id,
          tierName: data.tier.nameEn,
          profilesCount: data.profilesCount,
          profiles: []
        }
        visualDataArray.push(results)
      })
      visualDataCopy.visualDetails = visualDataArray
      setVisualData(visualDataCopy)
    }
  }, [tiersProfileCount])

  useEffect(() => {
    const constructedPersonalProfileList = []
    if (tiersProfileCount) {
      if (profileDetails && personalProfileList) {
        const personalProfileListCopy = deepCopy(personalProfileList)
        personalProfileListCopy.map(data => {
          const profile = data.relative
          profile.relation = data.relation
          constructedPersonalProfileList.push(profile)
        })
        constructTierProfilesData(profileDetails, constructedPersonalProfileList)
      }
    }
    if (personalProfileList) {
      setTimeout(() => {
        setActiveNetworkProfileList(constructedPersonalProfileList)
      }, 0)
    }
  }, [profileDetails, personalProfileList])

  useEffect(() => {
    if (tiersProfileCount) {
      if (profileDetails && educationalProfileList) {
        constructTierProfilesData(profileDetails, educationalProfileList)
      }
    }
    if (educationalProfileList) {
      setActiveNetworkProfileList(educationalProfileList)
    }
  }, [profileDetails, educationalProfileList])

  useEffect(() => {
    if (tiersProfileCount) {
      if (profileDetails && professionalProfileList) {
        constructTierProfilesData(profileDetails, professionalProfileList)
      }
    }
    if (professionalProfileList) {
      setActiveNetworkProfileList(professionalProfileList)
    }
  }, [profileDetails, professionalProfileList])

  const init = () => {
    getTiersProfileCount()
    fetchProfileDetails()
    fetchPersonalProfileList()
  }

  const fetchProfileDetails = () => {
    const profileId = match.params.profileId
    getProfile({ profileId })
  }

  const fetchPersonalProfileList = () => {
    const profileId = match.params.profileId
    getPersonalProfileList({ profileId })
  }

  const fetchProfessionalProfileList = () => {
    const profileId = match.params.profileId
    getProfessionalProfileList({ profileId })
  }

  const fetchEducationalProfileList = () => {
    const profileId = match.params.profileId
    getEducationalProfileList({ profileId })
  }

  const constructTierProfilesData = (profile, networkProfileList = []) => {
    const visualDataCopy = deepCopy(visualData)
    const tierIds = visualDataCopy.visualDetails.map((data, index) => {
      visualDataCopy.visualDetails[index].profiles = []
      return data.tierId
    })
    if (profile && profile.tier && tierIds.includes(profile.tier.id)) {
      const tierIndex = tierIds.indexOf(profile.tier.id)
      visualDataCopy.visualDetails[tierIndex].profiles.push(profile)
    }

    for (let profileIndex = 0; profileIndex < networkProfileList.length; profileIndex++) {
      const networkProfile = networkProfileList[profileIndex]
      networkProfile.isNetworkProfile = true
      if (networkProfile && networkProfile.tier && tierIds.includes(networkProfile.tier.id)) {
        const tierIndex = tierIds.indexOf(networkProfile.tier.id)
        visualDataCopy.visualDetails[tierIndex].profiles.push(networkProfile)
      }
    }
    setVisualData(visualDataCopy)
    setTimeout(() => {
      setVizLoading(false)
    }, 0)
  }

  const handleTabClick = (index) => {
    setActiveIndex(index)
    setVizLoading(true)
    if (index === 0) {
      fetchPersonalProfileList()
    } else if (index === 1) {
      fetchProfessionalProfileList()
    } else if (index === 2) {
      fetchEducationalProfileList()
    }
  }

  const handleBackBtnClick = () => {
    props.history.goBack()
  }

  const handleViewProfile = (profileId) => {
    setPreviousPath(props.match)
    props.history.push(`/profile-details/${profileId}`)
  }

  const renderTierVisualization = () => {
    return (
      <TierVisualization
        {...props}
        visualData={visualData}
        viewProfileCallback={(profileId) => handleViewProfile(profileId)}
      />
    )
  }

  const renderProfile = () => {
    const { nameAr, profileImage } = profileDetails
    const profileImagePath = profileImage ? profileImage.url : null

    return (
      <div>
        <div className='profile-details-wrapper'>
          <div className='profile-avatar-container'>
            {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='profile-avatar' />}
            {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='profile-avatar' />}
          </div>
          <Typography className='profile-name'>{nameAr}</Typography>
        </div>
        <div className='network-details-wrapper'>
          <div className='details-wrapper'>
            <div className='tabs-wrapper'>
              <div
                className={classNames(['tab', { 'active-tab': activeIndex === 0 }])}
                onClick={() => handleTabClick(0)}
              >
                <Typography className='tab-text'>أقـارب</Typography>
              </div>
              <div
                className={classNames(['tab', { 'active-tab': activeIndex === 1 }])}
                onClick={() => handleTabClick(1)}
              >
                <Typography className='tab-text'>زملاء عمل</Typography>
              </div>
              <div
                className={classNames(['tab', { 'active-tab': activeIndex === 2 }])}
                onClick={() => handleTabClick(2)}
              >
                <Typography className='tab-text'>زملاء دراسة</Typography>
              </div>
            </div>
            <div className='network-profile-list-wrapper'>
              {isProfileLoading ? <Loader color='white' /> : renderNetworkProfileList()}
            </div>
          </div>
          <div className='visualization-wrapper'>
            {isVizLoading ? <Loader color='white' /> : renderTierVisualization()}
          </div>
        </div>
      </div>
    )
  }

  const renderNetworkProfileList = () => {
    if (activeNetworkProfileList && activeNetworkProfileList.length) {
      return activeNetworkProfileList.map(profile => renderNetworkProfile(profile))
    }
    return renderEmptyState()
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-wrapper'>
        <Typography className='empty-state-text'>لم يتم العثور على ملفات شخصية</Typography>
      </div>
    )
  }

  const renderNetworkProfile = (profile) => {
    return (
      <NetworkProfileCard profile={profile} />
    )
  }

  return (
    <div className='profile-network-container'>
      <div className='back-button-container'>
        <img src={LEFT_ARROW} alt='Back arrow' className='back-btn' onClick={handleBackBtnClick} />
      </div>
      {profileDetails ? renderProfile() : <Loader color='white' />}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    // Profile
    profileDetails: state.profile.profile,
    isProfileLoading: state.profile.isProfileLoading,
    personalProfileList: state.profile.personalProfileList,
    educationalProfileList: state.profile.educationalProfileList,
    professionalProfileList: state.profile.professionalProfileList,

    // Insights
    profileList: state.insights.profileList,
    tiersProfileCount: state.insights.tiersProfileCount
  }
}

export default withTranslation(['translations'], translateOptions)(connect(mapStateToProps, {
  // Profile
  getProfile,
  setPreviousPath,
  getPersonalProfileList,
  getEducationalProfileList,
  getProfessionalProfileList,

  // Insights
  getTiersProfileCount
})(AchievementDialog))
