import ActionType from './auth.action-type'
import { deepCopy } from '../../utils/object.utils'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    // Login
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userSession: action.response
      }
    }
    case ActionType.LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        loginFailure: action.response
      }
    }
    case ActionType.CLEAR_LOGIN_VAR: {
      return {
        ...state,
        isLoading: false,
        loginFailure: undefined
      }
    }

    // Logout
    case ActionType.LOGOUT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialState
      }
    }
    case ActionType.LOGOUT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        logoutFailure: action.response
      }
    }

    // refresh token
    case ActionType.REFRESH_TOKEN: {
      return {
        ...state
      }
    }
    case ActionType.REFRESH_TOKEN_SUCCESS: {
      const userSessionCopy = deepCopy(state.userSession)
      userSessionCopy.token = action.response.token
      return {
        ...state,
        userSession: userSessionCopy
      }
    }
    case ActionType.REFRESH_TOKEN_FAILURE: {
      return {
        state: initialState
      }
    }

    // Reset reducer
    case ActionType.RESET_AUTH_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
