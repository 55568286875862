import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { deepCopy } from '../../../utils/object.utils'
import { translateOptions } from '../../../i18n/index'
import { SEARCH_PROFILE, BLUE_CLOSE } from '../../../constants/image-reference'
import { getUserList, setPreviousPath } from '../../../common/common.action-creator'
import {
  getProfileList, clearProfileList, loadMoreProfileList, setFilterState
} from '../../advanced-search/advanced-search.action-creator'
import {
  sendBulkRecom, clearSendBulkRecomVar, saveBoardRecom, clearSaveBoardRecomVar,
  getRecomProfiles, saveAndSendBoardRecom, clearSaveAndSendBoardRecomVariables,
  setRecommendedProfiles, unsetRecommendedProfiles, clearRecommendationAttributes, clearGetRecomProfiles
} from '../recommendation.action-creator'

import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../../common/loader/loader.component'
import SearchInput from '../../../common/search-input/search-input'
import ProfileCard from '../../../common/profile-card/profile-card.component'
import AlertDialog from '../../../common/alert-dialog/alert-dialog.component'
import RecommendationDialog from '../recommendation-dialog/recommendation-dialog.component'
import UsersDialog from '../users-dialog/users-dialog.component'
import PositionDialog from '../position-dialog/position-dialog.component'
import AdvancedFilters from '../../../common/advanced-filters/advanced-filters.component'
import RecommendationHeader from '../header/recommendation-header.component'
import RecommendationFooter from '../recommendation-footer/recommendation-footer.component'

import './board-recommendation-results.component.scss'

const constructRecommendationForFooter = (positions) => {
  if (positions) {
    return positions.map(position => { return { position: position, profiles: [] } })
  }
}

function BoardRecommendationResults (props) {
  const {
    t, isLoading, profileList, getProfileList, clearProfileList, positions, entity, boardMembers,
    saveBoardRecom, userList, getUserList, savedBoardRecomIds, sendBulkRecom, sendBulkRecomSuccess,
    clearSendBulkRecomVar, clearSaveBoardRecomVar, getRecomProfiles, recomProfiles, saveAndSendBoardRecom,
    savedBoardRecomDetails, recomDetail, clearSaveAndSendBoardRecomVariables, unsetRecommendedProfiles,
    loadMoreProfileList, recomConfig, history, clearRecommendationAttributes, setRecommendedProfiles,
    clearGetRecomProfiles, profileCount, setPreviousPath, previousFilters, previousSearchQuery, setFilterState
  } = props
  const [isEditView, setEditView] = useState(false)
  if (recomConfig && recomConfig.isEdit) {
    if (!isEditView) {
      setEditView(true)
    }
  }
  const [showAdvancedFiltersDialog, setAdvancedFiltersDialogOpen] = useState(false)
  const [showRecommendationDialog, setRecommendationDialog] = useState(false)
  const [showUsersDialog, setUsersDialog] = useState(false)
  const [showAlertDialog, setAlertDialog] = useState(false)
  const [showPositionDialog, setPositionDialog] = useState(false)
  const [recommendations, setActiveProfileRecommendations] = useState()
  const [approverUserId, setApproverUserId] = useState()
  const [isSendButtonClicked, setSendButtonStatus] = useState(false)
  const [shortlistedProfileIds, setShortListedProfileIds] = useState([])
  const [shortListedProfiles, setShortListedProfiles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTags, setSearchTags] = useState([])
  const [throttleTimer, setThrottleTimer] = useState()
  const [recomDetails, setRecomDetails] = useState()
  const [changeButtonState, setButtonState] = useState(false)
  const [hasFilters, setHasFilters] = useState(false)
  const [filterParams, setFilterParams] = useState('')
  const [showFooterOpen, setFooterOpen] = useState(false)
  const [profileRecommendations, setProfileRecommendations] = useState(constructRecommendationForFooter(boardMembers))
  const profileListLen = profileList.length

  useEffect(() => {
    if (!entity && !positions) {
      history.push('/recommendation')
    }

    if (isEditView) {
      fetchRecomProfiles()
    }

    if (previousFilters) {
      setHasFilters(true)
      setSearchTags(previousSearchQuery)
      fetchFilteredProfileList(previousFilters, previousSearchQuery)
    } else {
      fetchProfileList()
    }
  }, [])

  useEffect(() => {
    if (isSendButtonClicked) {
      setSendButtonStatus(false)
      sendBoardRecommendation(approverUserId, savedBoardRecomIds)
    } else {
      if (sendBulkRecomSuccess) {
        history.push('/sent-recommendation')
      } else if (savedBoardRecomDetails) {
        const isRecomSent = savedBoardRecomDetails.isSent
        if (isRecomSent) {
          history.push('/sent-recommendation')
        } else {
          history.push('/archive-recom/1')
        }
      } else {
        if (savedBoardRecomIds) {
          history.push('/archive-recom/1')
        }
      }
    }
  }, [savedBoardRecomIds, sendBulkRecomSuccess, savedBoardRecomDetails])

  useEffect(() => {
    if (throttleTimer) {
      clearTimeout(throttleTimer)
    }
    if (searchTags && searchTags.length) {
      setThrottleTimer(setTimeout(() => {
        if (!hasFilters) {
          fetchProfileList()
          setHasFilters(false)
        }
        setThrottleTimer(undefined)
      }, 400))
    }
  }, [searchTags])

  useEffect(() => {
    if (recomDetail && !changeButtonState) {
      setRecomDetails(recomDetail)
    }
  }, [recomDetail])

  useEffect(() => {
    if (recomProfiles && recomProfiles.length) {
      if (isEditView) {
        populateSelectedProfiles(recomProfiles)
      }
    }
  }, [recomProfiles])

  useEffect(() => {
    return () => {
      clearProfileList()
      clearSaveBoardRecomVar()
      clearSendBulkRecomVar()
      // unsetRecommendedProfiles()
      clearSaveAndSendBoardRecomVariables()
      // clearRecommendationAttributes()
      clearGetRecomProfiles()
    }
  }, [])

  const getRequestDelta = () => {
    const shortListedProfilesInitialIds = recomProfiles.map(profile => { return profile.profile.id })
    const delta = {
      addedProfiles: [],
      deletedProfiles: [],
      editedProfiles: []
    }
    delta.deletedProfiles = shortListedProfilesInitialIds.filter(id => !shortlistedProfileIds.includes(id))
    delta.addedProfiles = shortlistedProfileIds.filter(id => shortListedProfilesInitialIds.indexOf(id) === -1)
    delta.editedProfiles = shortlistedProfileIds.filter(id => !delta.deletedProfiles.includes(id) && !delta.addedProfiles.includes(id))

    delta.addedProfiles = getProfilesById(delta.addedProfiles, shortListedProfiles)
    delta.editedProfiles = getProfilesById(delta.editedProfiles, shortListedProfiles)

    return delta
  }

  const getProfilesById = (profileIds, shortListedProfiles) => {
    const profiles = []
    profileIds.forEach((profileId) => {
      shortListedProfiles.forEach(profile => {
        if (profile.profile) {
          if (profile.profile.id === profileId) {
            profiles.push({
              profileId: profile.profile.id,
              reason: profile.profile.reason || '',
              boardPositionId: profile.boardPosition.id
            })
          }
        } else {
          if (profile.id === profileId) {
            profiles.push({
              profileId: profile.id,
              reason: profile.reason || '',
              boardPositionId: profile.boardPositionId
            })
          }
        }
      })
    })
    return profiles
  }

  const showAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(true)
  }

  const hideAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(false)
  }

  const handleSearch = (value) => {
    setSearchQuery(value)
  }

  const handleSearchTags = (value) => {
    if (value.length) {
      const searchTagsCopy = deepCopy(searchTags)
      searchTagsCopy.push(value)
      setSearchTags(searchTagsCopy)
    }
    setSearchQuery('')
  }

  const removeTag = (index) => {
    const searchTagsCopy = deepCopy(searchTags)
    searchTagsCopy.splice(index, 1)
    setSearchTags(searchTagsCopy)
    if (!searchTagsCopy.length) {
      fetchProfileList(true)
    }
  }

  const getSearchQuery = (previousQuery) => {
    let search = ''
    if (previousQuery && previousQuery.length) {
      search = previousQuery.join(' ')
    } else if (searchTags && searchTags.length) {
      search = searchTags.join(' ')
    }
    if (searchQuery) {
      search = `${searchQuery} ${search}`
    }
    return search
  }

  const toggleRecommendationModal = (recommendations) => {
    setRecommendationDialog(!showRecommendationDialog)
    setActiveProfileRecommendations(recommendations)
  }

  const toggleUsersModal = () => {
    setUsersDialog(!showUsersDialog)
  }

  const togglePositionModal = () => {
    setPositionDialog(!showPositionDialog)
  }

  const toggleAlertDialog = () => {
    setAlertDialog(!showAlertDialog)
  }

  const fetchRecomProfiles = () => {
    const { recomId } = recomConfig
    getRecomProfiles({
      recommendationId: recomId,
      limit: 100,
      offset: 0
    })
  }

  const populateSelectedProfiles = (recomProfiles) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const shortListedProfiles = []
    const shortListedProfileIds = []
    const recomProfilesLen = recomProfiles.length
    for (let recomProfileIndx = 0; recomProfileIndx < recomProfilesLen; recomProfileIndx++) {
      shortListedProfiles.push(recomProfiles[recomProfileIndx])
      shortListedProfileIds.push(recomProfiles[recomProfileIndx].profile.id)
    }

    const profileRecomLen = profileRecommendationsCopy.length
    for (let profileRecomIndx = 0; profileRecomIndx < profileRecomLen; profileRecomIndx++) {
      const boardPositionId = profileRecommendationsCopy[profileRecomIndx].position.id
      for (let recomProfileIndx = 0; recomProfileIndx < recomProfilesLen; recomProfileIndx++) {
        const selectedProfileBoardPositionId = recomProfiles[recomProfileIndx].boardPosition.id
        if (selectedProfileBoardPositionId === boardPositionId) {
          profileRecommendationsCopy[profileRecomIndx].profiles.push(recomProfiles[recomProfileIndx].profile)
        }
      }
    }
    setShortListedProfileIds(shortListedProfileIds)
    setShortListedProfiles(shortListedProfiles)
    setProfileRecommendations(profileRecommendationsCopy)
  }

  const fetchFilteredProfileList = (filters, previousQuery) => {
    setFilterState(filters, searchTags)
    setFilterParams(filters)
    hideAdvancedFilters()
    getProfileList({
      isPublished: true,
      offset: 0,
      limit: 20,
      search: getSearchQuery(previousQuery),
      ...filters
    })
  }

  const fetchProfileList = (clear) => {
    setFilterState({ ...filterParams }, searchTags)
    getProfileList({
      isPublished: true,
      offset: 0,
      limit: 20,
      search: clear ? '' : getSearchQuery(),
      ...filterParams
    })
  }

  const showMoreProfiles = () => {
    setFilterState({ ...filterParams }, searchTags)
    loadMoreProfileList({
      isPublished: true,
      search: searchQuery,
      limit: 20,
      ...filterParams,
      offset: profileList && profileList.length ? profileList.length : 0
    })
  }

  const fetchUserList = () => {
    getUserList({
      userType: 'hh, adhoc',
      limit: 100,
      offset: 0
    })
  }

  const getSaveRecReq = () => {
    const req = {
      entityId: entity.id,
      boardId: positions[0].value,
      profiles: getProfiles(profileRecommendations)
    }

    return req
  }

  const getProfiles = (positions) => {
    const profiles = []
    for (let posIndx = 0; posIndx < positions.length; posIndx++) {
      const posDetails = positions[posIndx]
      const posProfiles = positions[posIndx].profiles
      for (let profileIndx = 0; profileIndx < posProfiles.length; profileIndx++) {
        const profile = posProfiles[profileIndx]
        profiles.push({
          profileId: profile.id,
          boardPositionId: posDetails.position.id,
          reason: profile.reason || ''
        })
      }
    }
    return profiles
  }

  const saveBoardRecommendation = () => {
    const request = getSaveRecReq()
    saveBoardRecom({}, request)
  }

  const sendBoardRecommendation = (userId, recomIds) => {
    sendBulkRecom({}, {
      userIds: [userId],
      recommendationIds: [recomIds]
    })
  }

  const saveAndSendRecommendation = () => {
    const { recomId } = recomConfig
    saveAndSendBoardRecom({ recommendationId: recomId }, {
      ...getRequestDelta(),
      entityId: entity.value,
      boardId: positions[0].value
    })
  }

  const initiateSendBoardRecommendation = (userId) => {
    setApproverUserId(userId)
    setSendButtonStatus(true)
    toggleUsersModal(false)
    saveBoardRecommendation()
  }

  const addProfile = (profile, position) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      if (profileRecommendationsCopy[positionIndex].position.value === position) {
        const recommendedProfilesCount = profileRecommendationsCopy[positionIndex].profiles.length
        // returns an index of object with id
        const itemIndex = profileRecommendationsCopy.map((item) => { return item.position.id }).indexOf(position)
        const availablePositions = profileRecommendationsCopy[itemIndex].position.profileLimitCount
        if (recommendedProfilesCount < availablePositions) {
          profile.boardPositionId = position
          profileRecommendationsCopy[positionIndex].profiles.push(profile)
          setShortListedProfileIds([...shortlistedProfileIds, profile.id])
          setShortListedProfiles([...shortListedProfiles, profile])
          setProfileRecommendations(profileRecommendationsCopy)
          break
        } else {
          toggleAlertDialog()
        }
      }
    }
    updateButtonStates()
  }

  const updateButtonStates = () => {
    const recomDetailsCopy = deepCopy(recomDetails)
    recomDetailsCopy.isDraft = false
    setButtonState(true)
    setRecomDetails(recomDetailsCopy)
  }

  const removeProfile = (positionId, profile) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      if (profileRecommendationsCopy[positionIndex].position.value === positionId) {
        const profiles = profileRecommendationsCopy[positionIndex].profiles
        for (let profileIdx = 0; profileIdx < profiles.length; profileIdx++) {
          if (profiles[profileIdx].id === profile.id) {
            profileRecommendationsCopy[positionIndex].profiles.splice(profileIdx, 1)
            setShortListedProfileIds(shortlistedProfileIds.filter(profileId => profileId !== profile.id))
            break
          }
        }
      }
    }
    setProfileRecommendations(profileRecommendationsCopy)
    updateButtonStates()
  }

  const updateReasonForNomination = (reason, profileId) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      const profiles = profileRecommendationsCopy[positionIndex].profiles
      for (let profileIdx = 0; profileIdx < profiles.length; profileIdx++) {
        if (profiles[profileIdx].id === profileId) {
          profiles[profileIdx].reason = reason
          break
        }
      }
    }
    setProfileRecommendations(profileRecommendationsCopy)
    updateButtonStates()
  }

  const getRecommendedProfiles = () => {
    let profilesToShow = []
    if (recomProfiles && profileList) {
      const alreadySelectedProfiles = recomProfiles.map(profile => { return profile.profile })
      const alreadySelectedProfileIds = recomProfiles.map(profile => { return profile.profile.id })
      profilesToShow = [...profilesToShow, ...alreadySelectedProfiles]
      const loadedProfileLen = profileList.length
      for (let profileIndx = 0; profileIndx < loadedProfileLen; profileIndx++) {
        const profile = profileList[profileIndx]
        if (!alreadySelectedProfileIds.includes(profile.id)) {
          profilesToShow.push(profile)
        }
      }
    }
    return profilesToShow
  }

  const handleNavigateToProfileDetails = (profileId) => {
    setPreviousPath(props.match)
    setRecommendedProfiles(getRecomProfilesForProfileDetailScreen(shortListedProfiles))
    history.push(`/profile-details/${profileId}`)
  }

  const getRecomProfilesForProfileDetailScreen = (recommendations) => {
    const result = []
    for (let index = 0; index < recommendations.length; index++) {
      if (recommendations[index].profile) {
        const profile = recommendations[index].profile
        result.push(profile)
      } else {
        result.push(recommendations[index])
      }
    }
    return result
  }

  const handleToggleFooter = () => {
    setFooterOpen(!showFooterOpen)
  }

  const handleTabClick = () => {
    if (!showFooterOpen) {
      setFooterOpen(true)
    }
  }

  const getBoardPosition = (profile) => {
    return { id: profile.boardPosition.id, value: profile.boardPosition.id, label: profile.boardPosition.positionAr }
  }

  const renderAdvancedFilter = () => {
    return (
      <AdvancedFilters
        {...props}
        fetchFilteredProfileListCallback={(filters) => fetchFilteredProfileList(filters)}
        handleClose={hideAdvancedFilters}
        filterParams={filterParams}
        clearFilterCallback={() => setFilterParams('')}
      />
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <div className='icon-wrapper'>
          <img src={SEARCH_PROFILE} alt='logo' className='search-profile-icon' />
        </div>
        <Typography className='empty-state-text'>
          {t('advancedSearch.emptyStateMessage')}
        </Typography>
      </div>
    )
  }

  const renderProfileList = () => {
    if (isEditView) {
      const profiles = getRecommendedProfiles()
      return (
        <InfiniteScroll
          scrollThreshold={0.75}
          next={showMoreProfiles}
          dataLength={profileListLen}
          loader={renderLoadMoreLoader()}
          className='profile-list-container'
          height='calc(100vh - 140px - 80px)'
          hasMore={profileCount > profileListLen}
          style={{
            marginBottom: (shortlistedProfileIds && shortlistedProfileIds.length)
              ? showFooterOpen
                ? '100px' : '0px' : '0px'
          }}
        >
          {profiles.map((profile) => (
            <ProfileCard
              {...props}
              key={profile.id}
              profile={profile}
              positions={profile.boardPosition ? getBoardPosition(profile) : boardMembers}
              isMultiplePositionFlow={boardMembers.length > 1}
              shortlistedProfileIds={shortlistedProfileIds}
              navigateToProfileDetailsCallback={(profileId) => handleNavigateToProfileDetails(profileId)}
              updateReasonForNominationCallback={(reason, profileId) => updateReasonForNomination(reason, profileId)}
              removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
              addProfileCallback={(selectedPos, profile) => addProfile(selectedPos, profile)}
              handleBandClick={(recommendations) => toggleRecommendationModal(recommendations)}
            />
          ))}
        </InfiniteScroll>
      )
    }
    return (
      <InfiniteScroll
        scrollThreshold={0.75}
        next={showMoreProfiles}
        dataLength={profileListLen}
        loader={renderLoadMoreLoader()}
        className='profile-list-container'
        height='calc(100vh - 140px - 80px)'
        hasMore={profileCount > profileListLen}
        style={{
          marginBottom: (shortlistedProfileIds && shortlistedProfileIds.length)
            ? showFooterOpen
              ? '200px' : '0px' : '0px',
          paddingTop: searchTags && searchTags.length ? '80px' : '120px'
        }}
      >
        {profileList.map((profile) => (
          <ProfileCard
            {...props}
            key={profile.id}
            profile={profile}
            positions={boardMembers}
            isMultiplePositionFlow={boardMembers.length > 1}
            shortlistedProfileIds={shortlistedProfileIds}
            navigateToProfileDetailsCallback={(profileId) => handleNavigateToProfileDetails(profileId)}
            updateReasonForNominationCallback={(reason, profileId) => updateReasonForNomination(reason, profileId)}
            removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
            addProfileCallback={(selectedPos, profile) => addProfile(selectedPos, profile)}
            handleBandClick={(recommendations) => toggleRecommendationModal(recommendations)}
          />
        ))}
      </InfiniteScroll>
    )
  }

  const renderLoadMoreLoader = () => {
    return (
      <div className='load-more-button-wrapper'>
        <Loader color='blue' />
      </div>
    )
  }

  const renderRecommendationDialog = () => {
    return (
      <RecommendationDialog
        {...props}
        recommendations={recommendations}
        handleClose={toggleRecommendationModal}
      />
    )
  }

  const renderSearchTags = () => {
    return (
      <div className='search-tags-container'>
        <div className='search-tag-view'>
          {searchTags.map((tag, index) => (
            <div className='search-tag' key={index}>
              <img src={BLUE_CLOSE} alt='close' className='close-icon' onClick={() => removeTag(index)} />
              <Typography className='tag-text'>{tag}</Typography>
            </div>))}
        </div>
      </div>
    )
  }

  const renderPositionDialog = () => {
    return (
      <PositionDialog
        {...props}
        positions={positions}
        boardMembers={boardMembers}
        handleClose={togglePositionModal}
      />
    )
  }

  const renderUsersDialog = () => {
    return (
      <UsersDialog
        {...props}
        userList={userList}
        getUserListCallback={fetchUserList}
        handleUserClick={(userId) => initiateSendBoardRecommendation(userId)}
        handleClose={toggleUsersModal}
      />
    )
  }

  const renderAlertDialog = () => {
    return (
      <AlertDialog {...props} type='info' handleClose={toggleAlertDialog}>
        {t('alertMessage.maxProfileSelected')}
      </AlertDialog>
    )
  }

  return (
    <div className='board-recommendation-search-container'>
      <RecommendationHeader {...props} positionClickCallback={togglePositionModal} />
      <div className='search-input-wrapper'>
        <SearchInput
          showFilters
          value={searchQuery}
          filterButtonCallback={showAdvancedFilters}
          handleChange={handleSearch}
          handleEnter={handleSearchTags}
        />
      </div>
      {searchTags && searchTags.length ? renderSearchTags() : null}
      {isLoading ? <Loader color='blue' /> : (profileList && profileList.length) ? renderProfileList() : renderEmptyState()}
      <RecommendationFooter
        {...props}
        isEditView={isEditView}
        recomDetails={recomDetails}
        showFooterOpen={showFooterOpen}
        tabClickCallback={handleTabClick}
        recommendations={profileRecommendations}
        toggleFooterCallback={handleToggleFooter}
        shortlistedProfileIds={shortlistedProfileIds}
        sendRecommendationCallback={toggleUsersModal}
        saveRecommendationCallback={saveBoardRecommendation}
        saveAndSendRecomCallback={saveAndSendRecommendation}
        removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
      />
      {showAdvancedFiltersDialog && renderAdvancedFilter()}
      {showRecommendationDialog && renderRecommendationDialog()}
      {showUsersDialog && renderUsersDialog()}
      {showPositionDialog && renderPositionDialog()}
      {showAlertDialog && renderAlertDialog()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    profileList: state.advancedSearch.profileList,
    previousFilters: state.advancedSearch.previousFilters,
    previousSearchQuery: state.advancedSearch.previousSearchQuery,
    isLoading: state.advancedSearch.isLoading,
    profileCount: state.advancedSearch.profileCount,
    entity: state.recommendation.entity,
    positions: state.recommendation.positions,
    boardMembers: state.recommendation.boardMembers,
    recomDetail: state.recommendation.recomDetail,
    recomConfig: state.recommendation.recomConfig,
    recomProfiles: state.recommendation.recomProfiles,
    savedBoardRecomIds: state.recommendation.savedBoardRecomIds,
    sendBulkRecomSuccess: state.recommendation.sendBulkRecomSuccess,
    savedBoardRecomDetails: state.recommendation.savedBoardRecomDetails,
    recomLoading: state.recommendation.recomLoading,
    userList: state.common.userList
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    getProfileList,
    getRecomProfiles,
    clearProfileList,
    saveBoardRecom,
    setFilterState,
    sendBulkRecom,
    saveAndSendBoardRecom,
    getUserList,
    clearSendBulkRecomVar,
    clearSaveBoardRecomVar,
    setRecommendedProfiles,
    unsetRecommendedProfiles,
    clearGetRecomProfiles,
    loadMoreProfileList,
    clearSaveAndSendBoardRecomVariables,
    clearRecommendationAttributes,
    setPreviousPath
  })(BoardRecommendationResults)
)
