
export const apis = {
  baseUrl: 'http://95.217.23.103',

  // auth
  login: 'auth/login/',
  logout: 'auth/logout/',
  refreshToken: 'auth/access-token/refresh/',

  // advanced search
  getProfileList: 'profiles/',

  // recommendation
  saveBulkRecom: 'recommendations/bulk/',
  sendBulkRecom: 'recommendations/bulk/share/',
  saveBoardRecom: 'board_recommendations/',
  deleteRecom: 'recommendations/{recommendationId}/delete/',
  getRecomDetails: 'recommendations/{recommendationId}/details/',
  getRecomProfiles: 'recommendations/{recommendationId}/profiles/',
  saveAndSendRecom: 'recommendations/{recommendationId}/details/',
  saveAndSendBoardRecom: 'board_recommendations/{recommendationId}/details/',

  // insights
  addInsight: 'insights/',
  getInsightList: 'insights/',
  getInsightDetails: 'insights/{insightId}',
  editInsight: 'insights/{insightId}',
  deleteInsight: 'insights/{insightId}',
  getInsightEntityTypes: 'insights/entities/types/',
  getEntityProfileCount: 'insights/entities/',
  getTiersProfileCount: 'insights/tiers/',
  sendInsight: 'insights/{insightId}/share/',
  getLeaderList: 'profiles/search/',
  getSectors: 'profiles/sector_visual/',

  // common
  addOrg: 'orgs/',
  getOrgDetails: 'orgs/{orgId}/details/',
  getOrgProfileList: 'orgs/{orgId}/profiles/',
  getOrgList: 'orgs/',
  getOrgTypes: 'common/orgs/types/',
  getOrgListBySectorId:'orgs/sectors/{sectorId}/',
  getTiersByOrg:'orgs/tier/{orgId}/profile_count/',
  addPosition: 'orgs/{orgId}/jobs/',
  getPositionList: 'orgs/{orgId}/jobs/',
  getBoardList: 'orgs/{orgId}/boards/',
  getBoardMemberList: 'orgs/{orgId}/boards/{boardId}/members/',
  getSectorList: 'orgs/sectors/',
  getUserList: 'users/',
  getTierList: 'common/tiers/',
  getEducationLevelList: 'common/education_levels/',
  getGenderList: 'common/genders/',
  getProfileTagList: 'profiles/profile_tags/',

  // profile details
  getProfile: 'profiles/{profileId}/details/',
  getPersonalProfileList: 'profiles/{profileId}/network/personal/',
  getProfessionalProfileList: 'profiles/{profileId}/network/professional/',
  getEducationalProfileList: 'profiles/{profileId}/network/educational/',
  getProfiles:'orgs/{orgId}/profiles/',

  // Recommendation
  getRecom: 'recommendations/'
}
