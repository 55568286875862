import React, { useState, useRef } from 'react'
import { Typography, Popover } from '@material-ui/core'
import { CARET_DOWN, CARET_DOWN_WHITE } from '../../constants/image-reference'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import './select-input.scss'

export default function SelectInput (props) {
  const wrapperRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchResultWidth, setSearchResultWidth] = useState(0)

  const { options, value, type, placeholder, id } = props
  const { handleChange } = props
  const open = Boolean(anchorEl)
  const elementId = open ? `select-input-${id}` : undefined

  const handleClick = event => {
    event.stopPropagation()
    const elementParentWidth = event.target.parentNode.offsetWidth
    // Subtract 20px to avoid padding of parent element
    const searchResultPopoverWidth = elementParentWidth - 20
    setAnchorEl(event.currentTarget)
    setTimeout(() => {
      setSearchResultWidth(searchResultPopoverWidth)
    }, 0)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (option) => {
    handleClose()
    handleChange(option)
  }

  const renderSearchOptions = () => {
    if (options && options.length) {
      return (
        <Popover
          id={elementId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          className='select-input-search-result-container'
        >
          <div className='select-input-result-wrapper' style={{ width: searchResultWidth ? `${searchResultWidth}px` : '100%' }}>
            {options.map((option, indx) => (
              <Typography
                key={indx}
                className='search-option'
                onClick={() => handleOptionClick(option)}
              >{option.label}
              </Typography>
            ))}
          </div>
        </Popover>
      )
    }
    if (options && !options.length) {
      return renderEmptyState()
    }
  }

  const renderEmptyState = () => {
    return (
      <div className='select-input-empty-state-container'>
        <div className='empty-state-container'>
          <Typography className='no-option-found-text'>لا توجد خيارات</Typography>
        </div>
      </div>
    )
  }

  const getSelectValue = () => {
    let textToDisplay = ''
    if (typeof value === 'object') {
      textToDisplay = value.label
    } else if ((typeof value === 'string') && value.length) {
      options.map(option => {
        if (option.value === value) {
          textToDisplay = option.label
        }
      })
    } else if (value) {
      options.map(option => {
        if (option.value === value) {
          textToDisplay = option.label
        }
      })
    } else {
      textToDisplay = placeholder
    }
    return textToDisplay
  }

  return (
    <div
      className={classNames(['select-input-container', {
        'white-border-dropdown': (type === 'whiteBorderDropdown'),
        'blue-border-dropdown': (type === 'blueBorderDropdown')
      }])}
      ref={wrapperRef}
    >
      <div className='select-input' onClick={handleClick}>
        <Typography className='select-input-text'>{getSelectValue()}</Typography>
        {(type === 'blueBorderDropdown') && <img src={CARET_DOWN} className='caret-icon' alt='Caret down' />}
        {(type === 'whiteBorderDropdown') && <img src={CARET_DOWN_WHITE} className='caret-icon' alt='Caret down' />}
      </div>
      {renderSearchOptions()}
    </div>
  )
}

SelectInput.propTypes = {
  type: PropTypes.oneOf(['blueBorderDropdown', 'whiteBorderDropdown'])
}
