
import React from 'react'
import classNames from 'classnames'

import './radio-button.component.scss'

function RadioButton (props) {
  const { label, value, activeButtonValue, handleChange, removeCallback } = props

  const handleOnClick = () => {
    if (activeButtonValue === value) {
      if (removeCallback) {
        removeCallback()
      }
    } else {
      if (handleChange) {
        handleChange(value)
      }
    }
  }

  return (
    <div
      className={classNames(['radio-button-container', {
        'radio-btn': !(activeButtonValue === value),
        'active-btn': activeButtonValue === value
      }])}
      onClick={handleOnClick}
    >
      <div className='radio-button-text'>{label}</div>
    </div>
  )
}

export default RadioButton
