import React, { useState } from 'react'
import { Typography, TextField, Slide } from '@material-ui/core'

import './filters.component.scss'

export default function TabThreeFilters (props) {
  const { t, onChangeDescCallback, headline, activeIndex, activeDirection } = props

  const [desc, setDesc] = useState(headline.value.length ? headline.value : '')

  const handleOnChange = (event) => {
    const text = event.target.value
    setDesc(text)
    onChangeDescCallback(text)
  }

  return (
    <Slide
      timeout={400}
      direction={activeDirection}
      in={activeIndex === 2}
      mountOnEnter
      unmountOnExit
    >
      <div className='tab-three-filters'>
        <div className='headline-wrapper'>
          <Typography className='title'>{t('insights.insightHeadline')}</Typography>
          <div className='search-input-wrapper'>
            <TextField
              className='search-input'
              margin='normal'
              variant='outlined'
              placeholder={t('common.writeHere')}
              fullWidth
              multiline
              rows='12'
              value={desc}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
    </Slide>
  )
}
