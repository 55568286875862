import React from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core'
import { PLUS_BLUE_ICON, MINUS_BLUE_ICON } from '../../constants/image-reference'

import './expansion-panel.scss'

function Accordion (props) {
  const [expanded, setExpanded] = React.useState(false)
  const { children, title, panel, defaultExpanded } = props

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const renderPlusIcon = () => {
    return (
      <img src={PLUS_BLUE_ICON} alt='Plus' className='accordion-icon' />
    )
  }

  const renderMinusIcon = () => {
    return (
      <img src={MINUS_BLUE_ICON} alt='Plus' className='accordion-icon' />
    )
  }

  return (
    <ExpansionPanel
      classes={{ root: 'expansion-panel-container' }}
      defaultExpanded={defaultExpanded}
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <ExpansionPanelSummary
        expandIcon={expanded ? renderMinusIcon() : renderPlusIcon()}
        aria-controls='panel1a-content'
        classes={{ root: 'title-wrapper' }}
        id='panel1a-header'
      >
        <Typography className='title'>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{ root: 'accordion-content' }}
      >{children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default Accordion
