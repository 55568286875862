import apiActionTypes from '../api/api.action-type'
import authActionTypes from '../app/auth/auth.action-type'
import profileActionTypes from '../app/profile/profile.action-type'
import advancedSearchActionTypes from '../app/advanced-search/advanced-search.action-type'
import recommendationActionTypes from '../app/recommendation/recommendation.action-type'
import insightsActionTypes from '../app/insights/insights.action-type'
import miscActionTypes from '../common/common.action-type'

const ACTION_TYPES = {
  ...apiActionTypes,
  ...authActionTypes,
  ...profileActionTypes,
  ...advancedSearchActionTypes,
  ...recommendationActionTypes,
  ...insightsActionTypes,
  ...miscActionTypes
}

export default ACTION_TYPES
