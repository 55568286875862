import React from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE } from '../../../constants/image-reference'

import './opinion-dialog.component.scss'

export default function OpinionDialog (props) {
  const { handleClose, opinion } = props
  const { title, description } = opinion

  return (
    <Dialog size='sm' className='opinion-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>الشخصية</Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' className='close-button' onClick={handleClose} />
      </div>
      <div className='dialog-content'>
        <Typography className='opinion-text'>آراء</Typography>
        <div className='opinion-details'>
          <Typography className='opinion-heading'>{title}</Typography>
          <Typography className='opinion-text'>{description}</Typography>
        </div>
      </div>
    </Dialog>
  )
}
