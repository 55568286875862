export const PRIMARY_LIGHT_COLOR = '#416897'
export const PRIMARY_MAIN_COLOR = '#001C47'
export const PRIMARY_DARK_COLOR = '#0c2e58'
export const PRIMARY_CONTRAST_COLOR = '#FFFFFF'
export const PRIMARY_BOX_SHADOW_COLOR = '#A1A1A1'

export const SECONDARY_LIGHT_COLOR = '#FFFFFF'
export const SECONDARY_MAIN_COLOR = '#FFFFFF'
export const SECONDARY_DARK_COLOR = '#B2B2B2'
export const SECONDARY_CONTRAST_COLOR = '#000000'
export const SECONDARY_BOX_SHADOW_COLOR = '#EEEEEE'
export const SECONDARY_BORDER_COLOR = '#DEDEDE'

export const CARD_TITLE_COLOR = '#404036'
export const ERROR_COLOR = '#D50000'
