import React from 'react'
import { Typography } from '@material-ui/core'

import './chip.component.scss'

function CustomChip (props) {
  const { label, id, type, crossClickCallback, crossIcon } = props

  const handleRemoveClick = () => {
    crossClickCallback(id, type)
  }

  return (
    <div className='custom-chip-container'>
      <Typography className='chip-label'>{label}</Typography>
      <img src={crossIcon} alt='Close' className='chip-icon' onClick={handleRemoveClick} />
    </div>
  )
}

export default CustomChip
