import ActionTypes from './auth.action-type'
import { apiAction } from '../../api/api.action-creator'

// Login
export const login = (params) => {
  return apiAction({
    method: 'POST',
    url: 'login',
    onSuccess: loginSuccess,
    onFailure: loginFailure,
    label: ActionTypes.LOGIN,
    body: params
  })
}
export const loginSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOGIN_SUCCESS,
      response
    })
  }
}
export const loginFailure = (response) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    response
  }
}
export const clearLoginVar = () => {
  return {
    type: ActionTypes.CLEAR_LOGIN_VAR
  }
}

// Logout
export const logout = () => {
  return apiAction({
    method: 'POST',
    url: 'logout',
    onSuccess: logoutSuccess,
    onFailure: logoutFailure,
    label: ActionTypes.LOGOUT
  })
}
export const logoutSuccess = () => {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOGOUT_SUCCESS
    })
    dispatch({
      type: ActionTypes.RESET_REDUCER
    })
    dispatch({
      type: ActionTypes.RESET_AUTH_REDUCER
    })
  }
}
export const logoutFailure = (response) => {
  return {
    type: ActionTypes.LOGOUT_FAILURE,
    response
  }
}

// refresh token
export const refreshToken = (params) => {
  return apiAction({
    method: 'POST',
    url: 'refreshToken',
    onSuccess: receiveRefreshTokenSuccess,
    onFailure: receiveRefreshTokenFailure,
    label: ActionTypes.REFRESH_TOKEN,
    body: params.body
  })
}
export const receiveRefreshTokenSuccess = (response) => {
  return {
    type: ActionTypes.REFRESH_TOKEN_SUCCESS,
    response
  }
}
export const receiveRefreshTokenFailure = () => {
  return {
    type: ActionTypes.REFRESH_TOKEN_FAILURE
  }
}
