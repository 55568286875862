import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { BRAND_LOGO, PLUS_GRAY, PINK_CIRCLE, GRAY_CIRCLE, BLUE_CLOSE } from '../../constants/image-reference'
import { debounce } from 'lodash'
import {
  getOrgList, getPositionList, getBoardList, getBoardMemberList, getSectorList, clearAddOrgVar,
  addOrg, getTierList, getGenderList, getEducationLevelList, addPosition, clearAddPositionVar,
  getProfileTagList, getOrgTypes
} from '../../common/common.action-creator'
import { setRecommendationAttributes, setRecommendationResultsPageConfig } from './recommendation.action-creator'
import { deepCopy } from '../../utils/object.utils'
import { getUserType } from '../../utils/string.utils'

import CustomChip from '../../common/chip/chip.component'
import AddEntityDialog from '../../common/add-entity/add-entity.component'
import AddPositionDialog from '../../common/add-position/add-position.component'
import SearchSelectInput from '../../common/search-select-input/search-select-input'

import './recommendation.component.scss'

function Recommendation (props) {
  const [values, setValues] = useState({
    entity: { type: 'text', value: '', isRequired: true },
    position: { type: 'text', value: [], isRequired: true },
    boardMembers: { type: 'text', value: [], isRequired: true }
  })
  const [showPositionDropdown, setShowPositionDropdown] = useState(true)
  const [showAddEntityDialog, setAddEntityDialog] = useState(false)
  const [showAddPositionDialog, setAddPositionDialog] = useState(false)

  const {
    t, getOrgList, getPositionList, getBoardList, addOrg, getTierList, getEducationLevelList,
    getGenderList, addPosition, clearAddPositionVar, setRecommendationAttributes, getBoardMemberList,
    setRecommendationResultsPageConfig, getSectorList, clearAddOrgVar, getProfileTagList, getOrgTypes
  } = props
  const {
    orgList, positionList, boardList, existingEntity, existingPositions, existingBoardMembers,
    boardMemberList, userSession, activeOrg, activePosition, isRecomEditable
  } = props
  const { entity, position, boardMembers } = values

  const delayedEntityCallback = debounce(value => fetchOrgList(value), 500)
  const delayedPositionCallback = debounce((id, value) => fetchPositionList(id, value), 500)
  const delayedBoardCallback = debounce((id, value) => fetchBoardList(id, value), 500)
  const userType = getUserType(userSession)

  useEffect(() => {
    fetchOrgList()

    if (isRecomEditable) {
      populateExistingAttributes()
    }
  }, [])

  useEffect(() => {
    if (boardMemberList && boardMemberList.length) {
      const boardMembers = getBoardMembers(boardMemberList)
      setBoardMembers(boardMembers)
    }
  }, [boardMemberList])

  useEffect(() => {
    const { activeOrg } = props
    if (activeOrg) {
      const fieldsCopy = deepCopy(values)
      fieldsCopy.entity.value = { label: activeOrg.nameAr, value: activeOrg.id }
      setValues(fieldsCopy)
      fetchPositionList(activeOrg.id)
      setAddEntityDialog(false)
      clearAddOrgVar()
    }
  }, [activeOrg])

  useEffect(() => {
    if (activePosition) {
      const fieldsCopy = deepCopy(values)
      fieldsCopy.position.value.push({ label: activePosition.positionAr, value: activePosition.id })
      setValues(fieldsCopy)
      clearAddPositionVar()
      setShowPositionDropdown(false)
      setAddPositionDialog(false)
    }
  }, [activePosition])

  const toggleAddEntityDialog = () => {
    setAddEntityDialog(!showAddEntityDialog)
  }

  const toggleAddPositionDialog = () => {
    setAddPositionDialog(!showAddPositionDialog)
  }

  const addEntity = (fields) => {
    addOrg({}, fields)
  }

  const addNewPosition = (fields) => {
    addPosition({ orgId: entity.value.id }, fields)
  }

  const getBoardMembers = () => {
    if (boardMemberList) {
      if (boardMemberList.length) {
        return boardMemberList.map(boardMember => {
          return {
            id: boardMember.id,
            label: boardMember.positionAr,
            value: boardMember.id,
            profileLimitCount: boardMember.requiredNumberOfProfiles || 1
          }
        })
      }
      return []
    }
  }

  const setBoardMembers = (members) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy.boardMembers.value = members
    setValues(fieldsCopy)
  }

  const populateExistingAttributes = () => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy.entity.value = existingEntity
    fieldsCopy.position.value = existingPositions
    fieldsCopy.boardMembers.value = existingBoardMembers
    unsetPositionDropdown()
    setTimeout(() => {
      setValues(fieldsCopy)
    }, 0)
  }

  const fetchOrgList = (search) => {
    getOrgList({
      limit: 100,
      offset: 0,
      search
    })
  }

  const fetchPositionList = (id, search) => {
    getPositionList({
      orgId: id,
      limit: 100,
      offset: 0,
      search
    })
  }

  const fetchSectorList = () => {
    getSectorList()
  }

  const fetchTierList = () => {
    getTierList()
  }

  const fetchEducationLevelList = () => {
    getEducationLevelList()
  }

  const fetchGenderList = () => {
    getGenderList()
  }

  const fetchProfileTagList = () => {
    getProfileTagList()
  }

  const fetchOrgTypes = () => {
    getOrgTypes()
  }

  const fetchBoardList = (id, search) => {
    getBoardList({
      orgId: id,
      status: 'future',
      limit: 100,
      offset: 0,
      search
    })
  }

  const fetchBoardMemberList = (boardId) => {
    getBoardMemberList({
      orgId: entity.value.value,
      boardId: boardId,
      limit: 100,
      offset: 0
    })
  }

  const onOptionChangeHandler = (field, key) => {
    const fieldsCopy = deepCopy(values)
    if (key === 'entity') {
      fieldsCopy[key].value = field
      const entityId = field.value
      fetchPositionList(entityId)
      fetchBoardList(entityId)
    } else if (key === 'position') {
      if (field.type === 'board') {
        fieldsCopy.position.value = []
        fetchBoardMemberList(field.value)
      }
      fieldsCopy[key].value.push(field)
      unsetPositionDropdown()
    }
    setValues(fieldsCopy)
  }

  const onInputChangeHandler = (value, key) => {
    if (key === 'entity') {
      delayedEntityCallback(value)
    } else if (key === 'position') {
      const { entity } = values
      delayedPositionCallback(entity.value.value, value)
      delayedBoardCallback(entity.value.value, value)
    }
  }

  const getOrgOptions = () => {
    if (orgList) {
      if (orgList.length) {
        return orgList.map(org => {
          return { id: org.id, label: org.nameAr, value: org.id }
        })
      }
      return []
    }
  }

  const getJobOptions = () => {
    const { position } = values
    const options = []
    if (positionList && positionList.length) {
      const selectedPos = position.value.map(data => { return data.value })
      positionList.map((position) => {
        if (!selectedPos.includes(position.id)) {
          options.push({ id: position.id, label: position.positionAr, value: position.id, type: 'position' })
        }
      })
    }
    if (boardList && boardList.length) {
      boardList.map((board) => {
        // change nameEn to nameAr
        options.push({ id: board.id, label: board.nameEn, value: board.id, type: 'board' })
      })
    }
    return options
  }

  const setPositionDropdown = () => {
    setShowPositionDropdown(true)
  }

  const unsetPositionDropdown = () => {
    setShowPositionDropdown(false)
  }

  const handleSearchClick = () => {
    if (position.value[0].type === 'board' && boardMemberList && boardMemberList.length) {
      setRecommendationAttributes(entity.value, position.value, boardMembers.value, true)
      setRecommendationResultsPageConfig({ isEdit: false, userType })
      props.history.push('/board-recommendation-results')
    } else {
      setRecommendationAttributes(entity.value, position.value, {}, true)
      setRecommendationResultsPageConfig({ isEdit: false, userType })
      props.history.push('/position-recommendation-results')
    }
  }

  const handleRemoveAttribute = (id, type) => {
    const fieldsCopy = deepCopy(values)
    if (type === 'entity') {
      fieldsCopy.entity.value = ''
      fieldsCopy.position.value = []
      fieldsCopy.boardMembers.value = []
      setPositionDropdown()
    } else {
      const positions = fieldsCopy.position.value
      for (let positionIndx = 0; positionIndx < positions.length; positionIndx++) {
        if (positions[positionIndx].value === id) {
          positions.splice(positionIndx, 1)
          if (positions.length === 0) {
            setPositionDropdown()
          }
        }
      }
    }
    setValues(fieldsCopy)
  }

  const renderEntityDropdown = () => {
    return (
      <SearchSelectInput
        {...props}
        showAddEntityLink
        type='whiteBorderDropdown'
        value={entity.value}
        options={getOrgOptions()}
        placeholder='اختار الجهة'
        handleSearch={(searchQuery) => onInputChangeHandler(searchQuery, 'entity')}
        handleChange={(option) => onOptionChangeHandler(option, 'entity')}
        addEntityCallback={toggleAddEntityDialog}
        recomView
      />
    )
  }

  const renderPositionDropdown = () => {
    return (
      <div className='position-filter'>
        <SearchSelectInput
          {...props}
          showAddPositionLink
          type='whiteBorderDropdown'
          value={position.value}
          disabled={!entity.value}
          placeholder='اختار المنصب'
          options={getJobOptions()}
          handleSearch={(searchQuery) => onInputChangeHandler(searchQuery, 'position')}
          handleChange={(option) => onOptionChangeHandler(option, 'position')}
          addPositionCallback={toggleAddPositionDialog}
          recomView
        />
      </div>
    )
  }

  const renderPositionChipContainer = () => {
    return (
      <div className='position-chip-container'>
        <div className='chip-wrapper'>
          {position.value.map(position => renderChip(position.label, position.value, 'position'))}
          {boardMemberList && boardMemberList.length ? boardMemberList.map(member => renderBoardMember(member)) : null}
        </div>
      </div>
    )
  }

  const renderChip = (label, id, type) => {
    return (
      <CustomChip
        key={id}
        label={label}
        id={id}
        type={type}
        crossIcon={BLUE_CLOSE}
        crossClickCallback={(id, type) => handleRemoveAttribute(id, type)}
      />
    )
  }

  const renderBoardMember = (member) => {
    return (
      <div className='board-member' key={member.id}>{member.positionAr}</div>
    )
  }

  const renderSearchButton = () => {
    return (
      <div className='button-wrapper'>
        <Button onClick={handleSearchClick} className='search-button' variant='contained' color='secondary'>
          {t('common.search')}
        </Button>
      </div>
    )
  }

  const renderAddEntityDialog = () => {
    return (
      <AddEntityDialog
        {...props}
        addEntityCallback={(fields) => addEntity(fields)}
        handleClose={toggleAddEntityDialog}
        fetchSectorListCallback={fetchSectorList}
        fetchOrgTypesCallback={fetchOrgTypes}
        clearAddEntityVarCallback={clearAddOrgVar}
      />
    )
  }

  const renderAddPositionDialog = () => {
    return (
      <AddPositionDialog
        {...props}
        entity={entity.value}
        addPositionCallback={(fields) => addNewPosition(fields)}
        handleClose={toggleAddPositionDialog}
        fetchSectorListCallback={fetchSectorList}
        fetchTierListCallback={fetchTierList}
        fetchEducationLevelListCallback={fetchEducationLevelList}
        fetchGenderListCallback={fetchGenderList}
        fetchProfileTagListCallback={fetchProfileTagList}
        clearAddPositionVarCallback={clearAddPositionVar}
      />
    )
  }

  return (
    <div className='recommendation-container'>
      <div className='heading-container'>
        <img src={BRAND_LOGO} alt='Brand logo' className='brand-logo' />
        <Typography className='heading-one'>{t('common.welcomeMessage')}</Typography>
        <Typography className='heading-two'>{t('recommendation.makeNewNomination')}</Typography>
      </div>
      <div className='filters-container'>
        <div className='row-wrapper'>
          <div className='filters'>
            <div className='entity-filter'>
              {(typeof entity.value === 'object')
                ? renderChip(entity.value.label, entity.value.id, 'entity')
                : renderEntityDropdown()}
            </div>

            {position.value.length ? renderPositionChipContainer() : null}
            {showPositionDropdown && renderPositionDropdown()}
            {position.value.length && position.value[0].type !== 'board' && !showPositionDropdown
              ? (
                <div className='add-more-link' onClick={setPositionDropdown}>
                  <img src={PLUS_GRAY} alt='Add position' className='add-icon' />
                  <Typography className='add-text'>{t('recommendation.addPosition')}</Typography>
                </div>
              )
              : null}
            {(entity.value && position.value.length) ? renderSearchButton() : null}
          </div>
          <div className='design-view'>
            <img src={PINK_CIRCLE} alt='circle icon' className='circle-icon' />
            <div className='dash-line' />
            <img src={GRAY_CIRCLE} alt='circle icon' className='dot-icon' />
          </div>
        </div>
      </div>
      {showAddEntityDialog && renderAddEntityDialog()}
      {showAddPositionDialog && renderAddPositionDialog()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // common
    activeOrg: state.common.activeOrg,
    orgList: state.common.orgList,
    activePosition: state.common.activePosition,
    positionList: state.common.positionList,
    boardList: state.common.boardList,
    boardMemberList: state.common.boardMemberList,
    sectorList: state.common.sectorList,
    tierList: state.common.tierList,
    educationLevelList: state.common.educationLevelList,
    genderList: state.common.genderList,
    addOrgError: state.common.addOrgError,
    addPositionError: state.common.addPositionError,
    profileTagList: state.common.profileTagList,
    orgTypes: state.common.orgTypes,

    // recommendation
    existingPositions: state.recommendation.positions,
    existingEntity: state.recommendation.entity,
    existingBoardMembers: state.recommendation.boardMembers,
    isRecomEditable: state.recommendation.isRecomEditable,

    // auth
    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    addOrg,
    getOrgList,
    getOrgTypes,
    addPosition,
    getTierList,
    getBoardList,
    getGenderList,
    getSectorList,
    clearAddOrgVar,
    getPositionList,
    getProfileTagList,
    getBoardMemberList,
    clearAddPositionVar,
    getEducationLevelList,
    setRecommendationAttributes,
    setRecommendationResultsPageConfig
  })(Recommendation))
