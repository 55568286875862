import React, { useEffect } from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE } from '../../../constants/image-reference'

import Loader from '../../../common/loader/loader.component'

import './users-dialog.component.scss'

export default function UsersDialog (props) {
  const { t, handleClose, getUserListCallback, handleUserClick, userList } = props

  const getUserList = () => {
    getUserListCallback()
  }

  const renderDialogContent = () => {
    if (userList.length) {
      return userList.map((user) => (
        <Typography key={user.id} className='user-text' onClick={() => handleUserClick(user.id)}>
          {user.name}
        </Typography>
      ))
    }
  }

  useEffect(() => {
    getUserList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog size='sm' className='users-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>
          {t('recommendation.sendTheCandidatesTo')}
        </Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' onClick={handleClose} className='close-button' />
      </div>
      <div className='dialog-content'>
        <div className='user-list'>
          {userList ? renderDialogContent() : <Loader color='blue' />}
        </div>
      </div>
    </Dialog>
  )
}
