import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { HH, ADHOC } from '../../../constants/misc'
import { translateOptions } from '../../../i18n/index'
import { deepCopy } from '../../../utils/object.utils'
import { getUserType } from '../../../utils/string.utils'
import { SEARCH_PROFILE, BLUE_CLOSE } from '../../../constants/image-reference'
import { getUserList, setPreviousPath } from '../../../common/common.action-creator'
import {
  getProfileList, clearProfileList, loadMoreProfileList, setFilterState
} from '../../advanced-search/advanced-search.action-creator'
import {
  saveBulkRecom, sendBulkRecom, clearSaveBulkRecomVar, clearSendBulkRecomVar, getRecomProfiles, unsetRecommendedProfiles,
  saveAndSendRecom, clearSaveAndSendRecomVariables, setRecommendedProfiles, clearRecommendationAttributes,
  clearGetRecomProfiles
} from '../recommendation.action-creator'

import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../../common/loader/loader.component'
import SearchInput from '../../../common/search-input/search-input'
import ProfileCard from '../../../common/profile-card/profile-card.component'
import RecommendationDialog from '../recommendation-dialog/recommendation-dialog.component'
import UsersDialog from '../users-dialog/users-dialog.component'
import PositionDialog from '../position-dialog/position-dialog.component'
import AdvancedFilters from '../../../common/advanced-filters/advanced-filters.component'
import RecommendationHeader from '../header/recommendation-header.component'
import RecommendationFooter from '../recommendation-footer/recommendation-footer.component'

import './position-recommendation-results.component.scss'

const constructRecommendationForFooter = (positions) => {
  if (positions) {
    return positions.map(position => { return { position: position, profiles: [] } })
  }
}

function PositionRecommendationResults (props) {
  const {
    t, history, isLoading, profileList, getProfileList, clearProfileList, positions, entity,
    saveBulkRecom, userList, getUserList, savedRecomIds, sendBulkRecom, sendBulkRecomSuccess,
    clearSendBulkRecomVar, clearSaveBulkRecomVar, getRecomProfiles, recomProfiles, saveAndSendRecom,
    savedRecomDetails, recomDetail, clearSaveAndSendRecomVariables, setRecommendedProfiles,
    loadMoreProfileList, recomConfig, isRecomProfileLoading, profileCount,
    clearRecommendationAttributes, unsetRecommendedProfiles, clearGetRecomProfiles,
    setPreviousPath, userSession, previousFilters, previousSearchQuery, setFilterState
  } = props
  const userType = getUserType(userSession)
  const isHhOrAdHocUser = (userType === HH || userType === ADHOC)
  const [isEditView, setEditView] = useState(false)

  let hasViewOnlyAccess = false
  if (recomConfig) {
    const { userType } = recomConfig
    hasViewOnlyAccess = ((userType === HH) || (userType === ADHOC))
  }

  if (recomConfig && recomConfig.isEdit) {
    if (!isEditView) {
      setEditView(true)
    }
  }
  const [showAdvancedFiltersDialog, setAdvancedFiltersDialogOpen] = useState(false)
  const [showRecommendationDialog, setRecommendationDialog] = useState(false)
  const [showUsersDialog, setUsersDialog] = useState(false)
  const [showPositionDialog, setPositionDialog] = useState(false)
  const [recommendations, setActiveProfileRecommendations] = useState()
  const [approverUserId, setApproverUserId] = useState()
  const [isSendButtonClicked, setSendButtonStatus] = useState(false)
  const [shortlistedProfileIds, setShortListedProfileIds] = useState([])
  const [shortListedProfiles, setShortListedProfiles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTags, setSearchTags] = useState([])
  const [throttleTimer, setThrottleTimer] = useState()
  const [recomDetails, setRecomDetails] = useState()
  const [hasFilters, setHasFilters] = useState(false)
  const [changeButtonState, setButtonState] = useState(false)
  const [filterParams, setFilterParams] = useState('')
  const [showFooterOpen, setFooterOpen] = useState(false)
  const [profileRecommendations, setProfileRecommendations] = useState(constructRecommendationForFooter(positions))
  const profileListLen = profileList.length

  useEffect(() => {
    if (!entity && !positions) {
      if (isHhOrAdHocUser) {
        history.push('/sent')
      } else {
        history.push('/recommendation')
      }
    }

    if (isEditView || hasViewOnlyAccess) {
      fetchRecomProfiles()
    }

    if (!hasViewOnlyAccess) {
      if (previousFilters) {
        setHasFilters(true)
        setSearchTags(previousSearchQuery)
        fetchFilteredProfileList(previousFilters, previousSearchQuery)
      } else {
        fetchProfileList()
      }
    }
  }, [])

  useEffect(() => {
    if (isSendButtonClicked) {
      setSendButtonStatus(false)
      sendBulkRecommendation(approverUserId, savedRecomIds)
    } else {
      if (sendBulkRecomSuccess) {
        props.history.push('/sent-recommendation')
      } else if (savedRecomDetails) {
        props.history.push('/archive-recom/1')
      } else {
        if (savedRecomIds) {
          props.history.push('/archive-recom/1')
        }
      }
    }
  }, [savedRecomIds, sendBulkRecomSuccess, savedRecomDetails])

  useEffect(() => {
    if (recomDetail && !changeButtonState) {
      setRecomDetails(recomDetail)
    }
  }, [recomDetail])

  useEffect(() => {
    if (recomProfiles && recomProfiles.length) {
      if (!isHhOrAdHocUser) {
        populateSelectedProfiles(recomProfiles)
      }
    }
  }, [recomProfiles])

  useEffect(() => {
    if (throttleTimer) {
      clearTimeout(throttleTimer)
    }
    if (searchTags && searchTags.length) {
      setThrottleTimer(setTimeout(() => {
        if (!hasFilters) {
          fetchProfileList()
          setHasFilters(false)
        }
        setThrottleTimer(undefined)
      }, 400))
    }
  }, [searchTags])

  useEffect(() => {
    return () => {
      clearProfileList()
      clearSaveBulkRecomVar()
      clearSendBulkRecomVar()
      // unsetRecommendedProfiles()
      // clearGetRecomProfiles()
      clearSaveAndSendRecomVariables()
      // clearRecommendationAttributes()
    }
  }, [])

  const getRequestDelta = () => {
    const shortListedProfilesInitialIds = recomProfiles.map(profile => { return profile.profile.id })
    const delta = {
      addedProfiles: [],
      deletedProfiles: [],
      editedProfiles: []
    }
    delta.deletedProfiles = shortListedProfilesInitialIds.filter(id => !shortlistedProfileIds.includes(id))
    delta.addedProfiles = shortlistedProfileIds.filter(id => shortListedProfilesInitialIds.indexOf(id) === -1)
    delta.editedProfiles = shortlistedProfileIds.filter(id => !delta.deletedProfiles.includes(id) && !delta.addedProfiles.includes(id))

    delta.addedProfiles = getProfilesById(delta.addedProfiles, shortListedProfiles)
    delta.editedProfiles = getProfilesById(delta.editedProfiles, shortListedProfiles)
    return delta
  }

  const getProfilesById = (profileIds, shortListedProfiles) => {
    const profiles = []
    profileIds.forEach((profileId) => {
      shortListedProfiles.forEach(profile => {
        if (profile.id === profileId) {
          profiles.push({ profileId: profile.id, reason: profile.reason || '' })
        }
      })
    })
    return profiles
  }

  const showAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(true)
  }

  const hideAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(false)
  }

  const handleSearch = (value) => {
    setSearchQuery(value)
  }

  const handleSearchTags = (value) => {
    if (value.length) {
      const searchTagsCopy = deepCopy(searchTags)
      searchTagsCopy.push(value)
      setSearchTags(searchTagsCopy)
    }
    setSearchQuery('')
  }

  const removeTag = (index) => {
    const searchTagsCopy = deepCopy(searchTags)
    searchTagsCopy.splice(index, 1)
    setSearchTags(searchTagsCopy)
    if (!searchTagsCopy.length) {
      fetchProfileList(true)
    }
  }

  const getSearchQuery = (previousQuery) => {
    let search = ''
    if (previousQuery && previousQuery.length) {
      search = previousQuery.join(' ')
    } else if (searchTags && searchTags.length) {
      search = searchTags.join(' ')
    }
    if (searchQuery) {
      search = `${searchQuery} ${search}`
    }
    return search
  }

  const toggleRecommendationModal = (recommendations) => {
    setRecommendationDialog(!showRecommendationDialog)
    setActiveProfileRecommendations(recommendations)
  }

  const toggleUsersModal = () => {
    setUsersDialog(!showUsersDialog)
  }

  const togglePositionModal = () => {
    setPositionDialog(!showPositionDialog)
  }

  const fetchProfileList = (clear) => {
    console.log('fetchProfileList');
    setFilterState({ ...filterParams }, searchTags)
    getProfileList({
      isPublished: true,
      offset: 0,
      limit: 20,
      recommend:true,
      search: clear ? '' : getSearchQuery(),
      organization : entity ? entity.value :'',
      position: positions ? Array.prototype.map.call(positions, function(item){return item.value;}).join(","):'',
      ...filterParams
    })
  }

  const showMoreProfiles = () => {
    console.log('showMoreProfiles');
    setFilterState({ ...filterParams }, searchTags)
    loadMoreProfileList({
      isPublished: true,
      search: getSearchQuery(),
      limit: 20,
      recommend:true,
      ...filterParams,
      organization : entity ? entity.value :'',
      position: positions ? Array.prototype.map.call(positions, function(item) { return item.value; }).join(","):'',
      offset: profileList && profileList.length ? profileList.length : 0
    })
  }

  const fetchFilteredProfileList = (filters, previousQuery) => {
    console.log('fetchFilteredProfileList');
    setFilterState(filters, searchTags)
    setFilterParams(filters)
    hideAdvancedFilters()
    getProfileList({
      offset: 0,
      isPublished: true,
      limit: 20,
      recommend:true,
      search: getSearchQuery(previousQuery),
      organization : entity ? entity.value :'',
      position: positions ? Array.prototype.map.call(positions, function(item) { return item.value; }).join(","):'',
      ...filters
    })
  }

  const fetchUserList = () => {
    getUserList({
      userType: 'hh, adhoc',
      limit: 100,
      offset: 0
    })
  }

  const fetchRecomProfiles = () => {
    const { recomId } = recomConfig
    getRecomProfiles({
      recommendationId: recomId,
      limit: 100,
      offset: 0
    })
  }

  const populateSelectedProfiles = (recomProfiles) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const shortListedProfiles = []
    const shortListedProfileIds = []

    for (let recomProfileIndx = 0; recomProfileIndx < recomProfiles.length; recomProfileIndx++) {
      shortListedProfiles.push(recomProfiles[recomProfileIndx].profile)
      shortListedProfileIds.push(recomProfiles[recomProfileIndx].profile.id)
      profileRecommendationsCopy[0].profiles = shortListedProfiles
    }
    setShortListedProfileIds(shortListedProfileIds)
    setShortListedProfiles(shortListedProfiles)
    setProfileRecommendations(profileRecommendationsCopy)
  }

  const getSaveRecReq = () => {
    const req = []
    const recLen = profileRecommendations.length
    for (let recIndx = 0; recIndx < recLen; recIndx++) {
      const position = profileRecommendations[recIndx].position
      const profiles = profileRecommendations[recIndx].profiles
      req.push({
        positionId: position.value,
        entityId: entity.id,
        profiles: profiles.map((profile) => {
          return { profileId: profile.id, reason: profile.reason || '' }
        })
      })
    }
    return req
  }

  const saveBulkRecommendation = () => {
    saveBulkRecom({}, {
      recommendations: getSaveRecReq()
    })
  }

  const sendBulkRecommendation = (userId, recomIds) => {
    sendBulkRecom({}, {
      userIds: [userId],
      recommendationIds: recomIds
    })
  }

  const saveAndSendRecommendation = () => {
    const { recomId } = recomConfig
    saveAndSendRecom({ recommendationId: recomId }, {
      ...getRequestDelta(),
      entityId: entity.value
    })
  }

  const initiateSendBulkRecommendation = (userId) => {
    setApproverUserId(userId)
    setSendButtonStatus(true)
    toggleUsersModal(false)
    saveBulkRecommendation()
  }

  const addProfile = (profile, position) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      if (profileRecommendationsCopy[positionIndex].position.value === position) {
        profileRecommendationsCopy[positionIndex].profiles.push(profile)
        setShortListedProfileIds([...shortlistedProfileIds, profile.id])
        setShortListedProfiles([...shortListedProfiles, profile])
        break
      }
    }
    setProfileRecommendations(profileRecommendationsCopy)
    updateButtonStates()
  }

  const updateButtonStates = () => {
    const recomDetailsCopy = deepCopy(recomDetails)
    recomDetailsCopy.isDraft = false
    setButtonState(true)
    setRecomDetails(recomDetailsCopy)
  }

  const removeProfile = (positionId, profile) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      if (profileRecommendationsCopy[positionIndex].position.value === positionId) {
        const profiles = profileRecommendationsCopy[positionIndex].profiles
        for (let profileIdx = 0; profileIdx < profiles.length; profileIdx++) {
          if (profiles[profileIdx].id === profile.id) {
            profileRecommendationsCopy[positionIndex].profiles.splice(profileIdx, 1)
            setShortListedProfileIds(shortlistedProfileIds.filter(profileId => profileId !== profile.id))
            setShortListedProfiles(shortListedProfiles.filter(shortListedProfile => shortListedProfile.id !== profile.id))
            break
          }
        }
      }
    }
    setProfileRecommendations(profileRecommendationsCopy)
    updateButtonStates()
  }

  const updateReasonForNomination = (reason, profileId) => {
    const profileRecommendationsCopy = deepCopy(profileRecommendations)
    const shortListedProfilesCopy = deepCopy(shortListedProfiles)
    const positionLen = profileRecommendationsCopy.length
    for (let positionIndex = 0; positionIndex < positionLen; positionIndex++) {
      const profiles = profileRecommendationsCopy[positionIndex].profiles
      for (let profileIdx = 0; profileIdx < profiles.length; profileIdx++) {
        if (profiles[profileIdx].id === profileId) {
          profiles[profileIdx].reason = reason
          break
        }
      }
    }
    for (let shortListedProfileIndx = 0; shortListedProfileIndx < shortListedProfilesCopy.length; shortListedProfileIndx++) {
      const shortListedProfile = shortListedProfilesCopy[shortListedProfileIndx]
      if (shortListedProfile.id === profileId) {
        shortListedProfile.reason = reason
        break
      }
    }
    updateButtonStates()
    setShortListedProfiles(shortListedProfilesCopy)
    setProfileRecommendations(profileRecommendationsCopy)
  }

  const getRecommendedProfiles = () => {
    let profilesToShow = []
    if (recomProfiles && profileList) {
      const alreadySelectedProfiles = recomProfiles.map(profile => { return profile.profile })
      const alreadySelectedProfileIds = recomProfiles.map(profile => { return profile.profile.id })
      profilesToShow = [...profilesToShow, ...alreadySelectedProfiles]
      const loadedProfileLen = profileList.length
      for (let profileIndx = 0; profileIndx < loadedProfileLen; profileIndx++) {
        const profile = profileList[profileIndx]
        if (!alreadySelectedProfileIds.includes(profile.id)) {
          profilesToShow.push(profile)
        }
      }
    }
    return profilesToShow
  }

  const handleToggleFooter = () => {
    setFooterOpen(!showFooterOpen)
  }

  const handleTabClick = () => {
    if (!showFooterOpen) {
      setFooterOpen(true)
    }
  }

  const renderAdvancedFilter = () => {
    return (
      <AdvancedFilters
        {...props}
        fetchFilteredProfileListCallback={(filters) => fetchFilteredProfileList(filters)}
        handleClose={hideAdvancedFilters}
        filterParams={filterParams}
        clearFilterCallback={() => setFilterParams('')}
      />
    )
  }

  const handleNavigateToProfileDetails = (profileId) => {
    setPreviousPath(props.match)
    if (isHhOrAdHocUser) {
      const recomProfileList = getRecomProfilesForProfileDetailScreen(recomProfiles)
      setRecommendedProfiles(recomProfileList)
    } else {
      setRecommendedProfiles(shortListedProfiles)
    }
    props.history.push(`/profile-details/${profileId}`)
  }

  const getRecomProfilesForProfileDetailScreen = (recommendations) => {
    const result = []
    for (let index = 0; index < recommendations.length; index++) {
      const profile = recommendations[index].profile
      result.push(profile)
    }
    return result
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <div className='icon-wrapper'>
          <img src={SEARCH_PROFILE} alt='logo' className='search-profile-icon' />
        </div>
        <Typography className='empty-state-text'>{t('advancedSearch.emptyStateMessage')}</Typography>
      </div>
    )
  }

  const renderProfileList = () => {
    if (isEditView || hasViewOnlyAccess) {
      const profiles = getRecommendedProfiles()
      return (
        <InfiniteScroll
          className='profile-list-container'
          scrollThreshold={0.75}
          next={showMoreProfiles}
          dataLength={profileListLen}
          loader={renderLoadMoreLoader()}
          height='calc(100vh - 140px - 80px)'
          hasMore={profileCount > profileListLen}
          style={{
            marginBottom: (shortlistedProfileIds && shortlistedProfileIds.length)
              ? showFooterOpen
                ? '200px' : '0px' : '0px'
          }}
        >
          {profiles.map((profile, index) => (
            <ProfileCard
              {...props}
              key={index}
              profile={profile}
              positions={positions}
              isMultiplePositionFlow={positions && positions.length > 1}
              shortlistedProfileIds={shortlistedProfileIds}
              navigateToProfileDetailsCallback={(profileId) => handleNavigateToProfileDetails(profileId)}
              updateReasonForNominationCallback={(reason, profileId) => updateReasonForNomination(reason, profileId)}
              removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
              addProfileCallback={(selectedPos, profile) => addProfile(selectedPos, profile)}
              handleBandClick={(recommendations) => toggleRecommendationModal(recommendations)}
            />
          ))}
        </InfiniteScroll>
      )
    }

    return (
      <InfiniteScroll
        className='profile-list-container'
        scrollThreshold={0.75}
        next={showMoreProfiles}
        dataLength={profileListLen}
        loader={renderLoadMoreLoader()}
        height='calc(100vh - 140px - 80px)'
        hasMore={profileCount > profileListLen}
        style={{
          marginBottom: (shortlistedProfileIds && shortlistedProfileIds.length)
            ? showFooterOpen
              ? '200px' : '0px' : '0px',
          paddingTop: searchTags && searchTags.length ? '80px' : '120px'
        }}
      >
        {(profileList && profileList.length) && profileList.map((profile, index) => (
          <ProfileCard
            {...props}
            key={index}
            profile={profile}
            positions={positions}
            isMultiplePositionFlow={positions && positions.length > 1}
            shortlistedProfileIds={shortlistedProfileIds}
            navigateToProfileDetailsCallback={(profileId) => handleNavigateToProfileDetails(profileId)}
            updateReasonForNominationCallback={(reason, profileId) => updateReasonForNomination(reason, profileId)}
            removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
            addProfileCallback={(selectedPos, profile) => addProfile(selectedPos, profile)}
            handleBandClick={(recommendations) => toggleRecommendationModal(recommendations)}
          />
        ))}
      </InfiniteScroll>
    )
  }

  const renderLoadMoreLoader = () => {
    return (
      <div className='load-more-button-wrapper'>
        <Loader color='blue' />
      </div>
    )
  }

  const renderRecommendationDialog = () => {
    return (
      <RecommendationDialog
        {...props}
        recommendations={recommendations}
        handleClose={toggleRecommendationModal}
      />
    )
  }

  const renderSearchTags = () => {
    return (
      <div className='search-tags-container'>
        <div className='search-tag-view'>
          {searchTags.map((tag, index) => (
            <div className='search-tag' key={index}>
              <img src={BLUE_CLOSE} alt='close' className='close-icon' onClick={() => removeTag(index)} />
              <Typography className='tag-text'>{tag}</Typography>
            </div>))}
        </div>
      </div>
    )
  }

  const renderPositionDialog = () => {
    return (
      <PositionDialog
        {...props}
        positions={positions}
        handleClose={togglePositionModal}
      />
    )
  }

  const renderUsersDialog = () => {
    return (
      <UsersDialog
        {...props}
        userList={userList}
        getUserListCallback={fetchUserList}
        handleUserClick={(userId) => initiateSendBulkRecommendation(userId)}
        handleClose={toggleUsersModal}
      />
    )
  }

  return (
    <div className='position-recommendation-search-container'>
      <RecommendationHeader
        {...props}
        positionClickCallback={togglePositionModal}
        hasViewOnlyAccess={hasViewOnlyAccess}
      />
      <div className='search-input-wrapper'>
        <SearchInput
          showFilters
          value={searchQuery}
          filterButtonCallback={showAdvancedFilters}
          handleChange={handleSearch}
          handleEnter={handleSearchTags}
        />
      </div>
      {searchTags && searchTags.length ? renderSearchTags() : null}
      {(isLoading || isRecomProfileLoading)
        ? <Loader color='blue' />
        : ((profileList && profileList.length) || (recomProfiles && recomProfiles.length))
          ? renderProfileList()
          : renderEmptyState()}
      <RecommendationFooter
        {...props}
        isEditView={isEditView}
        recomDetails={recomDetails}
        showFooterOpen={showFooterOpen}
        hasViewOnlyAccess={hasViewOnlyAccess}
        recommendations={profileRecommendations}
        shortlistedProfileIds={shortlistedProfileIds}
        saveRecommendationCallback={saveBulkRecommendation}
        saveAndSendRecomCallback={saveAndSendRecommendation}
        sendRecommendationCallback={toggleUsersModal}
        toggleFooterCallback={handleToggleFooter}
        tabClickCallback={handleTabClick}
        removeProfileCallback={(positionId, profile) => removeProfile(positionId, profile)}
      />
      {showAdvancedFiltersDialog && renderAdvancedFilter()}
      {showRecommendationDialog && renderRecommendationDialog()}
      {showUsersDialog && renderUsersDialog()}
      {showPositionDialog && renderPositionDialog()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    profileList: state.advancedSearch.profileList,
    previousFilters: state.advancedSearch.previousFilters,
    previousSearchQuery: state.advancedSearch.previousSearchQuery,
    moreProfilesLoading: state.advancedSearch.moreProfilesLoading,
    isLoading: state.advancedSearch.isLoading,
    isRecomProfileLoading: state.recommendation.recomLoading,
    entity: state.recommendation.entity,
    positions: state.recommendation.positions,
    recomDetail: state.recommendation.recomDetail,
    recomConfig: state.recommendation.recomConfig,
    savedRecomIds: state.recommendation.savedRecomIds,
    recomProfiles: state.recommendation.recomProfiles,
    savedRecomDetails: state.recommendation.savedRecomDetails,
    sendBulkRecomSuccess: state.recommendation.sendBulkRecomSuccess,
    recomLoading: state.recommendation.recomLoading,
    userSession: state.auth.userSession,
    userList: state.common.userList,
    profileCount: state.advancedSearch.profileCount,
    recomProfilesCount: state.recommendation.recomProfilesCount
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    getProfileList,
    getRecomProfiles,
    clearProfileList,
    saveBulkRecom,
    setFilterState,
    sendBulkRecom,
    saveAndSendRecom,
    getUserList,
    clearSendBulkRecomVar,
    clearSaveBulkRecomVar,
    setRecommendedProfiles,
    unsetRecommendedProfiles,
    loadMoreProfileList,
    clearGetRecomProfiles,
    clearRecommendationAttributes,
    clearSaveAndSendRecomVariables,
    setPreviousPath
  })(PositionRecommendationResults)
)
