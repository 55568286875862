import React from 'react'
import TabOneFilters from './tab-one-filters.component'
import TabTwoFilters from './tab-two-filters.component'
import TabThreeFilters from './tab-three-filters.component'

import './filters.component.scss'

export default function Filters (props) {
  const {
    activeIndex, dropdownChangeCallback, fetchOrgTypesCallback, fetchOrgListCallback,
    removeEntityCallback, onOrgChangeCallback, showErrorDialogCallback, fetchProfileListCallback,
    fetchTierListCallback, fetchEducationLevelListCallback, fetchGenderListCallback,
    onChangeCallback, onChangeDescCallback, activeDirection
  } = props

  const renderTabOneFilters = () => {
    return (
      <TabOneFilters
        {...props}
        activeIndex={activeIndex}
        activeDirection={activeDirection}
        dropdownChangeCallback={(value, key) => dropdownChangeCallback(value, key)}
        fetchOrgTypesCallback={fetchOrgTypesCallback}
        fetchOrgListCallback={(entityType, searchQuery) => fetchOrgListCallback(entityType, searchQuery)}
        onOrgChangeCallback={(value, key) => onOrgChangeCallback(value, key)}
        removeEntityCallback={(entityId, index) => removeEntityCallback(entityId, index)}
        showErrorDialogCallback={showErrorDialogCallback}
      />
    )
  }

  const renderTabTwoFilters = () => {
    return (
      <TabTwoFilters
        {...props}
        activeIndex={activeIndex}
        activeDirection={activeDirection}
        dropdownChangeCallback={(value, key) => dropdownChangeCallback(value, key)}
        onChangeCallback={(value, key) => onChangeCallback(value, key)}
        fetchProfileListCallback={(queryParams) => fetchProfileListCallback(queryParams)}
        fetchTierListCallback={fetchTierListCallback}
        fetchEducationLevelListCallback={fetchEducationLevelListCallback}
        fetchGenderListCallback={fetchGenderListCallback}
        showErrorDialogCallback={showErrorDialogCallback}
      />
    )
  }

  const renderTabThreeFilters = () => {
    return (
      <TabThreeFilters
        {...props}
        activeIndex={activeIndex}
        activeDirection={activeDirection}
        onChangeCallback={(value, key) => onChangeCallback(value, key)}
        onChangeDescCallback={(value) => onChangeDescCallback(value)}
      />
    )
  }

  return (
    <div className='insights-filters-container'>
      {activeIndex === 0
        ? renderTabOneFilters()
        : activeIndex === 1
          ? renderTabTwoFilters()
          : activeIndex === 2
            ? renderTabThreeFilters()
            : null}
    </div>
  )
}
