import React from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE } from '../../../constants/image-reference'

import './position-dialog.component.scss'

export default function PositionDialog (props) {
  const { handleClose, positions, boardMembers } = props
  return (
    <Dialog size='sm' className='position-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>وظائف مختارة في مؤسسة مطارات دبي</Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' className='close-button' onClick={handleClose} />
      </div>
      <div className='dialog-content'>
        <ul className='position-list'>
          {positions.map((position, index) => (
            <li key={index} className='position'>
              <Typography className='position-text'>{position.label}</Typography>
            </li>
          ))}
          {boardMembers && boardMembers.length &&
            <ul className='board-member-list'>
              {boardMembers.map((boardMember) => (
                <li key={boardMember.id} className='board-member'>
                  <Typography className='member-text'>{boardMember.label}</Typography>
                </li>
              ))}
            </ul>}
        </ul>
      </div>
    </Dialog>
  )
}
