import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { isUserSessionActive } from '../helpers/auth'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import styled from 'styled-components'
import StyleGuide from '../app/style-guide'
import Login from '../app/auth/login/login.component'
import Profile from '../app/profile/profile.component'
import Insights from '../app/insights/insights.component'
import Sectors from '../app/sectors/sectors.component'
import SentList from '../app/sent-list/sent-list.component'
import BaseLayout from '../common/base-layout/base-layout.component'
import ProfileNetwork from '../app/profile/network/network.component'
import SentInsights from '../app/sent-insight/sent-insight.component'
import NewInsight from '../app/insights/new-insight/new-insight.component'
import Recommendation from '../app/recommendation/recommendation.component'
import EditInsight from '../app/insights/edit-insight/edit-insight.component'
import AdvancedSearch from '../app/advanced-search/advanced-search.component'
import SentRecommendation from '../app/sent-recommendation/sent-recommendation.component'
import ArchiveInsight from '../app/sent-insight/archive-insight/archive-insight.component'
import ArchiveRecom from '../app/sent-recommendation/archive-recom/archive-recom.component'
import EntityMemberList from '../app/profile/entity-member-list/entity-member-list.component'
import BoardMemberList from '../app/sent-recommendation/board-member-list/board-member-list.component'
import BoardRecommendationResults from '../app/recommendation/board-recommendation-results/board-recommendation-results.component'
import PositionRecommendationResults from '../app/recommendation/position-recommendation-results/position-recommendation-results.component'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isUserSessionActive() ? <BaseLayout {...props}><Component {...props} /></BaseLayout> : <Redirect to='/' />
    )}
  />
)

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      !isUserSessionActive() ? <Component {...props} /> : <Redirect to='/advanced-search' />
    )}
  />
)

export default () => {
  return (
    <BrowserRouter>
      <Route
        render={({ location }) => {
          const { key } = location

          return (
            <Wrapper>
              <TransitionGroup>
                <CSSTransition
                  key={key}
                  timeout={{ enter: 300, exit: 300 }}
                  classNames='fade'
                >
                  <Switch location={location}>
                    <Route exact path='/style-guide' component={StyleGuide} />
                    <PublicRoute exact path='/' component={Login} />
                    <PublicRoute exact path='/login' component={Login} />
                    <PrivateRoute exact path='/profile-details/:profileId' component={Profile} />
                    <PrivateRoute exact path='/advanced-search' component={AdvancedSearch} />
                    <PrivateRoute exact path='/sent-recommendation' component={SentRecommendation} />
                    <PrivateRoute exact path='/sent-insight' component={SentInsights} />
                    <PrivateRoute exact path='/sent' component={SentList} />
                    <PrivateRoute exact path='/archive-recom/:tabIndex' component={ArchiveRecom} />
                    <PrivateRoute exact path='/archive-insight/:tabIndex' component={ArchiveInsight} />
                    <PrivateRoute exact path='/recommendation' component={Recommendation} />
                    <PrivateRoute exact path='/recommendation/:recomId/members' component={BoardMemberList} />
                    <PrivateRoute exact path='/orgs/:orgId/members' component={EntityMemberList} />
                    <PrivateRoute exact path='/position-recommendation-results' component={PositionRecommendationResults} />
                    <PrivateRoute exact path='/board-recommendation-results' component={BoardRecommendationResults} />
                    <PrivateRoute exact path='/insights' component={Insights} />
                    <PrivateRoute exact path='/insights/:insightId/edit' component={EditInsight} />
                    <PrivateRoute exact path='/profiles/:profileId/network' component={ProfileNetwork} />
                    <PrivateRoute exact path='/insights/new' component={NewInsight} />
                    <PrivateRoute exact path='/sectors' component={Sectors} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Wrapper>
          )
        }}
      />
    </BrowserRouter>
  )
}

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.9;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0.9;
    transition: opacity 200ms ease-out;
  }
`
