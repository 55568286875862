export function snakeToCamel (s) {
  return s.replace(/(_\w)/g, function (m) { return m[1].toUpperCase() })
}

export function camelToSnake (s) {
  return s.replace(/([A-Z])/g, function (m) { return '_' + m[0].toLowerCase() })
}

export function convertToLowerCase (string) {
  return string ? String(string).toLowerCase() : ''
}

export function getErrorMessage (error) {
  let errorString = ''
  if (!error) {
    errorString = ''
  } else if (error instanceof Array) {
    errorString = error.toString()
  } else if (error instanceof Object && error.message) {
    errorString = error.message
  } else if (typeof error === 'string') {
    errorString = error
  } else {
    for (const key in error) {
      errorString = `${errorString} ${key} : ${getErrorMessage(error[key])}`
    }
  }

  return errorString
}

export function replaceUnderscoreWithSpace (string) {
  if (string) {
    return string.replace(/_/g, ' ')
  }
}

export function getStringFromArrayOfObjects (array, key) {
  return array.map(function (elem) {
    return elem[key]
  }).join(', ')
}

export const getUserType = (session) => {
  if (session) {
    const { user } = session
    return user.userType
  }
}

export const getMainTag = (tags) => {
  if (tags && tags.length) {
    const tagsArr = tags.split(',')
    return tagsArr[tagsArr.length - 1]
  }
  return ''
}

export const getTrimmedString = (str) => {
  if (str) {
    return str.replace(/\s+$/, '')
  }
  return ''
}

export const imageBaseUrl = process.env.REACT_APP_API_ENDPOINT
  ? `${process.env.REACT_APP_API_ENDPOINT}`
  : `${window.location.origin}`
