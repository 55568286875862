import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { PROFILE, LEFT_ARROW_CIRCLE } from '../../constants/image-reference'
import { getRecom, loadMoreRecom, deleteRecom } from '../recommendation/recommendation.action-creator'
import { getUserType } from '../../utils/string.utils'
import { HH, ADHOC, BOARD } from '../../constants/misc'

import Loader from '../../common/loader/loader.component'
import InfiniteScroll from 'react-infinite-scroll-component'
import RecomCard from '../../common/recom-card/recom-card.component'

import './sent-recommendation.component.scss'

function SentRecommendation (props) {
  const { recom, isLoading, t, recomCount, userSession } = props
  const userType = getUserType(userSession)

  useEffect(() => {
    fetchRecom()
  }, [])

  const fetchRecom = () => {
    props.getRecom({
      queryParams: {
        limit: 5,
        offset: 0,
        isSent: true,
        isDeleted: false
      }
    })
  }

  const loadMoreRecom = () => {
    const { recom } = props
    props.loadMoreRecom({
      queryParams: {
        limit: 5,
        offset: recom && recom.length ? recom.length : 0,
        isSent: true,
        isDeleted: false
      }
    })
  }

  const deleteRecom = (recomId) => {
    props.deleteRecom({
      queryParams: {
        recommendationId: recomId
      },
      token: {
        token: `jwt ${props.token}`
      }
    })
  }

  const renderRecomList = () => {
    const recomListLen = recom.length
    return (
      <InfiniteScroll
        next={loadMoreRecom}
        scrollThreshold={0.75}
        dataLength={recomListLen}
        className='recom-list-view'
        hasMore={recomCount > recomListLen}
        height='calc(100vh - 140px - 80px)'
        loader={(
          <div className='pagination'>
            <Loader color='white' />
          </div>
        )}
      >
        {recom.map((data, index) => (
          <RecomCard
            {...props}
            index={index}
            key={index}
            sentRecomPage
            recomDetails={data}
            deleteRecomCallback={(recomId) => deleteRecom(recomId)}
            onClickCallback={(recomDetails) => clickHandle(recomDetails)}
          />
        ))}
      </InfiniteScroll>
    )
  }

  const clickHandle = (recomDetails) => {
    const { id, recommendationType } = recomDetails
    if (userType === HH || userType === ADHOC) {
      if (recommendationType === BOARD) {
        props.history.push(`/recommendation/${id}/members`)
      }
    }
  }

  const handleArchiveClick = () => {
    props.history.push('/archive-recom/0')
  }

  const goBack = () => {
    props.history.push('/sent')
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>
          {t('recommendation.noRecommendationsFound')}
        </Typography>
      </div>
    )
  }

  return (
    <div className='sent-recommendation-container'>
      <div className='header-container'>
        <div className='title-container'>
          <div className='nomination-title'>
            <img src={PROFILE} alt='Users' className='user-icon' />
            <Typography className='nomination-text'>{t('footer.nominations')}</Typography>
          </div>
          <Button onClick={handleArchiveClick} className='archive-button' variant='outlined' color='secondary'>
            {t('common.archive')}
          </Button>
        </div>
        <img src={LEFT_ARROW_CIRCLE} alt='Back' className='back-icon' onClick={goBack} />
      </div>
      {isLoading ? <Loader color='white' /> : (recom && recom.length) ? renderRecomList() : renderEmptyState()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // recommendation
    recom: state.recommendation.recom,
    isLoading: state.recommendation.recomLoading,
    moreRecomLoading: state.recommendation.moreRecomLoading,
    recomCount: state.recommendation.recomCount,

    // auth
    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    getRecom,
    deleteRecom,
    loadMoreRecom
  })(SentRecommendation))
