import React, { useRef } from 'react'
import { TextField, makeStyles, Paper, Button, Typography } from '@material-ui/core'
import { SEARCH_FILTER, SEARCH_ICON } from '../../constants/image-reference'

import './search-input.scss'

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

function SearchInput (props) {
  const inputRef = useRef(null)
  const { handleChange, filterButtonCallback, handleBlur, value, handleEnter, showFilters } = props

  const onChange = event => {
    if (handleChange) {
      handleChange(event.target.value)
    }
  }

  const onBlur = (event) => {
    if (handleBlur) {
      handleBlur(event.target.value)
    }
  }

  const handleClick = () => {
    filterButtonCallback()
  }

  const handleSearchInputIconClick = () => {
    const parentEle = inputRef.current.children[0]
    const inputEle = parentEle.children[0]
    inputEle.focus()
  }

  const keyPressed = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (handleEnter) {
        handleEnter(event.target.value)
      }
    }
  }

  const renderSearchInputIcon = () => {
    return (
      <div className='placeholder-wrapper' onClick={handleSearchInputIconClick}>
        <img src={SEARCH_ICON} alt='Search profiles' className='search-input-icon' />
        <Typography className='placeholder-text'>بحث</Typography>
      </div>
    )
  }

  return (
    <Paper component='form' className='search-input-container'>
      <div className='input-wrapper'>
        <TextField
          className='search-input'
          margin='normal'
          variant='outlined'
          fullWidth
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={keyPressed}
          ref={inputRef}
        />
        {(value.length === 0) && renderSearchInputIcon()}
      </div>
      {showFilters &&
        <div className='filter-button-wrapper'>
          <Button
            onClick={handleClick}
            classes={{ root: 'filter-button' }}
            startIcon={<img src={SEARCH_FILTER} alt='logo' className='search-button-icon' />}
          ><Typography className='filter-button-text'>تصنيف</Typography>
          </Button>
        </div>}
    </Paper>
  )
}

export default SearchInput
