import { connect } from 'react-redux'
import { Typography, Divider } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { translateOptions } from '../../i18n/index'
import { doScrolling } from '../../utils/animation'
import { getProfile } from './profile.action-creator'
import { imageBaseUrl, getMainTag, getTrimmedString } from '../../utils/string.utils'
import { constructDescriptionDetails } from '../../utils/profile-description.util'
import { unsetRecommendedProfiles } from '../recommendation/recommendation.action-creator'
import {
  AVATAR, RESIZE, RISING_STAR, USERS, FUTURISTIC, DOWN_ARROW, INSTAGRAM, FACEBOOK, TWITTER, USER,
  LINKEDIN, PERSONAL_VISION, CONTRIBUTE, PINK_CIRCLE
} from '../../constants/image-reference'

import Loader from '../../common/loader/loader.component'
import Accordion from '../../common/expansion-panel/expansion-panel'
import OpinionDialog from './opinion-dialog/opinion-dialog.component'
import ReferenceDialog from './reference-dialog/reference-dialog.component'
import MediaLibrary from '../../common/media-library/media-library.component'
import AchievementDialog from './achievement-dialog/achievement-dialog.component'
import PositionDialog from '../recommendation/position-dialog/position-dialog.component'
import RecommendationHeader from '../recommendation/header/recommendation-header.component'

import './profile.component.scss'
import BoardMemberListHeader from '../sent-recommendation/board-member-list/board-member-list-header.component'

function Profile (props) {
  const [showAchievementDialog, setAchievementDialog] = useState(false)
  const [showOpinionDialog, setOpinionDialog] = useState(false)
  const [showReferenceDialog, setReferenceDialog] = useState(false)
  const [activeEntity, setActiveEntity] = useState()
  const [showPositionDialog, setPositionDialog] = useState(false)
  const [activeAchievementIndex, setActiveAchievementIndex] = useState()
  const [showMediaLibrary, setShowMediaLibrary] = useState(false)
  const [otherRecomProfiles, setOtherRecomProfiles] = useState([])

  const { t, match, history, getProfile } = props
  const { profile, positions, recommendedProfiles, recomDetails } = props

  useEffect(() => {
    const profileId = match.params.profileId
    fetchProfileDetails(profileId)
    console.log(recommendedProfiles)
    if (recommendedProfiles && recommendedProfiles.length) {
      constructOtherRecomProfiles(profileId)
    }
  }, [])

  useEffect(() => {
    return () => {
      clearRecommendationProfiles()
    }
  })

  const clearRecommendationProfiles = () => {
    unsetRecommendedProfiles()
  }

  const toggleAchievementDialog = () => {
    setAchievementDialog(!showAchievementDialog)
  }

  const toggleOpinionDialog = () => {
    setOpinionDialog(!showOpinionDialog)
  }

  const toggleReferenceDialog = () => {
    setReferenceDialog(!showReferenceDialog)
  }

  const handleNetworkClick = () => {
    const profileId = match.params.profileId
    history.push(`/profiles/${profileId}/network`)
  }

  const handleEntityClick = (entity) => {
    history.push(`/orgs/${entity.id}/members`)
  }

  const fetchProfileDetails = (profileId) => {
    if (recommendedProfiles && recommendedProfiles.length) {
      constructOtherRecomProfiles(profileId)
    }
    getProfile({ profileId })
  }

  const constructOtherRecomProfiles = (profileId) => {
    const recomProfiles = []
    recommendedProfiles.map(profile => {
      if (profile.id !== Number(profileId)) {
        recomProfiles.push({
          id: profile.id,
          profileImage: profile.profileImage ? profile.profileImage.url : ''
        })
      }
    })
    setOtherRecomProfiles(recomProfiles)
  }

  const handleLinkClick = (entity, type) => {
    setActiveEntity(entity)
    if (type === 'opinion') {
      toggleOpinionDialog()
    } else if (type === 'reference') {
      toggleReferenceDialog()
    }
  }

  const handleAchievementLinkClick = (entity, index) => {
    setActiveEntity(entity)
    setActiveAchievementIndex(index + 1)
    toggleAchievementDialog()
  }

  const showLeadershipAccordion = (leadership) => {
    if (leadership.skills.length ||
      leadership.positions.length ||
      leadership.achievements.length) {
      return true
    }
    return false
  }

  const showExperienceAccordion = (experience) => {
    if (experience.educationCertifications.length ||
      experience.memberships.length ||
      experience.certifications.length) {
      return true
    }
    return false
  }

  const showPersonalityAccordion = (personality) => {
    if (personality.socialMedia.length ||
      personality.latestNews.length ||
      personality.references.length) {
      return true
    }
    return false
  }

  const showDescSection = () => {
    const { summaryAr } = profile
    if (summaryAr || constructDescriptionDetails(profile).length) {
      return true
    }
    return false
  }

  const openMediaLibrary = () => {
    const { media } = profile
    if ((media && media.mediaLinks.length) || (media && media.profileImage.url)) {
      setShowMediaLibrary(true)
    }
  }

  const getPositionDuration = (startYear, endYear) => {
    if (startYear && endYear) {
      return `${startYear} - ${endYear}`
    } else if (startYear && endYear === null) {
      return `${startYear} - حالياً`
    } else {
      return null
    }
  }

  const renderPositions = (leadership) => {
    const { positions } = leadership
    if (positions && positions.length) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>منـصـب القـيـادة</Typography>
          <div className='position-list'>
            {positions.map((position) => (
              <div key={position.id} className='position'>
                <div className='icon-wrapper' style={{ marginTop: position.startYear ? '0px' : '8px' }}>
                  <div className='position-icon' />
                </div>
                <div className='position-details'>
                  {getPositionDuration(position.startYear, position.endYear) && (
                    <Typography className='duration'>
                      {getPositionDuration(position.startYear, position.endYear)}
                    </Typography>)}
                  <Typography className='designation'>
                    {position.position ? position.position.positionAr : ''}
                  </Typography>
                  <Typography className='organisation'>
                    {position.organization ? position.organization.nameAr : ''}
                  </Typography>
                </div>

              </div>
            ))}
          </div>
        </div>
      )
    }
    return null
  }

  const renderAchievementsOfLeader = (leadership) => {
    const { achievements } = leadership
    if (achievements && achievements.length) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>إنـجـازات القـائـد</Typography>
          <div className='achievement-list'>
            {achievements.map((achievement, index) => (
              <div key={achievement.id} className='achievement'>
                <div className='icon-wrapper'>
                  <div className='achievement-icon'>{index + 1}</div>
                </div>
                <div className='achievement-details'>
                  {achievement.date && <Typography className='duration'>{achievement.date ? achievement.date.split('-')[0] : '-'}</Typography>}
                  <Typography className='designation'>{achievement.roleAr ? achievement.roleAr : ''}</Typography>
                  <Typography className='description'>{achievement.descriptionAr ? achievement.descriptionAr : ''}</Typography>
                  <Typography className='department link' onClick={() => handleAchievementLinkClick(achievement, index)}>المزيد عن الإنجاز</Typography>
                </div>
              </div>))}
          </div>
        </div>
      )
    }
    return null
  }

  const renderCertificates = (experience) => {
    const { educationCertifications, memberships, certifications } = experience
    return (
      <div className='section-wrapper'>
        <div className='certificate-content certificate-row-one'>
          {(certifications && certifications.length) ? (
            <div className='certificate-wrapper'>
              <Typography className='title'>شهـادات الـخبـرة</Typography>
              <div className='certificate-list'>
                {certifications.map((certification) => (
                  certification.year || certification.name || certification.trainingCourse || certification.provider ? (
                    <div key={certification.id} className='certificate'>
                      <div className='icon-wrapper'>
                        <div className='certificate-icon' />
                      </div>
                      <div className='certificate-details'>
                        <Typography className='duration'>{certification.year ? certification.year : ''}</Typography>
                        <Typography className='certificate-name-wrapper'>
                          <span className='certificate-name'>
                            {certification.name ? certification.name : certification.trainingCourse ? certification.trainingCourse : ''}
                          </span>
                          <span className='certificate-short-form' style={{ marginRight: '5px' }}>
                            {certification.provider ? certification.provider : ''}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : null
                ))}
              </div>
            </div>) : null}
          {(educationCertifications && educationCertifications.length) ? (
            <div className='certificate-wrapper'>
              <Typography className='title'>شهـادات تعليمية</Typography>
              <div className='certificate-list'>
                {educationCertifications.map((educationCertification) => (
                  educationCertification.year || educationCertification.specializationAr || educationCertification.universityAr ? (
                    <div key={educationCertification.id} className='certificate'>
                      <div className='icon-wrapper'>
                        <div className='certificate-icon' />
                      </div>
                      <div className='certificate-details'>
                        <Typography className='duration'>{educationCertification.year ? educationCertification.year : ''}</Typography>
                        <div className='certificate-name-wrapper'>
                          <Typography className='degree-name' style={{ display: 'inline' }}>
                            {getCertificateDetails(educationCertification)}
                          </Typography>
                          <Typography className='certificate-short-form' style={{ display: 'inline-block' }}>
                            {educationCertification.universityAr ? getTrimmedString(educationCertification.universityAr) : ''}
                          </Typography>
                        </div>
                      </div>
                    </div>) : null
                ))}
              </div>
            </div>) : null}
        </div>
        {(memberships && memberships.length) ? (
          <div className='certificate-content'>
            <div className='certificate-wrapper'>
              <Typography className='title'>العضويات</Typography>
              <div className='certificate-list'>
                {memberships.map((membership) => (
                  <div key={membership.id} className='certificate'>
                    <div className='icon-wrapper'>
                      <div className='certificate-icon' />
                    </div>
                    <div className='certificate-details'>
                      <Typography className='duration'>{membership.joinDate ? membership.joinDate : ''}</Typography>
                      <Typography className='certificate-name-wrapper'>
                        <span className='certificate-name'>{membership.board.nameAr}</span>
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>) : null}
      </div>
    )
  }

  const getCertificateDetails = (educationCertification) => {
    const { degree, specializationAr, universityAr } = educationCertification
    let certificateDetails = ''
    if (educationCertification) {
      if (degree && specializationAr && universityAr) {
        certificateDetails = `${degree.nameAr} في ${specializationAr} من `
      } else if (degree && !specializationAr && !universityAr) {
        certificateDetails = `${degree.nameAr} `
      } else if (specializationAr && !degree && !universityAr) {
        certificateDetails = `${specializationAr} `
      } else if (degree && specializationAr && !universityAr) {
        certificateDetails = `${degree.nameAr} في ${specializationAr} `
      } else if (!degree && specializationAr && universityAr) {
        certificateDetails = `${specializationAr} من `
      } else if (degree && !specializationAr && universityAr) {
        certificateDetails = `${degree.nameAr} من `
      }
    }
    return getTrimmedString(certificateDetails)
  }

  const renderOpinions = (personality) => {
    const { level } = personality
    const isReasonToJoinVisible = level.reasonToJoin && level.reasonToJoin.length
    const isPersonalVisionVisible = level.personalVision && level.personalVision.length
    const isImpactOfProgramVisible = level.impactOfProgram && level.impactOfProgram.length

    if (isReasonToJoinVisible || isPersonalVisionVisible || isImpactOfProgramVisible) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>آراء</Typography>
          <div className='opinion-list'>
            {isReasonToJoinVisible && (
              <div className='opinion'>
                <div className='icon-wrapper'>
                  <img src={USER} alt='Opinion' className='opinion-icon' />
                </div>
                <div className='opinion-details'>
                  <Typography className='reason'>سبب الإلتحاق بالبرنامج</Typography>
                  <Typography className='description'>{level.reasonToJoin}</Typography>
                  <Typography
                    className='hyperlink'
                    onClick={() => handleLinkClick({ title: 'سبب الإلتحاق بالبرنامج', description: level.reasonToJoin }, 'opinion')}
                  >المزيد
                  </Typography>
                </div>
              </div>)}
            {isPersonalVisionVisible && (
              <div className='opinion'>
                <div className='icon-wrapper'>
                  <img src={PERSONAL_VISION} alt='Opinion' className='opinion-icon' />
                </div>
                <div className='opinion-details'>
                  <Typography className='reason'>الرؤية الشخصية</Typography>
                  <Typography className='description'>{level.personalVision}</Typography>
                  <Typography
                    className='hyperlink'
                    onClick={() => handleLinkClick({ title: 'الرؤية الشخصية', description: level.personalVision }, 'opinion')}
                  >المزيد
                  </Typography>
                </div>
              </div>)}
            {isImpactOfProgramVisible && (
              <div className='opinion'>
                <div className='icon-wrapper'>
                  <img src={CONTRIBUTE} alt='Opinion' className='opinion-icon' />
                </div>
                <div className='opinion-details'>
                  <Typography className='reason'>كيفية الإستفادة من البرنامج</Typography>
                  <Typography className='description'>{level.impactOfProgram}</Typography>
                  <Typography
                    className='hyperlink'
                    onClick={() => handleLinkClick({ title: 'سبب الإلتحاق بالبرنامج', description: level.impactOfProgram }, 'opinion')}
                  >المزيد
                  </Typography>
                </div>
              </div>)}
          </div>
        </div>
      )
    }
    return null
  }

  const renderReferences = (personality) => {
    const { references } = personality
    if (references.length > 0) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>مـراجـع</Typography>
          <div className='reference-list'>
            {references.map((reference) => (
              <div key={reference.id} className='reference'>
                <div className='icon-wrapper'>
                  <div className='reference-icon' />
                </div>
                <div className='reference-details'>
                  <Typography className='name'>{reference.refNameAr}</Typography>
                  <Typography className='position'>{reference.jobTitleAr}</Typography>
                  <Typography className='department'>{reference.organizationAr}</Typography>
                  <Typography className='hyperlink' onClick={() => handleLinkClick(reference, 'reference')}>المـزيـد</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    return null
  }

  const renderLatestNews = (personality) => {
    const { latestNews } = personality
    if (latestNews.length) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>أخر الأخبار</Typography>
          <div className='news-list'>
            <div className='news'>
              <img src={AVATAR} alt='News thumbnail' className='news-thumbnail' />
              <div className='news-description'>
                <Typography className='news-headline'>شبكة التواصل الإجتماعي</Typography>
                <Typography className='hyperlink'>المـزيـد</Typography>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  const renderSocialMedia = (personality) => {
    const { socialMedia } = personality
    if (socialMedia.length > 0) {
      return (
        <div className='section-wrapper'>
          <Typography className='title'>شبكة التواصل الإجتماعي</Typography>
          <div className='social-media-list'>
            {socialMedia.map((media, index) => (
              <a key={index} href={media.link} target='_blank' rel='noreferrer noopener'>
                {media.platform === 'instagram' && <img src={INSTAGRAM} alt='Instagram' className='social-media-icon' />}
                {media.platform === 'facebook' && <img src={FACEBOOK} alt='Facebook' className='social-media-icon' />}
                {media.platform === 'twitter' && <img src={TWITTER} alt='Twitter' className='social-media-icon' />}
                {media.platform === 'linkedin' && <img src={LINKEDIN} alt='Linkedin' className='social-media-icon' />}
              </a>
            ))}
          </div>
        </div>
      )
    }
    return false
  }

  const renderAchievementDialog = () => {
    return (
      <AchievementDialog
        {...props}
        achievement={activeEntity}
        activeAchievementIndex={activeAchievementIndex}
        handleClose={toggleAchievementDialog}
      />
    )
  }

  const renderOpinionDialog = () => {
    return (
      <OpinionDialog
        {...props}
        opinion={activeEntity}
        handleClose={toggleOpinionDialog}
      />
    )
  }

  const renderReferenceDialog = () => {
    return (
      <ReferenceDialog
        {...props}
        reference={activeEntity}
        handleClose={toggleReferenceDialog}
      />
    )
  }

  const renderRecomProfileView = (profile) => {
    const { id, profileImage } = profile
    return (
      <div key={id} className='recom-profile'>
        {profileImage &&
          <img
            src={`${imageBaseUrl}${profileImage}`}
            alt='Recommended profile'
            className='recom-profile-icon'
            onClick={() => fetchProfileDetails(id)}
          />}
        {!profileImage &&
          <img
            src={AVATAR}
            alt='Profile avatar'
            className='profile-avatar'
            onClick={() => fetchProfileDetails(id)}
          />}
      </div>
    )
  }

  const renderMediaLibrary = () => {
    return (
      <MediaLibrary
        {...props}
        profileDetails={profile}
        onClose={() => setShowMediaLibrary(false)}
      />
    )
  }

  const renderDefaultProfileHeader = () => {
    const { profileImage } = profile
    const profileImagePath = profileImage ? profileImage.url : null
    return (
      <div className='profile-header-container'>
        <div className='profile-avatar-container' onClick={openMediaLibrary}>
          {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='profile-avatar' />}
          {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='profile-avatar' />}
          <img src={RESIZE} alt='Resize' className='avatar-resize-icon' />
        </div>
        <div className='recom-profiles-list'>
          {otherRecomProfiles && otherRecomProfiles.slice(0, 2).map((profile) => renderRecomProfileView(profile))}
          {(otherRecomProfiles && (otherRecomProfiles.length > 2)) &&
            <div className='recom-profile' style={{ cursor: 'default' }}>
              <div className='more-profile-count'>{`${otherRecomProfiles.length - 2}+`}</div>
            </div>}
        </div>
      </div>
    )
  }

  const togglePositionModal = () => {
    setPositionDialog(!showPositionDialog)
  }

  const renderRecomProfileHeader = () => {
    if (recomDetails && recomDetails.recommendationType === 'board') {
      return (
        <div className='recom-profile-header'>
          <BoardMemberListHeader
            {...props}
            handleBackButtonClick={() => history.goBack()}
          />
          {renderDefaultProfileHeader()}
        </div>
      )
    }

    return (
      <div className='recom-profile-header'>
        <RecommendationHeader
          {...props}
          hasViewOnlyAccess
          positionClickCallback={togglePositionModal}
        />
        {renderDefaultProfileHeader()}
      </div>
    )
  }

  const renderPositionDialog = () => {
    return (
      <PositionDialog
        {...props}
        positions={positions}
        handleClose={togglePositionModal}
      />
    )
  }

  const renderProfile = () => {
    const {
      nameAr, tags, summaryAr, leadership, experience, personality, isRisingStar
    } = profile
    const { positions } = leadership
    const organization = positions.length ? positions[0].organization : ''
    const position = positions.length ? positions[0].position : ''
    const isLeadershipAccordionVisible = showLeadershipAccordion(leadership)
    const isExperienceAccordionVisible = showExperienceAccordion(experience)
    const isPersonalityAccordionVisible = showPersonalityAccordion(personality)
    const isDescSectionVisible = showDescSection()

    return (
      <div className='profile-container' id='profile'>
        <div id='profile-wrapper' style={{ position: 'absolute', top: 0 }} />
        {(otherRecomProfiles && otherRecomProfiles.length)
          ? renderRecomProfileHeader()
          : renderDefaultProfileHeader()}
        <div className='profile-info-container'>
          <div className='basic-info'>
            <div className='name-wrapper'>
              {isRisingStar && <img src={RISING_STAR} alt='Rising star' className='rising-star-icon' />}
              <Typography className='name'>{nameAr}</Typography>
            </div>
            <div className='professional-info-wrapper'>
              <div className='knowledge-network-wrapper' onClick={handleNetworkClick}>
                <img src={USERS} alt='Users' className='users-icon' />
                <Typography className='text'>شـبـكة المـعارف</Typography>
              </div>
              {(position && organization) && <Divider className='info-divider' />}
              <Typography className='designation'>{position ? position.positionAr : ''}</Typography>
              {(position && organization) && <Typography className='designation' style={{ margin: '2px 5px 2px 2px' }}>-</Typography>}
              <Typography className='organisation' onClick={() => handleEntityClick(organization)}>
                {organization ? organization.nameAr : ''}
              </Typography>
              {tags && <Divider className='info-divider' />}
              {tags &&
                <div className='futuristic'>
                  <img src={FUTURISTIC} alt='Futuristic' className='futuristic-badge' />
                  <Typography className='text'>{getMainTag(tags)}</Typography>
                </div>}
            </div>
          </div>
          {isDescSectionVisible && (
            <div className='description-wrapper'>
              <div className='pink-circle-wrapper'>
                <img src={PINK_CIRCLE} alt='Circle' className='pink-circle' />
              </div>
              <Typography className='para-one'>
                {summaryAr || constructDescriptionDetails(profile)}
              </Typography>
              <div onClick={() => doScrolling('#skills', 500)} className='link-wrapper'>
                <Typography className='jump-to-link'>جميع مهارات القائد</Typography>
                <img src={DOWN_ARROW} alt='Link' className='icon' />
              </div>
            </div>)}
          <div className='leaders-skills-wrapper' id='skills'>
            <Typography className='section-heading'>جميع مهارات القائد</Typography>
            {isLeadershipAccordionVisible &&
              <Accordion title='القيادة' panel='leadership' defaultExpanded>
                {renderPositions(leadership)}
                {renderAchievementsOfLeader(leadership)}
              </Accordion>}
            {isExperienceAccordionVisible &&
              <Accordion title='الخـبـرة' panel='experience' defaultExpanded={false}>
                {renderCertificates(experience)}
              </Accordion>}
            {isPersonalityAccordionVisible &&
              <Accordion title='الشخصية' panel='personal' defaultExpanded={false}>
                {renderOpinions(personality)}
                {renderReferences(personality)}
                {renderLatestNews(personality)}
                {renderSocialMedia(personality)}
              </Accordion>}
          </div>
        </div>
        <div className='link-wrapper' onClick={() => doScrolling('#profile-wrapper', 500)}>
          <Typography className='jump-to-link'>{t('advancedSearch.backToTop')}</Typography>
          <img src={DOWN_ARROW} alt='Link' className='invert-icon' />
        </div>
        {showAchievementDialog && renderAchievementDialog()}
        {showOpinionDialog && renderOpinionDialog()}
        {showReferenceDialog && renderReferenceDialog()}
        {showMediaLibrary && renderMediaLibrary()}
        {showPositionDialog && renderPositionDialog()}
      </div>
    )
  }

  const renderLoader = () => {
    return (
      <div className='profile-details-loader-wrapper'>
        <div className='loader-wrapper-header' />
        <Loader color='blue' />
      </div>
    )
  }

  return (profile ? renderProfile() : renderLoader())
}

function mapStateToProps (state) {
  return {
    profile: state.profile.profile,
    userSession: state.auth.userSession,
    entity: state.recommendation.entity,
    positions: state.recommendation.positions,
    recomDetails: state.recommendation.recomDetails,
    boardMembers: state.recommendation.boardMembers,
    recomProfiles: state.recommendation.recomProfiles,
    recomProfilesCount: state.recommendation.recomProfilesCount,
    recommendedProfiles: state.recommendation.recommendedProfiles
  }
}

export default withTranslation(['translations'], translateOptions)(connect(mapStateToProps,
  { getProfile, unsetRecommendedProfiles })(Profile))
