import React from 'react'

import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'

import Header from '../header/header.component'
import Footer from '../footer/footer.component'

import './base-layout.component.scss'

function BaseLayout (props) {
  return (
    <div className='base-layout'>
      <Header {...props} />
      <div className='page-container'>
        {props.children}
      </div>
      <Footer {...props} />
    </div>
  )
}

export default withTranslation(['translations'], translateOptions)(BaseLayout)
