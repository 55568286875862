import { refreshToken } from '../app/auth/auth.action-creator'
import { REFRESH_TOKEN_EXPIRY_CAP, TOKEN_EXPIRY_CHECK_INTERVAL } from '../constants/timers'

let currentInterval

export const getAuthToken = () => {
  let token
  const serializedState = getStateFromLocalStorage()
  if (serializedState) {
    const authToken = serializedState.auth.userSession.token
    token = `JWT ${authToken}`
  }
  return token
}

export const setStateToLocalStorage = (state, dispatch) => {
  try {
    const serializedState = JSON.stringify(state)
    const authToken = state.auth.userSession.token
    window.localStorage.setItem('state', serializedState)
    if (state.auth.userSession.token) {
      clearInterval(currentInterval)
      currentInterval = setInterval(() => {
        checkTokenExpiryTime(authToken, dispatch)
      }, TOKEN_EXPIRY_CHECK_INTERVAL)
      // check token expiry every 5 mins
    }
  } catch (err) {
    console.log(err)
  }
}

export const getStateFromLocalStorage = () => {
  try {
    const serializedState = window.localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const isUserSessionActive = () => {
  const serializedState = window.localStorage.getItem('state')
  let isSessionActive = false
  if (serializedState) {
    const userSession = JSON.parse(serializedState).auth.userSession
    if (userSession && userSession.token) {
      isSessionActive = true
    } else {
      clearLocalStorage()
    }
  }
  return isSessionActive
}

// this method finds the difference between
// token expiry time and current time
// and refresh the token if its lesser than token expiry cap
export const checkTokenExpiryTime = (token, dispatch) => {
  const jwt = decodeJwtToken(token)
  const currentTime = new Date()
  const authTokenExpiryTime = new Date(jwt.exp * 1000)
  var timeDiff = Math.abs(authTokenExpiryTime.getTime() - currentTime.getTime())
  var minuteDiff = Math.round(timeDiff / 60000)
  if (minuteDiff < REFRESH_TOKEN_EXPIRY_CAP) {
    dispatch(refreshToken({ body: { token } }))
  }
}

export const decodeJwtToken = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const tokenDetails = JSON.parse(window.atob(base64))
  return tokenDetails
}

export const clearLocalStorage = () => {
  window.localStorage.clear()
  clearActiveInterval()
}

export const clearActiveInterval = () => {
  clearInterval(currentInterval)
}
