import React from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE } from '../../../constants/image-reference'

import './achievement-dialog.component.scss'

export default function AchievementDialog (props) {
  const { handleClose, achievement, activeAchievementIndex } = props
  const { descriptionAr, roleAr, organizationAr, financialImpactAr } = achievement

  return (
    <Dialog size='sm' className='achievement-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>القيادة</Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' className='close-button' onClick={handleClose} />
      </div>
      <div className='dialog-content'>
        <Typography className='achievement-text'>إنـجـازات القـائـد</Typography>
        <div className='achievement-details'>
          <div className='bullet-wrapper'>
            <div className='bullet'>{activeAchievementIndex}</div>
          </div>
          <div className='content'>
            {roleAr && <div className='role-details'>{roleAr}</div>}
            {descriptionAr && (
              <div className='description-wrapper'>
                <Typography className='description-heading'>وصف الإنجاز </Typography>
                <Typography className='description-text'>{descriptionAr || ''}</Typography>
              </div>)}
            <ul className='extra-details-list'>
              {organizationAr &&
                <li className='details'>
                  <Typography className='detail-heading'>الهـيـئة</Typography>
                  <Typography className='detail-text'>{organizationAr || ''}</Typography>
                </li>}
              {financialImpactAr &&
                <li className='details'>
                  <Typography className='detail-heading'>التأثير المالي</Typography>
                  <Typography className='detail-text'>{financialImpactAr || ''}</Typography>
                </li>}
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
