import ActionType from './recommendation.action-type'
import { deepCopy } from '../../utils/object.utils'

const initialState = {
  recom: [],
  recomDetail: {},
  recomProfiles: [],
  recomProfilesCount: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    // set recommendation attributes
    case ActionType.SET_RECOMMENDATION_ATTRIBUTES: {
      return {
        ...state,
        entity: action.entity,
        positions: action.positions,
        boardMembers: action.boardMembers,
        isRecomEditable: action.isRecomEditable
      }
    }

    // clear recommendation attributes
    case ActionType.CLEAR_RECOMMENDATION_ATTRIBUTES: {
      return {
        ...state,
        entity: undefined,
        positions: undefined,
        boardMembers: undefined,
        isRecomEditable: undefined
      }
    }

    // set recommendation detail
    case ActionType.SET_RECOMMENDATION_DETAIL: {
      return {
        ...state,
        recomDetail: action.recomDetail
      }
    }

    // clear recommendation detail
    case ActionType.CLEAR_RECOMMENDATION_DETAIL: {
      return {
        ...state,
        recomDetail: undefined
      }
    }

    // set recommendation results page config
    case ActionType.SET_RECOMMENDATION_RESULTS_PAGE_CONFIG: {
      return {
        ...state,
        recomConfig: action.recomConfig
      }
    }

    // clear recommendation results page config
    case ActionType.CLEAR_RECOMMENDATION_RESULTS_PAGE_CONFIG: {
      return {
        ...state,
        recomConfig: undefined
      }
    }

    // save bulk recom
    case ActionType.SAVE_BULK_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.SAVE_BULK_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        savedRecomIds: action.response.recommendationIds
      }
    }
    case ActionType.SAVE_BULK_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        saveBulkRecomError: action.response
      }
    }
    case ActionType.CLEAR_SAVE_BULK_RECOM_VAR: {
      return {
        ...state,
        recomLoading: false,
        savedRecomIds: undefined,
        saveBulkRecomError: undefined
      }
    }

    // send bulk recom
    case ActionType.SEND_BULK_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.SEND_BULK_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        sendBulkRecomSuccess: true
      }
    }
    case ActionType.SEND_BULK_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        sendBulkRecomError: action.response
      }
    }
    case ActionType.CLEAR_SEND_BULK_RECOM_VAR: {
      return {
        ...state,
        recomLoading: false,
        sendBulkRecomError: undefined,
        sendBulkRecomSuccess: undefined
      }
    }

    // save board recom
    case ActionType.SAVE_BOARD_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.SAVE_BOARD_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        savedBoardRecomIds: action.response.id
      }
    }
    case ActionType.SAVE_BOARD_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        saveBoardRecomError: action.response
      }
    }
    case ActionType.CLEAR_SAVE_BOARD_RECOM_VAR: {
      return {
        ...state,
        recomLoading: false,
        savedBoardRecomIds: undefined,
        saveBoardRecomError: undefined
      }
    }

    // get recom
    case ActionType.GET_RECOM: {
      return {
        ...state,
        recomLoading: true,
        isRecomListLoaded: false
      }
    }
    case ActionType.GET_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        isRecomListLoaded: true,
        recomCount: action.response.count,
        recom: action.response.results
      }
    }
    case ActionType.GET_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        isRecomListLoaded: true,
        getRecomError: action.response
      }
    }
    case ActionType.CLEAR_RECOM_VAR: {
      return {
        ...state,
        getRecomError: undefined,
        recomCount: undefined,
        recom: []
      }
    }

    // load more recom
    case ActionType.LOAD_MORE_RECOM: {
      return {
        ...state,
        moreRecomLoading: true
      }
    }
    case ActionType.LOAD_MORE_RECOM_SUCCESS: {
      return {
        ...state,
        moreRecomLoading: false,
        recomCount: action.response.count,
        recom: [...state.recom, ...action.response.results]
      }
    }
    case ActionType.LOAD_MORE_RECOM_FAILURE: {
      return {
        ...state,
        moreRecomLoading: false,
        getRecomError: action.response
      }
    }

    // delete recom
    case ActionType.DELETE_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.DELETE_RECOM_SUCCESS: {
      const recomCopy = deepCopy(state.recom)
      const recomId = action.recomId
      for (let recomIndx = 0; recomIndx < recomCopy.length; recomIndx++) {
        const recom = recomCopy[recomIndx]
        if (recom.id === Number(recomId)) {
          recomCopy.splice(recomIndx, 1)
          break
        }
      }
      return {
        ...state,
        recomLoading: false,
        recom: recomCopy
      }
    }
    case ActionType.DELETE_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        deleteRecomError: action.response
      }
    }

    // get recom details
    case ActionType.GET_RECOM_DETAILS: {
      return {
        ...state,
        recomDetailsLoading: true
      }
    }
    case ActionType.GET_RECOM_DETAILS_SUCCESS: {
      return {
        ...state,
        recomDetailsLoading: false,
        recomDetails: action.response
      }
    }
    case ActionType.GET_RECOM_DETAILS_FAILURE: {
      return {
        ...state,
        recomDetailsLoading: false,
        getRecomDetailsError: action.response
      }
    }
    case ActionType.CLEAR_GET_RECOM_DETAILS_VAR: {
      return {
        ...state,
        recomLoading: false,
        recomDetails: undefined,
        getRecomDetailsError: undefined
      }
    }

    // get recom profiles
    case ActionType.GET_RECOM_PROFILES: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.GET_RECOM_PROFILES_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        recomProfilesCount: action.response.count,
        recomProfiles: action.response.results
      }
    }
    case ActionType.GET_RECOM_PROFILES_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        getRecomProfilesError: action.response
      }
    }
    case ActionType.CLEAR_GET_RECOM_PROFILES_VAR: {
      return {
        ...state,
        recomProfiles: []
      }
    }

    // save and send profile recom
    case ActionType.SAVE_AND_SEND_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.SAVE_AND_SEND_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        savedRecomDetails: action.response
      }
    }
    case ActionType.SAVE_AND_SEND_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        saveAndSendRecomError: action.response
      }
    }
    case ActionType.CLEAR_SAVE_AND_SEND_RECOM_VARIABLES: {
      return {
        ...state,
        savedRecomDetails: undefined,
        saveAndSendRecomError: undefined
      }
    }

    // save and send board recom
    case ActionType.SAVE_AND_SEND_BOARD_RECOM: {
      return {
        ...state,
        recomLoading: true
      }
    }
    case ActionType.SAVE_AND_SEND_BOARD_RECOM_SUCCESS: {
      return {
        ...state,
        recomLoading: false,
        savedBoardRecomDetails: action.response
      }
    }
    case ActionType.SAVE_AND_SEND_BOARD_RECOM_FAILURE: {
      return {
        ...state,
        recomLoading: false,
        saveAndSendBoardRecomError: action.response
      }
    }
    case ActionType.CLEAR_SAVE_AND_SEND_BOARD_RECOM_VARIABLES: {
      return {
        ...state,
        savedBoardRecomDetails: undefined,
        saveAndSendBoardRecomError: undefined
      }
    }

    case ActionType.SET_RECOMMENDED_PROFILES: {
      return {
        ...state,
        recommendedProfiles: action.recommendedProfiles
      }
    }

    case ActionType.UNSET_RECOMMENDED_PROFILES: {
      return {
        ...state,
        recommendedProfiles: undefined
      }
    }

    // Reset reducer
    case ActionType.RESET_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
