import React from 'react'
import { Dialog, Button } from '@material-ui/core'

import './alert-dialog.component.scss'

export default function AlertDialog (props) {
  const { t, children, type, buttonText, handleClose, handleConfirm } = props
  return (
    <Dialog size='sm' className='alert-dialog' onClose={handleClose} open>
      <div className='message'>{children}</div>
      {type === 'info' &&
        <div className='btn-container'>
          <Button onClick={handleClose} className='ok-button' variant='text' color='default'>
            {t('common.ok')}
          </Button>
        </div>}
      {type !== 'info' &&
        <div className='btn-container'>
          <Button onClick={handleConfirm} className='action-button' variant='text' color='default'>
            {buttonText}
          </Button>
          <div className='seperator' />
          <Button onClick={handleClose} className='action-button warning' variant='text' color='default'>
            {t('common.cancel')}
          </Button>
        </div>}
    </Dialog>
  )
}
