import ActionType from './advanced-search.action-type'

const initialState = {
  profileCount: 0,
  profileList: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Get profile list
    case ActionType.GET_PROFILE_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        profileCount: action.response.count,
        profileList: action.response.results
      }
    }
    case ActionType.GET_PROFILE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getProfileListFailure: action.response
      }
    }
    case ActionType.CLEAR_PROFILE_LIST: {
      return {
        ...state,
        profileList: []
      }
    }

    // Set filter state
    case ActionType.SET_FILTER_STATE: {
      return {
        ...state,
        previousFilters: action.filters,
        previousSearchQuery: action.searchQuery
      }
    }

    // Clear filter state
    case ActionType.CLEAR_FILTER_STATE: {
      return {
        ...state,
        previousFilters: undefined
      }
    }

    // Load more profile list
    case ActionType.LOAD_MORE_PROFILES: {
      return {
        ...state,
        moreProfilesLoading: true
      }
    }
    case ActionType.LOAD_MORE_PROFILES_SUCCESS: {
      return {
        ...state,
        moreProfilesLoading: false,
        profileCount: action.response.count,
        profileList: [...state.profileList, ...action.response.results]
      }
    }
    case ActionType.LOAD_MORE_PROFILES_FAILURE: {
      return {
        ...state,
        moreProfilesLoading: false,
        getProfilesError: action.response
      }
    }

    // Reset reducer
    case ActionType.RESET_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
