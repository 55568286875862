import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { PROFILE, LEFT_ARROW_CIRCLE } from '../../constants/image-reference'
import { getUserType } from '../../utils/string.utils'
import { HH, ADHOC, BOARD } from '../../constants/misc'
import { getInsightList, loadMoreInsights, deleteInsight } from '../insights/insights.action-creator'

import Loader from '../../common/loader/loader.component'
import InsightCard from '../../common/insight-card/insight-card.component'

import './sent-insight.component.scss'

function SentInsight (props) {
  const { insightList, isLoading, t, recomCount, userSession, loadMoreInsights, getInsightList, deleteInsight } = props
  const userType = getUserType(userSession)

  // component mount
  useEffect(() => {
    fetchInsightList()
  }, [])

  const fetchInsightList = () => {
    getInsightList({
      limit: 5,
      offset: 0,
      // isSent: true,
      isDeleted: false
    })
  }

  const fetchMoreInsights = () => {
    loadMoreInsights({
      limit: 5,
      offset: insightList && insightList.length ? insightList.length : 0,
      // isSent: true,
      isDeleted: false
    })
  }

  const handleArchiveClick = () => {
    props.history.push('/archive-insight/0')
  }

  const removeInsight = (insightId) => {
    deleteInsight({
      insightId
    })
  }

  const renderRecomList = () => {
    return (
      <div className='recom-card-view'>
        {insightList.map((data, index) => (
          <InsightCard
            {...props}
            index={index}
            key={index}
            sentRecomPage
            recomDetails={data}
            deleteInsightCallback={(id) => removeInsight(id)}
            onClickCallback={(recomDetails) => clickHandle(recomDetails)}
          />
        ))}
      </div>
    )
  }

  const clickHandle = (recomDetails) => {
    // const { id, recommendationType } = recomDetails
    // if (userType === HH || userType === ADHOC) {
    //   if (recommendationType === BOARD) {
    //     props.history.push(`/recommendation/${id}/members`)
    //   }
    // }
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>
          {t('recommendation.noRecommendationsFound')}
        </Typography>
      </div>
    )
  }

  const goBack = () => {
    props.history.push('/sent')
  }

  return (
    <div className='sent-insight-container'>
      <div className='sent-recom'>
        <div className='header-container'>
          <div className='title-container'>
            <div className='nomination-title'>
              <img src={PROFILE} alt='Users' className='user-icon' />
              <Typography className='nomination-text'>{t('footer.nominations')}</Typography>
            </div>
            <Button onClick={handleArchiveClick} className='archive-button' variant='outlined' color='secondary'>
              {t('common.archive')}
            </Button>
          </div>
          <img src={LEFT_ARROW_CIRCLE} alt='Back' className='back-icon' onClick={goBack} />
        </div>
        {isLoading ? <Loader color='white' /> : (insightList && insightList.length) ? renderRecomList() : renderEmptyState()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // Recommendation
    insightList: state.insights.insightList,
    isLoading: state.insights.isLoading,
    recomCount: state.recommendation.recomCount,

    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, { getInsightList, loadMoreInsights, deleteInsight })(SentInsight))
