export const transformObjectKeys = function (input, transformer) {
  if (!input || typeof input !== 'object' || !Object.keys(input).length) return input
  if (Array.isArray(input)) return input.map((value) => transformObjectKeys(value, transformer))

  return Object.keys(input).reduce(function (newObj, key) {
    const val = input[key]
    const newVal = (typeof val === 'object') ? transformObjectKeys(val, transformer) : val
    newObj[transformer(key)] = newVal
    return newObj
  }, {})
}

// Makes deep copy of object and an array
// Works only for JSON serializable content
export function deepCopy (data) {
  if (data) {
    return JSON.parse(JSON.stringify(data))
  }
}

export function assignObjectValues (firstObj, secondObj) {
  const firstObjCopy = deepCopy(firstObj)
  for (const key in firstObjCopy) {
    if (secondObj.hasOwnProperty(key)) {
      firstObjCopy[key].value = secondObj[key] || ''
    }
  }
  return firstObjCopy
}

export function constructRequestBody (fields = {}) {
  const params = {}
  for (const key in fields) {
    if (typeof fields[key].value === 'object') {
      const objectVal = fields[key].value
      params[key] = objectVal.value
    } else if (typeof fields[key].value === 'boolean') {
      params[key] = fields[key].value
    } else {
      if (fields[key].value.length > 0) {
        params[key] = fields[key].value
      }
    }
  }
  return params
}

export function clearObjectValues (obj) {
  const firstObjCopy = deepCopy(obj)
  for (const key in firstObjCopy) {
    const type = typeof (firstObjCopy[key].value)
    if (type === 'string' || type === 'boolean' || type === 'number') {
      firstObjCopy[key].value = ''
    } else if (type === 'object') {
      firstObjCopy[key].value = []
    } else {
      firstObjCopy[key].value = ''
    }
  }
  return firstObjCopy
}
