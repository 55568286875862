import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { deepCopy, assignObjectValues } from '../../../utils/object.utils'
import { debounce } from 'lodash'
import { handleFormValidation } from '../../../utils/validation.utils'
import {
  getOrgTypes, getOrgList, getTierList, getEducationLevelList, getGenderList,
  setPreviousPath
} from '../../../common/common.action-creator'
import {
  getInsightEntityTypes, getEntityProfileCount, getTiersProfileCount,
  clearEntityProfileCount, clearTiersProfileCount, getLeaderList, getInsightDetails,
  editInsight, clearAddInsightVar
} from '../insights.action-creator'

import EntityVisualization from '../../../common/entity-visualization/entity-visualization.component'
import TierVisualization from '../../../common/tier-visualization/tier-visualization.component'
import AlertDialog from '../../../common/alert-dialog/alert-dialog.component'
import Loader from '../../../common/loader/loader.component'
import Header from '../new-insight/header/header.component'
import Filters from '../new-insight/filters/filters.component'
import Footer from '../new-insight/footer/footer.component'

import './edit-insight.component.scss'

function EditInsight (props) {
  const {
    history, getOrgTypes, getOrgList, getLeaderList, getTierList, getEducationLevelList,
    getGenderList, getInsightEntityTypes, getEntityProfileCount, getTiersProfileCount,
    clearEntityProfileCount, clearTiersProfileCount, editInsight, clearAddInsightVar,
    getInsightDetails
  } = props
  const {
    activeInsight, entityTypes, entityProfileCount, tiersProfileCount, insightList, editInsightSuccess, match
  } = props
  const [showVisualization, setVisualization] = useState(true)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeDirection, setActiveDirection] = useState('left')
  const [alertValues, setAlertValues] = useState({ isVisible: false, alertMessage: '' })
  const [values, setValues] = useState({
    title: { type: 'text', value: '', isRequired: true },
    insightType: { type: 'text', value: '', isRequired: true },
    entityTypeId: { type: 'text', value: '', isRequired: false },
    entityIds: { type: 'text', value: [], isRequired: false },
    filterType: { type: 'text', value: '', isRequired: true },
    filterOptions: { type: 'text', value: {}, isRequired: false },
    leaderIds: { type: 'text', value: [], isRequired: false },
    headline: { type: 'text', value: '', isRequired: true }
  })
  const [visualData, setVisualData] = useState({
    insightType: activeInsight ? activeInsight.insightType : '',
    visualDetails: []
  })
  const [insightDesc, setInsightDesc] = useState('')

  const { title, leaderIds, insightType, entityIds } = values

  const entitiesProfileCountDelayedCallback = debounce((orgIds, filterOptions) => fetchEntitiesProfileCount(orgIds, filterOptions), 500)
  const tierProfileCountDelayedCallback = debounce(filterOptions => fetchTierProfileCount(filterOptions), 500)
  const entityTypeProfileCountDelayedCallback = debounce(filterOptions => fetchInsightEntityTypes(filterOptions), 500)

  useEffect(() => {
    fetchInsightDetails()
  }, [])

  useEffect(() => {
    if (activeInsight) {
      populateFieldValues()
    }
  }, [activeInsight])

  useEffect(() => {
    if (editInsightSuccess) {
      clearAddInsightVar()
      navigateToInsightListing()
    }
  }, [editInsightSuccess, insightList])

  useEffect(() => {
    if (entityTypes && entityTypes.length) {
      const visualDataCopy = deepCopy(visualData)
      const visualDataArray = entityTypes.map(data => {
        return {
          entityId: data.id,
          entityName: data.typeAr,
          profilesCount: data.entitiesCount,
          profiles: []
        }
      })
      visualDataCopy.visualDetails = visualDataArray
      constructEntityTypeProfilesData(visualDataCopy)
      setTimeout(() => {
        setVisualization(false)
      }, 0)
    }
  }, [entityTypes])

  useEffect(() => {
    if (entityProfileCount && entityProfileCount.length) {
      const visualDataCopy = deepCopy(visualData)
      const visualDataArray = []
      entityProfileCount.map(data => {
        const results = {
          entityId: data.organization.id,
          entityName: data.organization.nameAr,
          profilesCount: data.profilesCount,
          profiles: []
        }
        visualDataArray.push(results)
      })
      visualDataCopy.visualDetails = visualDataArray
      constructEntityProfilesData(visualDataCopy)
      setTimeout(() => {
        setVisualization(false)
      }, 0)
    }
  }, [entityProfileCount])

  useEffect(() => {
    if (tiersProfileCount && tiersProfileCount.length) {
      const visualDataCopy = deepCopy(visualData)
      const visualDataArray = []
      tiersProfileCount.map(data => {
        const results = {
          tierId: data.tier.id,
          tierName: data.tier.nameEn,
          profilesCount: data.profilesCount,
          profiles: []
        }
        visualDataArray.push(results)
      })
      visualDataCopy.visualDetails = visualDataArray
      constructTierProfilesData(visualDataCopy)
      setTimeout(() => {
        setVisualization(false)
      }, 0)
    }
  }, [tiersProfileCount])

  useEffect(() => {
    if (insightType.value === 'tier') {
      constructTierProfilesData(visualData)
    } else {
      if (entityIds.value.length) {
        constructEntityProfilesData(visualData)
      } else {
        constructEntityTypeProfilesData(visualData)
      }
    }
  }, [values])

  useEffect(() => {
    return () => {
      clearEntityProfileCount()
      clearTiersProfileCount()
    }
  }, [])

  const fetchInsightDetails = () => {
    const { insightId } = match.params
    getInsightDetails({ insightId })
  }

  const constructEntityProfilesData = (visualDataDetails) => {
    const visualDataCopy = deepCopy(visualDataDetails)
    const profiles = leaderIds.value
    const entityIds = visualDataCopy.visualDetails.map((data, index) => {
      visualDataCopy.visualDetails[index].profiles = []
      return data.entityId
    })
    profiles.map(profileData => {
      const profile = profileData.profile
      const organization = profile.organization
      if (organization && entityIds.includes(organization.id)) {
        const entityIndex = entityIds.indexOf(organization.id)
        visualDataCopy.visualDetails[entityIndex].profiles.push(profile)
      }
    })
    setVisualData(visualDataCopy)
  }

  const constructEntityTypeProfilesData = (visualDataDetails) => {
    const visualDataCopy = deepCopy(visualDataDetails)
    const profiles = leaderIds.value
    const entityTypeIds = visualDataCopy.visualDetails.map((data, index) => {
      visualDataCopy.visualDetails[index].profiles = []
      return data.entityId
    })
    profiles.map(profileData => {
      const profile = profileData.profile
      const organization = profile.organization
      if (organization && organization.type && entityTypeIds.includes(organization.type.id)) {
        const entityIndex = entityTypeIds.indexOf(organization.type.id)
        visualDataCopy.visualDetails[entityIndex].profiles.push(profile)
      }
    })
    setVisualData(visualDataCopy)
  }

  const constructTierProfilesData = (visualDataDetails) => {
    const visualDataCopy = deepCopy(visualDataDetails)
    const profiles = leaderIds.value
    const tierIds = visualDataCopy.visualDetails.map((data, index) => {
      visualDataCopy.visualDetails[index].profiles = []
      return data.tierId
    })
    profiles.map(profileData => {
      const profile = profileData.profile
      if (profile.tier && tierIds.includes(profile.tier.id)) {
        const tierIndex = tierIds.indexOf(profile.tier.id)
        visualDataCopy.visualDetails[tierIndex].profiles.push(profile)
      }
    })
    setVisualData(visualDataCopy)
  }

  const populateFieldValues = () => {
    const { entityType, entityIds, leaderIds, insightType, headline } = activeInsight

    const visualDataCopy = deepCopy(visualData)
    visualDataCopy.insightType = insightType
    setVisualData(visualDataCopy)

    const fieldsCopy = assignObjectValues(values, activeInsight)

    if (entityType) {
      fieldsCopy.entityTypeId.value = entityType.id
    }

    if (entityIds) {
      fieldsCopy.entityIds.value = entityIds.map(data => data.id)
    }
    if (headline) {
      setInsightDesc(headline)
    }

    if (leaderIds && leaderIds.length) {
      fieldsCopy.leaderIds.value = leaderIds.map(data => {
        return { label: data.nameAr, value: data.id, profile: data }
      })
    } else {
      fieldsCopy.leaderIds.value = []
    }
    setValues(fieldsCopy)

    if (insightType === 'tier') {
      tierProfileCountDelayedCallback(fieldsCopy.filterOptions.value)
    } else if (fieldsCopy.entityIds.value.length) {
      entitiesProfileCountDelayedCallback(fieldsCopy.entityIds.value, fieldsCopy.filterOptions.value)
    } else {
      entityTypeProfileCountDelayedCallback(fieldsCopy.filterOptions.value)
    }
  }

  const fetchOrgTypes = () => {
    getOrgTypes()
  }

  const fetchOrgList = (type, searchQuery) => {
    getOrgList({
      limit: 10,
      offset: 0,
      search: searchQuery,
      type
    })
  }

  // Api to call on insight type entity select
  const fetchInsightEntityTypes = (filters) => {
    getInsightEntityTypes({}, filters)
  }

  // api to call for insight type entity
  const fetchEntitiesProfileCount = (entityIds, filters) => {
    getEntityProfileCount({}, {
      entityIds: entityIds,
      filters: filters
    })
  }

  // api to call for insight type tier
  const fetchTierProfileCount = (filters) => {
    getTiersProfileCount({}, filters)
  }

  const fetchProfileList = (queryParams) => {
    getLeaderList(queryParams)
  }

  const fetchTierList = () => {
    getTierList()
  }

  const fetchEducationLevelList = () => {
    getEducationLevelList()
  }

  const fetchGenderList = () => {
    getGenderList()
  }

  const navigateToInsightListing = () => {
    history.push('/insights')
  }

  const handleNextClick = (tabIndex) => {
    setActiveTabIndex(tabIndex)
    setActiveDirection('left')
  }

  const handlePreviousClick = (tabIndex) => {
    setActiveTabIndex(tabIndex)
    setActiveDirection('right')
  }

  const handleEditInsight = () => {
    const fields = deepCopy(values)
    fields.headline.value = insightDesc
    setValues(fields)

    const form = handleFormValidation(fields)
    if (form.isFormValid) {
      const fieldsCopy = constructRequestBody(fields)
      if (fieldsCopy.leaderIds && fieldsCopy.leaderIds.length) {
        const leaderIdsCopy = fieldsCopy.leaderIds.map(data => data.value)
        fieldsCopy.leaderIds = leaderIdsCopy
      }
      editInsight({ insightId: activeInsight.id }, fieldsCopy)
    }
  }

  const constructRequestBody = (fields = {}) => {
    const params = {}
    for (const key in fields) {
      if (fields[key].value) {
        params[key] = fields[key].value
      }
    }
    return params
  }

  const toggleAlertDialog = (errorMsg) => {
    const alertValuesCopy = deepCopy(alertValues)
    alertValuesCopy.isVisible = !alertValuesCopy.isVisible
    alertValuesCopy.alertMessage = errorMsg
    setAlertValues(alertValuesCopy)
  }

  const onChangeHandler = (value, key) => {
    const fieldsCopy = deepCopy(values)
    if (key === 'entityIds') {
      setVisualization(true)
      fieldsCopy[key].value.push(value)
      entitiesProfileCountDelayedCallback(fieldsCopy[key].value, fieldsCopy.filterOptions.value)
    } else if (key === 'filterOptions') {
      setVisualization(true)
      fieldsCopy[key].value = value
      if (insightType.value === 'tier') {
        tierProfileCountDelayedCallback(fieldsCopy[key].value)
      } else {
        if (fieldsCopy.entityIds.value.length) {
          entitiesProfileCountDelayedCallback(fieldsCopy.entityIds.value, fieldsCopy[key].value)
        } else {
          entityTypeProfileCountDelayedCallback(fieldsCopy[key].value)
        }
      }
    } else if (key === 'leaderIds') {
      setVisualization(true)
      fieldsCopy.leaderIds.value = value
    } else {
      fieldsCopy[key].value = value
    }
    setValues(fieldsCopy)
    if (key === 'leaderIds') {
      setTimeout(() => {
        setVisualization(false)
      }, 0)
    }
  }

  const handleDropdownChange = (field, key) => {
    setVisualization(true)
    const fieldsCopy = deepCopy(values)
    const visualDataCopy = deepCopy(visualData)
    if (key === 'insightType') {
      fieldsCopy.entityTypeId.value = ''
      fieldsCopy.entityIds.value = []
      visualDataCopy.insightType = field.value
      visualDataCopy.visualDetails = []
      if (field.value === 'tier') {
        tierProfileCountDelayedCallback(fieldsCopy.filterOptions.value)
      } else {
        visualDataCopy.insightType = field.value
        fetchInsightEntityTypes()
      }
    } else if (key === 'entityTypeId') {
      fieldsCopy.entityIds.value = []
    } else if (key === 'filterType') {
      fieldsCopy.filterOptions.value = {}
      fieldsCopy.leaderIds.value = []
      if (insightType.value === 'tier') {
        tierProfileCountDelayedCallback(fieldsCopy.filterOptions.value)
      } else {
        if (fieldsCopy.entityIds.value.length) {
          entitiesProfileCountDelayedCallback(fieldsCopy.entityIds.value, fieldsCopy.filterOptions.value)
        } else {
          entityTypeProfileCountDelayedCallback(fieldsCopy.filterOptions.value)
        }
      }
    }
    fieldsCopy[key].value = field.value
    setVisualData(visualDataCopy)
    setValues(fieldsCopy)
    if (key === 'entityTypeId') {
      setTimeout(() => {
        setVisualization(false)
      }, 0)
    }
  }

  const onChangeDescHandler = (value) => {
    setInsightDesc(value)
  }

  const handleRemoveEntity = (entityId, index) => {
    const fieldsCopy = deepCopy(values)
    const visualDataCopy = deepCopy(visualData)
    fieldsCopy.entityIds.value.splice(index, 1)
    for (let Indx = 0; Indx < visualDataCopy.visualDetails.length; Indx++) {
      const entity = visualDataCopy.visualDetails[Indx]
      if (entity.entityId === Number(entityId)) {
        visualDataCopy.visualDetails.splice(Indx, 1)
        break
      }
    }
    setValues(fieldsCopy)
    setVisualData(visualDataCopy)
  }

  const viewProfile = (profileId) => {
    setPreviousPath(props.match)
    props.history.push(`/profile-details/${profileId}`)
  }

  const renderEntityVisualization = () => {
    if (showVisualization) {
      return <Loader color='white' />
    }

    return (
      <EntityVisualization
        visualData={visualData}
        viewProfileCallback={(profileId) => viewProfile(profileId)}
      />
    )
  }

  const renderTierVisualization = () => {
    return (
      <TierVisualization
        {...props}
        visualData={visualData}
        viewProfileCallback={(profileId) => viewProfile(profileId)}
      />
    )
  }

  return (
    <div className='edit-insight-container'>
      <div className='content-wrapper'>
        <div className='filters-wrapper'>
          <div className='filters-modal'>
            <Header
              {...props}
              {...values}
              onChangeCallback={(value, key) => onChangeHandler(value, key)}
            />
            <Filters
              {...props}
              {...values}
              activeIndex={activeTabIndex}
              activeDirection={activeDirection}
              fetchOrgTypesCallback={fetchOrgTypes}
              fetchOrgListCallback={(entityType, searchQuery) => fetchOrgList(entityType, searchQuery)}
              fetchProfileListCallback={(queryParams) => fetchProfileList(queryParams)}
              fetchTierListCallback={fetchTierList}
              fetchEducationLevelListCallback={fetchEducationLevelList}
              fetchGenderListCallback={fetchGenderList}
              dropdownChangeCallback={(value, key) => handleDropdownChange(value, key)}
              onOrgChangeCallback={(value, key) => onChangeHandler(value, key)}
              removeEntityCallback={(entityId, index) => handleRemoveEntity(entityId, index)}
              showErrorDialogCallback={(error) => toggleAlertDialog(error)}
              onChangeCallback={(value, key) => onChangeHandler(value, key)}
              onChangeDescCallback={(value) => onChangeDescHandler(value)}
            />
            <Footer
              {...props}
              {...values}
              activeIndex={activeTabIndex}
              cancelClickCallback={navigateToInsightListing}
              nextClickCallback={(tabIndex) => handleNextClick(tabIndex)}
              previousClickCallback={(tabIndex) => handlePreviousClick(tabIndex)}
              saveClickCallback={handleEditInsight}
              insightDesc={insightDesc}
            />
          </div>
        </div>
        <div className='visualization-wrapper'>
          {(visualData.insightType === 'entity') ? renderEntityVisualization() : null}
          {(visualData.insightType === 'tier') ? renderTierVisualization() : null}
        </div>
      </div>
      {alertValues.isVisible &&
        <AlertDialog
          {...props}
          type='info'
          handleClose={() => toggleAlertDialog('')}
        >
          {alertValues.alertMessage}
        </AlertDialog>}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    // common
    orgTypes: state.common.orgTypes,
    orgList: state.common.orgList,
    tierList: state.common.tierList,
    educationLevelList: state.common.educationLevelList,
    genderList: state.common.genderList,

    // Insights
    activeInsight: state.insights.activeInsight,
    entityTypes: state.insights.entityTypes,
    entityProfileCount: state.insights.entityProfileCount,
    tiersProfileCount: state.insights.tiersProfileCount,
    insightList: state.insights.insightList,
    editInsightSuccess: state.insights.editInsightSuccess,
    profileList: state.insights.profileList
  }
}

export default withTranslation(['translations'], translateOptions)(connect(mapStateToProps, {
  getOrgTypes,
  getOrgList,
  getLeaderList,
  getTierList,
  getEducationLevelList,
  getGenderList,
  editInsight,
  setPreviousPath,
  clearAddInsightVar,

  getInsightEntityTypes,
  getEntityProfileCount,
  getTiersProfileCount,
  clearEntityProfileCount,
  clearTiersProfileCount,

  getInsightDetails
})(EditInsight))
