import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { HH, ADHOC } from '../../constants/misc'
import { translateOptions } from '../../i18n/index'
import { getUserType } from '../../utils/string.utils'
import { deepCopy } from '../../utils/object.utils'
import { getUserList, setPreviousPath } from '../../common/common.action-creator'
import {
  getRecom, setRecommendationDetail, setRecommendationAttributes, setRecommendationResultsPageConfig
} from '../recommendation/recommendation.action-creator'
import {
  getInsightList, deleteInsight, sendInsight, clearSendInsightVar, getTiersProfileCount, getLeaderList,
  clearSelectedInsight
} from './insights.action-creator'

import Loader from '../../common/loader/loader.component'
import InsightSearch from './insight-search/insight-search.component'
import InsightPreview from './insight-preview/insight-preview.component'
import UsersDialog from '../recommendation/users-dialog/users-dialog.component'
import AlertDialog from '../../common/alert-dialog/alert-dialog.component'

import './insights.component.scss'

function Insights (props) {
  const {
    t, history, getInsightList, deleteInsight, getUserList, sendInsight, clearSendInsightVar, userSession,
    getTiersProfileCount, getLeaderList, newInsightAdded, clearSelectedInsight, setPreviousPath, getRecom,
    setRecommendationDetail, setRecommendationAttributes, setRecommendationResultsPageConfig
  } = props
  const {
    isInsightListLoading, insightList, userList, profileList, sendInsightSuccess, selectedInsight, recomList,
    isRecomListLoaded, isInsightListLoaded
  } = props

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [activeInsight, setActiveInsight] = useState()
  const [showSearchedProfileView, setSearchedProfileView] = useState(false)
  const [activeInsightIndx, setActiveInsightIndx] = useState(0)
  const [showUsersDialog, setUsersDialog] = useState(false)
  const [insightRecomList, setRecomInsightList] = useState([])
  const userType = getUserType(userSession)
  const isHhOrAdHocUser = (userType === HH || userType === ADHOC)

  useEffect(() => {
    if (isHhOrAdHocUser) {
      fetchRecom()
    }
    if (!newInsightAdded) {
      fetchInsightList()
    }
  }, [])

  useEffect(() => {
    if (sendInsightSuccess) {
      clearSendInsightVar()
      const itemIndex = insightList.map((item) => { return item.id }).indexOf(activeInsight.id)
      const previouslyActiveInsight = insightList[itemIndex]
      setActiveInsight(previouslyActiveInsight)
    }
  }, [sendInsightSuccess])

  useEffect(() => {
    if (!isHhOrAdHocUser) {
      if ((insightList.length > 0) && (!activeInsight)) {
        let activeInsight
        if (selectedInsight) {
          activeInsight = selectedInsight
          clearSelectedInsight()
        } else {
          activeInsight = insightList[0]
        }
        setRecomInsightList(insightList)
        setActiveInsight(activeInsight)
      }
    }
  }, [insightList])

  useEffect(() => {
    if (isHhOrAdHocUser) {
      if (isRecomListLoaded && isInsightListLoaded && (!activeInsight)) {
        const recomListCopy = deepCopy(recomList)
        var list = []
        for (let recomIndx = 0; recomIndx < recomList.length; recomIndx++) {
          const recomDetails = recomListCopy[recomIndx]
          recomDetails.insightType = 'hybrid'
          recomDetails.title = getRecomTitle(recomDetails)
          recomDetails.profilesCount = [{
            id: 2,
            typeEn: 'Government',
            typeAr: 'حكومي',
            profilesCount: 2.5
          }]
          recomDetails.leaderIds = recomDetails.profiles
        }

        if (recomList.length) {
          list = recomListCopy
        }
        if (insightList.length) {
          list = [...list, ...insightList]
        }
        setRecomInsightList(list)

        let activeInsight
        if (selectedInsight) {
          activeInsight = selectedInsight
          clearSelectedInsight()
        } else {
          activeInsight = list[0]
        }
        setActiveInsight(activeInsight)
      }
    }
  }, [recomList, insightList])

  const getRecomTitle = (recomDetails) => {
    const { entity, profiles, boardPositions } = recomDetails
    return `${t('header.totalCandidatesFor', { total: profiles.length || 0 })}
    ${t('header.totalPositions', { total: boardPositions ? boardPositions.length : 1 })}
    ${t('header.atTheEntity', { entity: entity ? entity.nameAr : '' })}
    `
  }

  const handleSetActiveInsight = (activeIndx) => {
    if (isHhOrAdHocUser) {
      setActiveInsight(insightRecomList[activeIndx])
      setActiveInsightIndx(activeIndx)
    } else {
      setActiveInsight(insightList[activeIndx])
      setActiveInsightIndx(activeIndx)
    }
  }

  const toggleUsersModal = () => {
    setUsersDialog(!showUsersDialog)
  }

  const fetchInsightList = () => {
    getInsightList(
      { isDeleted: false }
    )
  }

  const fetchRecom = () => {
    getRecom({
      queryParams: {
        limit: 100,
        offset: 0,
        isDeleted: false,
        isSent: true
      }
    })
  }

  const fetchTierProfileCount = () => {
    getTiersProfileCount()
  }

  const fetchUserList = () => {
    getUserList({
      userType: 'hh,adhoc',
      limit: 100,
      offset: 0
    })
  }

  const fetchProfileList = (searchQuery) => {
    getLeaderList({
      search: searchQuery,
      limit: 10,
      isPublished: true,
      offset: 0
    })
  }

  const removeInsight = () => {
    deleteInsight({ insightId: activeInsight.id })
    setShowConfirmModal(false)
    setActiveInsight()
  }

  const handleSendInsight = (userId) => {
    toggleUsersModal()
    sendInsight(
      { insightId: activeInsight.id },
      { userIds: [userId] }
    )
  }

  const getProfileOptions = () => {
    if (profileList) {
      if (profileList.length) {
        return profileList.map(profile => {
          return { id: profile.id, label: profile.nameAr, value: profile.id, profile: profile }
        })
      }
      return []
    }
  }

  const handleAddInsight = () => {
    history.push('/insights/new')
  }

  const handleSetPreviousPath = () => {
    setPreviousPath(props.match)
  }

  const renderContent = () => {
    if ((insightRecomList.length > 0) && activeInsight && !showSearchedProfileView) {
      return renderInsightList()
    }
    return renderEmptyState()
  }

  const renderInsightList = () => {
    return (
      <InsightPreview
        {...props}
        activeInsight={activeInsight}
        activeInsightList={insightRecomList}
        isHhOrAdHocUser={isHhOrAdHocUser}
        activeInsightIndx={activeInsightIndx}
        setRecomDetailsCallback={(recomDetail) => setRecommendationDetail(recomDetail)}
        setRecommendationAttributesCallback={() => setRecommendationAttributes()}
        setRecommendationResultsPageConfigCallback={
          (recomPageConfig) => setRecommendationResultsPageConfig(recomPageConfig)
        }
        fetchUserCallback={fetchUserList}
        addInsightCallback={handleAddInsight}
        sendAnalyzesCallback={toggleUsersModal}
        setPreviousPathCallback={handleSetPreviousPath}
        badgeClickCallback={(insight) => setActiveInsight(insight)}
        removeInsightCallback={() => setShowConfirmModal(true)}
        showSearchedProfileCallback={() => setSearchedProfileView(true)}
        setActiveInsightCallback={(activeInsightIndx) => handleSetActiveInsight(activeInsightIndx)}
      />
    )
  }

  const renderEmptyState = () => {
    return (
      <InsightSearch
        {...props}
        isHhOrAdHocUser={isHhOrAdHocUser}
        showSearchedProfileView={showSearchedProfileView}
        insightList={insightList}
        options={getProfileOptions()}
        addInsightCallback={handleAddInsight}
        setPreviousPathCallback={handleSetPreviousPath}
        getTierInsightCallback={fetchTierProfileCount}
        showSearchedProfileCallback={() => setSearchedProfileView(false)}
        fetchProfileListCallback={(searchQuery) => fetchProfileList(searchQuery)}
      />
    )
  }

  const renderUsersDialog = () => {
    return (
      <UsersDialog
        {...props}
        userList={userList}
        getUserListCallback={fetchUserList}
        handleUserClick={(userId) => handleSendInsight(userId)}
        handleClose={toggleUsersModal}
      />
    )
  }

  const renderAlertDialog = () => {
    return (
      <AlertDialog
        {...props}
        type='warning'
        buttonText={t('common.emphasis')}
        handleConfirm={removeInsight}
        handleClose={() => setShowConfirmModal(false)}
      >
        {t('recommendation.areYouSureWantToDelete')}
      </AlertDialog>
    )
  }

  if (isHhOrAdHocUser) {
    return (
      <div
        className='insights-container'
        style={{ height: isHhOrAdHocUser ? isInsightListLoading ? 'calc(100vh - 80px)' : 'calc(100vh - 80px)' : 'calc(100vh - 120px)' }}
      >
        {(isInsightListLoaded && isRecomListLoaded) ? renderContent() : <Loader color='white' />}
        {showUsersDialog && renderUsersDialog()}
        {showConfirmModal && renderAlertDialog()}
      </div>
    )
  }

  return (
    <div
      className='insights-container'
      style={{ height: (isHhOrAdHocUser && !isInsightListLoading) ? 'calc(100vh - 120px)' : 'calc(100vh - 80px)' }}
    >
      {isInsightListLoading ? <Loader color='white' /> : renderContent()}
      {showUsersDialog && renderUsersDialog()}
      {showConfirmModal && renderAlertDialog()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    // common
    userList: state.common.userList,

    // Insights
    isLoading: state.insights.isLoading,
    isInsightListLoading: state.insights.isInsightListLoading,
    insightList: state.insights.insightList,
    selectedInsight: state.insights.selectedInsight,
    newInsightAdded: state.insights.newInsightAdded,
    profileList: state.insights.profileList,
    tiersProfileCount: state.insights.tiersProfileCount,
    sendInsightSuccess: state.insights.sendInsightSuccess,
    isLeaderListLoading: state.insights.isLeaderListLoading,
    isInsightListLoaded: state.insights.isInsightListLoaded,

    // Recom
    recomList: state.recommendation.recom,
    isRecomListLoaded: state.recommendation.isRecomListLoaded,

    // auth
    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(mapStateToProps, {
  // Recom
  setRecommendationResultsPageConfig,
  setRecommendationAttributes,
  setRecommendationDetail,
  // Insights
  getTiersProfileCount,
  clearSelectedInsight,
  clearSendInsightVar,
  setPreviousPath,
  getInsightList,
  deleteInsight,
  getLeaderList,
  getUserList,
  sendInsight,
  getRecom
})(Insights))
