import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { PRIMARY_MAIN_COLOR } from '../constants/color'
import { Button } from '@material-ui/core'

import PropTypes from 'prop-types'
import TextInput from '../common/text-input/text-input'
import SearchInput from '../common/search-input/search-input'
import SelectInput from '../common/select-input/select-input'
import Accordion from '../common/expansion-panel/expansion-panel'
import RangeSlider from '../common/range-slider/range-slider'
import ContinuousSlider from '../common/continuous-slider/continuous-slider'
import CheckBox from '../common/checkbox/checkbox.component'

const styles = theme => ({
  container: {
    height: '100vh',
    backgroundColor: PRIMARY_MAIN_COLOR,
    padding: theme.spacing(1)
  },
  mainHeading: {
    color: '#ffffff'
  },
  subHeading: {
    color: '#ffffff'
  },
  textField: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 40,
    '& fieldset': {
      borderRadius: 40
    }
  },
  inputHeight: {
    padding: 10
  },
  dense: {
    marginTop: 19
  },
  verticalAlign: {
    verticalAlign: 'middle'
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#dd4966',
    color: 'white',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#dd4966'
    }
  },
  select: {
    minWidth: 200,
    borderRadius: 50,
    padding: 3,
    border: '1px solid white',
    backgroundColor: 'transparent',
    color: 'white'
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  stepperRoot: {
    width: '80%'
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  loadingButtonWrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  menu: {
    width: 200
  },
  imageRadioGroup: {
    flexDirection: 'row'
  },
  icon: {
    fill: 'white'
  },
  dropdownStyle: {
    border: '1px solid black',
    borderRadius: '5%',
    backgroundColor: 'red'
  }
})

class StyleGuide extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sample: 'one'
    }
  }

  /**************************************
  *  METHODS
  **************************************/

  // Select input
  handleDropdownChange (value) {
    this.setState({ sample: value })
  }

  /**************************************
  *  LIFECYCLE
  **************************************/
  render () {
    const { classes } = this.props
    const { sample } = this.state

    return (
      <div className={classes.container}>
        <h1 className={classes.mainHeading}>STYLEGUIDE</h1>
        <h3 className={classes.subHeading}>INPUTS</h3>
        <form noValidate autoComplete='off'>
          <TextInput
            placeholder='سمو الشيخ محمد بن راشد آل مكتوم'
          />

          <SelectInput value={sample} handleChange={() => this.handleDropdownChange('sample')} options={[{ id: 1, label: 'One', value: 'one' }]} />

          <SearchInput />

          <CheckBox />
        </form>

        <h3 className={classes.subHeading}>BUTTONS</h3>
        <div>
          <Button variant='contained' className={classes.button}>تسجيل الدخول</Button>
        </div>

        <div style={{ width: '50%' }}>
          <h3 className={classes.subHeading}>ACCORDION</h3>
          <Accordion />
        </div>

        <h3 className={classes.subHeading}>SLIDERS</h3>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 20 }}>
            <h5 className={classes.subHeading}>Continuous Sliders</h5>
            <ContinuousSlider />
          </div>
          <div style={{ marginLeft: 20 }}>
            <h5 className={classes.subHeading}>Range Sliders</h5>
            <RangeSlider
              type='default'
              color='#001c47'
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <h5 className={classes.subHeading}>Customized Range Sliders</h5>
            <RangeSlider
              type='customized'
              color='#51cecc'
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <h5 className={classes.subHeading}>Customized Range Sliders</h5>
            <RangeSlider
              type='customized'
              color='#f77a40'
            />
          </div>
          <div>
            <h5 className={classes.subHeading}>Customized Range Sliders</h5>
            <RangeSlider
              type='customized'
              color='#8049c6'
            />
          </div>
        </div>
      </div>
    )
  }
}

StyleGuide.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(StyleGuide)
