import React from 'react'

import { Typography } from '@material-ui/core'
import { BRAND_LOGO, LEFT_ARROW } from '../../../constants/image-reference'

import './board-member-list-header.component.scss'

export default function BoardMemberListHeader (props) {
  const { t, recomDetails, handleBackButtonClick } = props

  return (
    <div className='board-member-list-header-container'>
      <div className='content-wrapper'>
        <div className='heading-wrapper'>
          <img src={BRAND_LOGO} alt='Brand logo' className='brand-logo' />
          <div className='sub-heading'>
            <Typography className='heading'>{t('common.newCandidates')}</Typography>
            <Typography className='title'>{t('recommendation.nominationForNewBoardOfDirectors')}</Typography>
            {recomDetails && <Typography className='title'>{recomDetails.board ? recomDetails.board.nameEn : ''}</Typography>}
          </div>
        </div>
        <div className='back-button-wrapper'>
          <img src={LEFT_ARROW} alt='Back' className='back-button' onClick={handleBackButtonClick} />
        </div>
      </div>
    </div>
  )
}
