import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { AVATAR } from '../../constants/image-reference'
import { imageBaseUrl } from '../../utils/string.utils'
import { constructDescriptionDetails } from '../../utils/profile-description.util'

import './member-card.component.scss'

export default function MemberCard (props) {
  const { t, handleViewProfileCallback } = props
  const { profileDetails, profileImage, position, tier } = props
  const { nameAr, id } = profileDetails
  const profileImagePath = profileImage ? profileImage.url : null

  const handleViewProfile = () => {
    handleViewProfileCallback(id)
  }

  return (
    <div className='member-card' style={{ width: (tier && tier === 'first') ? '100%' : 'calc(50% - 40px)' }}>
      <div className='card-details'>
        <div className='basic-info' style={{ width: (tier && tier === 'first') ? '70%' : '100%' }}>
          <div className='avatar-wrapper'>
            {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='avatar' />}
            {profileImagePath && (
              <img
                src={`${imageBaseUrl}${profileImagePath}`}
                alt='' onerror="this.style.display='none'"
                className='avatar'
              />)}
          </div>
          <div className='col-wrapper'>
            <Typography className='name'>{nameAr || ''}</Typography>
            <Typography className='member-position'>{position}</Typography>
          </div>
        </div>
        {(tier && tier === 'first') && (
          <div className='description'>
            {constructDescriptionDetails(profileDetails)}
          </div>
        )}
      </div>
      <div className='row-wrapper'>
        <Button
          variant='outlined'
          classes={{ root: 'view-button' }}
          onClick={handleViewProfile}
        >{t('common.viewProfile')}
        </Button>
      </div>
    </div>
  )
}
