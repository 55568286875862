import React from 'react'
import classNames from 'classnames'

import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE, USERS_BLUE, NOTE } from '../../../constants/image-reference'

import './recommendation-dialog.component.scss'

export default function RecommendationDialog (props) {
  const { handleClose, t, recommendations } = props

  const handleCloseClick = () => {
    handleClose()
  }

  return (
    <Dialog size='sm' className='recommendation-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>{t('profileCard.nominatedPositions')}</Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' className='close-button' onClick={handleCloseClick} />
      </div>
      <div className='dialog-content'>
        <div className='recommendation-list'>
          {recommendations.length && recommendations.map((recommendation, index) => (
            <div key={recommendation.id}
              className={classNames(['recommendation', {
                'border-line': index !== 0
              }])}
            >
              {/* <div className='title'>
                <Typography className='title-text'>{recommendation.title || ''}</Typography>
              </div> */}
              <div className='position'>
                {/* <img src={NOTE} alt='Users' className='users-icon' /> */}
                <Typography className='position-text'>{recommendation.position.positionAr || ''}</Typography>
              </div>
              <div className='entity'>
                {/* <img src={USERS_BLUE} alt='Users' className='users-icon' /> */}
                <Typography className='entity-text'>{recommendation.entity.nameAr || ''}</Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  )
}
