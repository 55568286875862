
import React from 'react'
import { Checkbox } from '@material-ui/core'
import { UNCHECKED_CHECKBOX, CHECKED_CHECKBOX } from '../../constants/image-reference'

import './checkbox.component.scss'

function CheckBox (props) {
  const { value, handleChange } = props

  const onChange = () => {
    if (handleChange) {
      handleChange(!value)
    }
  }

  return (
    <Checkbox
      className='checkbox-container'
      checked={value}
      value={value}
      onChange={onChange}
      icon={<img className='checkbox-icon' src={UNCHECKED_CHECKBOX} alt='' />}
      checkedIcon={<img className='checkbox-icon' src={CHECKED_CHECKBOX} alt='' />}
    />
  )
}

export default CheckBox
