import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { logout } from '../../app/auth/auth.action-creator'
import { clearLocalStorage } from '../../helpers/auth'
import { getUserType } from '../../utils/string.utils'
import { HH, ADHOC } from '../../constants/misc'
import { resetReducer } from '../common.action-creator'
import {
  PROFILE, LOGOUT, ADVANCED_SEARCH, DISCOVER, SENT_RECOM, RECOMMENDATIONS_FILLED, USERS_FILLED,
  ADVANCED_SEARCH_FILLED,
  INSIGHTS_FILLED, SECTORS, SECTORS_FILLED
} from '../../constants/image-reference'

import classNames from 'classnames'
import AlertDialog from '../alert-dialog/alert-dialog.component'

import './footer.component.scss'

function Footer (props) {
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false)

  const { t, history, match, logout, userSession, resetReducer } = props
  const { path } = match

  const userType = getUserType(userSession)
  const isProfileDetailsPageView = path === '/profile-details/:profileId'

  useEffect(() => {
    if (!userSession) {
      clearLocalStorage()
      history.push('/login')
    }
  })

  const isActive = (activePath) => {
    const { path } = match
    if (activePath === path) {
      return true
    }
    return false
  }
  isActive()

  const toggleLogoutConfirm = () => {
    setLogoutConfirmOpen(!logoutConfirmOpen)
  }

  const logoutUser = () => {
    setLogoutConfirmOpen(false)
    logout()
  }

  const renderAlertDialog = () => {
    return (
      <AlertDialog
        {...props}
        type='warning'
        buttonText={t('common.logout')}
        handleConfirm={logoutUser}
        handleClose={toggleLogoutConfirm}
      >
        {t('alertMessage.logoutAlert')}
      </AlertDialog>
    )
  }

  const handleTabClick = () => {
    resetReducer()
  }

  return (
    <div
      className={classNames(['footer-container', {
        'fixed-footer': isProfileDetailsPageView,
        'relative-footer': !isProfileDetailsPageView
      }])}
    >
      <div className='footer-item logout' onClick={toggleLogoutConfirm}>
        <Typography className='name'>{t('footer.signOut')}</Typography>
        <img src={LOGOUT} alt='logo' className='icon' />
      </div>
      <NavLink activeClassName='active-section' className='footer-item' to='/advanced-search' onClick={handleTabClick}>
        <Typography className='name'>{t('footer.advancedSearch')}</Typography>
        <img src={isActive('/advanced-search') ? ADVANCED_SEARCH_FILLED : ADVANCED_SEARCH} alt='logo' className='icon' />
      </NavLink>
      <NavLink activeClassName='active-section' className='footer-item' to='/sent' onClick={handleTabClick}>
        <Typography className='name'>{t('footer.sent')}</Typography>
        <img src={isActive('/sent') ? RECOMMENDATIONS_FILLED : SENT_RECOM} alt='Sent Recommendation Logo' className='icon' />
      </NavLink>
      {(userType !== HH && userType !== ADHOC) &&
        <NavLink activeClassName='active-section' className='footer-item' to='/recommendation' onClick={handleTabClick}>
          <Typography className='name'>{t('footer.nominations')}</Typography>
          <img src={isActive('/recommendation') ? USERS_FILLED : PROFILE} alt='Nominations Logo' className='icon' />
        </NavLink>}
      <NavLink activeClassName='active-section' className='footer-item' to='/insights' onClick={handleTabClick}>
        <Typography className='name'>{t('footer.discover')}</Typography>
        <img src={isActive('/insights') ? INSIGHTS_FILLED : DISCOVER} alt='Discover Logo' className='icon' />
      </NavLink>
      <NavLink activeClassName='active-section' className='footer-item' to='/sectors' onClick={handleTabClick}>
        <Typography className='name'>{t('footer.sectors')}</Typography>
        <img src={isActive('/sectors') ? SECTORS_FILLED : SECTORS} alt='Discover Logo' className='icon' />
      </NavLink>
      {logoutConfirmOpen && renderAlertDialog()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    userSession: state.auth.userSession
  }
}

export default (connect(mapStateToProps, { logout, resetReducer })(Footer))
