import React from 'react'
import { TextField } from '@material-ui/core'

import classnames from 'classnames'

import './text-input.scss'

function TextInput (props) {
  const { placeholder, rtl, type, handleChange, error, value, className, fullWidth = true, multiline } = props

  const onChange = event => {
    if (handleChange) {
      handleChange(event.target.value)
    }
  }

  return (
    <div className={classnames('text-input-container', className, { rtl: rtl })}>
      <TextField
        multiline={multiline}
        color='secondary'
        className='text-input'
        placeholder={placeholder}
        margin='dense'
        variant='outlined'
        type={type}
        fullWidth={fullWidth}
        onChange={onChange}
        error={error}
        value={value}
        rowsMax='10'
      />
    </div>
  )
}

export default TextInput
