import React, { useState, useEffect, useRef } from 'react'
import { Typography, TextField, Slide } from '@material-ui/core'
import { SEARCH_BLUE } from '../../../../constants/image-reference'
import { debounce } from 'lodash'

import SelectInput from '../../../../common/select-input/select-input'
import './filters.component.scss'

const viewTypes = [
  { id: 1, value: 'entity', label: 'جهات' },
  { id: 2, value: 'tier', label: 'مستويات' }
]

export default function TabOneFilters (props) {
  const inputRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeEntityType, setActiveEntityType] = useState('')
  const [isFirstLoad, setFirstLoadState] = useState(true)

  const {
    t, insightType, entityTypeId, entityIds, orgTypes, activeInsight, dropdownChangeCallback,
    fetchOrgTypesCallback, fetchOrgListCallback, orgList, removeEntityCallback, onOrgChangeCallback,
    showErrorDialogCallback, activeIndex, activeDirection
  } = props
  const isEntityTypeFilterVisible = insightType.value === 'entity'
  const isEntityFilterVisible = !!entityTypeId.value

  const delayedCallback = debounce((entityType, searchQuery) => fetchOrgList(entityType, searchQuery), 500)

  useEffect(() => {
    fetchOrgTypes()
  }, [])

  useEffect(() => {
    if (insightType.value) {
      if (isFirstLoad) {
        setFirstLoadState(false)
      }
    }
  }, [insightType])

  useEffect(() => {
    if (activeInsight && activeInsight.entityType) {
      setActiveEntityType(activeInsight.entityType.id)
    }
  }, [activeInsight])

  useEffect(() => {
    if (activeEntityType || searchQuery) {
      delayedCallback(activeEntityType, searchQuery)
    }
  }, [activeEntityType, searchQuery])

  const fetchOrgTypes = () => {
    fetchOrgTypesCallback()
  }

  const fetchOrgList = (entityType, searchQuery) => {
    fetchOrgListCallback(entityType, searchQuery)
  }

  const handleDropdownChange = (option, type) => {
    dropdownChangeCallback(option, type)
    setActiveEntityType(option.type)
  }

  const onOrgChange = (value, key) => {
    let index = ''
    let isSelected = false
    const errorMsg = t('insights.maxEntitiesError')
    entityIds.value.map((data, indx) => {
      if (data === value) {
        index = indx
        isSelected = true
      }
    })
    if (isSelected) {
      removeEntityCallback(value, index)
    } else {
      if (entityIds.value.length < 4) {
        onOrgChangeCallback(value, key)
      } else {
        showErrorDialogCallback(errorMsg)
      }
    }
  }

  const handleSearch = (event) => {
    setSearchQuery(event.target.value)
  }

  const entityTypeOptions = orgTypes && orgTypes.map(orgType => {
    return { id: orgType.id, value: orgType.id, label: orgType.nameAr, type: orgType.id }
  })

  const isEntitySelected = (entities, id) => {
    let isSelected = false
    entities.map(data => {
      if (data === id) {
        isSelected = true
      }
    })
    return isSelected
  }

  const handleSearchInputIconClick = () => {
    const parentEle = inputRef.current.children[0]
    const inputEle = parentEle.children[0]
    inputEle.focus()
  }

  const renderSearchInputIcon = () => {
    return (
      <div className='placeholder-wrapper' onClick={handleSearchInputIconClick}>
        <img src={SEARCH_BLUE} alt='Search profiles' className='search-input-icon' />
        <Typography className='placeholder-text'>{t('common.search')}</Typography>
      </div>
    )
  }

  const renderOrgList = () => {
    return (
      <div className='search-results-wrapper' style={{ overflowY: 'hidden' }}>
        {orgList.map((org) => renderOrg(org))}
      </div>
    )
  }

  const renderOrg = (org) => {
    return (
      <div key={org.id} className='search-result'>
        <Typography className='label'>{org.nameAr}</Typography>
        <span className='org-checkbox' onClick={() => onOrgChange(org.id, 'entityIds')}>
          <input
            type='checkbox'
            checked={isEntitySelected(entityIds.value, org.id)}
            onChange={() => onOrgChange(org.id, 'entityIds')}
          />
          <span>
            <span className='checkmark' />
          </span>
        </span>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='select-input-empty-state-container'>
        <div className='empty-state-container'>
          <Typography className='no-option-found-text'>لا توجد خيارات</Typography>
        </div>
      </div>
    )
  }

  return (
    <Slide
      timeout={isFirstLoad ? { exit: 400 } : { enter: 400, exit: 400 }}
      direction={activeDirection}
      in={activeIndex === 0}
      mountOnEnter
      unmountOnExit
    >
      <div className='tab-one-filters'>
        <div className='filter-wrapper'>
          <SelectInput
            id='insightType'
            type='blueBorderDropdown'
            placeholder='نوع العرض'
            value={insightType.value}
            handleChange={(option) => handleDropdownChange(option, 'insightType')}
            options={viewTypes}
          />
        </div>

        {isEntityTypeFilterVisible &&
          <div className='filter-wrapper'>
            <SelectInput
              id='entityTypeId'
              type='blueBorderDropdown'
              placeholder={t('insights.entityTypeOptional')}
              value={entityTypeId.value}
              handleChange={(option) => handleDropdownChange(option, 'entityTypeId')}
              options={entityTypeOptions}
            />
          </div>}

        {isEntityFilterVisible &&
          <div className='entity-filter-wrapper'>
            <Typography className='title'>{t('insights.chooseAnEntityOptional')}</Typography>
            <div className='search-input-wrapper'>
              <TextField
                fullWidth
                ref={inputRef}
                className='search-input'
                margin='normal'
                variant='outlined'
                value={searchQuery}
                onChange={handleSearch}
              />
              {(searchQuery.length === 0) && renderSearchInputIcon()}
            </div>
            {(orgList && orgList.length) ? renderOrgList() : renderEmptyState()}
          </div>}
      </div>
    </Slide>
  )
}
