import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { PROFILE_CIRCLE, AVATAR } from '../../../constants/image-reference'
import { imageBaseUrl } from '../../../utils/string.utils'
import classnames from 'classnames'

import './entity-visualization.component.scss'

export default function EntityVisualization (props) {
  const { visualData, isHybridView } = props
  const [visualPoints, setVisualPoints] = useState('')
  const [showVisual, setShowVisual] = useState(false)

  const normalise = (val, max, min) => {
    if (isHybridView) return val
    if (!max || (val === max && val === min)) {
      return 0.5
    }
    return ((val - min) / (max - min))
  }

  const getCirclePoints = (noOfPoints, radius, center) => {
    var initialOffset = 2 * Math.PI * Math.random()
    var points = []
    var slice = 2 * Math.PI / noOfPoints
    for (var i = 0; i < noOfPoints; i++) {
      var angle = slice * i + initialOffset
      var newX = radius + radius * Math.cos(angle)
      var newY = radius + radius * Math.sin(angle)
      points.push({
        x: Math.floor(newX),
        y: Math.floor(newY)
      })
    }
    return points
  }

  useEffect(() => {
    console.log('useEffect....', visualData);
    var margin = 25
    var container = {
      width: 600,
      height: 600
    }
    setShowVisual(false)
    const { visualDetails } = visualData
    console.log('##########', visualDetails);
    let minCount, maxCount
    let sortedVisualData = []
    if (visualDetails && visualDetails.length) {
      sortedVisualData = [...visualDetails]
      sortedVisualData.sort((a, b) => {
        return b.profilesCount - a.profilesCount
      })
      minCount = sortedVisualData[sortedVisualData.length - 1].profilesCount
      maxCount = sortedVisualData[0].profilesCount
    }
    const visualPointsCopy = sortedVisualData.map(data => {
      const { profiles, profilesCount } = data
      var value = normalise(profilesCount, maxCount, minCount)
      var diameter = (value * 180) + 140
      var profilePoints = getCirclePoints(profiles.length, diameter / 2)
      return {
        diameter,
        width: diameter,
        height: diameter,
        profilePoints
      }
    })
    if (visualPointsCopy.length === 1) {
      visualPointsCopy[0].x = container.width / 2 - visualPointsCopy[0].diameter / 2 - margin
      visualPointsCopy[0].y = container.height / 2 - visualPointsCopy[0].diameter / 2 - margin
    }
    if (visualPointsCopy.length === 2) {
      visualPointsCopy[0].x = container.width / 2 - visualPointsCopy[0].diameter - (margin * 2)
      visualPointsCopy[0].y = container.height / 2 - visualPointsCopy[0].diameter - (margin * 2)
      visualPointsCopy[1].x = container.width / 2 + (margin / 2)
      visualPointsCopy[1].y = container.height / 2 + (margin / 2)
    }
    if (visualPointsCopy.length === 3) {
      visualPointsCopy[0].x = container.width / 2 - visualPointsCopy[0].diameter / 2 - margin
      visualPointsCopy[0].y = container.height / 2 - visualPointsCopy[0].diameter - (margin * 2)
      visualPointsCopy[1].x = container.width / 2 + (margin / 2)
      visualPointsCopy[1].y = container.height / 2 + (margin / 2) + 40
      visualPointsCopy[2].x = container.width / 2 - visualPointsCopy[2].diameter - (margin * 2)
      visualPointsCopy[2].y = container.height / 2 + (margin / 2)
    }
    if (visualPointsCopy.length === 4) {
      visualPointsCopy[0].x = container.width / 2 - visualPointsCopy[0].diameter - (margin * 2)
      visualPointsCopy[0].y = container.height / 2 - visualPointsCopy[0].diameter - (margin * 2)
      visualPointsCopy[3].x = container.width / 2
      visualPointsCopy[3].y = container.height / 2 - visualPointsCopy[3].diameter - (margin * 2) + 40
      visualPointsCopy[1].x = container.width / 2 + (margin / 2)
      visualPointsCopy[1].y = container.height / 2 + (margin / 2) + 40
      visualPointsCopy[2].x = container.width / 2 - visualPointsCopy[2].diameter - (margin * 2)
      visualPointsCopy[2].y = container.height / 2 + (margin / 2)
    }
    
    setVisualPoints(visualPointsCopy)
    setTimeout(() => {
      setShowVisual(true)
    }, 500)
  }, [visualData])

  return (
    <div className={classnames(['entity-visualization-container', showVisual ? 'show-visual' : 'hide-visual'])}>
      {visualPoints ? visualPoints.map((data, visualIndx) => (
        <div
          key={visualIndx}
          className='entity-circle'
          style={{
            width: data.width,
            height: data.height,
            borderRadius: data.height / 2,
            position: visualPoints.length < 5 ? 'absolute' : 'relative',
            left: visualPoints.length < 5 ? data.x : 0,
            top: visualPoints.length < 5 ? data.y : 0,
	    cursor: 'pointer'		  
          }}

         onClick={() => props.viewProfileCallback(visualData.visualDetails[visualIndx])}
        >
       
          <Typography className='entity-text'>
            {visualData.visualDetails[visualIndx] ? visualData.visualDetails[visualIndx].entityName : ''}
          </Typography>
        </div>
      )) : null}
    </div>
  )
}
