import React, { useState, useEffect } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import {
  createTheme,
  MuiThemeProvider
} from "@material-ui/core";
import classnames from "classnames";

import "./sectors-entity-visualization.component.scss";


const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#99b4d7", padding : "20px", color : "#000000", fontSize:"20px", borderRadius:"20px"
      },
    },
  },
});

export default function SectorsEntityVisualization(props) {
  const { sectors, visualData } = props;
  const [visualPoints, setVisualPoints] = useState("");
  const [showVisual, setShowVisual] = useState(false);
  const [sectorsList, setSectorsList] = useState(null);

  const isHybridView = false;

  const normalise = (val, max, min) => {
    if (isHybridView) return val;
    if (!max || (val === max && val === min)) {
      return 0.5;
    }
    return (val - min) / (max - min);
  };

  const getCirclePoints = (noOfPoints, radius, center) => {
    var initialOffset = 2 * Math.PI * Math.random();
    var points = [];
    var slice = (2 * Math.PI) / noOfPoints;
    for (var i = 0; i < noOfPoints; i++) {
      var angle = slice * i + initialOffset;
      var newX = radius + radius * Math.cos(angle);
      var newY = radius + radius * Math.sin(angle);
      points.push({
        x: Math.floor(newX),
        y: Math.floor(newY),
      });
    }
    return points;
  };

  useEffect(() => {
    var margin = 25;
    var container = {
      width: 600,
      height: 600,
    };
    setShowVisual(false);
    //const { visualDetails } = sec
    const visualDetails = sectors;

    let minCount, maxCount;
    let sortedVisualData = [];
    if (visualDetails && visualDetails.length) {
      sortedVisualData = [...visualDetails];
      sortedVisualData.sort((a, b) => {
        return b.totalEmp - a.totalEmp;
      });
      minCount = sortedVisualData[sortedVisualData.length - 1].totalEmp;
      maxCount = sortedVisualData[0].totalEmp;
    }
    setSectorsList(sortedVisualData);
    const visualPointsCopy = sortedVisualData.map((data) => {
      const { totalEmp } = data;
      var value = normalise(totalEmp, maxCount, minCount);
      var diameter = value * 180 + 140;
      return {
        diameter,
        width: diameter,
        height: diameter,
      };
    });

    if (visualPointsCopy.length === 1) {
      visualPointsCopy[0].x =
        container.width / 2 - visualPointsCopy[0].diameter / 2 - margin;
      visualPointsCopy[0].y =
        container.height / 2 - visualPointsCopy[0].diameter / 2 - margin;
    }
    if (visualPointsCopy.length === 2) {
      visualPointsCopy[0].x =
        container.width / 2 - visualPointsCopy[0].diameter - margin * 2;
      visualPointsCopy[0].y =
        container.height / 2 - visualPointsCopy[0].diameter - margin * 2;
      visualPointsCopy[1].x = container.width / 2 + margin / 2;
      visualPointsCopy[1].y = container.height / 2 + margin / 2;
    }
    if (visualPointsCopy.length === 3) {
      visualPointsCopy[0].x =
        container.width / 2 - visualPointsCopy[0].diameter / 2 - margin;
      visualPointsCopy[0].y =
        container.height / 2 - visualPointsCopy[0].diameter - margin * 2;
      visualPointsCopy[1].x = container.width / 2 + margin / 2;
      visualPointsCopy[1].y = container.height / 2 + margin / 2 + 40;
      visualPointsCopy[2].x =
        container.width / 2 - visualPointsCopy[2].diameter - margin * 2;
      visualPointsCopy[2].y = container.height / 2 + margin / 2;
    }
    if (visualPointsCopy.length === 4) {
      visualPointsCopy[0].x =
        container.width / 2 - visualPointsCopy[0].diameter - margin * 2;
      visualPointsCopy[0].y =
        container.height / 2 - visualPointsCopy[0].diameter - margin * 2;
      visualPointsCopy[3].x = container.width / 2;
      visualPointsCopy[3].y =
        container.height / 2 - visualPointsCopy[3].diameter - margin * 2 + 40;
      visualPointsCopy[1].x = container.width / 2 + margin / 2;
      visualPointsCopy[1].y = container.height / 2 + margin / 2 + 40;
      visualPointsCopy[2].x =
        container.width / 2 - visualPointsCopy[2].diameter - margin * 2;
      visualPointsCopy[2].y = container.height / 2 + margin / 2;
    }

    setVisualPoints(visualPointsCopy);

    setTimeout(() => {
      setShowVisual(true);
    }, 500);
  }, [visualData]);

  const showProfiles = (id) => {
    console.log("-----showProfiles", id);
  };

  return (
    <div
      className={classnames([
        "entity-visualization-container",
        showVisual ? "show-visual" : "hide-visual",
      ])}
    >
      {visualPoints
        ? visualPoints.map((data, visualIndx) => (
            <MuiThemeProvider theme={theme}>
              <Tooltip
                className="tooltip-con"
                key={visualIndx}
                title={
                  sectorsList[visualIndx] ? (
                    <React.Fragment>
                      <div className="tooltip">
                      <span className="sectorscount">{sectorsList[visualIndx].totalEmp}</span>
                        <p>مجموع القادة</p>                        
                        <hr className="sectorsline"></hr>
                        <table>
                          <tbody>
                          <tr>
                              <td>{sectorsList[visualIndx].femaleTotal}</td>
                              <td>{sectorsList[visualIndx].maleTotal}</td>
                            </tr>
                            <tr>
                              <td>إناث</td>
                              <td>ذكور</td>
                            </tr>
                           
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )
                }
              >
                <div
                  key={visualIndx}
                  className="entity-circle"
                  style={{
                    width: data.width,
                    height: data.height,
                    borderRadius: "50%",
                    position: visualPoints.length < 5 ? "absolute" : "relative",
                    left: visualPoints.length < 5 ? data.x : 0,
                    top: visualPoints.length < 5 ? data.y : 0,
                    border: "5px solid #001c47",
                    cursor: "pointer",
                    boxShadow:
                      "0 0 0 3px #1789be, 0 0 0 8px #001c47, 0 0 0 10px #1c6da3",
                  }}
                  onClick={() =>
                    props.viewEntitiesCallback(sectorsList[visualIndx])
                  }
                >
                  <Typography className="entity-text" component={"span"}>
                    <img
                      src={`http://94.200.225.232${sectorsList[visualIndx].image}`}
                      className="photo"
                    ></img>
                    <p>
                      {sectorsList[visualIndx]
                        ? sectorsList[visualIndx].nameAr
                        : ""}
                    </p>
                  </Typography>
                </div>
              </Tooltip>
            </MuiThemeProvider>
          ))
        : null}
    </div>
  );
}
