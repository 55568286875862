import { zipObject } from 'lodash'

const ACTION_TYPES = [
  // set/unset recommendation attributes
  'SET_RECOMMENDATION_ATTRIBUTES',
  'CLEAR_RECOMMENDATION_ATTRIBUTES',

  // set/unset recommendation detail
  'SET_RECOMMENDATION_DETAIL',
  'CLEAR_RECOMMENDATION_DETAIL',

  // set/unset recommendation results page config
  'SET_RECOMMENDATION_RESULTS_PAGE_CONFIG',
  'CLEAR_RECOMMENDATION_RESULTS_PAGE_CONFIG',

  // save bulk recom
  'SAVE_BULK_RECOM',
  'SAVE_BULK_RECOM_SUCCESS',
  'SAVE_BULK_RECOM_FAILURE',
  'CLEAR_SAVE_BULK_RECOM_VAR',

  // Send bulk recom
  'SEND_BULK_RECOM',
  'SEND_BULK_RECOM_SUCCESS',
  'SEND_BULK_RECOM_FAILURE',
  'CLEAR_SEND_BULK_RECOM_VAR',

  // save board recom
  'SAVE_BOARD_RECOM',
  'SAVE_BOARD_RECOM_SUCCESS',
  'SAVE_BOARD_RECOM_FAILURE',
  'CLEAR_SAVE_BOARD_RECOM_VAR',

  // Get recom
  'GET_RECOM',
  'GET_RECOM_SUCCESS',
  'GET_RECOM_FAILURE',
  'CLEAR_RECOM_VAR',

  // load more recom
  'LOAD_MORE_RECOM',
  'LOAD_MORE_RECOM_SUCCESS',
  'LOAD_MORE_RECOM_FAILURE',

  // delete recom
  'DELETE_RECOM',
  'DELETE_RECOM_SUCCESS',
  'DELETE_RECOM_FAILURE',

  // Get recom details
  'GET_RECOM_DETAILS',
  'GET_RECOM_DETAILS_SUCCESS',
  'GET_RECOM_DETAILS_FAILURE',
  'CLEAR_GET_RECOM_DETAILS_VAR',

  // get recom profiles
  'GET_RECOM_PROFILES',
  'GET_RECOM_PROFILES_SUCCESS',
  'GET_RECOM_PROFILES_FAILURE',
  'CLEAR_GET_RECOM_PROFILES_VAR',

  // save and send profile recom
  'SAVE_AND_SEND_RECOM',
  'SAVE_AND_SEND_RECOM_SUCCESS',
  'SAVE_AND_SEND_RECOM_FAILURE',
  'CLEAR_SAVE_AND_SEND_RECOM_VARIABLES',

  // save and send board recom
  'SAVE_AND_SEND_BOARD_RECOM',
  'SAVE_AND_SEND_BOARD_RECOM_SUCCESS',
  'SAVE_AND_SEND_BOARD_RECOM_FAILURE',
  'CLEAR_SAVE_AND_SEND_BOARD_RECOM_VARIABLES',

  // set/unset recommended profiles
  'SET_RECOMMENDED_PROFILES',
  'UNSET_RECOMMENDED_PROFILES',

  // reset reducer
  'RESET_REDUCER'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
