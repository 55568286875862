import ActionTypes from './insights.action-type'
import { apiAction } from '../../api/api.action-creator'

// get insight list
export function getInsightList (queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getInsightList',
    onSuccess: getInsightListSuccess,
    onFailure: getInsightListFailure,
    label: ActionTypes.GET_INSIGHT_LIST,
    isSecureCall: true,
    queryParams
  })
}
export function getInsightListSuccess (response) {
  return {
    type: ActionTypes.GET_INSIGHT_LIST_SUCCESS,
    response: response.data
  }
}
export function getInsightListFailure (response) {
  return {
    type: ActionTypes.GET_INSIGHT_LIST_FAILURE,
    response
  }
}

// load more insights
export function loadMoreInsights (queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getInsightList',
    onSuccess: loadMoreInsightsSuccess,
    onFailure: loadMoreInsightsFailure,
    label: ActionTypes.LOAD_MORE_INSIGHTS,
    isSecureCall: true,
    queryParams
  })
}
export function loadMoreInsightsSuccess (response) {
  return {
    type: ActionTypes.LOAD_MORE_INSIGHTS_SUCCESS,
    response: response.data
  }
}
export function loadMoreInsightsFailure (response) {
  return {
    type: ActionTypes.LOAD_MORE_INSIGHTS_FAILURE,
    response
  }
}

// get insight details
export function getInsightDetails (queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getInsightDetails',
    onSuccess: getInsightDetailsSuccess,
    onFailure: getInsightDetailsFailure,
    label: ActionTypes.GET_INSIGHT_DETAILS,
    isSecureCall: true,
    queryParams
  })
}
export function getInsightDetailsSuccess (response) {
  return {
    type: ActionTypes.GET_INSIGHT_DETAILS_SUCCESS,
    response: response.data
  }
}
export function getInsightDetailsFailure (response) {
  return {
    type: ActionTypes.GET_INSIGHT_DETAILS_FAILURE,
    response
  }
}

// add insight
export function addInsight (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'addInsight',
    onSuccess: addInsightSuccess,
    onFailure: addInsightFailure,
    label: ActionTypes.ADD_INSIGHT,
    isSecureCall: true,
    body: request
  })
}
export function addInsightSuccess (response) {
  return {
    type: ActionTypes.ADD_INSIGHT_SUCCESS,
    response: response.data
  }
}
export function addInsightFailure (response) {
  return {
    type: ActionTypes.ADD_INSIGHT_FAILURE,
    response
  }
}
export function clearAddInsightVar () {
  return {
    type: ActionTypes.CLEAR_ADD_INSIGHT_VAR
  }
}

// edit insight
export function editInsight (queryParams, request) {
  return apiAction({
    method: 'PUT',
    url: 'editInsight',
    onSuccess: editInsightSuccess,
    onFailure: editInsightFailure,
    label: ActionTypes.EDIT_INSIGHT,
    isSecureCall: true,
    queryParams,
    body: request
  })
}
export function editInsightSuccess (response, request) {
  return {
    type: ActionTypes.EDIT_INSIGHT_SUCCESS,
    response: response.data,
    request
  }
}
export function editInsightFailure (response) {
  return {
    type: ActionTypes.EDIT_INSIGHT_FAILURE,
    response
  }
}

// delete insight
export function deleteInsight (queryParams) {
  return apiAction({
    method: 'DELETE',
    url: 'deleteInsight',
    onSuccess: deleteInsightSuccess,
    onFailure: deleteInsightFailure,
    label: ActionTypes.DELETE_INSIGHT,
    isSecureCall: true,
    queryParams
  })
}
export function deleteInsightSuccess (request) {
  return {
    type: ActionTypes.DELETE_INSIGHT_SUCCESS,
    request
  }
}
export function deleteInsightFailure (response) {
  return {
    type: ActionTypes.DELETE_INSIGHT_FAILURE,
    response
  }
}

// get insight entity types
export function getInsightEntityTypes (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'getInsightEntityTypes',
    onSuccess: getInsightEntityTypesSuccess,
    onFailure: getInsightEntityTypesFailure,
    label: ActionTypes.GET_INSIGHT_ENTITY_TYPES,
    isSecureCall: true,
    body: request || {}
  })
}
export function getInsightEntityTypesSuccess (response) {
  return {
    type: ActionTypes.GET_INSIGHT_ENTITY_TYPES_SUCCESS,
    response: response.data
  }
}
export function getInsightEntityTypesFailure (response) {
  return {
    type: ActionTypes.GET_INSIGHT_ENTITY_TYPES_FAILURE,
    response
  }
}

// get entity profile count
export function getEntityProfileCount (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'getEntityProfileCount',
    onSuccess: getEntityProfileCountSuccess,
    onFailure: getEntityProfileCountFailure,
    label: ActionTypes.GET_ENTITY_PROFILE_COUNT,
    isSecureCall: true,
    body: request
  })
}
export function getEntityProfileCountSuccess (response, request, body) {
  return {
    type: ActionTypes.GET_ENTITY_PROFILE_COUNT_SUCCESS,
    response: response.data,
    filters: body
  }
}
export function getEntityProfileCountFailure (response) {
  return {
    type: ActionTypes.GET_ENTITY_PROFILE_COUNT_FAILURE,
    response
  }
}
export function clearEntityProfileCount () {
  return {
    type: ActionTypes.CLEAR_ENTITY_PROFILE_COUNT
  }
}

// get tiers profile count
export function getTiersProfileCount (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'getTiersProfileCount',
    onSuccess: getTiersProfileCountSuccess,
    onFailure: getTiersProfileCountFailure,
    label: ActionTypes.GET_TIERS_PROFILE_COUNT,
    isSecureCall: true,
    body: request || {}
  })
}
export function getTiersProfileCountSuccess (response, request, body) {
  return {
    type: ActionTypes.GET_TIERS_PROFILE_COUNT_SUCCESS,
    response: response.data,
    filters: body
  }
}
export function getTiersProfileCountFailure (response) {
  return {
    type: ActionTypes.GET_TIERS_PROFILE_COUNT_FAILURE,
    response
  }
}
export function clearTiersProfileCount () {
  return {
    type: ActionTypes.CLEAR_TIERS_PROFILE_COUNT
  }
}

// send insight
export function sendInsight (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'sendInsight',
    onSuccess: sendInsightSuccess,
    onFailure: sendInsightFailure,
    label: ActionTypes.SEND_INSIGHT,
    isSecureCall: true,
    body: request,
    queryParams
  })
}
export function sendInsightSuccess (response, request) {
  return {
    type: ActionTypes.SEND_INSIGHT_SUCCESS,
    response: response.data,
    request
  }
}
export function sendInsightFailure (response) {
  return {
    type: ActionTypes.SEND_INSIGHT_FAILURE,
    response
  }
}
export function clearSendInsightVar () {
  return {
    type: ActionTypes.CLEAR_SEND_INSIGHT_VAR
  }
}

// Get leader list
export const getLeaderList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getLeaderList',
    isSecureCall: true,
    onSuccess: getLeaderListSuccess,
    onFailure: getLeaderListFailure,
    label: ActionTypes.GET_LEADER_LIST,
    queryParams
  })
}
export const getLeaderListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_LEADER_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getLeaderListFailure = (response) => {
  return {
    type: ActionTypes.GET_LEADER_LIST_FAILURE,
    response
  }
}
export const clearLeaderList = () => {
  return {
    type: ActionTypes.CLEAR_LEADER_LIST
  }
}

// Set selected insight
export const setSelectedInsight = (selectedInsight) => {
  return {
    type: ActionTypes.SET_SELECTED_INSIGHT,
    selectedInsight
  }
}

// Clear selected insight
export const clearSelectedInsight = () => {
  return {
    type: ActionTypes.CLEAR_SELECTED_INSIGHT
  }
}
