import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { HH, ADHOC } from '../../constants/misc'
import { imageBaseUrl, getMainTag } from '../../utils/string.utils'
import { Typography, Button, TextField, Divider } from '@material-ui/core'
import { constructDescriptionDetails } from '../../utils/profile-description.util'
import {
  AVATAR, RISING_STAR, FUTURISTIC, BLUE_PENCIL, ADD, WHITE_CLOSE, REMOVE_ICON, BACK_ARROW_WHITE
} from '../../constants/image-reference'

import classNames from 'classnames'
import Truncate from 'react-truncate'
import RecRadioButton from '../../app/recommendation/recommendation-radio-button/recommendation-radio-button.component'

import './profile-card.component.scss'

function ProfileCard (props) {
  const [profileCardExpand, setProfileCardExpand] = useState(false)
  const {
    t, profile, history, handleBandClick, positions, addProfileCallback,
    shortlistedProfileIds, removeProfileCallback, isMultiplePositionFlow,
    updateReasonForNominationCallback, navigateToProfileDetailsCallback, recomConfig
  } = props
  const { recommendations } = profile
  let hasEditAccess = false
  if (recomConfig) {
    const { userType } = recomConfig
    hasEditAccess = ((userType !== HH) && (userType !== ADHOC))
  }

  const isRecommendationScreen = (
    history.location.pathname === '/position-recommendation-results' ||
    history.location.pathname === '/board-recommendation-results'
  )
  const [activePos, setActivePos] = useState(isRecommendationScreen ? positions ? positions[0].value : '' : '')
  const [showReasonInputField, setReasonInputField] = useState(false)
  const [nominationReason, setNominationReason] = useState('')

  const showRecommendationBand = (isRecommendationScreen && recommendations && recommendations.length) && hasEditAccess

  const handleChooseButtonClick = () => {
    addProfileCallback(profile, activePos)
    closeProfileCard()
  }

  const handleProfileChooseClick = () => {
    if (isMultiplePositionFlow) {
      openProfileCard()
    } else {
      addProfileCallback(profile, activePos)
    }
  }

  const openProfileCard = () => {
    setProfileCardExpand(true)
  }

  const handleRemoveClick = () => {
    removeProfileCallback(activePos, profile)
  }

  const closeProfileCard = () => {
    setProfileCardExpand(false)
  }

  const handleCancelButtonClick = () => {
    closeProfileCard()
  }

  const handleRecommendationBandClick = () => {
    handleBandClick(recommendations)
  }

  const navigateToProfileDetailsPage = () => {
    navigateToProfileDetailsCallback(profile.id)
  }

  const handleReasonChange = (event) => {
    setNominationReason(event.target.value)
  }

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      setReasonInputField(false)
      updateReasonForNominationCallback(nominationReason, profile.id)
    }
  }

  const handleAddReasonClick = () => {
    setReasonInputField(true)
  }

  const renderRecommendationBand = () => {
    const showReasonLink = (isRecommendationScreen && shortlistedProfileIds.includes(profile.id) && hasEditAccess)
    return (
      <div
        className={classNames(['recommendation-band', {
          'selected-profile-band-width': showReasonLink,
          'unselected-profile-band-width': !showReasonLink
        }])}
        onClick={handleRecommendationBandClick}
      >
        <Typography className='recommendation-band-text'>{t('profileCard.alsoNominatedForAnotherPosition')}</Typography>
        <img src={BACK_ARROW_WHITE} alt='Back arrow' className='recommendation-band-icon' />
      </div>
    )
  }

  const renderGiveReasonLink = () => {
    return (
      <div className='nomination-reason-wrapper' onClick={handleAddReasonClick}>
        <img src={BLUE_PENCIL} alt='Modify nomination reason' className='modify-icon' />
        <Typography className='text'>{t('recommendation.modifyReasonForNomination')}</Typography>
      </div>
    )
  }

  const renderNominationReason = () => {
    return (
      <div className='nomination-reason-wrapper' onClick={handleAddReasonClick}>
        <img src={BLUE_PENCIL} alt='Modify nomination reason' className='modify-icon' />
        <Typography className='text'>{nominationReason}</Typography>
      </div>
    )
  }

  const renderReasonInput = () => {
    return (
      <TextField
        classes={{ root: 'reason-input' }}
        placeholder={t('recommendation.modifyReasonForNomination')}
        margin='normal'
        variant='outlined'
        fullWidth
        value={nominationReason}
        type='text'
        onChange={handleReasonChange}
        onKeyPress={handleOnKeyPress}
      />
    )
  }

  const renderProfileDetails = () => {
    const { nameAr, tags, profileImage, summaryAr, isRisingStar, leadership } = profile
    const { positions } = leadership
    const profileImagePath = profileImage ? profileImage.url : null
    const organization = positions.length ? positions[0].organization : ''
    const position = positions.length ? positions[0].position : ''
    const showReasonLink = (isRecommendationScreen && shortlistedProfileIds.includes(profile.id) && hasEditAccess)
    const summary = summaryAr || constructDescriptionDetails(profile)

    return (
      <div
        className={classNames(['card-left-side-content', {
          'half-width': profileCardExpand,
          'selected-profile': showReasonLink
        }])}
        style={{ height: showRecommendationBand ? '435px' : '385px' }}
      >
        {showRecommendationBand ? renderRecommendationBand() : null}
        <div className='profile-details-wrapper'>
          <div className='avatar-wrapper'>
            {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='avatar' />}
            {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='avatar' />}
          </div>
          <div className='info-wrapper'>
            <div className='name-wrapper'>
              {isRisingStar && <img src={RISING_STAR} alt='Rising star' className='rising-star-badge' />}
              <Typography className='name'>{nameAr}</Typography>
            </div>
            <div className='professional-info-wrapper'>
              {position && <Typography className='position'>{position ? position.positionAr : ''}</Typography>}
              {organization && <Typography className='organisation'>{organization ? organization.nameAr : ''}</Typography>}
              {((tags && position) || organization) && <Divider className='info-divider' />}
              {tags &&
                <div className='futuristic'>
                  <img src={FUTURISTIC} alt='Futuristic' className='futuristic-badge' />
                  <Typography className='text'>{getMainTag(tags)}</Typography>
                </div>}
            </div>
            <div className='summary-wrapper'>
              <Truncate
                lines={2}
                className='summary'
                ellipsis={<span>...</span>}
              >{summary}
              </Truncate>
            </div>
            {/**{showReasonLink
              ? showReasonInputField
                ? renderReasonInput() : nominationReason.length
                  ? renderNominationReason() : renderGiveReasonLink() : null}
            **/}
          </div>
          <div className='buttons-wrapper'>
            <Button
              variant='outlined'
              classes={{ root: 'outline-button', disabled: 'disabled-outline-button' }}
              disabled={profileCardExpand}
              onClick={navigateToProfileDetailsPage}
            >
            عرض الملف
            </Button>
            {isRecommendationScreen && shortlistedProfileIds.includes(profile.id) && hasEditAccess &&
              <Button
                variant='outlined'
                classes={{ root: 'red-outline-button' }}
                onClick={handleRemoveClick}
              >
                <img src={REMOVE_ICON} alt='Remove' className='remove-icon' />
            حـذف
              </Button>}
            {isRecommendationScreen && !shortlistedProfileIds.includes(profile.id) && hasEditAccess &&
              <Button
                variant='outlined'
                classes={{ root: 'contained-button', disabled: 'disabled-contained-button' }}
                onClick={handleProfileChooseClick}
                disabled={profileCardExpand}
              >
                <img src={ADD} alt='Add' className='add-icon' />
            اخـتـار
              </Button>}
          </div>
        </div>
      </div>
    )
  }

  const renderNominationDetails = () => {
    return (
      <div className={classNames(['card-right-side-content', { 'half-width': profileCardExpand }])}>
        <Typography className='heading'>أضف كمنصب</Typography>
        <div className='positions-wrapper'>
          {(positions && positions.length > 1) && positions.map((position, index) => (
            <RecRadioButton
              key={index}
              value={position.value}
              label={position.label}
              activeButtonValue={activePos}
              handleChange={(value) => setActivePos(value)}
            />
          ))}
        </div>
        <div className='buttons-wrapper'>
          <Button variant='outlined' className='outline-button' onClick={handleChooseButtonClick}>تطبيق</Button>
          <Button variant='outlined' className='outline-button' onClick={handleCancelButtonClick}>
            <img src={WHITE_CLOSE} alt='Close card' className='close-icon' />
            اخـتـار
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames([
        'profile-card-container', {
          'expanded-card': profileCardExpand,
          'collapsed-card': !profileCardExpand
        }]
      )}
    >
      {renderProfileDetails()}
      {profileCardExpand && renderNominationDetails()}
    </div>
  )
}

export default withRouter(ProfileCard)
