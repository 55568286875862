import React from 'react'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { TEO, BRAND_LOGO } from '../../../constants/image-reference'

import './auth-layout.component.scss'

function AuthLayout (props) {
  const { t } = props
  return (
    <div className='auth-layout'>
      <div className='content'>
        <img src={TEO} alt='The executive office' className='teo' />
        <h1 className='welcome-msg'>{t('common.welcomeMessage')}</h1>
        <img className='logo' src={BRAND_LOGO} alt='logo' />
        {props.children}
      </div>
    </div>
  )
}

export default withTranslation(['translations'], translateOptions)(AuthLayout)
