import ActionType from './profile.action-type'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    // Get profile
    case ActionType.GET_PROFILE: {
      return {
        ...state,
        profile: undefined,
        isLoading: true
      }
    }
    case ActionType.GET_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        profile: action.response
      }
    }
    case ActionType.GET_PROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getProfileFailure: action.response
      }
    }

    // Get personal network profile list
    case ActionType.GET_PERSONAL_PROFILE_LIST: {
      return {
        ...state,
        isProfileLoading: true
      }
    }
    case ActionType.GET_PERSONAL_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isProfileLoading: false,
        personalProfileList: action.response
      }
    }
    case ActionType.GET_PERSONAL_PROFILE_LIST_FAILURE: {
      return {
        ...state,
        isProfileLoading: false,
        getPersonalProfileListFailure: action.response
      }
    }

    // Get professional profile list
    case ActionType.GET_PROFESSIONAL_PROFILE_LIST: {
      return {
        ...state,
        isProfileLoading: true
      }
    }
    case ActionType.GET_PROFESSIONAL_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isProfileLoading: false,
        professionalProfileList: action.response
      }
    }
    case ActionType.GET_PROFESSIONAL_PROFILE_LIST_FAILURE: {
      return {
        ...state,
        isProfileLoading: false,
        getProfessionalProfileListFailure: action.response
      }
    }

    // Get educational profile list
    case ActionType.GET_EDUCATIONAL_PROFILE_LIST: {
      return {
        ...state,
        isProfileLoading: true
      }
    }
    case ActionType.GET_EDUCATIONAL_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        isProfileLoading: false,
        educationalProfileList: action.response
      }
    }
    case ActionType.GET_EDUCATIONAL_PROFILE_LIST_FAILURE: {
      return {
        ...state,
        isProfileLoading: false,
        getEducationalProfileListFailure: action.response
      }
    }

    // Reset reducer
    case ActionType.RESET_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
