import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { unregister } from './serviceWorker'

import i18n from './i18n/index'
import store from './store/index'
import AppRoutes from './routes/index'
import theme from './styles/theme'

import './styles/index.scss'

const target = document.querySelector('#root')

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <AppRoutes dir='rtl' />
      </MuiThemeProvider>
    </Provider>
  </I18nextProvider>, target
)

// unregister service worker
unregister()
