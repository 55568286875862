import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dialog, Typography, Button, Divider, TextField } from '@material-ui/core'
import { RISING_STAR } from '../../constants/image-reference'
import {
  getOrgList, getPositionList, getSectorList, getProfileTagList, getGenderList, getTierList,
  getEducationLevelList
} from '../common.action-creator'
import { debounce } from 'lodash'
import { deepCopy, assignObjectValues, clearObjectValues } from '../../utils/object.utils'

import SelectInput from '../select-input/select-input'
import SearchSelectInput from '../search-select-input/search-select-input'
import RangeSlider from '../range-slider/range-slider'
import CheckBox from '../checkbox/checkbox.component'
import RadioButton from '../radio-button/radio-button.component'

import './advanced-filters.component.scss'

function AdvancedFilters(props) {
  const [values, setValues] = useState({
    name: { type: 'text', value: '', isRequired: false },
    fromManhoum: { type: 'boolean', value: false, isRequired: false },
    organization: { type: 'text', value: '', isRequired: false },
    sector: { type: 'text', value: '', isRequired: false },
    position: { type: 'text', value: '', isRequired: false },
    gender: { type: 'text', value: '', isRequired: false },
    ageMin: { type: 'number', value: '', isRequired: false },
    ageMax: { type: 'number', value: '', isRequired: false },
    isRisingStar: { type: 'boolean', value: false, isRequired: false },
    tier: { type: 'text', value: '', isRequired: false },
    tags: { type: 'text', value: '', isRequired: false },
    educationLevel: { type: 'text', value: '', isRequired: false }
  })

  const delayedOrgCallback = debounce(value => fetchOrgList(value), 500)
  const delayedPositionCallback = debounce((id, value) => fetchPositionList(id, value), 500)

  const {
    t, handleClose, getOrgList, getPositionList, getSectorList, getProfileTagList, getGenderList,
    fetchFilteredProfileListCallback, clearFilterCallback, getTierList, getEducationLevelList
  } = props
  const {
    orgList, positionList, sectorList, profileTagList, genderList, filterParams, educationLevelList, tierList
  } = props
  const showClearFilterButton = typeof filterParams === 'object' && Object.keys(filterParams).length

  const {
    name,
    fromManhoum,
    organization,
    position,
    sector,
    gender,
    isRisingStar,
    tier,
    educationLevel,
    tags,
    ageMin,
    ageMax
  } = values

  useEffect(() => {
    init()
    if (filterParams) {
      populateFilterValues(filterParams)
    }
  }, [])

  const init = () => {
    fetchOrgList()
    fetchSectors()
    fetchGenderList()
    fetchProfileTags()
    fetchTierList()
    fetchEducationLevelList()
  }

  const fetchSectors = () => {
    getSectorList()
  }

  const populateFilterValues = (filters) => {
    const fieldsCopy = assignObjectValues(values, filters)
    setValues(fieldsCopy)
  }

  const fetchOrgList = (searchQuery) => {
    getOrgList({
      limit: 100,
      offset: 0,
      search: searchQuery
    })
  }

  const fetchGenderList = () => {
    getGenderList()
  }

  const fetchTierList = () => {
    getTierList()
  }

  const fetchEducationLevelList = () => {
    getEducationLevelList()
  }

  const fetchProfileTags = () => {
    getProfileTagList()
  }

  const fetchPositionList = (org, searchQuery) => {
    getPositionList({
      orgId: typeof org === 'object' ? org.id : org,
      limit: 100,
      offset: 0,
      search: searchQuery
    })
  }

  const handleSearch = (searchQuery, dropdownType) => {
    if (dropdownType === 'organization') {
      delayedOrgCallback(searchQuery)
    } else if (dropdownType === 'position') {
      const { organization } = values
      delayedPositionCallback(organization.value, searchQuery)
    }
  }

  const handleChange = (value, key) => {
    const fieldsCopy = deepCopy(values)
    if (key === 'ageMin') {
      fieldsCopy.ageMin.value = value[0]
      fieldsCopy.ageMax.value = value[1]
    } else {
      fieldsCopy[key].value = value
    }
    setValues(fieldsCopy)
  }

  const removeHandler = (key) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy[key].value = ''
    setValues(fieldsCopy)
  }

  const handleOptionChange = (value, key) => {
    if (key === 'organization') {
      fetchPositionList(value.value)
    }
    const fieldsCopy = deepCopy(values)
    fieldsCopy[key].value = value
    setValues(fieldsCopy)
  }

  const handleClick = () => {
    fetchFilteredProfileListCallback(constructRequestBody(values))
  }

  const clearFilters = () => {
    clearFilterCallback()
    const fieldsCopy = clearObjectValues(values)
    setValues(fieldsCopy)
  }

  const constructRequestBody = (fields = {}) => {
    const params = {}
    for (const key in fields) {
      if (typeof fields[key].value === 'object') {
        const objectVal = fields[key].value
        params[key] = objectVal ? objectVal.value : objectVal
      } else if (typeof fields[key].value === 'boolean' && fields[key].value) {
        params[key] = fields[key].value
      } else if (typeof fields[key].value === 'number') {
        params[key] = fields[key].value
      } else {
        if (fields[key].value.length > 0) {
          params[key] = fields[key].value
        }
      }
    }
    return params
  }

  const getOrgOptions = () => {
    if (orgList) {
      if (orgList.length) {
        return orgList.map(org => {
          return { id: org.id, label: org.nameAr, value: org.id }
        })
      }
      return []
    }
  }

  const getPositionOptions = () => {
    if (positionList) {
      if (positionList.length) {
        return positionList.map(position => {
          return { id: position.id, label: position.positionAr, value: position.id }
        })
      }
      return []
    }
  }

  const sectorsOption = sectorList && sectorList.map(sector => {
    return { id: sector.id, label: sector.nameAr, value: sector.id }
  })

  const profileTagOptions = profileTagList && profileTagList.map(tagOption => {
    return { label: tagOption, value: tagOption }
  })

  const tierOptions = tierList && tierList.map(tier => {
    return { id: tier.id, label: tier.nameAr, value: tier.id }
  })

  const eduLevelOptions = educationLevelList && educationLevelList.map(eduLevel => {
    return { id: eduLevel.id, label: eduLevel.nameEn, value: eduLevel.id }
  })

  const genderOptions = genderList.map(gender => {
    return { id: gender.id, label: gender.nameAr, value: gender.id }
  })
  if (genderList && genderList.length) {
    genderOptions.push({ label: 'الـكـل', value: 'الكل' })
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      classes={{ paper: 'advanced-filters-dialog' }}
      BackdropProps={{
        classes: {
          root: 'advanced-filters-overlay'
        }
      }}
    >
      <div className='filters-container'>
        <div className='row'>
          <div className='radio-filter-wrapper' style={{ width: '200px' }}>
            <div className='icon-label'>
              <Typography className='select-wrapper-label'>بيانات من منصة من هم</Typography>
            </div>
            <CheckBox
              value={fromManhoum.value}
              handleChange={(value) => handleChange(value, 'fromManhoum')}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '70%' }}>
            <Typography className='filter-label'>{t('advancedSearch.candidateName')}</Typography>
            <TextField
              classes={{ root: 'name-input' }}
              placeholder={t('common.writeHere')}
              margin='normal'
              variant='outlined'
              fullWidth
              value={name.value}
              type='text'
              onChange={(event) => handleChange(event.target.value, 'name')}
            />
          </div>
        </div>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='select-wrapper-label'>منـصـب</Typography>
            <SearchSelectInput
              type='blueBorderDropdown'
              value={position.value}
              disabled={!organization.value}
              placeholder='منـصـب'
              options={getPositionOptions()}
              handleSearch={(searchQuery) => handleSearch(searchQuery, 'position')}
              handleChange={(option) => handleOptionChange(option, 'position')}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper'>
            <Typography className='filter-label'>قـطـاع</Typography>
            <SelectInput
              id='sector'
              type='blueBorderDropdown'
              placeholder='قـطـاع'
              value={sector.value}
              handleChange={(option) => handleOptionChange(option, 'sector')}
              options={sectorsOption}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper'>
            <Typography className='select-wrapper-label'>الهيئة</Typography>
            <SearchSelectInput
              type='blueBorderDropdown'
              value={organization.value}
              disabled={false}
              options={getOrgOptions()}
              placeholder='الهيئة'
              handleSearch={(searchQuery) => handleSearch(searchQuery, 'organization')}
              handleChange={(option) => handleOptionChange(option, 'organization')}
            />
          </div>
        </div>

        <div className='row'>
          <div className='radio-filter-wrapper'>
            <div className='icon-label'>
              <Typography className='select-wrapper-label'>نجم صاعد</Typography>
              <img src={RISING_STAR} alt='Rising star' className='rising-star' />
            </div>
            <CheckBox
              value={isRisingStar.value}
              handleChange={(value) => handleChange(value, 'isRisingStar')}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '30%', justifyContent: 'space-between' }}>
            {genderOptions.map((option, index) => (
              <RadioButton
                key={index}
                value={option.value}
                label={option.label}
                activeButtonValue={gender.value}
                handleChange={(value) => handleChange(value, 'gender')}
                removeCallback={() => removeHandler('gender')}
              />
            ))}
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '37%' }}>
            <Typography className='slider-label'>السن</Typography>
            <RangeSlider
              type='default'
              color='#001c47'
              step={1}
              minValue={ageMin.value}
              maxValue={ageMax.value}
              handleChangeCallback={(value) => handleChange(value, 'ageMin')}
            />
          </div>
        </div>

        <div className='row'>
          <div className='filter-wrapper' style={{ width: '47%' }}>
            <Typography className='select-wrapper-label'>مستوى</Typography>
            <SelectInput
              id='tier'
              type='blueBorderDropdown'
              placeholder='مستوى'
              value={tier.value}
              handleChange={(option) => handleOptionChange(option, 'tier')}
              options={tierOptions}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '47%' }}>
            <Typography className='select-wrapper-label'>تخصص</Typography>
            <SelectInput
              id='tags'
              type='blueBorderDropdown'
              placeholder='تخصص'
              value={tags.value}
              handleChange={(option) => handleOptionChange(option, 'tags')}
              options={profileTagOptions}
            />
          </div>
        </div>

        <div className='row'>
          <div className='filter-wrapper' style={{ width: '100%' }}>
            <Typography className='select-wrapper-label'>المستوى التعليمي</Typography>
            <SelectInput
              id='educationLevel'
              type='blueBorderDropdown'
              placeholder='المستوى التعليمي'
              value={educationLevel.value}
              handleChange={(option) => handleOptionChange(option, 'educationLevel')}
              options={eduLevelOptions}
            />
          </div>
        </div>
        <div className='button-wrapper'>
          <Button
            variant='contained'
            className='search-button'
            onClick={handleClick}
          >تطبيق
          </Button>
          {showClearFilterButton ? (
            <Button
              variant='outlined'
              className='clear-button'
              onClick={clearFilters}
            >{t('common.reset')}
            </Button>
          ) : null}
        </div>
      </div>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    orgList: state.common.orgList,
    genderList: state.common.genderList,
    positionList: state.common.positionList,
    sectorList: state.common.sectorList,
    profileTagList: state.common.profileTagList,
    educationLevelList: state.common.educationLevelList,
    tierList: state.common.tierList
  }
}

export default (connect(mapStateToProps,
  {
    getOrgList,
    getPositionList,
    getSectorList,
    getProfileTagList,
    getGenderList,
    getTierList,
    getEducationLevelList
  }
)(AdvancedFilters))
