import { zipObject } from 'lodash'

const ACTION_TYPES = [
  'GET_TOKEN',
  'GET_USER',
  'SAVE_TOKEN',
  'SAVE_USER_DETAILS',
  'REMOVE_TOKEN',
  'REMOVE_USER_DETAILS',
  'LOADING',
  'ERROR',

  // login
  'LOGIN',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'CLEAR_LOGIN_VAR',

  // logout
  'LOGOUT',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',

  // refresh token
  'REFRESH_TOKEN',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAILURE',

  // reset reducer
  'RESET_REDUCER',

  // reset reducer
  'RESET_AUTH_REDUCER'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
