import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { LEFT_ARROW, BRAND_LOGO } from '../../../constants/image-reference'
import {
  setPreviousPath, clearGetOrgDetailsVar, getOrgDetails, clearOrgProfileList, getOrgProfileList
} from '../../../common/common.action-creator'

import Loader from '../../../common/loader/loader.component'
import MemberCard from '../../../common/member-card/member-card.component'

import './entity-member-list.component.scss'

function EntityMemberList (props) {
  const { orgDetails, orgProfileList, orgProfilesLoading } = props
  const {
    t, match, history, clearGetOrgDetailsVar, clearOrgProfileList,
    getOrgDetails, setPreviousPath, getOrgProfileList
  } = props

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    return () => {
      clearGetOrgDetailsVar()
      clearOrgProfileList()
    }
  }, [])

  const init = () => {
    fetchOrgDetails()
    fetchOrgProfileList()
  }

  const fetchOrgDetails = () => {
    const { orgId } = match.params
    getOrgDetails({
      orgId: orgId
    })
  }

  const fetchOrgProfileList = () => {
    const { orgId } = match.params
    getOrgProfileList({
      orgId,
      limit: 100,
      offset: 0
    })
  }

  const getProfilePosition = (profile) => {
    const { leadership } = profile
    const { positions } = leadership
    const position = positions.length ? positions[0].titleAr : ''
    return position
  }

  const getTierDetails = (profile) => {
    if (profile.tier) {
      return profile.tier.nameEn
    }
    return undefined
  }

  const handleBackButtonClick = () => {
    history.goBack()
  }

  const handleViewProfile = (profileId) => {
    setPreviousPath()
    props.history.push(`/profile-details/${profileId}`)
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>{t('recommendation.noRecommendationsFound')}</Typography>
      </div>
    )
  }

  const renderBoardMembers = () => {
    if (orgProfileList && orgProfileList.length) {
      return (
        <div className='entity-members-container'>
          {orgProfileList.map((orgProfile, index) => (
            <MemberCard
              {...props}
              key={index}
              profileDetails={orgProfile}
              profileImage={orgProfile.profileImage}
              tier={getTierDetails(orgProfile)}
              position={getProfilePosition(orgProfile)}
              handleViewProfileCallback={(profileId) => handleViewProfile(profileId)}
            />
          ))}
        </div>
      )
    }
    return renderEmptyState()
  }

  return (
    <div className='entity-member-list-container'>
      <div className='header-container'>
        <div className='header-wrapper'>
          <img src={BRAND_LOGO} alt='Brand logo' className='brand-logo' />
          <div className='heading-wrapper'>
            <Typography className='small-heading'>{t('common.authorityFile')}</Typography>
            <Typography className='headline'>
              {t('header.entityMembersHeadline', { entityName: orgDetails ? `${orgDetails.nameAr}` : '' })}
            </Typography>
          </div>
        </div>
        <img src={LEFT_ARROW} alt='Back' className='back-button' onClick={handleBackButtonClick} />
      </div>
      {orgProfilesLoading ? <Loader {...props} color='blue' /> : renderBoardMembers()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    // common
    orgDetails: state.common.orgDetails,
    orgProfilesLoading: state.common.orgProfilesLoading,
    orgProfileList: state.common.orgProfileList
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    setPreviousPath,
    getOrgDetails,
    getOrgProfileList,
    clearGetOrgDetailsVar,
    clearOrgProfileList
  })(EntityMemberList)
)
