import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { SEARCH_PROFILE, BLUE_CLOSE } from '../../constants/image-reference'
import { getProfileList, loadMoreProfileList, clearProfileList, setFilterState } from './advanced-search.action-creator'
import { setPreviousPath } from '../../common/common.action-creator'
import { deepCopy } from '../../utils/object.utils'

import Loader from '../../common/loader/loader.component'
import InfiniteScroll from 'react-infinite-scroll-component'
import SearchInput from '../../common/search-input/search-input'
import ProfileCard from '../../common/profile-card/profile-card.component'
import AdvancedFilters from '../../common/advanced-filters/advanced-filters.component'

import './advanced-search.component.scss'

function AdvancedSearch (props) {
  const [showAdvancedFiltersDialog, setAdvancedFiltersDialogOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filterParams, setFilterParams] = useState('')
  const [searchTags, setSearchTags] = useState([])
  const [throttleTimer, setThrottleTimer] = useState()
  const { isLoading, profileList, profileCount } = props
  const {
    t, getProfileList, clearProfileList, loadMoreProfileList, setPreviousPath,
    setFilterState, previousFilters, previousSearchQuery
  } = props

  useEffect(() => {
    if (previousFilters || previousSearchQuery) {
      setSearchTags(previousSearchQuery)
      fetchFilteredProfileList(previousFilters, previousSearchQuery)
    }
  }, [])

  useEffect(() => {
    if (throttleTimer) {
      clearTimeout(throttleTimer)
    }
    if (searchTags.length) {
      setThrottleTimer(setTimeout(() => {
        fetchProfileList()
        setThrottleTimer(undefined)
      }, 400))
    }
  }, [searchTags])

  useEffect(() => {
    return () => {
      clearProfileList()
    }
  }, [])

  const showAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(true)
  }

  const hideAdvancedFilters = () => {
    setAdvancedFiltersDialogOpen(false)
  }

  const handleSearch = (value) => {
    setSearchQuery(value)
  }

  const handleSearchTags = (value) => {
    if (value.length) {
      const searchTagsCopy = deepCopy(searchTags)
      searchTagsCopy.push(value)
      setSearchTags(searchTagsCopy)
    }
    setSearchQuery('')
  }

  const removeTag = (index) => {
    const searchTagsCopy = deepCopy(searchTags)
    searchTagsCopy.splice(index, 1)
    setSearchTags(searchTagsCopy)
    if (!searchTagsCopy.length) {
      fetchProfileList(true)
    }
  }

  const getSearchQuery = (previousQuery) => {
    let search = ''
    if (previousQuery && previousQuery.length) {
      search = previousQuery.join(' ')
    } else if (searchTags && searchTags.length) {
      search = searchTags.join(' ')
    }
    if (searchQuery) {
      search = `${searchQuery} ${search}`
    }
    return search
  }

  const fetchProfileList = (clear) => {
    setFilterState({ ...filterParams }, searchTags)
    getProfileList({
      isPublished: true,
      offset: 0,
      limit: 20,
      search: clear ? undefined : getSearchQuery(),
      ...filterParams
    })
  }

  const fetchFilteredProfileList = (filters, previousQuery) => {
    setFilterState(filters, searchTags)
    setFilterParams(filters)
    hideAdvancedFilters()
    getProfileList({
      isPublished: true,
      offset: 0,
      limit: 20,
      search: getSearchQuery(previousQuery),
      ...filters
    })
  }

  const showMoreProfiles = () => {
    setFilterState({ ...filterParams }, searchTags)
    loadMoreProfileList({
      isPublished: true,
      search: getSearchQuery(),
      limit: 20,
      offset: profileList && profileList.length ? profileList.length : 0,
      ...filterParams
    })
  }

  const handleNavigateToProfileDetails = (profileId) => {
    setPreviousPath(props.match)
    props.history.push(`/profile-details/${profileId}`)
  }

  const renderAdvancedFilter = () => {
    return (
      <AdvancedFilters
        {...props}
        fetchFilteredProfileListCallback={(filters) => fetchFilteredProfileList(filters)}
        handleClose={hideAdvancedFilters}
        filterParams={filterParams}
        clearFilterCallback={() => setFilterParams('')}
      />
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <div className='icon-wrapper'>
          <img src={SEARCH_PROFILE} alt='logo' className='search-profile-icon' />
        </div>
        <Typography className='empty-state-text'>{t('advancedSearch.emptyStateMessage')}</Typography>
      </div>
    )
  }

  const renderProfileList = () => {
    const profileListLen = profileList.length
    return (
      <InfiniteScroll
        scrollThreshold={0.75}
        next={showMoreProfiles}
        dataLength={profileListLen}
        hasMore={profileCount > profileListLen}
        loader={(
          <div className='load-more-button-wrapper'>
            <Loader color='blue' />
          </div>
        )}
        className='profile-list-container'
        height='calc(100vh - 140px - 80px)'
        style={{ paddingTop: searchTags.length ? '80px' : '120px' }}
      >
        {profileList.map((profile) => (
          <ProfileCard
            {...props}
            key={profile.id}
            profile={profile}
            navigateToProfileDetailsCallback={(profileId) => handleNavigateToProfileDetails(profileId)}
          />))}
      </InfiniteScroll>
    )
  }

  const renderSearchTags = () => {
    return (
      <div className='search-tags-container'>
        <div className='search-tag-view'>
          {searchTags.map((tag, index) => (
            <div className='search-tag' key={index}>
              <img src={BLUE_CLOSE} alt='close' className='close-icon' onClick={() => removeTag(index)} />
              <Typography className='tag-text'>{tag}</Typography>
            </div>))}
        </div>
      </div>
    )
  }

  return (
    <div className='advanced-search-container'>
      <div className='search-input-wrapper'>
        <SearchInput
          value={searchQuery}
          showFilters
          filterButtonCallback={showAdvancedFilters}
          handleChange={handleSearch}
          handleEnter={handleSearchTags}
        />
      </div>
      {searchTags && searchTags.length ? renderSearchTags() : null}
      {isLoading ? <Loader color='blue' /> : (profileList && profileList.length) ? renderProfileList() : renderEmptyState()}
      {showAdvancedFiltersDialog && renderAdvancedFilter()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    isLoading: state.advancedSearch.isLoading,
    profileList: state.advancedSearch.profileList,
    recomConfig: state.recommendation.recomConfig,
    profileCount: state.advancedSearch.profileCount,
    previousFilters: state.advancedSearch.previousFilters,
    previousSearchQuery: state.advancedSearch.previousSearchQuery
  }
}

export default withTranslation(['translations'], translateOptions)(
  connect(mapStateToProps, {
    getProfileList,
    setFilterState,
    clearProfileList,
    loadMoreProfileList,
    setPreviousPath
  })(AdvancedSearch))
