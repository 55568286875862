
import React from 'react'
import classNames from 'classnames'

import './recommendation-radio-button.component.scss'

function RecRadioButton (props) {
  const { label, value, activeButtonValue, handleChange } = props

  const handleOnClick = () => {
    if (handleChange) {
      handleChange(value)
    }
  }

  return (
    <div
      key={value}
      className={classNames(['rec-radio-button-container', {
        'rec-radio-btn': !(activeButtonValue === value),
        'rec-active-btn': activeButtonValue === value
      }])}
      onClick={handleOnClick}
    >
      <div className='radio-button-text'>{label}</div>
    </div>
  )
}

export default RecRadioButton
