import ActionTypes from './profile.action-type'
import { apiAction } from '../../api/api.action-creator'

// Get profile
export const getProfile = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getProfile',
    isSecureCall: true,
    onSuccess: getProfileSuccess,
    onFailure: getProfileFailure,
    label: ActionTypes.GET_PROFILE,
    queryParams
  })
}
export const getProfileSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_PROFILE_SUCCESS,
      response: response.data
    })
  }
}
export const getProfileFailure = (response) => {
  return {
    type: ActionTypes.GET_PROFILE_FAILURE,
    response
  }
}

// Get personal network profile list
export const getPersonalProfileList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getPersonalProfileList',
    isSecureCall: true,
    onSuccess: getPersonalProfileListSuccess,
    onFailure: getPersonalProfileListFailure,
    label: ActionTypes.GET_PERSONAL_PROFILE_LIST,
    queryParams
  })
}
export const getPersonalProfileListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_PERSONAL_PROFILE_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getPersonalProfileListFailure = (response) => {
  return {
    type: ActionTypes.GET_PERSONAL_PROFILE_LIST_FAILURE,
    response
  }
}

// Get professional profile list
export const getProfessionalProfileList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getProfessionalProfileList',
    isSecureCall: true,
    onSuccess: getProfessionalProfileListSuccess,
    onFailure: getProfessionalProfileListFailure,
    label: ActionTypes.GET_PROFESSIONAL_PROFILE_LIST,
    queryParams
  })
}
export const getProfessionalProfileListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_PROFESSIONAL_PROFILE_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getProfessionalProfileListFailure = (response) => {
  return {
    type: ActionTypes.GET_PROFESSIONAL_PROFILE_LIST_FAILURE,
    response
  }
}

// Get educational profile list
export const getEducationalProfileList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getEducationalProfileList',
    isSecureCall: true,
    onSuccess: getEducationalProfileListSuccess,
    onFailure: getEducationalProfileListFailure,
    label: ActionTypes.GET_EDUCATIONAL_PROFILE_LIST,
    queryParams
  })
}
export const getEducationalProfileListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_EDUCATIONAL_PROFILE_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getEducationalProfileListFailure = (response) => {
  return {
    type: ActionTypes.GET_EDUCATIONAL_PROFILE_LIST_FAILURE,
    response
  }
}
