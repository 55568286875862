import { zipObject } from 'lodash'

const ACTION_TYPES = [
  // Get insight list
  'GET_INSIGHT_LIST',
  'GET_INSIGHT_LIST_SUCCESS',
  'GET_INSIGHT_LIST_FAILURE',

  // load more insights
  'LOAD_MORE_INSIGHTS',
  'LOAD_MORE_INSIGHTS_SUCCESS',
  'LOAD_MORE_INSIGHTS_FAILURE',

  // Get insight details
  'GET_INSIGHT_DETAILS',
  'GET_INSIGHT_DETAILS_SUCCESS',
  'GET_INSIGHT_DETAILS_FAILURE',

  // Add insight
  'ADD_INSIGHT',
  'ADD_INSIGHT_SUCCESS',
  'ADD_INSIGHT_FAILURE',
  'CLEAR_ADD_INSIGHT_VAR',

  // edit insight
  'EDIT_INSIGHT',
  'EDIT_INSIGHT_SUCCESS',
  'EDIT_INSIGHT_FAILURE',

  // delete insight
  'DELETE_INSIGHT',
  'DELETE_INSIGHT_SUCCESS',
  'DELETE_INSIGHT_FAILURE',

  // get insight entity types
  'GET_INSIGHT_ENTITY_TYPES',
  'GET_INSIGHT_ENTITY_TYPES_SUCCESS',
  'GET_INSIGHT_ENTITY_TYPES_FAILURE',

  // get entity profile count
  'GET_ENTITY_PROFILE_COUNT',
  'GET_ENTITY_PROFILE_COUNT_SUCCESS',
  'GET_ENTITY_PROFILE_COUNT_FAILURE',
  'CLEAR_ENTITY_PROFILE_COUNT',

  // get tiers profile count
  'GET_TIERS_PROFILE_COUNT',
  'GET_TIERS_PROFILE_COUNT_SUCCESS',
  'GET_TIERS_PROFILE_COUNT_FAILURE',
  'CLEAR_TIERS_PROFILE_COUNT',

  // send insight
  'SEND_INSIGHT',
  'SEND_INSIGHT_SUCCESS',
  'SEND_INSIGHT_FAILURE',
  'CLEAR_SEND_INSIGHT_VAR',

  // get leader list
  'GET_LEADER_LIST',
  'GET_LEADER_LIST_SUCCESS',
  'GET_LEADER_LIST_FAILURE',
  'CLEAR_LEADER_LIST',

  // set/unset selected insight
  'SET_SELECTED_INSIGHT',
  'CLEAR_SELECTED_INSIGHT',

  // reset reducer
  'RESET_REDUCER',
  'GET_SECTORS',
  'GET_SECTORS_SUCCESS',
  'GET_SECTORS_FAILURE'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
