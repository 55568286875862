import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { getStateFromLocalStorage, setStateToLocalStorage } from '../helpers/auth'
import { createBrowserHistory } from 'history'

import thunk from 'redux-thunk'
import rootReducer from '../reducers/root'
import apiMiddleware from '../api/api.middleware'

export const history = createBrowserHistory()

const enhancers = []
const middleware = [
  thunk,
  apiMiddleware,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const persistedState = getStateFromLocalStorage()

const store = createStore(
  rootReducer,
  persistedState,
  composedEnhancers
)

store.subscribe(() => {
  if (store.getState().auth.userSession != null) {
    setStateToLocalStorage({
      auth: {
        userSession: store.getState().auth.userSession
      }
    }, store.dispatch)
  }
})

export default store
