import ActionType from './common.action-type'

const initialState = {
  orgTypes: [],
  genderList: [],
  previousPath: '',
  orgListBySector: [],
  tiers: []
}

export default (state = initialState, action) => {

  switch (action.type) {
    // add org
    case ActionType.ADD_ORG: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.ADD_ORG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orgList: [...state.orgList, action.response],
        activeOrg: action.response
      }
    }
    case ActionType.ADD_ORG_FAILURE: {
      return {
        ...state,
        isLoading: false,
        addOrgError: action.response
      }
    }
    case ActionType.CLEAR_ADD_ORG_VAR: {
      return {
        ...state,
        isLoading: false,
        activeOrg: undefined,
        addOrgError: undefined
      }
    }

    // Get org details
    case ActionType.GET_ORG_DETAILS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_ORG_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orgDetails: action.response
      }
    }
    case ActionType.GET_ORG_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getOrgDetailsFailure: action.response
      }
    }
    case ActionType.CLEAR_GET_ORG_DETAILS_VAR: {
      return {
        ...state,
        orgDetails: undefined
      }
    }

    // Get org profile list
    case ActionType.GET_ORG_PROFILE_LIST: {
      return {
        ...state,
        orgProfilesLoading: true
      }
    }
    case ActionType.GET_ORG_PROFILE_LIST_SUCCESS: {
      return {
        ...state,
        orgProfilesLoading: false,
        orgProfileList: action.response
      }
    }
    case ActionType.GET_ORG_PROFILE_LIST_FAILURE: {
      return {
        ...state,
        orgProfilesLoading: false,
        getOrgProfileListFailure: action.response
      }
    }
    case ActionType.CLEAR_ORG_PROFILE_LIST: {
      return {
        ...state,
        orgProfileList: undefined
      }
    }

    // Get org list
    case ActionType.GET_ORG_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_ORG_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orgList: action.response.results
      }
    }
    case ActionType.GET_ORG_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getOrgListFailure: action.response
      }
    }

    // Get org list by sector id
    case ActionType.GET_ORG_LIST_BY_SECTOR_ID: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_ORG_LIST_BY_SECTOR_ID_SUCCESS: {
      console.log('test...', action.response);
      return {
        ...state,
        isLoading: false,
        orgListBySector: action.response
      }
    }
    case ActionType.GET_ORG_LIST_BY_SECTOR_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getOrgListBySectorFailure: action.response
      }
    }
    //Get tires list by org
    case ActionType.GET_TIERS_BY_ORG: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_TIERS_BY_ORG_SUCCESS: {
      console.log('test...', action.response);
      return {
        ...state,
        isLoading: false,
        tiers: action.response
      }
    }
    case ActionType.GET_TIERS_BY_ORG_FAILURE: {
      return {
        ...state,
        isLoading: false,
        tiersByOrgFailure: action.response
      }
    }
    //Get Profiles
    case ActionType.GET_PROFILES: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_PROFILES_SUCCESS: {
      console.log('test...', action.response);
      return {
        ...state,
        isLoading: false,
        profiles: action.response
      }
    }
    case ActionType.GET_PROFILES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getorgProfilesFailure: action.response
      }
    }
    // add position
    case ActionType.ADD_POSITION: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.ADD_POSITION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        positionList: [...state.positionList, action.response],
        activePosition: action.response
      }
    }
    case ActionType.ADD_POSITION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        addPositionError: action.response
      }
    }
    case ActionType.CLEAR_ADD_POSITION_VAR: {
      return {
        ...state,
        isLoading: false,
        activePosition: undefined,
        addPositionError: undefined
      }
    }

    // Get position list
    case ActionType.GET_POSITION_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_POSITION_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        positionList: action.response.results
      }
    }
    case ActionType.GET_POSITION_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getPositionListFailure: action.response
      }
    }

    // Get sector list
    case ActionType.GET_SECTOR_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_SECTOR_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sectorList: action.response
      }
    }
    case ActionType.GET_SECTOR_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getSectorListFailure: action.response
      }
    }

    // get users list
    case ActionType.GET_USER_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userList: action.response.results
      }
    }
    case ActionType.GET_USER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getUserListError: action.response
      }
    }

    // Get board list
    case ActionType.GET_BOARD_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_BOARD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        boardList: action.response
      }
    }
    case ActionType.GET_BOARD_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getBoardListError: action.response
      }
    }

    // Get board member list
    case ActionType.GET_BOARD_MEMBER_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_BOARD_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        boardMemberList: action.response
      }
    }
    case ActionType.GET_BOARD_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        boardMemberListError: action.response
      }
    }

    // Get org types
    case ActionType.GET_ORG_TYPES: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_ORG_TYPES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orgTypes: action.response
      }
    }
    case ActionType.GET_ORG_TYPES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getOrgTypesError: action.response
      }
    }

    // get Tiers list
    case ActionType.GET_TIER_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_TIER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tierList: action.response
      }
    }
    case ActionType.GET_TIER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getTierListError: action.response
      }
    }

    // get education level list
    case ActionType.GET_EDUCATION_LEVEL_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_EDUCATION_LEVEL_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        educationLevelList: action.response
      }
    }
    case ActionType.GET_EDUCATION_LEVEL_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getEducationLevelListError: action.response
      }
    }

    // get gender list
    case ActionType.GET_GENDER_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_GENDER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        genderList: action.response
      }
    }
    case ActionType.GET_GENDER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getGenderListError: action.response
      }
    }

    // get profile tag list
    case ActionType.GET_PROFILE_TAG_LIST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_PROFILE_TAG_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        profileTagList: action.response
      }
    }
    case ActionType.GET_PROFILE_TAG_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getProfileTagListError: action.response
      }
    }

    // set previous path
    case ActionType.SET_PREVIOUS_PATH: {
      return {
        ...state,
        previousPath: action.response
      }
    }

    // Reset reducer
    case ActionType.RESET_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
