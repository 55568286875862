import React, { useState, useEffect } from 'react'
import { Dialog, Typography, Button, TextField, InputAdornment } from '@material-ui/core'
import { WHITE_CLOSE, BLUE_CLOSE, CANCEL_BTN, ADD_BLUE } from '../../constants/image-reference'
import { deepCopy } from '../../utils/object.utils'
import { handleFormValidation } from '../../utils/validation.utils'

import SelectInput from '../select-input/select-input'
import RadioButton from '../radio-button/radio-button.component'

import './add-entity.component.scss'

export default function AddEntityDialog (props) {
  const [values, setValues] = useState({
    nameAr: { type: 'text', value: '', isRequired: true },
    sector: { type: 'text', value: '', isRequired: true },
    type: { type: 'text', value: '', isRequired: true },
    tagsAr: { type: 'text', value: [], isRequired: true }
  })
  const [errors, setErrors] = useState({})
  const [tagValue, setTagValue] = useState('')
  const [showAddTagInput, setAddTagInput] = useState(true)
  const { nameAr, sector, type, tagsAr } = values

  const {
    t, handleClose, addEntityCallback, fetchSectorListCallback, clearAddEntityVarCallback, fetchOrgTypesCallback
  } = props
  const { sectorList, addOrgError, orgTypes } = props

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    return () => {
      clearAddEntityVarCallback()
    }
  }, [])

  const init = () => {
    fetchSectorListCallback()
    fetchOrgTypesCallback()
  }

  const handleOptionChange = (value, key) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy[key].value = value
    setValues(fieldsCopy)
  }

  const handleNameChange = (event) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy.nameAr.value = event.target.value
    setValues(fieldsCopy)
  }

  const handleTagChange = (event) => {
    setTagValue(event.target.value)
  }

  const handleAddTag = () => {
    setAddTagInput(true)
  }

  const handleCloseInput = () => {
    setAddTagInput(false)
  }

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      const tagName = event.target.value
      const fieldsCopy = deepCopy(values)
      if (tagName.length && fieldsCopy.tagsAr.value.indexOf(tagName) === -1) {
        fieldsCopy.tagsAr.value.push(tagName)
        setAddTagInput(false)
        setTagValue('')
        setValues(fieldsCopy)
      }
    }
  }

  const handleBlur = (event) => {
    const tagName = event.target.value
    const fieldsCopy = deepCopy(values)
    if (tagName.length && fieldsCopy.tagsAr.value.indexOf(tagName) === -1) {
      fieldsCopy.tagsAr.value.push(tagName)
      setAddTagInput(false)
      setTagValue('')
      setValues(fieldsCopy)
    }
  }

  const removeTag = (index) => {
    const fieldsCopy = deepCopy(values)
    fieldsCopy.tagsAr.value.splice(index, 1)
    setValues(fieldsCopy)
    if (fieldsCopy.tagsAr.value.length === 0) {
      setAddTagInput(true)
    }
  }

  const handleAddEntity = () => {
    const formValidation = handleFormValidation(values)
    if (!formValidation.isFormValid) {
      const FormErrors = formValidation.errors
      setErrors({ ...errors, ...FormErrors })
    } else {
      setErrors({})
      const fieldValues = constructRequestBody(values)
      fieldValues.tagsAr = fieldValues.tagsAr.join(',')
      addEntityCallback(fieldValues)
    }
  }

  const constructRequestBody = (fields = {}) => {
    const params = {}
    for (const key in fields) {
      if (fields[key].value) {
        params[key] = fields[key].value
      }
    }
    return params
  }

  const sectorsOption = sectorList && sectorList.map(sector => {
    return { id: sector.id, label: sector.nameAr, value: sector.id }
  })

  const orgTypeOptions = orgTypes && orgTypes.map(orgType => {
    return { id: orgType.id, value: orgType.id, label: orgType.nameAr }
  })

  const renderTag = (tag, index) => {
    return (
      <div className='tag' key={index}>
        <img src={CANCEL_BTN} className='remove-tag-icon' alt='Cancel' onClick={() => removeTag(index)} />
        <Typography className='tag-name'>{tag}</Typography>
      </div>
    )
  }

  return (
    <Dialog size='sm' className='add-entity-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>
          {t('recommendation.addEntity')}
        </Typography>
        <img src={WHITE_CLOSE} alt='Close dialog' onClick={handleClose} className='close-button' />
      </div>
      <div className='dialog-content'>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='filter-label'>إسم الجهة</Typography>
            <TextField
              classes={{ root: 'entity-name-input' }}
              placeholder={t('common.writeHere')}
              margin='normal'
              variant='outlined'
              fullWidth
              value={nameAr.value}
              type='text'
              onChange={handleNameChange}
              onKeyPress={handleOnKeyPress}
            />
          </div>
        </div>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='filter-label'>القطاع</Typography>
            <SelectInput
              id='sector'
              type='blueBorderDropdown'
              placeholder='اختار قطاع'
              value={sector.value}
              handleChange={(option) => handleOptionChange(option.value, 'sector')}
              options={sectorsOption}
            />
          </div>
        </div>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='filter-label'>نوع الجهة</Typography>
            <div className='radio-btn-list-container'>
              {orgTypeOptions && orgTypeOptions.map((option) => (
                <RadioButton
                  key={option.id}
                  value={option.value}
                  label={option.label}
                  activeButtonValue={type.value}
                  handleChange={(value) => handleOptionChange(value, 'type')}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='filter-label'>علامة مميزة</Typography>
            <div className='tag-filter-wrapper'>
              {tagsAr.value.length
                ? (
                  <div className='tags-container'>
                    {tagsAr.value.length ? tagsAr.value.map((tag, index) => renderTag(tag, index)) : null}
                    {(!showAddTagInput && tagsAr.value.length) && (
                      <div className='add-new-tag-container' onClick={handleAddTag}>
                        <img src={ADD_BLUE} className='add-icon' alt='Add tag' />
                        <Typography className='add-tag-text'>{t('common.addTag')}</Typography>
                      </div>
                    )}
                  </div>
                ) : null}
              {showAddTagInput && (
                <TextField
                  classes={{ root: 'entity-tag-input' }}
                  placeholder={t('common.typeTagName')}
                  margin='normal'
                  variant='outlined'
                  fullWidth
                  value={tagValue}
                  type='text'
                  onChange={handleTagChange}
                  onKeyPress={handleOnKeyPress}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: tagsAr.value.length ? (
                      <InputAdornment className='hide-input' position='end' onClick={handleCloseInput}>
                        <img src={BLUE_CLOSE} alt='Close' />
                      </InputAdornment>
                    ) : null
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {addOrgError && <Typography className='server-error'>{addOrgError}</Typography>}
        <div className='button-wrapper'>
          <Button
            variant='contained'
            className='add-button'
            onClick={handleAddEntity}
          >تطبيق
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
