const errorMessage = {
  required: 'Please provide valid input',
  email: 'Please provide valid email',
  options: 'Please provide required options'
}

export const regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^[a-z0-9A-Z-]+$/,
  stringBetweenCurlyBraces: /{([^}]+)}/
}

export function handleFormValidation (fields = {}) {
  const result = {}
  const errors = {}
  let isFormValid = true
  for (const key in fields) {
    const field = key
    const value = fields[field].value
    if (fields[field].isRequired) {
      if (Array.isArray(value) && value.length < 0) {
        isFormValid = false
        errors[field] = errorMessage.options
      } else {
        if (value === '' || value === undefined || value === null || value === 'undefined') {
          isFormValid = false
          errors[field] = errorMessage.required
        } else if (fields[field].type === 'email') {
          if (!value.match(regex.email)) {
            isFormValid = false
            errors[field] = errorMessage.email
          }
        }
      }
    }
  }
  result.errors = errors
  result.isFormValid = isFormValid
  return result
}
