import React from 'react'
import { connect } from 'react-redux'

import { BRAND_LOGO, LEFT_ARROW } from '../../constants/image-reference'
import { Typography } from '@material-ui/core'

import classNames from 'classnames'

import './header.component.scss'

function Header (props) {
  const { t, match, previousPath, history } = props
  const { path } = match

  const navigateBack = () => {
    if (previousPath) {
      history.push(previousPath.url)
    } else {
      history.goBack()
    }
  }

  const isProfileDetailsPageView = path === '/profile-details/:profileId'
  return (
    <div
      className={classNames(['header-container', {
        'fixed-header': isProfileDetailsPageView,
        'relative-header': !isProfileDetailsPageView
      }])}
    >
      <div className='content-wrapper'>
        <div className='header-wrapper'>
          <img src={BRAND_LOGO} alt='Brand logo' className='brand-logo' />
          <Typography className='heading'>{t('header.defaultHeadingOne')}</Typography>
        </div>
        {isProfileDetailsPageView && <img src={LEFT_ARROW} alt='Back arrow' className='back-btn' onClick={navigateBack} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // Misc
    previousPath: state.common.previousPath
  }
}

export default (connect(
  mapStateToProps)(Header))
