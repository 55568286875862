import ActionType from './insights.action-type'
import { deepCopy } from '../../utils/object.utils'
import { isEqual } from 'lodash'

const initialState = {
  insightList: [],
  entityTypes: [],
  entityProfileCount: [],
  tiersProfileCount: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    // get insight list
    case ActionType.GET_INSIGHT_LIST: {
      return {
        ...state,
        isInsightListLoading: true,
        isInsightListLoaded: false
      }
    }
    case ActionType.GET_INSIGHT_LIST_SUCCESS: {
      return {
        ...state,
        isInsightListLoading: false,
        isInsightListLoaded: true,
        insightList: action.response
      }
    }
    case ActionType.GET_INSIGHT_LIST_FAILURE: {
      return {
        ...state,
        isInsightListLoading: false,
        isInsightListLoaded: true,
        getInsightListError: action.response
      }
    }

    // load more insights
    case ActionType.LOAD_MORE_INSIGHTS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.LOAD_MORE_INSIGHTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        insightList: [...state.insightList, ...action.response.results]
      }
    }
    case ActionType.LOAD_MORE_INSIGHTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        loadMoreInsightsError: action.response
      }
    }

    // get insight details
    case ActionType.GET_INSIGHT_DETAILS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.GET_INSIGHT_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activeInsight: action.response
      }
    }
    case ActionType.GET_INSIGHT_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getInsightDetailsError: action.response
      }
    }

    // add insight
    case ActionType.ADD_INSIGHT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.ADD_INSIGHT_SUCCESS: {
      const insightListCopy = deepCopy(state.insightList)
      insightListCopy.unshift(action.response)
      return {
        ...state,
        isLoading: false,
        insightList: insightListCopy,
        newInsightAdded: true
      }
    }
    case ActionType.ADD_INSIGHT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        addInsightError: action.response
      }
    }
    case ActionType.CLEAR_ADD_INSIGHT_VAR: {
      return {
        ...state,
        newInsightAdded: undefined,
        editInsightSuccess: undefined
      }
    }

    // edit insight
    case ActionType.EDIT_INSIGHT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.EDIT_INSIGHT_SUCCESS: {
      const insightListCopy = deepCopy(state.insightList)
      const updatedInsightId = action.request.insightId
      for (let insightIndx = 0; insightIndx < insightListCopy.length; insightIndx++) {
        const insight = insightListCopy[insightIndx]
        if (insight.id === Number(updatedInsightId)) {
          insightListCopy.splice(insightIndx, 1, action.response)
          break
        }
      }
      return {
        ...state,
        isLoading: false,
        editInsightSuccess: true,
        insightList: insightListCopy
      }
    }
    case ActionType.EDIT_INSIGHT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        addInsightError: action.response
      }
    }

    // delete insight
    case ActionType.DELETE_INSIGHT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.DELETE_INSIGHT_SUCCESS: {
      const insightListCopy = deepCopy(state.insightList)
      const deleteInsightId = action.request.insightId
      for (let insightIndx = 0; insightIndx < insightListCopy.length; insightIndx++) {
        const insight = insightListCopy[insightIndx]
        if (insight.id === Number(deleteInsightId)) {
          insightListCopy.splice(insightIndx, 1)
          break
        }
      }
      return {
        ...state,
        isLoading: false,
        insightList: insightListCopy
      }
    }
    case ActionType.DELETE_INSIGHT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        deleteInsightError: action.response
      }
    }

    // get insight entity types
    case ActionType.GET_INSIGHT_ENTITY_TYPES: {
      return {
        ...state,
        isLoading: false
      }
    }
    case ActionType.GET_INSIGHT_ENTITY_TYPES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        entityTypes: action.response
      }
    }
    case ActionType.GET_INSIGHT_ENTITY_TYPES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getEntityTypesError: action.response
      }
    }

    // get entity profile count
    case ActionType.GET_ENTITY_PROFILE_COUNT: {
      return {
        ...state,
        isLoading: false,
        filters: action.request
      }
    }
    case ActionType.GET_ENTITY_PROFILE_COUNT_SUCCESS: {
      const previousReqFilters = state.filters
      const currentReqFilters = action.filters
      if (isEqual(previousReqFilters, currentReqFilters)) {
        return {
          ...state,
          isLoading: false,
          entityProfileCount: action.response
        }
      }
      return {
        ...state,
        isLoading: false,
        entityProfileCount: state.entityProfileCount
      }
    }
    case ActionType.GET_ENTITY_PROFILE_COUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getEntityProfileCountError: action.response
      }
    }
    case ActionType.CLEAR_ENTITY_PROFILE_COUNT: {
      return {
        ...state,
        entityProfileCount: [],
        getEntityProfileCountError: undefined
      }
    }

    // get tiers profile count
    case ActionType.GET_TIERS_PROFILE_COUNT: {
      return {
        ...state,
        isLoading: false,
        filters: action.request
      }
    }
    case ActionType.GET_TIERS_PROFILE_COUNT_SUCCESS: {
      const previousReqFilters = state.filters
      const currentReqFilters = action.filters
      if (isEqual(previousReqFilters, currentReqFilters)) {
        return {
          ...state,
          isLoading: false,
          tiersProfileCount: action.response
        }
      }
      return {
        ...state,
        isLoading: false,
        tiersProfileCount: state.tiersProfileCount
      }
    }
    case ActionType.GET_TIERS_PROFILE_COUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        getTiersProfileCountError: action.response
      }
    }
    case ActionType.CLEAR_TIERS_PROFILE_COUNT: {
      return {
        ...state,
        tiersProfileCount: [],
        getTiersProfileCountError: undefined
      }
    }

    // send insight
    case ActionType.SEND_INSIGHT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case ActionType.SEND_INSIGHT_SUCCESS: {
      const sentInsightId = action.request.insightId
      const insightListCopy = deepCopy(state.insightList)
      for (let insightIndx = 0; insightIndx < insightListCopy.length; insightIndx++) {
        const insightDetails = insightListCopy[insightIndx]
        if (insightDetails.id === sentInsightId) {
          insightListCopy[insightIndx].isSent = true
          break
        }
      }
      return {
        ...state,
        isLoading: false,
        sendInsightSuccess: true,
        insightList: insightListCopy
      }
    }
    case ActionType.SEND_INSIGHT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        sendInsightError: action.response
      }
    }
    case ActionType.CLEAR_SEND_INSIGHT_VAR: {
      return {
        ...state,
        isLoading: false,
        sendInsightError: undefined,
        sendInsightSuccess: undefined
      }
    }

    // Get leader list
    case ActionType.GET_LEADER_LIST: {
      return {
        ...state,
        isLeaderListLoading: true
      }
    }
    case ActionType.GET_LEADER_LIST_SUCCESS: {
      return {
        ...state,
        isLeaderListLoading: false,
        profileList: action.response
      }
    }
    case ActionType.GET_LEADER_LIST_FAILURE: {
      return {
        ...state,
        isLeaderListLoading: false,
        getLeaderListFailure: action.response
      }
    }
    case ActionType.CLEAR_LEADER_LIST: {
      return {
        ...state,
        profileList: undefined
      }
    }

    // Set selected insight
    case ActionType.SET_SELECTED_INSIGHT: {
      return {
        ...state,
        selectedInsight: action.selectedInsight
      }
    }

    // Clear selected insight
    case ActionType.CLEAR_SELECTED_INSIGHT: {
      return {
        ...state,
        selectedInsight: undefined
      }
    }

    // Reset reducer
    case ActionType.RESET_REDUCER: {
      state = initialState
      return state
    }

    default:
      return state
  }
}
