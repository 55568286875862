import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { Typography } from '@material-ui/core'
import {
  getRecomDetails, getRecomProfiles, clearGetRecomDetails, clearGetRecomProfiles, setRecommendedProfiles
} from '../../recommendation/recommendation.action-creator'
import { setPreviousPath } from '../../../common/common.action-creator'

import BoardMemberListHeader from './board-member-list-header.component'
import MemberCard from '../../../common/member-card/member-card.component'
import Loader from '../../../common/loader/loader.component'

import './board-member-list.component.scss'

function BoardMemberList (props) {
  const {
    t, match, history, recomLoading, profiles, clearGetRecomDetails, clearGetRecomProfiles,
    getRecomDetails, setPreviousPath, getRecomProfiles, setRecommendedProfiles
  } = props

  useEffect(() => {
    fetchRecomDetails()
    fetchRecomProfiles()
  }, [])

  useEffect(() => {
    return () => {
      // clearGetRecomDetails()
      clearGetRecomProfiles()
    }
  }, [])

  const fetchRecomDetails = () => {
    const { recomId } = match.params
    getRecomDetails({
      recommendationId: recomId
    })
  }

  const fetchRecomProfiles = () => {
    const { recomId } = match.params
    getRecomProfiles({
      recommendationId: recomId,
      limit: 100,
      offset: 0
    })
  }

  const handleBackButtonClick = () => {
    history.goBack()
  }

  const handleViewProfile = (profileId) => {
    setPreviousPath()
    const recomProfileList = getRecomProfilesForProfileDetailScreen(profiles)
    setRecommendedProfiles(recomProfileList)
    props.history.push(`/profile-details/${profileId}`)
  }

  const getRecomProfilesForProfileDetailScreen = (recommendations) => {
    const result = []
    for (let index = 0; index < recommendations.length; index++) {
      const profile = recommendations[index].profile
      result.push(profile)
    }
    return result
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>{t('recommendation.noRecommendationsFound')}</Typography>
      </div>
    )
  }

  const renderBoardMembers = () => {
    if (profiles && profiles.length) {
      return (
        <div className='board-members-container'>
          {profiles.map((boardMember, index) => (
            <MemberCard
              {...props}
              key={index}
              profileDetails={boardMember.profile}
              profileImage={boardMember.profile.profileImage}
              position={boardMember.boardPosition ? boardMember.boardPosition.positionAr : ''}
              handleViewProfileCallback={(profileId) => handleViewProfile(profileId)}
            />
          ))}
        </div>
      )
    }
    return renderEmptyState()
  }

  return (
    <div className='board-member-list-container'>
      <BoardMemberListHeader
        {...props}
        handleBackButtonClick={handleBackButtonClick}
      />
      {recomLoading ? <Loader {...props} color='blue' /> : renderBoardMembers()}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    // recommendation
    recomLoading: state.recommendation.recomLoading,
    recomDetails: state.recommendation.recomDetails,
    profiles: state.recommendation.recomProfiles
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    setPreviousPath,
    getRecomDetails,
    getRecomProfiles,
    clearGetRecomDetails,
    clearGetRecomProfiles,
    setRecommendedProfiles
  })(BoardMemberList)
)
