import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../i18n/index";
import { getSectors } from "./sectors.action-creator";
import {
  getOrgListBySectorId,
  getTiersByOrg,
  getProfiles,
} from "../../common/common.action-creator";
import { Grid, Typography } from "@material-ui/core";
import {
  BRAND_LOGO,
  LEFT_ARROW,
  AVATAR,
} from "../../constants/image-reference";
import classNames from "classnames";

import "./sectors.component.scss";
import SectorsEntityVisualization from "../../common/sectors-entity-visualization/sectors-entity-visualization.component";
import EntityVisualization from "./entity-visualization/entity-visualization.component";
import TierVisualization from "../../common/tier-visualization/tier-visualization.component";

const Sectors = (props) => {
  const [showSectors, setShowSectors] = useState(true);
  const [showEntities, setShowEntities] = useState(false);
  const [showProfiles, setShowProfiles] = useState(false);
  const [orgVisualData, setOrgVisualData] = useState(null);
  const [tierVisualData, setTierVisualData] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedSector, setSelectedSector] = useState(null);

  const [profilesGrid, setProfilesGrid] = useState(null);

  const {
    //destructing
    getSectors,
    sectorList,
    getOrgListBySectorId,
    orgListBySector,
    tiers,
    getTiersByOrg,
    getProfiles,
    profiles,
  } = props;

  const visualData = [
    {
      diameter: 320,
      width: 320,
      height: 320,
      profilePoints: [
        {
          x: 108,
          y: 311,
        },
      ],
      x: -70,
      y: -70,
    },
    {
      diameter: 140,
      width: 140,
      height: 140,
      profilePoints: [],
      x: 312.5,
      y: 352.5,
    },
    {
      diameter: 140,
      width: 140,
      height: 140,
      profilePoints: [],
      x: 110,
      y: 312.5,
    },
    {
      diameter: 140,
      width: 140,
      height: 140,
      profilePoints: [],
      x: 300,
      y: 150,
    },
  ];

  const tiers1 = {
    insightType: "tier",
    visualDetails: [
      {
        tierId: 5,
        tierName: "first",
        profilesCount: 273,
        profiles: [
          {
            id: 209071,
            tier: {
              nameEn: "first",
              nameAr: "اول",
              id: 5,
            },
            profileImage: {
              id: 208293,
              confidentialityLevel: null,
              image:
                "/media/profile_images/209071/%D8%B9%D9%8A%D8%B3%D9%89-%D8%A7%D9%84%D8%BA%D8%B1%D9%8A%D8%B1.jpg",
              url: "/media/profile_images/209071/%D8%B9%D9%8A%D8%B3%D9%89-%D8%A7%D9%84%D8%BA%D8%B1%D9%8A%D8%B1.jpg",
              profile: 209071,
            },
            organization: {
              id: 14617,
              sector: {
                nameEn: "Other",
                nameAr: "أخرى",
                id: 34,
              },
              type: {
                nameEn: "Semi Government ",
                nameAr: "أخرى",
                id: 4,
              },
              nameEn: "Essa Al Ghurarir Investment",
              nameAr: "شركة عيسى الغرير للاستثمار",
              tagsEn: "",
              tagsAr: "",
              createdAt: "2021-06-27T11:28:28.349332Z",
              updatedAt: "2021-06-27T11:28:28.349363Z",
              isActive: true,
            },
            manhoumId: null,
            email: null,
            nameEn: "Eissa Abdullah",
            nameAr: "عيسى عبدالله الغرير",
            confidentialityLevel: "high",
            summaryEn: "",
            summaryAr:
              'رئيس مجلس إدارة "شركة عيسى الغرير للاستثمار"، ورئيس مجلس إدارة "مؤسسة الأوقاف وشؤون القصر"، ورئيس "منتدى ثروات للشركات العائلية"، ونائب رئيس مجلس إدارة "جمعية الإمارات للغوص"، ومدير عام "شركة كرم لصناعة الأغذية"، ومساهم في "مجموعة برايم للرعاية الصحية".\n\nكما أنه عضو مجلس إدارة كل من "مركز دبي لتطوير الاقتصاد الإسلامي" منذ 23 يناير (كانون الثاني) 2020، و"مجلس الإمارات لتغير المناخ والبيئة"، و"هيئة الإمارات للمواصفات والمقاييس"، و"غرفة تجارة وصناعة دبي"، وعضو اللجنة الاستشارية الدولية للمنتدى الاقتصادي الإسلامي العالمي.\n\nوهو مدير في شركة أنتا للسياحة والسفر "(ANTA Travel & Tours (L.L.C"، في مركز دبي المالي العالمي "DIFC" بدولة الإمارات العربية المتحدة.\n\nوهو أيضاً شريك مؤسس ومدير في شركة ستار لإدارة العقارات المحدودة "Star Property Management Ltd"، في مركز دبي المالي العالمي "DIFC" بدولة الإمارات العربية المتحدة.\n\nمدير وشريك مؤسس في "الشركة العربية السورية للألمنيوم" بالجمهورية العربية السورية، ويمتلك 10 حصص في الشركة، بنسبة 1%، قيمتها 100,000 ليرة سورية.',
            traitEn: null,
            traitAr: null,
            isRisingStar: false,
            leaderAssessmentResult: null,
            categories: null,
            isPublished: true,
            createdAt: "2021-07-05T10:05:15.665361Z",
            updatedAt: "2021-09-28T05:53:04.183463Z",
            tags: "مالي",
            dataSource: null,
          },
        ],
      },
      {
        tierId: 6,
        tierName: "second",
        profilesCount: 663,
        profiles: [],
      },
      {
        tierId: 7,
        tierName: "third",
        profilesCount: 447,
        profiles: [],
      },
      {
        tierId: 8,
        tierName: "fourth",
        profilesCount: 440,
        profiles: [],
      },
    ],
  };

  useEffect(() => {
    getSectors();
  }, []);

  useEffect(() => {
    const visualDataArray =
      orgListBySector &&
      orgListBySector.map((data) => {
        return {
          entityId: data.organization_id,
          entityName: data.organization_nameAr,
          profilesCount: data.count,
          profiles: [],
        };
      });

    let tempList = [];
    orgListBySector &&
      orgListBySector.map((data) => {
        const results = {
          entityId: data.organization_id,
          entityName: data.organization_nameAr,
          profilesCount: data.count,
          profiles: [],
        };
        tempList.push(results);
      });
    if (tempList.length !== 0) {
      let data = {
        insightType: "entity",
        visualDetails: tempList,
      };
      setOrgVisualData(data);
      setShowSectors(false);
      setShowEntities(true);
    }
  }, [orgListBySector]);

  useEffect(() => {
    let tempList = [];

    if (tiers.length > 0) {
      tiers.map((data) => {
        console.log(data);
        const results = {
          tierId: data.tier.id,
          tierName: data.tier.nameEn,
          profilesCount: data.profilesCount,
          profiles: [],
        };
        tempList.push(results);
      });
      let data = {
        insightType: "tier",
        visualDetails: tempList,
      };
      console.log("tier::", selectedEntity);
      setTierVisualData(data);
      getProfiles({ orgId: selectedEntity.entityId });
    }
  }, [tiers]);

  const navigateToEntries = (sector) => {
    console.log(sector, ":sector:");
    setSelectedSector(sector);
    getOrgListBySectorId({ sectorId: sector.id });
  };

  const handleNavigateToProfileDetails = (profileId) => {
   // setPreviousPath(props.match)
    props.history.push(`/profile-details/${profileId}`)
  }

  useEffect(() => {
    console.log("profiles:::", profiles);
    let temp =
      profiles &&
      profiles.map((profile) => {
        return (
          <div className="card" key={profile.id}>
            <div className="card-profile">
              <img src={AVATAR} alt="" />
            </div>
            <div className="card-details">
              <p className="card-details-name" onClick={()=>handleNavigateToProfileDetails(profile.id)}>{profile.nameAr}</p>
              <p className="card-detail">
                {profile.leadership.positions[0].position.positionAr}
              </p>
            </div>
          </div>
        );
      });
    setProfilesGrid(temp);
  }, [profiles]);

  const backToSectors = () => {
    setShowSectors(true);
    setShowEntities(false);
    setShowProfiles(false);
  };

  const backToEntities = () => {
    setShowSectors(false);
    setShowEntities(true);
    setShowProfiles(false);
  };

  const navigateToProfiles = (selectedEntity) => {
    // getProfiles({ orgId: orgId });
    getTiersByOrg({ orgId: selectedEntity.entityId });
    setSelectedEntity(selectedEntity);
    console.log("------>>>>>", selectedEntity.entityId);
    setShowSectors(false);
    setShowEntities(false);
    setShowProfiles(true);
  };

  const renderProfiles = () => {
    return (
      <div className="sectors-wrapper">
        <div className="sectors-header sectors-header-inner">
          <Grid
            style={{ display: "flex", gap: "5px" }}
            onClick={() => backToEntities()}
          >
            <img src={LEFT_ARROW} className="sectors-arrow" alt="" />
            <Typography className="sectors-title">
              {selectedSector.nameAr}
            </Typography>
          </Grid>
        </div>

        <div className="sectors-details">
          <div className="sectors-left">
            <div
              className="tier-insight-visualization-view"
              style={{ marginBottom: isHhOrAdHocUser ? "60px" : "10px" }}
            >
              <TierVisualization
                {...props}
                visualData={tierVisualData}
                //visualData={tiers1}
                // viewProfileCallback={(profileId) => navigateToProfile(profileId)}
              />
            </div>
          </div>

          <div className="sectors-right">
          <Typography className="sectors-title" style={{margin:"0px 20px 10px 0px", fontFamily:'HelveticaRoman', fontSize:'17px'}}>
              {selectedEntity.entityName}
            </Typography>
            {profilesGrid}</div>
        </div>
      </div>
    );
  };

  let isHhOrAdHocUser = true;
  let isHybridView = false;

  const renderEntities = () => {
    return (
      <div
        className={classNames([
          "entity-insight-visualization-view entity-view",
          {
            "hybrid-insight-viz-view": isHhOrAdHocUser,
          },
        ])}
        style={{
          marginTop: isHhOrAdHocUser ? "15px" : "40px",
        }}
      >
        <div className="header-wrapper">
          <div className="show-details sectors-header">
            <Grid
              style={{ display: "flex", gap: "1rem", paddingRight: "20px" }}
              onClick={() => backToSectors()}
            >
              <img src={LEFT_ARROW} className="brand-logo" alt="Brand icon" />
              <Typography
                style={{ marginTop: "5px", fontSize: "14px" }}
                className="sectors-title "
              >
                <span>رجوع لجميع القطاعات</span>
              </Typography>
            </Grid>
            <Grid
              style={{ display: "flex", gap: "1rem", paddingRight: "20px" }}
            >
              <Typography
                style={{ marginTop: "5px", fontSize: "15px" }}
                className="sectors-name"
              >
                <span> {selectedSector.nameAr}</span>
              </Typography>
            </Grid>
          </div>
        </div>
        <EntityVisualization
          {...props}
          visualData={orgVisualData}
          isHybridView={isHybridView}
          viewProfileCallback={(selectedEntity) => navigateToProfiles(selectedEntity)}
        />
      </div>
    );
  };

  const renderSectors = () => {
    return (
      <div className="insight-preview-container">
        <div className="header-wrapper">
          <div className="show-details">
            <Grid style={{ display: "flex", gap: "1rem" }}>
              <img src={BRAND_LOGO} className="brand-logo" alt="Brand icon" />
              <Typography
                style={{
                  marginTop: "-5px",
                  fontSize: "40px",
                  fontFamily: "HelveticaRoman",
                }}
                className="sectors-title main-sectors-title"
              >
                القطاعات
              </Typography>
            </Grid>
          </div>
        </div>
        <div className="page-container" style={{ backgroundColor: "#001c47" }}>
          <SectorsEntityVisualization
            {...props}
            sectors={sectorList}
            visualData={visualData}
            isHybridView={true}
            viewEntitiesCallback={(selectedSector) =>
              navigateToEntries(selectedSector)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="insights-container"
      style={{ height: "calc(100vh - 80px)" }}
    >
      {showSectors && renderSectors()}
      {showEntities && renderEntities()}
      {showProfiles && tierVisualData && renderProfiles()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sectorList: state.sectors.sectorList,
    orgListBySector: state.common.orgListBySector,
    tiers: state.common.tiers,
    profiles: state.common.profiles,
  };
}

export default withTranslation(
  ["translations"],
  translateOptions
)(
  connect(mapStateToProps, {
    getSectors,
    getOrgListBySectorId,
    getTiersByOrg,
    getProfiles,
  })(Sectors)
);
