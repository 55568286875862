export function constructDescriptionDetails (profileDetails) {
  let desc = ''

  const { leadership, experience, personal } = profileDetails

  const firstName = profileDetails.nameAr

  const positionOrgName = leadership.positions.length > 1 && leadership.positions[1].organization ? leadership.positions[1].organization.nameAr : ''
  const positionJobTitle = leadership.positions.length > 1 ? leadership.positions[1].titleAr : ''
  const joinDate1 = leadership.positions.length ? leadership.positions[0].startYear : ''
  const joinDate2 = leadership.positions.length > 1 ? leadership.positions[1].startYear : ''
  const graduationDate1 = experience.certifications.length ? experience.certifications[0].year : ''
  const trainingCourse1 = experience.certifications.length ? experience.certifications[0].trainingCourse : ''
  const graduationDate2 = experience.certifications.length > 1 ? experience.certifications[1].year : ''
  const trainingCourse2 = experience.certifications.length > 1 ? experience.certifications[1].trainingCourse : ''
  const orgNameAch = leadership.achievements.length ? leadership.achievements[0].organizationAr : ''
  const achDate = leadership.achievements.length ? leadership.achievements[0].date : ''
  const financialImpact = leadership.achievements.length ? leadership.achievements[0].financialImpactEn : ''

  const dateOfBirth = personal.dob || ''
  const age = personal.age || ''
  const nationality = personal.nationalityEn
  const universityName1 = experience.educationCertifications.length ? experience.educationCertifications[0].universityAr : ''
  const degree1 = experience.educationCertifications.length ? experience.educationCertifications[0].specializationAr : ''
  const gradDate1 = experience.educationCertifications.length ? experience.educationCertifications[0].year : ''
  const country1 = 'N/A'
  const universityName2 = experience.educationCertifications.length > 1 ? experience.educationCertifications[1].universityAr : ''
  const degree2 = experience.educationCertifications.length > 1 ? experience.educationCertifications[1].specializationAr : ''
  const gradDate2 = experience.educationCertifications.length > 1 ? experience.educationCertifications[1].year : ''
  const country2 = 'N/A'

  // female
  if (personal.gender && personal.gender.nameEn === 'female') {
    if (firstName) {
      let sentence = ''
      if (positionJobTitle) {
        sentence = `${firstName} شغلت منصب ${positionJobTitle}.`
        if (positionOrgName) {
          sentence = `${firstName} شغلت منصب ${positionJobTitle} في ${positionOrgName}.`
          if (joinDate2) {
            sentence = `${firstName} شغلت منصب ${positionJobTitle} في ${positionOrgName} في ${joinDate2}.`
            if (joinDate1) {
              sentence = `${firstName} شغلت منصب ${positionJobTitle} في ${positionOrgName} في ${joinDate2} ثم إنتقلت لمنصبها الحالي في ${joinDate1}. `
            }
          }
        }
      }
      desc = sentence
    }
    if (firstName) {
      let sentence = ''
      if (trainingCourse1) {
        sentence = `حصلت ${firstName} على شهادات خبرة مثل ${trainingCourse1}.`
        if (graduationDate1) {
          sentence = `حصلت ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1}.`
          if (trainingCourse2) {
            sentence = `حصلت ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1} و ${trainingCourse2}.`
            if (graduationDate2) {
              sentence = `حصلت ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1} و ${trainingCourse2} في ${graduationDate2}. `
            }
          }
        }
      }
      desc += sentence
    }
    if (achDate) {
      let sentence = ''
      if (achDate) {
        sentence = `حققت إنجاز في ${achDate}.`
        if (orgNameAch) {
          sentence = `حققت إنجاز في ${achDate} في ${orgNameAch}.`
          if (financialImpact) {
            sentence = `حققت إنجاز في ${achDate} في ${orgNameAch} تأثيره الالي ${financialImpact}.`
          }
        }
      }
      desc += sentence
    }
    if (desc) {
      desc += '\n\n'
    }
    if (firstName) {
      let sentence = ''
      if (nationality) {
        sentence = `ولدت ${firstName} في ${nationality}.`
        //if (dateOfBirth) {
        //  sentence = `ولدت ${firstName} في ${nationality} في ${dateOfBirth}.`
        //  if (age) {
        //    sentence = `ولدت ${firstName} في ${nationality} في ${dateOfBirth} بالغة من العمر ${age} عاماً. `
        //  }
        //}
      }
      desc += sentence
    }
    if (firstName) {
      let sentence = ''
      if (universityName1) {
        sentence = `${firstName} تخرجت من جامعة ${universityName1}.`
        if (degree1) {
          sentence = `${firstName} تخرجت من جامعة ${universityName1} حاصلة على ${degree1}.`
          if (gradDate1) {
            sentence = `${firstName} تخرجت من جامعة ${universityName1} حاصلة على ${degree1} في ${gradDate1}.`
            if (degree2) {
              sentence = `${firstName} تخرجت من جامعة ${universityName1} حاصلة على ${degree1} في ${gradDate1} و حصلت أيضاً على شهادة ${degree2}.`
              if (universityName2) {
                sentence = `${firstName} تخرجت من جامعة ${universityName1} حاصلة على ${degree1} في ${gradDate1} و حصلت أيضاً على شهادة ${degree2} من جامعة ${universityName2}.`
                if (gradDate2) {
                  sentence = `${firstName} تخرجت من جامعة ${universityName1} حاصلة على ${degree1} في ${gradDate1} و حصلت أيضاً على شهادة ${degree2} من جامعة ${universityName2} في ${gradDate2}. `
                }
              }
            }
          }
        }
      }
      desc += sentence
    }
    return desc
  }

  // male
  if (firstName) {
    let sentence = ''
    if (positionJobTitle) {
      sentence = `${firstName} شغل منصب ${positionJobTitle}.`
      if (positionOrgName) {
        sentence = `${firstName} شغل منصب ${positionJobTitle} في ${positionOrgName}.`
        if (joinDate2) {
          sentence = `${firstName} شغل منصب ${positionJobTitle} في ${positionOrgName} في ${joinDate2}.`
          if (joinDate1) {
            sentence = `${firstName} شغل منصب ${positionJobTitle} في ${positionOrgName} في ${joinDate2} ثم إنتقل لمنصبه الحالي في ${joinDate1}. `
          }
        }
      }
    }
    desc = sentence
  }
  if (firstName) {
    let sentence = ''
    if (trainingCourse1) {
      sentence = `حصل ${firstName} على شهادات خبرة مثل ${trainingCourse1}.`
      if (graduationDate1) {
        sentence = `حصل ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1}.`
        if (trainingCourse2) {
          sentence = `حصل ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1} و ${trainingCourse2}.`
          if (graduationDate2) {
            sentence = `حصل ${firstName} على شهادات خبرة مثل ${trainingCourse1} في ${graduationDate1} و ${trainingCourse2} في ${graduationDate2}. `
          }
        }
      }
    }
    desc += sentence
  }
  if (achDate) {
    let sentence = ''
    if (achDate) {
      sentence = `حقق إنجاز في ${achDate}.`
      if (orgNameAch) {
        sentence = `حقق إنجاز في ${achDate} في ${orgNameAch}.`
        if (financialImpact) {
          sentence = `حقق إنجاز في ${achDate} في ${orgNameAch} تأثيره المالي ${financialImpact}.`
        }
      }
    }
    desc += sentence
  }
  if (desc) {
    desc += '\n\n'
  }
  if (firstName) {
    let sentence = ''
    if (nationality) {
      sentence = `ولد ${firstName} في ${nationality}.`
      //if (dateOfBirth) {
      //  sentence = `ولد ${firstName} في ${nationality} في ${dateOfBirth}.`
      //  if (age) {
      //    sentence = `ولد ${firstName} في ${nationality} في ${dateOfBirth} بالغاً من العمر ${age} عاماً. `
      //  }
      //}
    }
    desc += sentence
  }
  if (firstName) {
    let sentence = ''
    if (universityName1) {
      sentence = `${firstName} تخرج من جامعة ${universityName1}.`
      if (degree1) {
        sentence = `${firstName} تخرج من جامعة ${universityName1} حاصل على ${degree1}.`
        if (gradDate1) {
          sentence = `${firstName} تخرج من جامعة ${universityName1} حاصل على ${degree1} في ${gradDate1}.`
          if (degree2) {
            sentence = `${firstName} تخرج من جامعة ${universityName1} حاصل على ${degree1} في ${gradDate1} و حصل أيضاً على شهادة ${degree2}.`
            if (universityName2) {
              sentence = `${firstName} تخرج من جامعة ${universityName1} حاصل على ${degree1} في ${gradDate1} و حصل أيضاً على شهادة ${degree2} من جامعة ${universityName2}.`
              if (gradDate2) {
                sentence = `${firstName} تخرج من جامعة ${universityName1} حاصل على ${degree1} في ${gradDate1} و حصل أيضاً على شهادة ${degree2} من جامعة ${universityName2} في ${gradDate2}.`
              }
            }
          }
        }
      }
    }
    desc += sentence
  }
  return desc
}
