import React, { useState, useEffect } from 'react'
import { AppBar, Tabs, Tab, Typography, Button, Badge, Tooltip } from '@material-ui/core'
import { AVATAR, CANCEL_BTN } from '../../../constants/image-reference'
import { imageBaseUrl } from '../../../utils/string.utils'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from '../../../common/loader/loader.component'

import './recommendation-footer.component.scss'

function TabPanel (props) {
  const { children } = props
  return (
    <div className='tab-panel-wrapper'>
      <div className='content-wrapper'>{children}</div>
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node
}

const getActivePositionProfiles = (recommendations, activePosition) => {
  if (recommendations) {
    return recommendations[activePosition].profiles
  }
  return []
}

export default function RecommendationFooter (props) {
  const {
    t, removeProfileCallback, saveRecommendationCallback, saveAndSendRecomCallback, sendRecommendationCallback,
    toggleFooterCallback, tabClickCallback
  } = props
  const {
    recommendations, recomLoading, recomDetails, isEditView, shortlistedProfileIds, hasViewOnlyAccess,
    showFooterOpen
  } = props
  const [activePosition, setActivePosition] = useState(0)
  // const [showFooterOpen, setFooterOpen] = useState(false)
  const [activePositionProfiles, setActivePositionProfiles] = useState(getActivePositionProfiles(recommendations, activePosition))

  useEffect(() => {
    setActivePosition(activePosition)
    setActivePositionProfiles(getActivePositionProfiles(recommendations, activePosition))
  }, [recommendations])

  const handleChange = (event, newValue) => {
    setActivePosition(newValue)
    setActivePositionProfiles(recommendations[newValue].profiles)
  }

  const toggleFooter = () => {
    // setFooterOpen(!showFooterOpen)
    toggleFooterCallback()
  }

  const handleTabClick = () => {
    // if (!showFooterOpen) {
    // setFooterOpen(true)
    tabClickCallback()
    // }
  }

  const handleSaveRecommendation = () => {
    if (isEditView) {
      saveAndSendRecomCallback()
    } else {
      saveRecommendationCallback()
    }
  }

  const handleSendRecommendation = () => {
    sendRecommendationCallback()
  }

  const handleRemoveClick = (profile) => {
    const positionId = recommendations[activePosition].position.value
    removeProfileCallback(positionId, profile)
  }

  const renderFooterProfile = (profile) => {
    const { id, nameAr, leadership, profileImage } = profile
    const { positions } = leadership
    const profileImagePath = profileImage ? profileImage.url : null
    const organization = positions.length ? positions[0].organization : null
    const position = positions.length ? positions[0].position : null
    return (
      <div className='rec-profile-wrapper' key={id}>
        <div className='avatar-wrapper'>
          <img src={CANCEL_BTN} alt='Remove profile' className='remove-btn' onClick={() => handleRemoveClick(profile)} />
          {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='avatar' />}
          {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='avatar' />}
        </div>
        <div className='details-wrapper'>
          <Tooltip
            placement='bottom'
            title={<span aria-label='tooltip' className='tooltip-text'>{nameAr}</span>}
          >
            <Typography className='name'>{nameAr}</Typography>
          </Tooltip>
          {position &&
            <Tooltip
              placement='bottom'
              title={<span aria-label='tooltip' className='tooltip-text'>{position.positionAr || ''}</span>}
            >
              <Typography className='position'>{position.positionAr || ''}</Typography>
            </Tooltip>}
          {organization &&
            <Tooltip
              placement='bottom'
              title={<span aria-label='tooltip' className='tooltip-text'>{organization.nameAr || ''}</span>}
            >
              <Typography className='org'>{organization.nameAr || ''}</Typography>
            </Tooltip>}
        </div>
      </div>
    )
  }

  const renderTabPanel = () => {
    if (recomDetails) {
      const { isDraft, isSent } = recomDetails
      return (
        <TabPanel value={activePosition}>
          <div className='rec-profile-list' style={{ display: !showFooterOpen ? 'none' : 'flex' }}>
            {activePositionProfiles.length ? activePositionProfiles.map((profile) => renderFooterProfile(profile)) : null}
          </div>
          <div className='button-wrapper'>
            {!isSent &&
              <Button onClick={handleSendRecommendation} style={{ display: !showFooterOpen ? 'none' : 'flex' }} className='contained-button' variant='contained'>
                {t('recommendation.sendTheCandidates')}
              </Button>}
            {!isDraft && !isSent &&
              <Button onClick={handleSaveRecommendation} style={{ display: !showFooterOpen ? 'none' : 'flex' }} className='outline-button' variant='outlined'>
                {t('recommendation.sendLater')}
              </Button>}
            {isDraft &&
              <Button onClick={console.log('saved')} style={{ display: !showFooterOpen ? 'none' : 'flex' }} className='contained-button inactive-button' variant='outlined'>
                {t('recommendation.saved')}
              </Button>}
            {isSent && isEditView && isDraft &&
              <Button onClick={console.log('sent successfully')} style={{ display: !showFooterOpen ? 'none' : 'flex' }} className='contained-button' variant='outlined'>
                {t('recommendation.setSuccessfully')}
              </Button>}
            {isSent && isEditView && !isDraft &&
              <Button onClick={saveAndSendRecomCallback} style={{ display: !showFooterOpen ? 'none' : 'flex' }} className='contained-button' variant='outlined'>
                {t('recommendation.saveAndSendTheCandidates')}
              </Button>}
          </div>
        </TabPanel>
      )
    }
  }

  const renderLoadingState = () => {
    return (
      <TabPanel value={activePosition} style={{ textAlign: 'center' }}>
        <Loader color='white' />
      </TabPanel>
    )
  }

  if (hasViewOnlyAccess) {
    return null
  }

  return (
    <div
      className={classNames(['recommendation-footer-container', {
        'panel-fade-out': shortlistedProfileIds.length,
        'panel-fade-in': !shortlistedProfileIds.length
      }])}
    >
      <div
        className={classNames(['footer-handle', {
          'open-footer': showFooterOpen,
          'closed-footer': !showFooterOpen
        }])}
        onClick={toggleFooter}
      />
      <div
        className={classNames(['tab-container', {
          'expanded-footer': showFooterOpen,
          'collapsed-footer': !showFooterOpen
        }])}
      >
        <AppBar position='static' classes={{ root: 'app-bar' }}>
          <Tabs
            value={activePosition}
            onChange={handleChange}
            classes={{
              indicator: 'active-tab-indicator',
              scroller: 'tab-scroller',
              scrollButtons: 'scroll-button'
            }}
            variant='scrollable'
            scrollButtons='on'
            aria-label='recommendation'
          >
            {(recommendations && recommendations.length) ? recommendations.map((recommendation) => (
              <Tab
                key={recommendation.position.value}
                onClick={handleTabClick}
                icon={<Badge classes={{ badge: 'badge' }} badgeContent={recommendation.profiles.length || '0'} />}
                label={<Typography className='tab-label'>{recommendation.position.label}</Typography>}
                classes={{ root: 'tab', selected: (recommendations.length > 1) ? 'active-tab' : '' }}
              />
            )) : null}
          </Tabs>
        </AppBar>
        {recomLoading ? renderLoadingState() : renderTabPanel()}
      </div>
    </div>
  )
}
