import ActionTypes from './common.action-type'
import { apiAction } from '../api/api.action-creator'

// Add orgs
export function addOrg(queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'addOrg',
    onSuccess: addOrgSuccess,
    onFailure: addOrgFailure,
    label: ActionTypes.ADD_ORG,
    isSecureCall: true,
    body: request
  })
}
export function addOrgSuccess(response) {
  return {
    type: ActionTypes.ADD_ORG_SUCCESS,
    response: response.data
  }
}
export function addOrgFailure(response) {
  return {
    type: ActionTypes.ADD_ORG_FAILURE,
    response
  }
}
export function clearAddOrgVar() {
  return {
    type: ActionTypes.CLEAR_ADD_ORG_VAR
  }
}

// Get org details
export const getOrgDetails = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getOrgDetails',
    isSecureCall: true,
    onSuccess: getOrgDetailsSuccess,
    onFailure: getOrgDetailsFailure,
    label: ActionTypes.GET_ORG_DETAILS,
    queryParams
  })
}
export const getOrgDetailsSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_ORG_DETAILS_SUCCESS,
      response: response.data
    })
  }
}
export const getOrgDetailsFailure = (response) => {
  return {
    type: ActionTypes.GET_ORG_DETAILS_FAILURE,
    response
  }
}
export function clearGetOrgDetailsVar() {
  return {
    type: ActionTypes.CLEAR_GET_ORG_DETAILS_VAR
  }
}

// Get org profile list
export const getOrgProfileList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getOrgProfileList',
    isSecureCall: true,
    onSuccess: getOrgProfileListSuccess,
    onFailure: getOrgProfileListFailure,
    label: ActionTypes.GET_ORG_PROFILE_LIST,
    queryParams
  })
}
export const getOrgProfileListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_ORG_PROFILE_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getOrgProfileListFailure = (response) => {
  return {
    type: ActionTypes.GET_ORG_PROFILE_LIST_FAILURE,
    response
  }
}
export function clearOrgProfileList() {
  return {
    type: ActionTypes.CLEAR_ORG_PROFILE_LIST
  }
}

// Get org list
export const getOrgList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getOrgList',
    isSecureCall: true,
    onSuccess: getOrgListSuccess,
    onFailure: getOrgListFailure,
    label: ActionTypes.GET_ORG_LIST,
    queryParams
  })
}
export const getOrgListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_ORG_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getOrgListFailure = (response) => {
  return {
    type: ActionTypes.GET_ORG_LIST_FAILURE,
    response
  }
}

// Get org list by sector Id
export const getOrgListBySectorId = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getOrgListBySectorId',
    isSecureCall: true,
    onSuccess: getOrgListBySectorIdSuccess,
    onFailure: getOrgListBySectorIdFailure,
    label: ActionTypes.GET_ORG_LIST_BY_SECTOR_ID,
    queryParams
  })
}
export const getOrgListBySectorIdSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_ORG_LIST_BY_SECTOR_ID_SUCCESS,
      response: response.data
    })
  }
}
export const getOrgListBySectorIdFailure = (response) => {
  return {
    type: ActionTypes.GET_ORG_LIST_BY_SECTOR_ID_FAILURE,
    response
  }
}
//get Tiers By Org
export const getTiersByOrg = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getTiersByOrg',
    isSecureCall: true,
    onSuccess: getTiersByOrgSuccess,
    onFailure: getTiersByOrgFailure,
    label: ActionTypes.GET_TIERS_BY_ORG,
    queryParams
  })
}
export const getTiersByOrgSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_TIERS_BY_ORG_SUCCESS,
      response: response.data
    })
  }
}
export const getTiersByOrgFailure = (response) => {
  return {
    type: ActionTypes.GET_TIERS_BY_ORG_FAILURE,
    response
  }
}
//get profiles
export const getProfiles = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getProfiles',
    isSecureCall: true,
    onSuccess: getProfilesSuccess,
    onFailure: getProfilesFailure,
    label: ActionTypes.GET_PROFILES,
    queryParams
  })
}
export const getProfilesSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_PROFILES_SUCCESS,
      response: response.data
    })
  }
}
export const getProfilesFailure = (response) => {
  return {
    type: ActionTypes.GET_PROFILES_FAILURE,
    response
  }
}

// Add position
export function addPosition(queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'addPosition',
    onSuccess: addPositionSuccess,
    onFailure: addPositionFailure,
    label: ActionTypes.ADD_POSITION,
    isSecureCall: true,
    queryParams,
    body: request
  })
}
export function addPositionSuccess(response) {
  return {
    type: ActionTypes.ADD_POSITION_SUCCESS,
    response: response.data
  }
}
export function addPositionFailure(response) {
  return {
    type: ActionTypes.ADD_POSITION_FAILURE,
    response
  }
}
export function clearAddPositionVar() {
  return {
    type: ActionTypes.CLEAR_ADD_POSITION_VAR
  }
}

// Get position list
export const getPositionList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getPositionList',
    isSecureCall: true,
    onSuccess: getPositionListSuccess,
    onFailure: getPositionListFailure,
    label: ActionTypes.GET_POSITION_LIST,
    queryParams
  })
}
export const getPositionListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_POSITION_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getPositionListFailure = (response) => {
  return {
    type: ActionTypes.GET_POSITION_LIST_FAILURE,
    response
  }
}

// Get section list
export const getSectorList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getSectorList',
    isSecureCall: true,
    onSuccess: getSectorListSuccess,
    onFailure: getSectorListFailure,
    label: ActionTypes.GET_SECTOR_LIST,
    queryParams
  })
}
export const getSectorListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_SECTOR_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getSectorListFailure = (response) => {
  return {
    type: ActionTypes.GET_SECTOR_LIST_FAILURE,
    response
  }
}

// get users list
export function getUserList(queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getUserList',
    onSuccess: getUserListSuccess,
    onFailure: getUserListFailure,
    label: ActionTypes.GET_USER_LIST,
    isSecureCall: true,
    queryParams
  })
}
export function getUserListSuccess(response) {
  return {
    type: ActionTypes.GET_USER_LIST_SUCCESS,
    response: response.data
  }
}
export function getUserListFailure(response) {
  return {
    type: ActionTypes.GET_USER_LIST_FAILURE,
    response
  }
}

// Get board list
export function getBoardList(queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getBoardList',
    onSuccess: getBoardListSuccess,
    onFailure: getBoardListFailure,
    label: ActionTypes.GET_BOARD_LIST,
    isSecureCall: true,
    queryParams
  })
}
export function getBoardListSuccess(response) {
  return {
    type: ActionTypes.GET_BOARD_LIST_SUCCESS,
    response: response.data.results
  }
}
export function getBoardListFailure(response) {
  return {
    type: ActionTypes.GET_BOARD_LIST_FAILURE,
    response
  }
}

// Get board member list
export function getBoardMemberList(queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getBoardMemberList',
    onSuccess: getBoardMemberListSuccess,
    onFailure: getBoardMemberListFailure,
    label: ActionTypes.GET_BOARD_MEMBER_LIST,
    isSecureCall: true,
    queryParams
  })
}
export function getBoardMemberListSuccess(response) {
  return {
    type: ActionTypes.GET_BOARD_MEMBER_LIST_SUCCESS,
    response: response.data.results
  }
}
export function getBoardMemberListFailure(response) {
  return {
    type: ActionTypes.GET_BOARD_MEMBER_LIST_FAILURE,
    response
  }
}

// Get org types
export function getOrgTypes() {
  return apiAction({
    method: 'GET',
    url: 'getOrgTypes',
    onSuccess: getOrgTypesSuccess,
    onFailure: getOrgTypesFailure,
    label: ActionTypes.GET_ORG_TYPES,
    isSecureCall: true
  })
}
export function getOrgTypesSuccess(response) {
  return {
    type: ActionTypes.GET_ORG_TYPES_SUCCESS,
    response: response.data
  }
}
export function getOrgTypesFailure(response) {
  return {
    type: ActionTypes.GET_ORG_TYPES_FAILURE,
    response
  }
}

// get tiers list
export function getTierList() {
  return apiAction({
    method: 'GET',
    url: 'getTierList',
    onSuccess: getTierListSuccess,
    onFailure: getTierListFailure,
    label: ActionTypes.GET_TIER_LIST,
    isSecureCall: true
  })
}
export function getTierListSuccess(response) {
  return {
    type: ActionTypes.GET_TIER_LIST_SUCCESS,
    response: response.data
  }
}
export function getTierListFailure(response) {
  return {
    type: ActionTypes.GET_TIER_LIST_FAILURE,
    response
  }
}

// get education levels
export function getEducationLevelList() {
  return apiAction({
    method: 'GET',
    url: 'getEducationLevelList',
    onSuccess: getEducationLevelListSuccess,
    onFailure: getEducationLevelListFailure,
    label: ActionTypes.GET_EDUCATION_LEVEL_LIST,
    isSecureCall: true
  })
}
export function getEducationLevelListSuccess(response) {
  return {
    type: ActionTypes.GET_EDUCATION_LEVEL_LIST_SUCCESS,
    response: response.data
  }
}
export function getEducationLevelListFailure(response) {
  return {
    type: ActionTypes.GET_EDUCATION_LEVEL_LIST_FAILURE,
    response
  }
}

// get genders
export function getGenderList() {
  return apiAction({
    method: 'GET',
    url: 'getGenderList',
    onSuccess: getGenderListSuccess,
    onFailure: getGenderListFailure,
    label: ActionTypes.GET_GENDER_LIST,
    isSecureCall: true
  })
}
export function getGenderListSuccess(response) {
  return {
    type: ActionTypes.GET_GENDER_LIST_SUCCESS,
    response: response.data
  }
}
export function getGenderListFailure(response) {
  return {
    type: ActionTypes.GET_GENDER_LIST_FAILURE,
    response
  }
}

// get tags
export function getProfileTagList() {
  return apiAction({
    method: 'GET',
    url: 'getProfileTagList',
    onSuccess: getProfileTagListSuccess,
    onFailure: getProfileTagListFailure,
    label: ActionTypes.GET_PROFILE_TAG_LIST,
    isSecureCall: true
  })
}
export function getProfileTagListSuccess(response) {
  return {
    type: ActionTypes.GET_PROFILE_TAG_LIST_SUCCESS,
    response: response.data
  }
}
export function getProfileTagListFailure(response) {
  return {
    type: ActionTypes.GET_PROFILE_TAG_LIST_FAILURE,
    response
  }
}

// Set previous path
export function setPreviousPath(response) {
  return {
    type: ActionTypes.SET_PREVIOUS_PATH,
    response
  }
}

//
export function resetReducer() {
  return {
    type: ActionTypes.RESET_REDUCER
  }
}
