import React from 'react'
import { Slider } from '@material-ui/core'

import './continuous-slider.scss'

function ContinuousSlider (props) {
  const { handleChange, minValue, maxValue, defaultValue, step } = props

  const onChange = (event, value) => {
    if (handleChange) {
      handleChange(value)
    }
  }

  return (
    <div className='continuous-slider-container'>
      <Slider
        style={{ root: 'continuous-slider' }}
        aria-label='custom thumb label'
        onChange={onChange}
        min={minValue}
        step={step}
        max={maxValue}
        defaultValue={defaultValue}
        valueLabelDisplay='on'
      />
    </div>
  )
}

export default ContinuousSlider
