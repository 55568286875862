import { zipObject } from 'lodash'

const ACTION_TYPES = [
  // get profile
  'GET_PROFILE',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_FAILURE',

  // Get personal network profile list
  'GET_PERSONAL_PROFILE_LIST',
  'GET_PERSONAL_PROFILE_LIST_SUCCESS',
  'GET_PERSONAL_PROFILE_LIST_FAILURE',

  // Get professional profile list
  'GET_PROFESSIONAL_PROFILE_LIST',
  'GET_PROFESSIONAL_PROFILE_LIST_SUCCESS',
  'GET_PROFESSIONAL_PROFILE_LIST_FAILURE',

  // Get educational profile list
  'GET_EDUCATIONAL_PROFILE_LIST',
  'GET_EDUCATIONAL_PROFILE_LIST_SUCCESS',
  'GET_EDUCATIONAL_PROFILE_LIST_FAILURE',

  // reset reducer
  'RESET_REDUCER'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
