import React from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { GRAY_CLOSE } from '../../../constants/image-reference'

import './reference-dialog.component.scss'

export default function ReferenceDialog (props) {
  const { handleClose, reference } = props
  const { refNameAr, jobTitleAr, organizationAr, mobile, email } = reference

  return (
    <Dialog size='sm' className='reference-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>الشخصية</Typography>
        <img src={GRAY_CLOSE} alt='Close dialog' className='close-button' onClick={handleClose} />
      </div>
      <div className='dialog-content'>
        <Typography className='reference-text'>مـراجـع</Typography>
        <div className='reference-details'>
          <Typography className='reference-heading'>{refNameAr || ''}</Typography>
          <ul className='details-wrapper'>
            <li className='detail'>
              <Typography className='detail-heading'>المنـصـب</Typography>
              <Typography className='detail-text'>{jobTitleAr}</Typography>
            </li>
            <li className='detail'>
              <Typography className='detail-heading'>الهـيـئة</Typography>
              <Typography className='detail-text'>{organizationAr}</Typography>
            </li>
            <li className='detail'>
              <Typography className='detail-heading'>رقم الهاتف المحمول</Typography>
              <Typography className='detail-text'>{mobile}</Typography>
            </li>
            <li className='detail'>
              <Typography className='detail-heading'>البريد الإلكتروني</Typography>
              <Typography className='detail-text'>{email}</Typography>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  )
}
