import { combineReducers } from 'redux'

import appReducer from '../api/api.reducer'
import authReducer from '../app/auth/auth.reducer'
import advancedSearchReducer from '../app/advanced-search/advanced-search.reducer'
import profileReducer from '../app/profile/profile.reducer'
import recommendationReducer from '../app/recommendation/recommendation.reducer'
import insightsReducer from '../app/insights/insights.reducer'
import sectorsReducer from '../app/sectors/sectors.reducer'
import commonReducer from '../common/common.reducer'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  advancedSearch: advancedSearchReducer,
  profile: profileReducer,
  recommendation: recommendationReducer,
  insights: insightsReducer,
  sectors: sectorsReducer,
  common: commonReducer
})

export default rootReducer
