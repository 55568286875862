import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../../i18n/index'
import { TextField, Button, IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { login, clearLoginVar } from '../auth.action-creator'
import { regex } from '../../../utils/validation.utils'

import AuthLayout from '../auth-layout/auth-layout.component'

import './login.component.scss'

function Login (props) {
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  })
  const [errors, setErrors] = useState({})
  const passwordRef = useRef('')

  const { t, userSession, login, loginFailure, clearLoginVar } = props

  const validateForm = (form, prop) => {
    form = form || values
    const errorObj = {}
    if (!prop || prop === 'email') {
      errorObj.email = !form.email || !form.email.match(regex.email)
    }

    if (!prop || prop === 'password') {
      errorObj.shop = !form.password || !form.password.match(regex.password)
    }
    const formError = { ...errors, ...errorObj }
    setErrors(formError)
    return formError
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
    validateForm({ ...values, [prop]: event.target.value }, prop)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const errorObj = validateForm()
    if (!errorObj.email && !errorObj.password) {
      login(values)
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const { email, password } = values
  const renderAdornment = () => {
    return (
      <InputAdornment position='end'>
        <IconButton
          aria-label='toggle password visibility'
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }

  useEffect(() => {
    if (userSession) {
      props.history.push('/advanced-search')
    }
  })

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      passwordRef.current.focus()
    }
  }

  // component unmount
  useEffect(() => {
    return () => {
      clearLoginVar()
    }
  }, [])

  return (
    <AuthLayout>
      <form className='form-container' autoComplete='off'>
        <TextField
          classes={{ root: 'auth-input' }}
          placeholder={t('auth.username')}
          margin='normal'
          variant='outlined'
          fullWidth
          type='email'
          onChange={handleChange('email')}
          error={errors.email}
          onKeyDown={keyPress}
        />
        <TextField
          classes={{ root: 'auth-input' }}
          placeholder={t('auth.password')}
          margin='normal'
          variant='outlined'
          inputRef={passwordRef}
          fullWidth
          type={values.showPassword ? 'text' : 'password'}
          onChange={handleChange('password')}
          error={errors.password}
          InputProps={{
            endAdornment: renderAdornment()
          }}
        />
        {loginFailure && <p className='login-error'>{loginFailure}</p>}
        {email && password ? <Button onClick={handleSubmit} className='auth-button' variant='contained' color='secondary'>
          {t('auth.signIn')}
                             </Button> : null}
      </form>
    </AuthLayout>
  )
}

function mapStateToProps (state) {
  return {
    userSession: state.auth.userSession,
    loginFailure: state.auth.loginFailure
  }
}

export default withTranslation(['translations'], translateOptions)(connect(mapStateToProps, { login, clearLoginVar })(Login))
