import ActionTypes from './recommendation.action-type'
import { apiAction } from '../../api/api.action-creator'

// Set recommendation attributes
export const setRecommendationAttributes = (entity, positions, boardMembers, isRecomEditable) => {
  return {
    type: ActionTypes.SET_RECOMMENDATION_ATTRIBUTES,
    entity,
    positions,
    boardMembers,
    isRecomEditable
  }
}

// clear recommendation attributes
export const clearRecommendationAttributes = () => {
  return {
    type: ActionTypes.CLEAR_RECOMMENDATION_ATTRIBUTES
  }
}

// set recommendation details
export const setRecommendationDetail = (recomDetail) => {
  return {
    type: ActionTypes.SET_RECOMMENDATION_DETAIL,
    recomDetail
  }
}

// clear recommendation details
export const clearRecommendationDetail = () => {
  return {
    type: ActionTypes.CLEAR_RECOMMENDATION_DETAIL
  }
}

// set recommendation results page config
export const setRecommendationResultsPageConfig = (recomConfig) => {
  return {
    type: ActionTypes.SET_RECOMMENDATION_RESULTS_PAGE_CONFIG,
    recomConfig
  }
}

// clear recommendation results page config
export const clearRecommendationResultsPageConfig = () => {
  return {
    type: ActionTypes.CLEAR_RECOMMENDATION_RESULTS_PAGE_CONFIG
  }
}

// Save bulk recom
export function saveBulkRecom (queryParams, body) {
  return apiAction({
    method: 'POST',
    url: 'saveBulkRecom',
    onSuccess: saveBulkRecomSuccess,
    onFailure: saveBulkRecomFailure,
    label: ActionTypes.SAVE_BULK_RECOM,
    isSecureCall: true,
    body
  })
}
export function saveBulkRecomSuccess (response) {
  return {
    type: ActionTypes.SAVE_BULK_RECOM_SUCCESS,
    response: response.data
  }
}
export function saveBulkRecomFailure (response) {
  return {
    type: ActionTypes.SAVE_BULK_RECOM_FAILURE,
    response
  }
}
export function clearSaveBulkRecomVar () {
  return {
    type: ActionTypes.CLEAR_SAVE_BULK_RECOM_VAR
  }
}

// Send bulk recom
export function sendBulkRecom (queryParams, body) {
  return apiAction({
    method: 'POST',
    url: 'sendBulkRecom',
    onSuccess: sendBulkRecomSuccess,
    onFailure: sendBulkRecomFailure,
    label: ActionTypes.SEND_BULK_RECOM,
    isSecureCall: true,
    body
  })
}
export function sendBulkRecomSuccess () {
  return {
    type: ActionTypes.SEND_BULK_RECOM_SUCCESS
  }
}
export function sendBulkRecomFailure (response) {
  return {
    type: ActionTypes.SEND_BULK_RECOM_FAILURE,
    response
  }
}
export function clearSendBulkRecomVar () {
  return {
    type: ActionTypes.CLEAR_SEND_BULK_RECOM_VAR
  }
}

// Save board recommendation
export function saveBoardRecom (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'saveBoardRecom',
    onSuccess: saveBoardRecomSuccess,
    onFailure: saveBoardRecomFailure,
    label: ActionTypes.SAVE_BOARD_RECOM,
    isSecureCall: true,
    queryParams,
    body: request
  })
}
export function saveBoardRecomSuccess (response) {
  return {
    type: ActionTypes.SAVE_BOARD_RECOM_SUCCESS,
    response: response.data
  }
}
export function saveBoardRecomFailure (response) {
  return {
    type: ActionTypes.SAVE_BOARD_RECOM_FAILURE,
    response
  }
}
export function clearSaveBoardRecomVar () {
  return {
    type: ActionTypes.CLEAR_SAVE_BOARD_RECOM_VAR
  }
}

// get recom
export function getRecom (params) {
  return apiAction({
    method: 'GET',
    url: 'getRecom',
    onSuccess: getRecomSuccess,
    onFailure: getRecomFailure,
    label: ActionTypes.GET_RECOM,
    isSecureCall: true,
    queryParams: params.queryParams
  })
}
export function getRecomSuccess (response) {
  return {
    type: ActionTypes.GET_RECOM_SUCCESS,
    response: response.data
  }
}
export function getRecomFailure (response) {
  return {
    type: ActionTypes.GET_RECOM_FAILURE,
    response
  }
}

export function clearRecomVar () {
  return {
    type: ActionTypes.CLEAR_RECOM_VAR
  }
}

// load more recom
export function loadMoreRecom (params) {
  return apiAction({
    method: 'GET',
    url: 'getRecom',
    onSuccess: loadMoreRecomSuccess,
    onFailure: loadMoreRecomFailure,
    label: ActionTypes.LOAD_MORE_RECOM,
    isSecureCall: true,
    queryParams: params.queryParams
  })
}
export function loadMoreRecomSuccess (response) {
  return {
    type: ActionTypes.LOAD_MORE_RECOM_SUCCESS,
    response: response.data
  }
}
export function loadMoreRecomFailure (response) {
  return {
    type: ActionTypes.LOAD_MORE_RECOM_FAILURE,
    response
  }
}

// delete recom
export function deleteRecom (params) {
  return apiAction({
    method: 'DELETE',
    url: 'deleteRecom',
    onSuccess: deleteRecomSuccess,
    onFailure: deleteRecomFailure,
    label: ActionTypes.DELETE_RECOM,
    isSecureCall: true,
    queryParams: params.queryParams
  })
}
export function deleteRecomSuccess (response, request) {
  return {
    type: ActionTypes.DELETE_RECOM_SUCCESS,
    recomId: request.recommendationId
  }
}
export function deleteRecomFailure (response) {
  return {
    type: ActionTypes.DELETE_RECOM_FAILURE,
    response
  }
}

// get recom details
export function getRecomDetails (queryParams, request) {
  return apiAction({
    method: 'GET',
    url: 'getRecomDetails',
    onSuccess: getRecomDetailsSuccess,
    onFailure: getRecomDetailsFailure,
    label: ActionTypes.GET_RECOM_DETAILS,
    isSecureCall: true,
    queryParams
  })
}
export function getRecomDetailsSuccess (response) {
  return {
    type: ActionTypes.GET_RECOM_DETAILS_SUCCESS,
    response: response.data
  }
}
export function getRecomDetailsFailure (response) {
  return {
    type: ActionTypes.GET_RECOM_DETAILS_FAILURE,
    response
  }
}
export function clearGetRecomDetails () {
  return {
    type: ActionTypes.CLEAR_GET_RECOM_DETAILS_VAR
  }
}

// get recom profiles
export function getRecomProfiles (queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getRecomProfiles',
    onSuccess: getRecomProfilesSuccess,
    onFailure: getRecomProfilesFailure,
    label: ActionTypes.GET_RECOM_PROFILES,
    isSecureCall: true,
    queryParams
  })
}
export function getRecomProfilesSuccess (response) {
  return {
    type: ActionTypes.GET_RECOM_PROFILES_SUCCESS,
    response: response.data
  }
}
export function getRecomProfilesFailure (response) {
  return {
    type: ActionTypes.GET_RECOM_PROFILES_FAILURE,
    response
  }
}
export function clearGetRecomProfiles () {
  return {
    type: ActionTypes.CLEAR_GET_RECOM_PROFILES_VAR
  }
}

// save and send profile recom
export function saveAndSendRecom (queryParams, request) {
  return apiAction({
    method: 'PUT',
    url: 'saveAndSendRecom',
    onSuccess: saveAndSendRecomSuccess,
    onFailure: saveAndSendRecomFailure,
    label: ActionTypes.SAVE_AND_SEND_RECOM,
    isSecureCall: true,
    queryParams,
    body: request
  })
}
export function saveAndSendRecomSuccess (response) {
  return {
    type: ActionTypes.SAVE_AND_SEND_RECOM_SUCCESS,
    response: response.data
  }
}
export function saveAndSendRecomFailure (response) {
  return {
    type: ActionTypes.SAVE_AND_SEND_RECOM_FAILURE,
    response
  }
}
export function clearSaveAndSendRecomVariables () {
  return {
    type: ActionTypes.CLEAR_SAVE_AND_SEND_RECOM_VARIABLES
  }
}

// save and send board recom
export function saveAndSendBoardRecom (queryParams, request) {
  return apiAction({
    method: 'POST',
    url: 'saveAndSendBoardRecom',
    onSuccess: saveAndSendBoardRecomSuccess,
    onFailure: saveAndSendBoardRecomFailure,
    label: ActionTypes.SAVE_AND_SEND_BOARD_RECOM,
    isSecureCall: true,
    queryParams,
    body: request
  })
}
export function saveAndSendBoardRecomSuccess (response) {
  return {
    type: ActionTypes.SAVE_AND_SEND_BOARD_RECOM_SUCCESS,
    response: response.data
  }
}
export function saveAndSendBoardRecomFailure (response) {
  return {
    type: ActionTypes.SAVE_AND_SEND_BOARD_RECOM_FAILURE,
    response
  }
}
export function clearSaveAndSendBoardRecomVariables () {
  return {
    type: ActionTypes.CLEAR_SAVE_AND_SEND_BOARD_RECOM_VARIABLES
  }
}

// set recommended profiles
export function setRecommendedProfiles (recommendedProfiles) {
  return {
    type: ActionTypes.SET_RECOMMENDED_PROFILES,
    recommendedProfiles
  }
}

// unset recommended profiles
export function unsetRecommendedProfiles () {
  return {
    type: ActionTypes.UNSET_RECOMMENDED_PROFILES
  }
}
