import React, { useState, useEffect } from 'react'
import { Dialog, Typography, Button, TextField, Divider } from '@material-ui/core'
import { WHITE_CLOSE, RISING_STAR } from '../../constants/image-reference'
import { deepCopy } from '../../utils/object.utils'
import { handleFormValidation } from '../../utils/validation.utils'

import SearchSelectInput from '../search-select-input/search-select-input'
import RadioButton from '../radio-button/radio-button.component'
import SelectInput from '../select-input/select-input'
import RangeSlider from '../range-slider/range-slider'
import CheckBox from '../checkbox/checkbox.component'

import './add-position.component.scss'

export default function AddPositionDialog (props) {
  const [values, setValues] = useState({
    sector: { type: 'text', value: '', isRequired: false },
    positionAr: { type: 'text', value: '', isRequired: true },
    gender: { type: 'text', value: '', isRequired: false },
    ageRangeMin: { type: 'number', value: '', isRequired: false },
    ageRangeMax: { type: 'number', value: '', isRequired: false },
    isRisingStar: { type: 'boolean', value: false, isRequired: false },
    tier: { type: 'text', value: '', isRequired: false },
    tags: { type: 'text', value: '', isRequired: false },
    educationLevel: { type: 'text', value: '', isRequired: false }
  })

  const [errors, setErrors] = useState({})

  const {
    t, handleClose, addPositionCallback, fetchSectorListCallback, fetchTierListCallback, fetchProfileTagListCallback,
    fetchEducationLevelListCallback, fetchGenderListCallback, clearAddPositionVarCallback
  } = props
  const { entity, sectorList, tierList, educationLevelList, genderList, profileTagList } = props
  const { sector, positionAr, gender, isRisingStar, tier, educationLevel, tags, ageRangeMin, ageRangeMax } = values

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    return () => {
      clearAddPositionVarCallback()
    }
  }, [])

  const init = () => {
    fetchSectorListCallback()
    fetchTierListCallback()
    fetchEducationLevelListCallback()
    fetchGenderListCallback()
    fetchProfileTagListCallback()
  }

  const handleAddPosition = () => {
    const formValidation = handleFormValidation(values)
    if (!formValidation.isFormValid) {
      const FormErrors = formValidation.errors
      setErrors({ ...errors, ...FormErrors })
    } else {
      setErrors({})
      const fieldValues = constructRequestBody(values)
      addPositionCallback(fieldValues)
    }
  }

  const constructRequestBody = (fields = {}) => {
    const params = {}
    for (const key in fields) {
      if (fields[key].value) {
        params[key] = fields[key].value
      }
    }
    return params
  }

  const handleOptionChange = (value, key) => {
    const fieldsCopy = deepCopy(values)
    if (key === 'ageRangeMin') {
      fieldsCopy.ageRangeMin.value = value[0]
      fieldsCopy.ageRangeMax.value = value[1]
    } else {
      fieldsCopy[key].value = value
    }

    setValues(fieldsCopy)
  }

  const handlePositionChange = (event) => {
    const fieldsCopy = deepCopy(values)
    const positionName = event.target.value
    fieldsCopy.positionAr.value = positionName
    setValues(fieldsCopy)
  }

  const sectorsOption = sectorList && sectorList.map(sector => {
    return { id: sector.id, label: sector.nameAr, value: sector.id }
  })

  const tierOptions = tierList && tierList.map(tier => {
    return { id: tier.id, label: tier.nameAr, value: tier.id }
  })

  const eduLevelOptions = educationLevelList && educationLevelList.map(eduLevel => {
    return { id: eduLevel.id, label: eduLevel.nameEn, value: eduLevel.id }
  })

  const profileTagOptions = profileTagList && profileTagList.map(tagOption => {
    return { label: tagOption, value: tagOption }
  })

  const genderOptions = genderList && genderList.map(gender => {
    return { id: gender.id, label: gender.nameAr, value: gender.id }
  })
  if (genderList && genderList.length) {
    genderOptions.push({ label: 'الـكـل', value: 'الكل' })
  }

  return (
    <Dialog size='sm' className='add-position-dialog' onClose={handleClose} open>
      <div className='dialog-header'>
        <Typography className='header-title'>
          {t('recommendation.addPosition')}
        </Typography>
        <img src={WHITE_CLOSE} alt='Close dialog' onClick={handleClose} className='close-button' />
      </div>
      <div className='dialog-content'>
        <div className='row'>
          <div className='filter-wrapper'>
            <Typography className='filter-label'>الهيئة</Typography>
            <SearchSelectInput
              type='blueBorderDropdown'
              value={entity}
              disabled
              options={[]}
              placeholder='الهيئة'
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper'>
            <Typography className='filter-label'>قـطـاع</Typography>
            <SelectInput
              id='sector'
              type='blueBorderDropdown'
              placeholder='قـطـاع'
              value={sector.value}
              handleChange={(option) => handleOptionChange(option.value, 'sector')}
              options={sectorsOption}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper'>
            <Typography className='filter-label'>إسم الجهة</Typography>
            <TextField
              classes={{ root: 'position-name-input' }}
              placeholder={t('common.writeHere')}
              margin='normal'
              variant='outlined'
              fullWidth
              value={positionAr.value}
              type='text'
              onChange={handlePositionChange}
            />
          </div>
        </div>

        <div className='row'>
          <div className='filter-wrapper' style={{ width: '30%', justifyContent: 'space-between' }}>
            {genderOptions.map((option, index) => (
              <RadioButton
                key={index}
                value={option.value}
                label={option.label}
                activeButtonValue={gender.value}
                handleChange={(value) => handleOptionChange(value, 'gender')}
              />
            ))}
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '70%' }}>
            <Typography className='slider-label'>السن</Typography>
            <RangeSlider
              type='default'
              color='#001c47'
              step={1}
              minValue={ageRangeMin.value}
              maxValue={ageRangeMax.value}
              handleChangeCallback={(value) => handleOptionChange(value, 'ageRangeMin')}
            />
          </div>
        </div>

        <div className='row'>
          <div className='radio-filter-wrapper'>
            <div className='icon-label'>
              <Typography className='filter-label'>نجم صاعد</Typography>
              <img src={RISING_STAR} alt='Rising star' className='rising-star' />
            </div>
            <CheckBox
              value={isRisingStar.value}
              handleChange={(value) => handleOptionChange(value, 'isRisingStar')}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '37%' }}>
            <Typography className='filter-label'>مستوى</Typography>
            <SelectInput
              id='tier'
              type='blueBorderDropdown'
              placeholder='مستوى'
              value={tier.value}
              handleChange={(option) => handleOptionChange(option.value, 'tier')}
              options={tierOptions}
            />
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='filter-wrapper' style={{ width: '37%' }}>
            <Typography className='filter-label'>علامة مميزة</Typography>
            <SelectInput
              id='tags'
              type='blueBorderDropdown'
              placeholder='علامة مميز'
              value={tags.value}
              handleChange={(option) => handleOptionChange(option.value, 'tags')}
              options={profileTagOptions}
            />
          </div>
        </div>

        <div className='row'>
          <div className='filter-wrapper' style={{ width: '100%' }}>
            <Typography className='filter-label'>المستوى التعليمي</Typography>
            <SelectInput
              id='educationLevel'
              type='blueBorderDropdown'
              placeholder='المستوى التعليمي'
              value={educationLevel.value}
              handleChange={(option) => handleOptionChange(option.value, 'educationLevel')}
              options={eduLevelOptions}
            />
          </div>
        </div>

        <div className='button-wrapper'>
          <Button
            variant='contained'
            className='add-button'
            onClick={handleAddPosition}
          >تطبيق
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
