import React from 'react'
import { useHistory } from "react-router-dom";
import { Typography, Paper } from '@material-ui/core'
import { AVATAR } from '../../../constants/image-reference'
import { imageBaseUrl } from '../../../utils/string.utils'

import './network.component.scss'

export default function NetworkProfileCard (props) {
  const { profile } = props
  let history = useHistory();
  const { nameAr, leadership, profileImage, id} = profile
  const { positions } = leadership

  const profileImagePath = profileImage ? profileImage.url : null
  const organization = positions.length ? positions[0].organization : ''
  const position = positions.length ? positions[0].position : ''

  const onNetworkClick = () => {
    history.push(`/profile-details/${id}`)
  }

  return (
    <Paper className='network-profile-card-container' elevation={3} onClick={onNetworkClick}>
      <div className='profile-image'>
        <div className='profile-avatar-container'>
          {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='profile-avatar' />}
          {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='profile-avatar' />}
        </div>
      </div>
      <div className='profile-info'>
        <div className='relation-wrapper'>
          <Typography className='relation'>{profile.relation || ''}</Typography>
        </div>
        <Typography className='name'>{nameAr || ''}</Typography>
        <Typography className='position'>
          {`${organization.nameAr || ''} - ${position.positionAr || ''}`}
        </Typography>
      </div>
    </Paper>
  )
}
