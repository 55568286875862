import { zipObject } from 'lodash'

const ACTION_TYPES = [
  // get profile list
  'GET_PROFILE_LIST',
  'GET_PROFILE_LIST_SUCCESS',
  'GET_PROFILE_LIST_FAILURE',
  'CLEAR_PROFILE_LIST',

  'SET_FILTER_STATE',
  'CLEAR_FILTER_STATE',

  // load more profile list
  'LOAD_MORE_PROFILES',
  'LOAD_MORE_PROFILES_SUCCESS',
  'LOAD_MORE_PROFILES_FAILURE',

  // reset reducer
  'RESET_REDUCER'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
