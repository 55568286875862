import logo from '../assets/images/logo.png'
import teo from '../assets/images/teo.svg'
import caretDown from '../assets/images/arrow_down.png'
import caretDownWhite from '../assets/images/caret-down-white.png'
import profiles from '../assets/images/profiles.svg'
import logoutIcon from '../assets/images/logout.svg'
import advancedSearch from '../assets/images/advanced-search.png'
import discover from '../assets/images/discover.png'
import searchProfile from '../assets/images/search-profile.png'
import ratingIcon from '../assets/images/rating-icon.png'
import risingStar from '../assets/images/rising_star.png'
import profileAvatar from '../assets/images/avatar.png'
import futuristic from '../assets/images/futuristic.png'
import bluePencil from '../assets/images/pencil.png'
import add from '../assets/images/add.png'
import whiteClose from '../assets/images/white-close-icon.png'
import grayClose from '../assets/images/close_white.png'
import blueClose from '../assets/images/close_blue.png'
import resize from '../assets/images/resize.png'
import users from '../assets/images/users.png'
import downArrow from '../assets/images/down_arrow.png'
import facebook from '../assets/images/fb.png'
import instagram from '../assets/images/insta.png'
import linkedin from '../assets/images/linkedin.png'
import twitter from '../assets/images/twitter.png'
import user from '../assets/images/user.png'
import personalVision from '../assets/images/vision.png'
import contribute from '../assets/images/contribute.png'
import plusGray from '../assets/images/plus-gray.png'
import pinkCircle from '../assets/images/pink-circle.png'
import pinkPencil from '../assets/images/pencil_pink.png'
import grayCircle from '../assets/images/gray-circle.png'
import backArrowWhite from '../assets/images/back-arrow-white.png'
import usersBlue from '../assets/images/user_blue.png'
import cancelBtn from '../assets/images/cancel.png'
import searchFilter from '../assets/images/search_filter.png'
import remove from '../assets/images/remove.png'
import sentRecom from '../assets/images/sent.png'
import leftArrowCircle from '../assets/images/left_arrow_circle.png'
import note from '../assets/images/note.png'
import edit from '../assets/images/edit.png'
import deleteImage from '../assets/images/delete.png'
import menu from '../assets/images/menu.png'
import pull from '../assets/images/pull.png'
import leftArrow from '../assets/images/left_arrow.png'
import closeCircle from '../assets/images/close_circle.png'
import searchIcon from '../assets/images/search.png'
import plusBlue from '../assets/images/plus.png'
import minusBlue from '../assets/images/minus.png'
import galaxy from '../assets/images/galaxy.png'
import searchBlue from '../assets/images/search-blue.png'
import profileCircle from '../assets/images/profile_circle.png'
import addCircle from '../assets/images/add_circle.png'
import insight from '../assets/images/insight.png'
import entityThumbnail from '../assets/images/entity-thumbnail.png'
import tierThumbnail from '../assets/images/tier-thumbnail.png'
import leftArrowCircle1 from '../assets/images/left_arrow_circle_1.png'
import rightArrowCircle from '../assets/images/right_arrow_circle.png'
import addBlue from '../assets/images/add_blue.png'
import closeWhite from '../assets/images/close_white_1.png'
import searchWhite from '../assets/images/search-white.png'
import downArrowGrey from '../assets/images/arrow_down_grey.png'
import usersFilled from '../assets/images/users-filled.png'
import recommendationsFilled from '../assets/images/recommendations-filled.png'
import insightsFilled from '../assets/images/insights-filled.png'
import advancedSearchFilled from '../assets/images/advanced-search-filled.png'
import checkedCheckbox from '../assets/images/checked-checkbox.png'
import unCheckedCheckbox from '../assets/images/unchecked-checkbox.png'
import bullet from '../assets/images/bullet.png'
import sectors from '../assets/images/sectors.png'
import sectorsFilled from '../assets/images/sectors-filled.png'

export const BULLET = bullet
export const CHECKED_CHECKBOX = checkedCheckbox
export const UNCHECKED_CHECKBOX = unCheckedCheckbox
export const USERS_FILLED = usersFilled
export const RECOMMENDATIONS_FILLED = recommendationsFilled
export const INSIGHTS_FILLED = insightsFilled
export const ADVANCED_SEARCH_FILLED = advancedSearchFilled
export const SEARCH_WHITE = searchWhite
export const CLOSE_WHITE = closeWhite
export const ADD_BLUE = addBlue
export const LEFT_ARROW_CIRCLE_BLUE = leftArrowCircle1
export const RIGHT_ARROW_CIRCLE = rightArrowCircle
export const ADD_CIRCLE = addCircle
export const PROFILE_CIRCLE = profileCircle
export const SEARCH_BLUE = searchBlue
export const PLUS_BLUE_ICON = plusBlue
export const MINUS_BLUE_ICON = minusBlue
export const REMOVE_ICON = remove
export const SEARCH_FILTER = searchFilter
export const CANCEL_BTN = cancelBtn
export const USERS_BLUE = usersBlue
export const BACK_ARROW_WHITE = backArrowWhite
export const BRAND_LOGO = logo
export const TEO = teo
export const CARET_DOWN = caretDown
export const CARET_DOWN_WHITE = caretDownWhite
export const PROFILE = profiles
export const LOGOUT = logoutIcon
export const ADVANCED_SEARCH = advancedSearch
export const DISCOVER = discover
export const SEARCH_PROFILE = searchProfile
export const RATING_ICON = ratingIcon
export const RISING_STAR = risingStar
export const AVATAR = profileAvatar
export const FUTURISTIC = futuristic
export const BLUE_PENCIL = bluePencil
export const ADD = add
export const WHITE_CLOSE = whiteClose
export const BLUE_CLOSE = blueClose
export const RESIZE = resize
export const USERS = users
export const DOWN_ARROW = downArrow
export const FACEBOOK = facebook
export const INSTAGRAM = instagram
export const LINKEDIN = linkedin
export const TWITTER = twitter
export const USER = user
export const PERSONAL_VISION = personalVision
export const CONTRIBUTE = contribute
export const PLUS_GRAY = plusGray
export const PINK_CIRCLE = pinkCircle
export const GRAY_CIRCLE = grayCircle
export const GRAY_CLOSE = grayClose
export const PINK_PENCIL = pinkPencil
export const SENT_RECOM = sentRecom
export const LEFT_ARROW_CIRCLE = leftArrowCircle
export const NOTE = note
export const EDIT = edit
export const DELETE = deleteImage
export const MENU = menu
export const PULL = pull
export const LEFT_ARROW = leftArrow
export const CLOSE_CIRCLE = closeCircle
export const SEARCH_ICON = searchIcon
export const GALAXY = galaxy
export const INSIGHT = insight
export const ENTITY_THUMBNAIL = entityThumbnail
export const TIER_THUMBNAIL = tierThumbnail
export const DOWN_ARROW_GREY = downArrowGrey
export const SECTORS = sectors
export const SECTORS_FILLED = sectorsFilled