import ActionTypes from './advanced-search.action-type'
import { apiAction } from '../../api/api.action-creator'

// Get profile list
export const getProfileList = (queryParams) => {
  return apiAction({
    method: 'GET',
    url: 'getProfileList',
    isSecureCall: true,
    onSuccess: getProfileListSuccess,
    onFailure: getProfileListFailure,
    label: ActionTypes.GET_PROFILE_LIST,
    queryParams
  })
}
export const getProfileListSuccess = (response) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.GET_PROFILE_LIST_SUCCESS,
      response: response.data
    })
  }
}
export const getProfileListFailure = (response) => {
  return {
    type: ActionTypes.GET_PROFILE_LIST_FAILURE,
    response
  }
}
export const clearProfileList = () => {
  return {
    type: ActionTypes.CLEAR_PROFILE_LIST
  }
}

// set filter state
export const setFilterState = (filters, searchQuery) => {
  return {
    type: ActionTypes.SET_FILTER_STATE,
    filters,
    searchQuery
  }
}
// clear filter state
export const clearFilterState = () => {
  return {
    type: ActionTypes.CLEAR_FILTER_STATE
  }
}

// load more profile list
export function loadMoreProfileList (queryParams) {
  return apiAction({
    method: 'GET',
    url: 'getProfileList',
    onSuccess: loadMoreProfileListSuccess,
    onFailure: loadMoreProfileListFailure,
    label: ActionTypes.LOAD_MORE_PROFILES,
    isSecureCall: true,
    queryParams
  })
}
export function loadMoreProfileListSuccess (response) {
  return {
    type: ActionTypes.LOAD_MORE_PROFILES_SUCCESS,
    response: response.data
  }
}
export function loadMoreProfileListFailure (response) {
  return {
    type: ActionTypes.LOAD_MORE_PROFILES_FAILURE,
    response
  }
}
