import { createMuiTheme } from '@material-ui/core/styles'
import {
  PRIMARY_LIGHT_COLOR, PRIMARY_MAIN_COLOR, PRIMARY_DARK_COLOR, PRIMARY_CONTRAST_COLOR,
  SECONDARY_LIGHT_COLOR, SECONDARY_MAIN_COLOR, SECONDARY_DARK_COLOR, SECONDARY_CONTRAST_COLOR
} from '../constants/color'

const theme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: {
      light: PRIMARY_LIGHT_COLOR,
      main: PRIMARY_MAIN_COLOR,
      dark: PRIMARY_DARK_COLOR,
      contrastText: PRIMARY_CONTRAST_COLOR
    },
    secondary: {
      light: SECONDARY_LIGHT_COLOR,
      main: SECONDARY_MAIN_COLOR,
      dark: SECONDARY_DARK_COLOR,
      contrastText: SECONDARY_CONTRAST_COLOR
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 13
  },
  MuiButtonBase: {
    disableRipple: true
  },
  MuiPaper: {
    elevation1: {
      boxShadow: 'none'
    },
    elevation2: {
      boxShadow: 'none'
    }
  },
  MuiInput: {
    input: {
      fontSize: '13px'
    }
  },
  MuiTab: {
    root: {
      minWidth: '100px'
    }
  },
  MuiPrivateTabScrollButton: {
    root: {
      opacity: 0.5
    }
  }
})

export default theme
