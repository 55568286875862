import React from 'react'
import { Button } from '@material-ui/core'

import classNames from 'classnames'

import './footer.component.scss'

export default function ButtonFooter (props) {
  const { activeIndex, entityTypeId, insightType, entityIds, filterType, insightDesc } = props
  const {
    t, cancelClickCallback, nextClickCallback, previousClickCallback, saveClickCallback
  } = props

  const handleCancelClick = () => {
    cancelClickCallback()
  }

  const handleNextClick = (tabIndex) => {
    nextClickCallback(tabIndex)
  }

  const handlePreviousClick = (tabIndex) => {
    previousClickCallback(tabIndex)
  }

  const handleSaveClick = () => {
    saveClickCallback()
  }

  const isTabOneNextButtonVisible = () => {
    let isVisible = false
    if (insightType.value === 'entity') {
      if (entityTypeId.value) {
        if (entityIds.value.length) {
          isVisible = true
        }
      } else {
        isVisible = true
      }
    } else if (insightType.value === 'tier') {
      isVisible = true
    }
    return isVisible
  }

  const isTabTwoNextButtonVisible = () => {
    let isVisible = false
    if (filterType.value === 'filter') {
      // if (tier.value && ageMax.value && ageMin.value && educationLevel.value.length && gender.value) {
      isVisible = true
      // }
    } else if (filterType.value === 'leader') {
      // if (leaders.value.length) {
      isVisible = true
      // }
    }
    return isVisible
  }

  const isTabThreeButtonVisible = () => {
    let isVisible = false
    if (insightDesc && insightDesc.length) {
      isVisible = true
    }
    return isVisible
  }

  const renderTabOneButtons = () => {
    const showTabOneNextButton = isTabOneNextButtonVisible()
    return (
      <div className='tab-one-buttons'>
        {showTabOneNextButton ? renderEnabledNextButton(1) : renderDisabledNextButton()}
        <Button
          variant='outlined'
          className='outlined-button'
          onClick={handleCancelClick}
        >{t('common.cancel')}
        </Button>
      </div>
    )
  }

  const renderTabTwoButtons = () => {
    const showTabTwoNextButton = isTabTwoNextButtonVisible()
    return (
      <div className='tab-two-buttons'>
        {showTabTwoNextButton ? renderEnabledNextButton(2) : renderDisabledNextButton()}
        <Button
          variant='outlined'
          className='outlined-button'
          onClick={() => handlePreviousClick(0)}
        >{t('common.back')}
        </Button>
      </div>
    )
  }

  const renderTabThreeButtons = () => {
    const showTabThreeSubmitButton = isTabThreeButtonVisible()
    return (
      <div className='tab-three-buttons'>
        {showTabThreeSubmitButton ? (
          <Button
            variant='contained'
            className='contained-button'
            onClick={handleSaveClick}
          >{t('common.application')}
          </Button>)
          : (
            <Button
              variant='contained'
              className='contained-button disabled'
            >{t('common.application')}
            </Button>
          )}
        <Button
          variant='outlined'
          className='outlined-button'
          onClick={() => handlePreviousClick(1)}
        >{t('common.back')}
        </Button>
      </div>
    )
  }

  const renderEnabledNextButton = (tabIndex) => {
    return (
      <Button
        variant='contained'
        className='contained-button'
        onClick={() => handleNextClick(tabIndex)}
      >{t('common.next')}
      </Button>
    )
  }

  const renderDisabledNextButton = () => {
    return (
      <Button
        variant='contained'
        className='contained-button disabled'
      >{t('common.next')}
      </Button>
    )
  }

  return (
    <div className='footer'>
      <ul className='indicator-list'>
        <li
          className={classNames(['indicator', {
            'active-indicator': activeIndex === 0,
            'inactive-indicator': activeIndex !== 0
          }])}
        />
        <li
          className={classNames(['indicator', {
            'active-indicator': activeIndex === 1,
            'inactive-indicator': activeIndex !== 1
          }])}
        />
        <li
          className={classNames(['indicator', {
            'active-indicator': activeIndex === 2,
            'inactive-indicator': activeIndex !== 2
          }])}
        />
      </ul>
      <div className='button-wrapper'>
        {activeIndex === 0
          ? renderTabOneButtons()
          : activeIndex === 1
            ? renderTabTwoButtons()
            : activeIndex === 2
              ? renderTabThreeButtons()
              : null}
      </div>
    </div>
  )
}
