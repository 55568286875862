import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Dialog } from '@material-ui/core'
import { CLOSE_CIRCLE, AVATAR, RISING_STAR, FUTURISTIC, USERS } from '../../constants/image-reference'
import { imageBaseUrl, getMainTag } from '../../utils/string.utils'

import Slider from 'react-slick'

import './media-library.component.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function MediaLibrary (props) {
  const [mediaImages, setMediaImages] = useState([])

  const { profileDetails, t, match, history } = props
  const { nameAr, tags, leadership, isRisingStar, profileImage, media } = profileDetails

  useEffect(() => {
    constructMediaImages()
  }, [])

  const handleClose = () => {
    props.onClose()
  }

  const handleNetworkClick = () => {
    const profileId = match.params.profileId
    history.push(`/profiles/${profileId}/network`)
  }

  // returns images array
  const constructMediaImages = () => {
    const mediaProfiles = []
    if (media && media.mediaLinks.length) {
      media.mediaLinks.map(data => mediaProfiles.push({ url: data.url }))
      if (media.profileImage.image) {
        mediaProfiles.splice(1, 0, { url: media.profileImage.image })
      }
    } else {
      mediaProfiles.push({ url: media.profileImage.image })
    }
    setMediaImages(mediaProfiles)
  }

  const { positions } = leadership
  const organization = positions.length ? positions[0].organization : ''
  const position = positions.length ? positions[0].position : ''
  const profileImagePath = profileImage ? profileImage.url : null

  var settings = {
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: mediaImages.length < 4 ? mediaImages.length : 3,
    arrows: false
  }

  return (
    <Dialog
      maxWidth='lg'
      classes={{ paper: 'media-library-container' }}
      onClose={handleClose}
      open
    >
      <div className='media-library'>
        <img src={CLOSE_CIRCLE} alt='Close' className='close-icon' onClick={handleClose} />
        <div className='content'>
          <div className='profile-header-container'>
            <div className='profile-avatar-container'>
              {!profileImagePath && <img src={AVATAR} alt='Profile avatar' className='profile-avatar' />}
              {profileImagePath && <img src={`${imageBaseUrl}${profileImagePath}`} alt='' onerror="this.style.display='none'" className='profile-avatar' />}
            </div>
          </div>
          <div className='basic-info'>
            <div className='name-wrapper'>
              {isRisingStar && <img src={RISING_STAR} alt='Rising star' className='rising-star-icon' />}
              <Typography className='name'>{nameAr}</Typography>
            </div>
            <div className='professional-info-wrapper'>
              <div className='knowledge-network-wrapper' onClick={handleNetworkClick}>
                <img src={USERS} alt='Users' className='users-icon' />
                <Typography className='text'>شـبـكة المـعارف</Typography>
              </div>
              <Typography className='designation'>{position ? position.positionAr : ''} -</Typography>
              <Typography className='organisation'>{organization ? organization.nameAr : ''}</Typography>
              {tags &&
                <div className='futuristic'>
                  <img src={FUTURISTIC} alt='Futuristic' className='futuristic-badge' />
                  <Typography className='text'>{getMainTag(tags)}</Typography>
                </div>}
            </div>
          </div>
          <div className='carousel-view'>
            <Slider {...settings}>
              {mediaImages.map((data, index) => (
                <div className='profile-image-container' key={index}>
                  <img src={`${imageBaseUrl}${data.url}`} alt='Futuristic' className='profile-image' />
                </div>
              ))}
            </Slider>
          </div>
          <div className='footer-wrapper'>
            <Typography className='name-info'>{t('common.profilePhoto', { name: nameAr })}</Typography>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default withRouter(MediaLibrary)
