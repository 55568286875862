import { zipObject } from 'lodash'

const ACTION_TYPES = [

  // add org
  'ADD_ORG',
  'ADD_ORG_SUCCESS',
  'ADD_ORG_FAILURE',
  'CLEAR_ADD_ORG_VAR',

  // get org details
  'GET_ORG_DETAILS',
  'GET_ORG_DETAILS_SUCCESS',
  'GET_ORG_DETAILS_FAILURE',
  'CLEAR_GET_ORG_DETAILS_VAR',

  // get org list
  'GET_ORG_LIST',
  'GET_ORG_LIST_SUCCESS',
  'GET_ORG_LIST_FAILURE',

  // get org list by sector id
  'GET_ORG_LIST_BY_SECTOR_ID',
  'GET_ORG_LIST_BY_SECTOR_ID_SUCCESS',
  'GET_ORG_LIST_BY_SECTOR_ID_FAILURE',

  // get org profile list
  'GET_ORG_PROFILE_LIST',
  'GET_ORG_PROFILE_LIST_SUCCESS',
  'GET_ORG_PROFILE_LIST_FAILURE',
  'CLEAR_ORG_PROFILE_LIST',

  // get org types
  'GET_ORG_TYPES',
  'GET_ORG_TYPES_SUCCESS',
  'GET_ORG_TYPES_FAILURE',

  // add position
  'ADD_POSITION',
  'ADD_POSITION_SUCCESS',
  'ADD_POSITION_FAILURE',
  'CLEAR_ADD_POSITION_VAR',

  // get position list
  'GET_POSITION_LIST',
  'GET_POSITION_LIST_SUCCESS',
  'GET_POSITION_LIST_FAILURE',

  // get sector list
  'GET_SECTOR_LIST',
  'GET_SECTOR_LIST_SUCCESS',
  'GET_SECTOR_LIST_FAILURE',

  // get Users List
  'GET_USER_LIST',
  'GET_USER_LIST_SUCCESS',
  'GET_USER_LIST_FAILURE',

  // get board list
  'GET_BOARD_LIST',
  'GET_BOARD_LIST_SUCCESS',
  'GET_BOARD_LIST_FAILURE',

  // get board member list
  'GET_BOARD_MEMBER_LIST',
  'GET_BOARD_MEMBER_LIST_SUCCESS',
  'GET_BOARD_MEMBER_LIST_FAILURE',

  // get Tier list
  'GET_TIER_LIST',
  'GET_TIER_LIST_SUCCESS',
  'GET_TIER_LIST_FAILURE',

  // get education level list
  'GET_EDUCATION_LEVEL_LIST',
  'GET_EDUCATION_LEVEL_LIST_SUCCESS',
  'GET_EDUCATION_LEVEL_LIST_FAILURE',

  // get gender list
  'GET_GENDER_LIST',
  'GET_GENDER_LIST_SUCCESS',
  'GET_GENDER_LIST_FAILURE',

  // get tag list
  'GET_PROFILE_TAG_LIST',
  'GET_PROFILE_TAG_LIST_SUCCESS',
  'GET_PROFILE_TAG_LIST_FAILURE',

  //get tires 
  'GET_TIERS_BY_ORG',
  'GET_TIERS_BY_ORG_SUCCESS',
  'GET_TIERS_BY_ORG_FAILURE',

  //get profiles 
  'GET_PROFILES',
  'GET_PROFILES_SUCCESS',
  'GET_PROFILES_FAILURE',

  // Set previous path
  'SET_PREVIOUS_PATH',

  // reset reducer
  'RESET_REDUCER'
]

export default zipObject(ACTION_TYPES, ACTION_TYPES)
