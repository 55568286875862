import React, { useState, useEffect } from 'react'
import { BOARD } from '../../../constants/misc'
import { deepCopy } from '../../../utils/object.utils'
import { Typography, Button } from '@material-ui/core'
import { getUserType } from '../../../utils/string.utils'
import {
  ADD_CIRCLE, BRAND_LOGO, DELETE, EDIT, RIGHT_ARROW_CIRCLE, LEFT_ARROW_CIRCLE_BLUE, SEARCH_WHITE
} from '../../../constants/image-reference'

import classNames from 'classnames'
import TierVisualization from '../../../common/tier-visualization/tier-visualization.component'
import EntityVisualization from '../../../common/entity-visualization/entity-visualization.component'

import './insight-preview.component.scss'

const initialVisualData = {
  insightType: '',
  visualDetails: []
}

export default function InsightPreview (props) {
  const {
    t, history, badgeClickCallback, fetchUserCallback, addInsightCallback, removeInsightCallback,
    sendAnalyzesCallback, setActiveInsightCallback, showSearchedProfileCallback, setRecommendationAttributesCallback,
    setPreviousPathCallback, setRecomDetailsCallback, setRecommendationResultsPageConfigCallback
  } = props
  const { activeInsight, activeInsightList, isHhOrAdHocUser, activeInsightIndx, userSession } = props

  const [isHybridView, setHybridView] = useState(false)
  const [visualData, setVisualData] = useState(initialVisualData)
  const [showDetails, setDetails] = useState(true)
  const userType = getUserType(userSession)

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    const { profilesCount, insightType, entityIds } = activeInsight
    if (profilesCount && profilesCount.length) {
      if (insightType === 'tier') {
        setHybridView(false)
        constructTierVisualData()
      } else if (insightType === 'hybrid') {
        setHybridView(true)
        constructHybridVisualData()
      } else if (entityIds && entityIds.length) {
        setHybridView(false)
        constructEntityVisualData()
      } else {
        setHybridView(false)
        constructEntityTypeVisualData()
      }
    }

    setDetails(!showDetails)
  }, [activeInsight])

  useEffect(() => {
    setTimeout(() => {
      setDetails(true)
    }, 500)
  }, [showDetails])

  const fetchUsers = () => {
    fetchUserCallback()
  }

  const constructEntityVisualData = () => {
    const { profilesCount, leaderIds, insightType } = activeInsight
    const visualDataCopy = deepCopy(visualData)
    visualDataCopy.insightType = insightType
    const visualDataArray = []
    profilesCount.map(data => {
      const results = {
        entityId: data.organization.id,
        entityName: data.organization.nameAr,
        profilesCount: data.profilesCount,
        profiles: []
      }
      visualDataArray.push(results)
    })
    visualDataCopy.visualDetails = visualDataArray
    const entityIds = visualDataCopy.visualDetails.map((data, index) => {
      return data.entityId
    })
    if (leaderIds && leaderIds.length) {
      leaderIds.map(profileData => {
        const profile = profileData
        const organization = profile.organization
        if (organization && entityIds.includes(organization.id)) {
          const entityIndex = entityIds.indexOf(organization.id)
          visualDataCopy.visualDetails[entityIndex].profiles.push(profile)
        }
      })
    }
    setVisualData(visualDataCopy)
  }

  const constructTierVisualData = () => {
    const { profilesCount, leaderIds, insightType } = activeInsight
    const visualDataArray = []
    const visualDataCopy = deepCopy(visualData)
    visualDataCopy.insightType = insightType
    profilesCount.map(data => {
      const results = {
        tierId: data.tier.id,
        tierName: data.tier.nameEn,
        profilesCount: data.profilesCount,
        profiles: []
      }
      visualDataArray.push(results)
    })
    visualDataCopy.visualDetails = visualDataArray
    const tierIds = visualDataCopy.visualDetails.map((data, index) => {
      return data.tierId
    })
    if (leaderIds && leaderIds.length) {
      leaderIds.map(profileData => {
        const profile = profileData
        if (profile.tier && tierIds.includes(profile.tier.id)) {
          const tierIndex = tierIds.indexOf(profile.tier.id)
          visualDataCopy.visualDetails[tierIndex].profiles.push(profile)
        }
      })
    }
    setVisualData(visualDataCopy)
  }

  const constructEntityTypeVisualData = () => {
    const { profilesCount, leaderIds, insightType } = activeInsight
    const visualDataCopy = deepCopy(visualData)
    visualDataCopy.insightType = insightType
    const visualDataArray = []
    profilesCount.map(data => {
      const results = {
        entityId: data.id,
        entityName: data.typeAr,
        profilesCount: data.profilesCount,
        profiles: []
      }
      visualDataArray.push(results)
    })
    visualDataCopy.visualDetails = visualDataArray
    const entityTypeIds = visualDataCopy.visualDetails.map((data, index) => {
      return data.entityId
    })
    if (leaderIds && leaderIds.length) {
      leaderIds.map(profileData => {
        const profile = profileData
        const organization = profile.organization
        if (organization && organization.type && entityTypeIds.includes(organization.type.id)) {
          const entityTypeIndex = entityTypeIds.indexOf(organization.type.id)
          visualDataCopy.visualDetails[entityTypeIndex].profiles.push(profile)
        }
      })
    }
    setVisualData(visualDataCopy)
  }

  const constructHybridVisualData = () => {
    const { profilesCount, leaderIds, insightType, profiles } = activeInsight
    const visualDataCopy = deepCopy(visualData)
    visualDataCopy.insightType = insightType
    const visualDataArray = []
    profilesCount.map(data => {
      const results = {
        entityId: data.id,
        entityName: data.typeAr,
        profilesCount: data.profilesCount,
        profiles: []
      }
      visualDataArray.push(results)
    })
    visualDataCopy.visualDetails = visualDataArray
    if (leaderIds && leaderIds.length) {
      visualDataCopy.visualDetails[0].profiles = profiles
    }
    setVisualData(visualDataCopy)
  }

  const handleBadgeClick = (insight) => {
    if (activeInsight.id !== insight.id) {
      setVisualData(initialVisualData)
      badgeClickCallback(insight)
    }
  }

  const handleSubmitAnalyzes = () => {
    sendAnalyzesCallback()
  }

  const handleRemoveClick = () => {
    const insightId = activeInsight.id
    removeInsightCallback(insightId)
  }

  const handleEditClick = () => {
    const insightId = activeInsight.id
    history.push(`/insights/${insightId}/edit`)
  }

  const handleNextClick = () => {
    setActiveInsightCallback(activeInsightIndx + 1)
  }

  const handlePreviousClick = () => {
    setActiveInsightCallback(activeInsightIndx - 1)
  }

  const navigateToProfile = (profileId) => {
    setPreviousPathCallback()
    props.history.push(`/profile-details/${profileId}`)
  }

  const getRecomDetail = (recomDetails) => {
    return { isSent: recomDetails.isSent, isDraft: recomDetails.isDraft, isDeleted: recomDetails.isDeleted }
  }

  const getEntity = (entity) => {
    return { id: entity.id, label: entity.nameAr, value: entity.id }
  }

  const getPosition = (position) => {
    return [{ id: position.id, label: position.positionAr, value: position.id }]
  }

  const handleShowCandidateClick = () => {
    const { id, entity, position, recommendationType } = activeInsight
    if (recommendationType === BOARD) {
      history.push(`/recommendation/${id}/members`)
    } else {
      setRecommendationAttributesCallback(getEntity(entity), getPosition(position))
      setRecomDetailsCallback(getRecomDetail(activeInsight))
      setRecommendationResultsPageConfigCallback({ isEdit: false, recomId: id, userType })
      history.push('/position-recommendation-results')
    }
  }

  const renderInsightBadge = (insight) => {
    const isActiveInsight = activeInsight.id === insight.id
    return (
      <div
        key={insight.id}
        className={classNames(['insight-badge', {
          'active-badge': isActiveInsight
        }])}
        onClick={() => handleBadgeClick(insight)}
      >
        <Typography className='badge-text'>{insight.title}</Typography>
      </div>
    )
  }

  const renderEntityVisualization = () => {
    console.log('visualData::::isHybridView', visualData,isHybridView);
    return (
      <div
        className={classNames(['entity-insight-visualization-view', {
          'hybrid-insight-viz-view': isHhOrAdHocUser
        }])}
        style={{
          marginTop: isHhOrAdHocUser ? '80px' : '40px'
        }}
      >
        <EntityVisualization
          {...props}
          visualData={visualData}
          isHybridView={isHybridView}
          viewProfileCallback={(profileId) => navigateToProfile(profileId)}
        />
      </div>
    )
  }

  const renderTierVisualization = () => {
    return (
      <div className='tier-insight-visualization-view' style={{ marginBottom: isHhOrAdHocUser ? '60px' : '10px' }}>
        <TierVisualization
          {...props}
          visualData={visualData}
          viewProfileCallback={(profileId) => navigateToProfile(profileId)}
        />
      </div>
    )
  }

  const renderTeoViewButtonsWrapper = () => {
    return (
      <div className='buttons-wrapper'>
        {activeInsight.isSent ? renderInsightSendButton() : renderShareInsightButton()}
        <div className='icon-wrapper'>
          <img src={EDIT} className='edit-icon' alt='Edit insight' onClick={handleEditClick} />
          <img src={DELETE} className='remove-icon' alt='Remove insight' onClick={handleRemoveClick} />
        </div>
      </div>
    )
  }

  const renderHhViewButtons = () => {
    if (visualData.insightType === 'hybrid') {
      return (
        <div className='buttons-wrapper' style={{ marginRight: '50px' }}>
          <Button
            variant='outlined'
            className='share-insight-button'
            onClick={() => handleShowCandidateClick()}
          >{t('insights.showCandidate')}
          </Button>
        </div>
      )
    }
    return null
  }

  const renderShareInsightButton = () => {
    return (
      <Button
        variant='outlined'
        className='share-insight-button'
        onClick={handleSubmitAnalyzes}
      >{t('insights.submitAnalyzes')}
      </Button>
    )
  }

  const renderInsightSendButton = () => {
    return (
      <Button
        variant='contained'
        className='sent-successfully-button'
      >{t('insights.sentSuccessfully')}
      </Button>
    )
  }

  const renderPagination = () => {
    return (
      <div className='pagination-wrapper'>
        <div className='pagination'>
          <img
            src={RIGHT_ARROW_CIRCLE}
            alt='Next insight'
            className={classNames(['next-insight-arrow', {
              'disabled-button': (activeInsightIndx === activeInsightList.length - 1)
            }])}
            onClick={handleNextClick}
          />
          <Typography className='active-insight-index'>
            {`${activeInsightIndx + 1}/${activeInsightList.length}`}
          </Typography>
          <img
            src={LEFT_ARROW_CIRCLE_BLUE}
            alt='Previous insight'
            className={classNames(['previous-insight-arrow', {
              'disabled-button': (activeInsightIndx === 0)
            }])}
            onClick={handlePreviousClick}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='insight-preview-container'>
      <div className='header-wrapper' style={{ justifyContent: isHhOrAdHocUser ? 'flex-end' : 'space-between' }}>
        {!isHhOrAdHocUser && (
          <div className='insight-badge-list'>
            {activeInsightList.map(insight => renderInsightBadge(insight))}
            <div className='add-insight-icon-wrapper'>
              <img
                src={ADD_CIRCLE}
                alt='Add insight'
                className='add-insight-icon'
                onClick={addInsightCallback}
              />
            </div>
          </div>
        )}
        <div className='search-icon-wrapper'>
          <div className='search-icon'>
            <img
              alt='Add insight'
              src={SEARCH_WHITE}
              className='search-profile-icon'
              onClick={showSearchedProfileCallback}
            />
          </div>
        </div>
      </div>
      <div className='content-wrapper'>
        <div
          className={classNames(['insight-details', {
            'show-details': showDetails,
            'hide-details': !showDetails
          }])}
          style={{ marginTop: (visualData.insightType === 'tier') ? '-140px' : '-200px' }}
        >
          <div className='title-wrapper'>
            <img src={BRAND_LOGO} className='brand-logo' alt='Brand icon' />
            <Typography className='insight-title'>{activeInsight.headline || activeInsight.title || ''}</Typography>
          </div>
          {isHhOrAdHocUser ? renderHhViewButtons() : renderTeoViewButtonsWrapper()}
        </div>
        {visualData.insightType === 'entity' ? renderEntityVisualization() : null}
        {visualData.insightType === 'hybrid' ? renderEntityVisualization() : null}
        {visualData.insightType === 'tier' ? renderTierVisualization() : null}
      </div>
      {isHhOrAdHocUser && renderPagination()}
    </div>
  )
}
