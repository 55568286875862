import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography, Button } from '@material-ui/core'
import { PROFILE, INSIGHT } from '../../constants/image-reference'
import { getRecom, loadMoreRecom, deleteRecom } from '../recommendation/recommendation.action-creator'
import { getUserType } from '../../utils/string.utils'
import { HH, ADHOC, BOARD } from '../../constants/misc'
import { getInsightList, deleteInsight } from '../insights/insights.action-creator'

import Loader from '../../common/loader/loader.component'
import RecomCard from '../../common/recom-card/recom-card.component'
import InsightCard from '../../common/insight-card/insight-card.component'

import './sent-list.component.scss'

function SentRecommendation (props) {
  const { recom, isLoading, t, userSession, insightList, getInsightList, insightLoading, deleteInsight } = props
  const userType = getUserType(userSession)

  // component mount
  useEffect(() => {
    fetchRecom()
    fetchInsightList()
  }, [])

  const fetchRecom = () => {
    props.getRecom({
      queryParams: {
        limit: 3,
        offset: 0,
        isSent: true,
        isDeleted: false
      }
    })
  }

  const fetchInsightList = () => {
    getInsightList({
      isDeleted: false,
      isSent: true,
      limit: 3,
      offset: 0
    })
  }

  const handleArchiveClick = () => {
    props.history.push('/sent-recommendation')
  }

  const handleInsightArchiveClick = () => {
    props.history.push('/insights')
  }

  const deleteRecom = (recomId) => {
    props.deleteRecom({
      queryParams: {
        recommendationId: recomId
      },
      token: {
        token: `jwt ${props.token}`
      }
    })
  }

  const removeInsight = (insightId) => {
    deleteInsight({ insightId })
  }

  const renderRecomList = () => {
    return (
      <div className='recom-card-view' style={{ paddingTop: '140px' }}>
        {recom.map((data, index) => (
          <RecomCard
            {...props}
            index={index}
            key={index}
            sentRecomPage
            recomDetails={data}
            deleteRecomCallback={(recomId) => deleteRecom(recomId)}
            onClickCallback={(recomDetails) => clickHandle(recomDetails)}
          />
        ))}
      </div>
    )
  }

  const renderInsightList = () => {
    return (
      <div className='recom-card-view'>
        {insightList.map((data, index) => {
          if (index < 2) {
            return (
              <InsightCard
                {...props}
                index={index}
                key={index}
                sentRecomPage
                recomDetails={data}
                deleteInsightCallback={(insightId) => removeInsight(insightId)}
                onClickCallback={(recomDetails) => clickHandle(recomDetails)}
              />
            )
          }
        })}
      </div>
    )
  }

  const clickHandle = (recomDetails) => {
    const { id, recommendationType } = recomDetails
    if (userType === HH || userType === ADHOC) {
      if (recommendationType === BOARD) {
        props.history.push(`/recommendation/${id}/members`)
      }
    }
  }

  const renderEmptyState = () => {
    return (
      <div className='empty-state-container'>
        <Typography className='empty-state-text'>
          {t('recommendation.noRecommendationsFound')}
        </Typography>
      </div>
    )
  }

  return (
    <div className='sent-list-container'>
      <div className='sent-recom'>
        <div className='header-container' style={{ position: 'fixed' }}>
          <div className='title-container'>
            <div className='nomination-title'>
              <img src={PROFILE} alt='Users' className='user-icon' />
              <Typography className='nomination-text'>{t('footer.nominations')}</Typography>
            </div>
            <Button onClick={handleArchiveClick} className='archive-button' variant='outlined' color='secondary'>
              {t('common.viewAll')}
            </Button>
          </div>
        </div>
        {isLoading ? <Loader color='white' /> : (recom && recom.length) ? renderRecomList() : renderEmptyState()}
        <div className='header-container'>
          <div className='title-container'>
            <div className='nomination-title'>
              <img src={INSIGHT} alt='Insights' className='user-icon' />
              <Typography className='nomination-text'>{t('insights.insights')}</Typography>
            </div>
            <Button onClick={handleInsightArchiveClick} className='archive-button' variant='outlined' color='secondary'>
              {t('common.viewAll')}
            </Button>
          </div>
        </div>
        {insightLoading ? <Loader color='white' /> : (insightList && insightList.length) ? renderInsightList() : renderEmptyState()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // Recommendation
    recom: state.recommendation.recom,
    isLoading: state.recommendation.recomLoading,
    userSession: state.auth.userSession,

    // Insights
    insightList: state.insights.insightList,
    insightLoading: state.insights.isLoading
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, { getRecom, loadMoreRecom, deleteRecom, getInsightList, deleteInsight })(SentRecommendation))
