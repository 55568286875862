import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateOptions } from '../../i18n/index'
import { Typography } from '@material-ui/core'
import { AVATAR, EDIT, DELETE } from '../../constants/image-reference'
import {
  setRecommendationAttributes, setRecommendationDetail, setRecommendationResultsPageConfig
} from '../../app/recommendation/recommendation.action-creator'
import { useSwipeable } from 'react-swipeable'
import { getUserType, imageBaseUrl } from '../../utils/string.utils'
import { HH, ADHOC, BOARD, POSITION } from '../../constants/misc'

import moment from 'moment'
import AlertDialog from '../alert-dialog/alert-dialog.component'

import './recom-card.component.scss'

function RecomCard (props) {
  const [expanded, setExpanded] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const {
    t, recomDetails, sentRecomPage, userSession, history, setRecommendationResultsPageConfig, deleteRecomCallback,
    setRecommendationAttributes, setRecommendationDetail
  } = props
  const { profiles, position, entity, board, createdAt, id, recommendationType, boardPositions } = recomDetails
  const userType = getUserType(userSession)

  const onSwipeRight = () => {
    if (sentRecomPage && (userType !== HH && userType !== ADHOC)) {
      setExpanded(true)
    }
  }

  const onSwipeLeft = () => {
    setExpanded(false)
  }

  const deleteRecom = () => {
    setExpanded(false)
    setShowConfirmModal(false)
    deleteRecomCallback(id)
  }

  const handleEditClick = () => {
    if (recommendationType === POSITION) {
      setRecommendationAttributes(getEntity(entity), getPosition(position), {}, false)
      setRecommendationDetail(getRecomDetail(recomDetails))
      setRecommendationResultsPageConfig({ isEdit: true, recomId: id, userType })
      history.push('/position-recommendation-results')
    } else {
      setRecommendationAttributes(getEntity(entity), getBoard(board), getBoardPositions(boardPositions), false)
      setRecommendationDetail(getRecomDetail(recomDetails))
      setRecommendationResultsPageConfig({ isEdit: true, recomId: id, userType })
      history.push('/board-recommendation-results')
    }
  }

  const handleCardClick = () => {
    if (userType === HH || userType === ADHOC) {
      if (recommendationType === BOARD) {
        history.push(`/recommendation/${id}/members`)
      } else {
        setRecommendationAttributes(getEntity(entity), getPosition(position))
        setRecommendationDetail(getRecomDetail(recomDetails))
        setRecommendationResultsPageConfig({ isEdit: false, recomId: id, userType })
        history.push('/position-recommendation-results')
      }
    } else {
      if (!expanded && !recomDetails.isDeleted) {
        handleEditClick()
      }
    }
  }

  const getEntity = (entity) => {
    return { id: entity.id, label: entity.nameAr, value: entity.id }
  }

  const getPosition = (position) => {
    return [{ id: position.id, label: position.positionAr, value: position.id }]
  }

  const getBoard = (board) => {
    return [{ id: board.id, label: board.nameAr || board.nameEn, value: board.id }]
  }

  const getBoardPositions = boardPositions => {
    return boardPositions.map(position => {
      return {
        id: position.id,
        label: position.positionAr || position.positionEn,
        value: position.id,
        profileLimitCount: position.requiredNumberOfProfiles || 1
      }
    })
  }

  const getRecomDetail = (recomDetails) => {
    return { isSent: recomDetails.isSent, isDraft: recomDetails.isDraft, isDeleted: recomDetails.isDeleted }
  }

  const renderProfileImages = () => {
    return (
      <div className='recom-profiles-wrapper' onClick={handleCardClick}>
        <div className='recom-profiles'>
          {profiles.length
            ? (
              <div className='profile-image-container'>
                {profiles[0].url
                  ? <img src={`${imageBaseUrl}${profiles[0].url}`} alt='profile icon' className='profile-image' />
                  : <img src={AVATAR} alt='profile icon' className='profile-image' />}
              </div>)
            : null}
          {profiles.length > 1
            ? (
              <div className='profile-image-container' style={{ marginRight: '-35px' }}>
                {profiles[1].url
                  ? <img src={`${imageBaseUrl}${profiles[1].url}`} alt='profile icon' className='profile-image' />
                  : <img src={AVATAR} alt='profile icon' className='profile-image' />}
              </div>)
            : null}
          {profiles.length > 2
            ? (
              <div className='profile-image-container' style={{ marginRight: '-35px' }}>
                {profiles[2].url
                  ? <img src={`${imageBaseUrl}${profiles[2].url}`} alt='profile icon' className='profile-image' />
                  : <img src={AVATAR} alt='profile icon' className='profile-image' />}
              </div>)
            : null}
          {profiles.length > 3
            ? (
              <div className='more-image-container' style={{ marginRight: '-35px' }}>
                <Typography className='more-text'>{profiles.length - 3}+</Typography>
              </div>)
            : null}
        </div>
      </div>
    )
  }

  const renderAlertDialog = () => {
    return (
      <AlertDialog
        {...props}
        type='warning'
        buttonText={t('common.ok')}
        handleConfirm={deleteRecom}
        handleClose={() => setShowConfirmModal(false)}
      >
        {t('recommendation.areYouSureWantToDelete')}
      </AlertDialog>
    )
  }

  const handlers = useSwipeable({
    onSwipedRight: () => onSwipeRight(),
    onSwipedLeft: () => onSwipeLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <div style={{ width: expanded ? '100%' : '60%' }}>
      <div className='recom-card-container'>
        <div className='recom-card' {...handlers}>
          <div className='recom-details' onClick={handleCardClick}>
            <Typography className='title'>{t('recommendation.candidatesForThePosition')}</Typography>
            <Typography className='position'>{position ? position.positionAr : ''}</Typography>
            <Typography className='position'>{entity ? entity.nameAr : ''}</Typography>
            <Typography className='date'>{t('recommendation.createdOn', { date: moment(createdAt).format('DD-MM-YYYY') })}</Typography>
          </div>
          {renderProfileImages()}
        </div>
        {expanded && (
          <div className='recom-action'>
            <div className='action-button'>
              <img src={EDIT} alt='Edit' className='edit-icon' onClick={handleEditClick} />
              <Typography className='action-text'>{t('recommendation.edit')}</Typography>
            </div>
            <div className='action-button'>
              <img src={DELETE} alt='Delete' className='delete-icon' onClick={() => setShowConfirmModal(true)} />
              <Typography className='action-text'>{t('recommendation.delete')}</Typography>
            </div>
          </div>)}
        {showConfirmModal && renderAlertDialog()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userSession: state.auth.userSession
  }
}

export default withTranslation(['translations'], translateOptions)(connect(
  mapStateToProps, {
    setRecommendationResultsPageConfig,
    setRecommendationAttributes,
    setRecommendationDetail
  })(RecomCard))
