import axios from 'axios'
import ActionTypes from '../constants/action-type'
import { apiError, apiStart, apiEnd } from '../api/api.action-creator'
// import { terminateUserSessions } from '../actions/auth'
import { apis } from '../api/api.constants'
import { snakeToCamel, camelToSnake, getErrorMessage } from '../utils/string.utils'
import { transformObjectKeys, deepCopy } from '../utils/object.utils'
import { getAuthToken, clearActiveInterval } from '../helpers/auth'
import { regex } from '../utils/validation.utils'

export const baseURL = process.env.REACT_APP_API_ENDPOINT
  ? `${process.env.REACT_APP_API_ENDPOINT}/api/`
  : `${window.location.origin}/api/`

 // export const baseURL = 'http://koku.someshwara.com:9050/api/'
//  export const baseURL = 'https://hareesh.pagekite.me:8000/api/'
function replaceEmbeddedUrlParam (requestUrl, requestData) {
  const regExp = regex.stringBetweenCurlyBraces
  while (requestUrl.match(regExp)) {
    const matchData = requestUrl.match(regExp)
    requestUrl = requestUrl.replace(matchData[0], requestData[matchData[1]])
    delete requestData[matchData[1]]
  }
  const returnUrl = requestUrl

  return returnUrl
}

function jsonToQueryString (queryParams) {
  if (Object.keys(queryParams).length) {
    return '?' +
    Object.keys(queryParams).map((key) => {
      if (Array.isArray(queryParams[key])) {
        var str = ''
        queryParams[key].forEach(function (item) {
          str += `${encodeURIComponent(key)}=${encodeURIComponent(item)}&`
        })
        return str
      } else {
        const queryParamsValue = queryParams[key]
        if (queryParamsValue !== undefined) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        }
      }
    }).join('&')
  }
  return ''
}

function getRequestURL (url, queryParams) {
  let requestUrl = `${baseURL}${apis[url]}`
  if (queryParams && Object.keys(queryParams).length) {
    requestUrl = replaceEmbeddedUrlParam(requestUrl, queryParams)
    const snakeCaseParams = transformObjectKeys(queryParams, camelToSnake)
    requestUrl += jsonToQueryString(snakeCaseParams)
  }

  return requestUrl
}

const apiMiddleware = ({ dispatch }) => next => action => {
  if (!action.type) return
  next(action)

  if (action.type !== ActionTypes.API) return
  let {
    url,
    method,
    body,
    queryParams,
    onSuccess,
    onFailure,
    label,
    headers = {},
    isSecureCall
  } = action.payload
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data'

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || ''
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  if (isSecureCall) {
    axios.defaults.headers.common.Authorization = getAuthToken()
  }

  if (label) {
    dispatch(apiStart(label, body))
  }

  const queryParamsCopy = deepCopy(queryParams)
  const requestURL = getRequestURL(url, queryParamsCopy)

  // Change object keys casing
  body = transformObjectKeys(body, camelToSnake)
  queryParams = transformObjectKeys(queryParams, camelToSnake)

  axios
    .request({
      url: requestURL,
      method,
      headers,
      [dataOrParams]: body
    })
    .then((response) => {
      if (response.status === 204) {
        let camelCaseResponse
        if (method === 'DELETE' || method === 'POST') {
          camelCaseResponse = transformObjectKeys(queryParams, snakeToCamel)
        } else {
          camelCaseResponse = transformObjectKeys(body, snakeToCamel)
        }
        dispatch(onSuccess(camelCaseResponse))
      } else {
        const successResponse = response.data
        const camelCaseResponse = transformObjectKeys(successResponse, snakeToCamel)
        const camelCaseRequest = transformObjectKeys(queryParams, snakeToCamel)
        const camelCaseBody = transformObjectKeys(body, snakeToCamel)
        dispatch(onSuccess(camelCaseResponse, camelCaseRequest, camelCaseBody))
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 500) { // Internal server error
        dispatch(apiError())
      } else if (error.response && error.response.status === 401) { // Unauthorized
        delete axios.defaults.headers.common.Authorization
        clearActiveInterval()
        // dispatch(terminateUserSessions())
      } else if (error.response && error.response.status === 403) { // Permission denied
        dispatch(apiError())
      } else {
        if (error.response) {
          const errorResponse = error.response.data.error
          dispatch(onFailure(getErrorMessage(errorResponse)))
        }
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label))
      }
    })
}

export default apiMiddleware
